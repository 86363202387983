import { Card } from 'primereact/card';
import React, { useEffect, useRef, useState } from 'react';
import { getData } from '../../../Hooks/api';
import { formattedDate, formattedDateWithDay, formattedDateWithOutTime, saveAsExcelFile } from '../../../Hooks/helper';
import { Toast } from 'primereact/toast';
import { TabPanel, TabView } from 'primereact/tabview';
import TableClass from './TableClass';
import { FilterMatchMode, FilterService } from 'primereact/api';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import FormAddUpdateClass from './FormAddUpdateClass';
import Overview from './Overview';


FilterService.register('custom_sesiTerlaksana', (value, filters) => {
  const [from, to] = filters ?? [null, null];
  
  if (from === null && to === null) return true;
  if (from !== null && to === null) return from <= value;
  if (from === null && to !== null) return value <= to;
  return from <= value && value <= to;
});

FilterService.register('custom_sesiBelumTerlaksana', (value, filters) => {
  const [from, to] = filters ?? [null, null];
  if (from === null && to === null) return true;
  if (from !== null && to === null) return from <= value;
  if (from === null && to !== null) return value <= to;
  return from <= value && value <= to;
});

FilterService.register('custom_jadwal', (value, filters) => {
  return RegExp(filters, 'i').test(formattedDateWithDay(value));
});

const ListClass = () => {
  const toast = useRef(null);
  const [ loading, setLoading ] = useState(false);
  const [ loadingFinishClass, setLoadingFinishClass] = useState(false);
  
  // Dialog Handler
  const [ addClassDialog, setAddClassDialog ] = useState(false);

  // Data Handler
  const [ listActiveClass, setListActiveClass ] = useState({
    Aktif: [],
    'Belum PTC': [],
    'Belum RR': [],
    'Belum Diselesaikan': []
  });
  const [ listFinishClass, setListFinishClass ] = useState([]);

  // Filter Handler
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [ filters, setFilters ] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    subject: {value: null, matchMode: FilterMatchMode.CONTAINS },
    'guru.nama': {value: null, matchMode: FilterMatchMode.CONTAINS },
    jenisKelas: {value: null, matchMode: FilterMatchMode.CONTAINS },
    level: { value: null, matchMode: FilterMatchMode.EQUALS },
    materiKelas: {value: null, matchMode: FilterMatchMode.EQUALS },
    jadwal: {value: null, matchMode: FilterMatchMode.CUSTOM },
    statusKelas: { value: null, matchMode: FilterMatchMode.EQUALS },
    sesiTerlaksana: { value: null, matchMode: FilterMatchMode.CUSTOM },
    sesiBelumTerlaksana: { value: null, matchMode: FilterMatchMode.CUSTOM },
    jumlahSiswa: { value: null, matchMode: FilterMatchMode.EQUALS }
  });
  const onGlobalFilterChange = (e) => {
    const value = e.target.value
    let _filters = {...filters}

    _filters['global'].value = value
    setFilters(_filters)
    setGlobalFilterValue(value)
  };

  const getActiveClass = () => {
    setLoading(true);
    getData('class')
    .then((response) => {
      let listActiveClass = response.data;
      
      let activeClassCluster = {
        Aktif: [],
        'Belum PTC': [],
        'Belum RR': [],
        'Belum Diselesaikan': [],
      }

      listActiveClass.forEach((kelas) => {
        let dataListAttendance = kelas.absensi;
        let listSesi = [];
        let terlaksana = 0, belumTerlaksana = 0;
        kelas['jadwalText'] = formattedDate(kelas['jadwal']);

        // Check Attendance
        if (Array.isArray(dataListAttendance) && dataListAttendance.length !== 0) {
          let lengthSession = dataListAttendance[0].session.length;

          // Check accomplished session 
          dataListAttendance.forEach((attendance) => {
            attendance.session.forEach((session) => {
              if (session.status !== "Belum diabsen" && !listSesi.includes(session.sesi)) {
                terlaksana++;
                listSesi.push(session.sesi);
              }
            });
          });

          belumTerlaksana = lengthSession - terlaksana;
        }

        kelas["subject"] = `${kelas.kodeBatch}.${kelas.kodeKelas} ${kelas.namaKelas}`;
        kelas.sesiTerlaksana = terlaksana;
        kelas.sesiBelumTerlaksana = belumTerlaksana;

        switch (kelas.statusKelas) {
          case "Aktif":
            activeClassCluster['Aktif'].push(kelas);
            break;

          case "Belum PTC":
            activeClassCluster['Belum PTC'].push(kelas);
            break;
          
          case "Belum RR":
            activeClassCluster['Belum RR'].push(kelas);
            break;

          case "Belum Diselesaikan":
            activeClassCluster['Belum Diselesaikan'].push(kelas);
            break;
        
          default:
            break;
        }
      });

      setListActiveClass(activeClassCluster);
      setLoading(false);
    })
    .catch((error) => {
      toast.current.show({
        severity: 'error',
        summary: 'Failed Load Data!',
        detail: error.response.data.message,
        life: 3000
      });

      setLoading(false);
    });
  };

  const getFinishClass = () => {
    setLoadingFinishClass(true);

    getData('class-finish')
    .then((response) => {
      let listFinishClass = response.data;

      listFinishClass.forEach((kelas) => {
        kelas["subject"] = `${kelas.kodeBatch}.${kelas.kodeKelas} ${kelas.namaKelas}`;
        kelas["jadwalText"] = formattedDate(kelas['jadwal'])
      });

      setListFinishClass(listFinishClass);
      setLoadingFinishClass(false);
    })
    .catch((error) => {
      toast.current.show({
        severity: 'error',
        summary: 'Failed Load Data!',
        detail: error.response.data.message,
        life: 3000
      });
      
      setLoadingFinishClass(false);
    });
  };

  useEffect(() => {
    getActiveClass();
    getFinishClass();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headerTableTemplate = () => (
    <div className='flex flex-column gap-2'>
        <div className='flex lg:flex-row md:flex-row sm:flex-row flex-column justify-content-between align-items-center gap-2'>
          <h2 className='m-0 p-0 lg:text-left md:text-left sm:text-left text-center'>List Class</h2>
          <div className='flex lg:flex-row md:flex-row flex-column  gap-2'>
            <Button label='Clear' icon='pi pi-filter-slash' size='small' outlined onClick={clearFilter}/>
            <IconField iconPosition="left" className='lg:w-max md:w-max sm:w-max w-full'>
                <InputIcon className="pi pi-search"> </InputIcon>
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" className='p-inputtext-sm w-full'/>
            </IconField>
            <Button label='+ Add' severity='success' size='small' onClick={() => setAddClassDialog(true)}/>
          </div> 
        </div>
    </div>
  );

  const clearFilter = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      subject: {value: null, matchMode: FilterMatchMode.CONTAINS },
      'guru.nama': {value: null, matchMode: FilterMatchMode.CONTAINS },
      jenisKelas: {value: null, matchMode: FilterMatchMode.CONTAINS },
      level: { value: null, matchMode: FilterMatchMode.EQUALS },
      materiKelas: {value: null, matchMode: FilterMatchMode.EQUALS },
      jadwal: {value: null, matchMode: FilterMatchMode.CONTAINS },
      statusKelas: { value: null, matchMode: FilterMatchMode.EQUALS },
      sesiTerlaksana: { value: null, matchMode: FilterMatchMode.CUSTOM },
      sesiBelumTerlaksana: { value: null, matchMode: FilterMatchMode.CUSTOM },
      jumlahSiswa: { value: null, matchMode: FilterMatchMode.EQUALS }
    });
    setGlobalFilterValue('');
  }

  const exportExcel = async (listClass, titleFile) => {
    let data = listClass.map((kelas) => {
      let newFormat;

      if (titleFile === "Finish Class") {
        newFormat = {
          _id: kelas._id,
          Subject: kelas.subject,
          Teacher: kelas.guru.nama,
          'Class Type': kelas.jenisKelas,
          'Class Level': kelas.level,
          'Course Level': kelas.materiKelas,
          Schedule: formattedDateWithDay(kelas.jadwal),
          Status: kelas.statusKelas,
          'Qty Students': kelas.jumlahSiswa
        }
      } else {
        newFormat = {
          _id: kelas._id,
          Subject: kelas.subject,
          Teacher: kelas.guru.nama,
          'Class Type': kelas.jenisKelas,
          'Class Level': kelas.level,
          'Course Level': kelas.materiKelas,
          Schedule: formattedDateWithDay(kelas.jadwal),
          Status: kelas.statusKelas,
          'Sesi Terlaksana': kelas.sesiTerlaksana,
          'Sesi Belum Terlaksana': kelas.sesiBelumTerlaksana,
          'Qty Students': kelas.jumlahSiswa
        }
      }

      return newFormat;
    });

    import('xlsx').then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(data);   
      
        const workbook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(workbook, worksheet, "List Class");
        // { Sheets: { data: worksheet }, SheetNames: ['WAITING CLASS'] };
        const excelBuffer = xlsx.write(workbook, {
            bookType: 'xlsx',
            type: 'array'
        });

        const fileName = `${titleFile} (${formattedDateWithOutTime(new Date())})`;

        saveAsExcelFile(excelBuffer, fileName);
    });
  }

  return (
    <>
      <Toast ref={toast}/>
      <FormAddUpdateClass
        visiblity={addClassDialog}
        setVisibility={() => setAddClassDialog(false)}
        titleDialog="ADD CLASS"
        loading={loading}
        setLoading={setLoading}
        toast={toast}
        refreshAction={() => getActiveClass()}
      />

      <Overview listActiveClass={listActiveClass} listFinishClass={listFinishClass}/>
      <Card>
        <TabView>
          <TabPanel header="Active">
            <TableClass 
              listClass={listActiveClass['Aktif']} 
              setListClass={setListActiveClass}
              loading={loading} 
              setLoading={setLoading}
              toast={toast}
              headerTableTemplate={headerTableTemplate} 
              filters={filters}
              refreshTable={() => getActiveClass()}
              downLoadTable={() => exportExcel(listActiveClass['Aktif'], "Active Class")}
            />
          </TabPanel>
          <TabPanel header="Need PTC">
            <TableClass 
              listClass={listActiveClass['Belum PTC']} 
              setListClass={setListActiveClass}
              loading={loading} 
              setLoading={setLoading}
              toast={toast}
              headerTableTemplate={headerTableTemplate} 
              filters={filters}
              refreshTable={() => getActiveClass()}
              downLoadTable={() => exportExcel(listActiveClass['Belum PTC'], "Need PTC Class")}
            />
          </TabPanel>
          <TabPanel header="Need RR">
            <TableClass 
              listClass={listActiveClass['Belum RR']} 
              setListClass={setListActiveClass}
              loading={loading} 
              setLoading={setLoading}
              toast={toast}
              headerTableTemplate={headerTableTemplate} 
              filters={filters}
              refreshTable={() => getActiveClass()}
              downLoadTable={() => exportExcel(listActiveClass['Belum RR'], "Need RR Class")}
            />
          </TabPanel>
          <TabPanel header="Need End">
            <TableClass 
              listClass={listActiveClass['Belum Diselesaikan']}
              setListClass={setListActiveClass} 
              loading={loading} 
              setLoading={setLoading}
              toast={toast}
              headerTableTemplate={headerTableTemplate} 
              filters={filters}
              refreshTable={() => getActiveClass()}
              downLoadTable={() => exportExcel(listActiveClass['Belum Diselesaikan'], "Need End Class")}
            />
          </TabPanel>
          <TabPanel header="Finish">
            <TableClass 
              listClass={listFinishClass} 
              setListClass={setListFinishClass}
              loading={loadingFinishClass} 
              setLoading={setLoadingFinishClass}
              toast={toast}
              hiddenColumn={['sesiTerlaksana', 'sesiBelumTerlaksana']} 
              headerTableTemplate={headerTableTemplate} 
              filters={filters}
              refreshTable={() => getFinishClass()}
              downLoadTable={() => exportExcel(listFinishClass, "Finish Class")}
            />
          </TabPanel>
        </TabView>
      </Card>
    </>
  )
}

export default ListClass
