import React from 'react'

const NotFound = () => {
  return (
    <div className='flex flex-column justify-content-center align-items-center w-screen h-screen text-center'>
        <img src='/svg/not-found.svg' alt='Not Found' className='max-w-30rem w-10' />
        <h1 className='lg:text-4xl text-2xl'>Oops, the page you visited was not found!</h1>
    </div>
  )
}

export default NotFound
