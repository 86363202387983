import { TabPanel, TabView } from 'primereact/tabview';
import React, { useRef } from 'react';
import UnverifiedClass from './UnverifiedClass';
import { Toast } from 'primereact/toast';
import TransferClass from './TransferClass.js';

const ClassOperation = () => {
  const toast = useRef(null);

  return (
    <>
      <Toast ref={toast}/>
      <TabView>
        <TabPanel header="Unverified Class">
          <UnverifiedClass toast={toast}/>
        </TabPanel>
        <TabPanel header="Transfer Class">
          <TransferClass toast={toast}/>
        </TabPanel>
      </TabView>
    </>
  )
}

export default ClassOperation
