import { Card } from 'primereact/card'
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable'
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react'
import WALinkTemplate from '../../../Components/TagTemplate/WALinkTemplate';
import { FilterMatchMode } from 'primereact/api';
import { getData } from '../../../Hooks/api';
import { formatTTL } from '../../../Hooks/helper';
import RRClusterTemplate from '../../../Components/TagTemplate/RRClusterTemplate';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import Overview from './Overview';
import FormUpdateTeacher from './FormUpdateTeacher';
import { Link } from 'react-router-dom';

const ListTeacher = () => {
  const toast= useRef(null);
  const [ loading, setLoading ] = useState(false);
  const [ nameFreeze, setNameFreeze ] = useState(false);
  
  // Data Handler
  const [ listTeachers, setListTeachers ] = useState([]);
  const [ detailTeacher, setDetailTeacher ] = useState({});

  // Dialog Handler 
  const [ updateTeacherDialog, setUpdateTeacherDialog ] = useState(false);

  // Filter Handler
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const getAllTeachers = async () => {
    setLoading(true);

    getData('teachers')
    .then((response) => {
      let teachers = response.data.filter(teacher => teacher.status !== 'Belum terverifikasi');
      teachers.forEach(teacher => {
        teacher.qtyKelas = teacher.kelas.length;
      });

      setListTeachers(teachers);
      setLoading(false);
    })
    .catch((error) => {
      toast.current.show({
        severity: 'error',
        summary: 'Failed Load Data',
        detail: error.response.data.message,
        life: 3000
      });
      setLoading(false);
    });
  }

  useEffect(() => {
    getAllTeachers();
  }, []);

  const headerTableTemplate = () => (
    <div className='flex flex-column gap-2'>
        <div className='flex lg:flex-row md:flex-row sm:flex-row flex-column justify-content-between align-items-center gap-2'>
          <h2 className='m-0 p-0 lg:text-left md:text-left sm:text-left text-center'>List Teacher</h2>
          <div className='flex lg:flex-row md:flex-row flex-column  gap-2'>
            <IconField iconPosition="left" className='lg:w-max md:w-max sm:w-max w-full'>
                <InputIcon className="pi pi-search"> </InputIcon>
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" className='p-inputtext-sm w-full'/>
            </IconField>
          </div> 
        </div>
    </div>
  );

  const headerNameTemplate = () => (
    <div className='flex justify-content-between align-items-center gap-3'>
      <span>Name</span> 
      <i 
        className={ nameFreeze ? 'pi pi-lock cursor-pointer' : 'pi pi-lock-open cursor-pointer' } 
        onClick={() => setNameFreeze(!nameFreeze)}
      />
    </div>
  );

  const actionBodyTemplate = (rowData) => (
    <div className='flex gap-1'>
      <Link to={`/teacher-management/list/${rowData._id}`}><Button icon='pi pi-eye' severity='success' size='small' outlined/></Link>
      <Button icon='pi pi-pencil' outlined severity='info' size='small' onClick={() => {
        setDetailTeacher(rowData);
        setUpdateTeacherDialog(true);
      }}/>
    </div>
  )

  return (
    <>
      <Toast ref={toast} />
      <Overview listTeacher={listTeachers}/>
      <FormUpdateTeacher
        visible={updateTeacherDialog}
        visibleHandler={() => {
          setUpdateTeacherDialog(false);
          setDetailTeacher({});
        }}
        detailTeacherAccount={detailTeacher}
        loading={loading}
        setLoading={setLoading}
        toast={toast}
        listTeachers={listTeachers}
        setListTeachers={setListTeachers}
      />

      <Card>
        <DataTable
          value={listTeachers}
          scrollable
          removableSort
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          loading={loading}
          filters={filters}
          globalFilterFields={[
            'nama', 
            'nomor', 
            'domisili', 
            'pendidika', 
            'tempatLahir', 
            'tanggalLahir', 
            'namaBank', 
            'rekening', 
            'status'
          ]}
          header={headerTableTemplate}
          className='lg:text-sm md:text-sm sm:text-sm text-xs p-datatable-sm'
        >
          <Column header="No" body={(data, Options) => Options.rowIndex + 1} />
          <Column 
            field='nama'
            header={headerNameTemplate}
            className='none-dragable'
            frozen={nameFreeze}
            style={{minWidth: '12rem'}}
          />
          <Column
            field='nomor'
            header='Contact'
            sortable
            body={(e) => <WALinkTemplate data={e.nomor}/>}
            align='center'
            style={{minWidth: '8rem'}}
          />
          <Column
            field='domisili'
            header='Domicile'
            sortable
            className='none-dragable'
            style={{minWidth: '10rem'}}
          />
          <Column 
            header="TTL" 
            body={(e) => formatTTL(e.tempatLahir, e.tanggalLahir)} 
            alignHeader='center'
            className='none-dragable'
            style={{ minWidth: '14rem' }}
          />
          <Column
            field='pendidikan'
            header='Education'
            className='none-dragable'
            style={{minWidth: '14rem'}}
          />
          <Column
            field='namaBank'
            header='Bank'
            sortable
            className='none-dragable'
            style={{minWidth: '10rem'}}
          />
          <Column
            field='rekening'
            header='Bank Account'
            sortable
            className='none-dragable'
            style={{minWidth: '10rem'}}
          />
          <Column
            field='atasNamaRekening'
            header='Bank username'
            sortable
            body={(e) => e.atasNamaRekening ? e.atasNamaRekening: e.nama }
            className='none-dragable'
            style={{minWidth: '10rem'}}
          />
          <Column
            field='retentionRate'
            header='RR'
            sortable
            body={(e) => <RRClusterTemplate rr={ parseFloat(e.retentionRate).toFixed(2) }/> }
            align='center'
            className='none-dragable'
            style={{minWidth: '6rem'}}
          />
          <Column
            field='qtyKelas'
            header='Class'
            sortable
            align='center'
            className='none-dragable'
            style={{minWidth: '6rem'}}
          />
          <Column
            body={actionBodyTemplate}
            style={{minWidth: '8rem'}}
          />
        </DataTable>
      </Card>
    </>
  )
}

export default ListTeacher
