import { Dialog } from 'primereact/dialog'
import React, { useEffect, useState } from 'react'
import { putData } from '../../../Hooks/api';
import { Field, Form } from 'react-final-form';
import { Dropdown } from 'primereact/dropdown';
import { getFormErrorMessage, teacherType } from '../../../Hooks/helper';
import { Button } from 'primereact/button';

const VerifiedTeacher = ({
  visible,
  visibleHandler,
  detailTeacherAccount,
  loading,
  setLoading,
  refreshAction,
  toast,
  listTeacher,
  setListTeacher,
  listTeachersUnverified,
  setListTeacherUnverified
}) => {
  const [  initialValues, setInitialValues ] = useState({
    _id: '',
    nama: '',
    email: '',
    typeTeacher: ''
  });

  useEffect(() => {
    if (detailTeacherAccount._id) { 
        const getTeacherStatus = (superTeacher, volunteerTeacher) => {
            let typeTeacher;
            
            if (superTeacher) typeTeacher = "Super Teacher";
            else if (volunteerTeacher) typeTeacher = "Volunteer Teacher";
            else typeTeacher = "Premium Teacher";

            return typeTeacher
        }

        setInitialValues({
            ...detailTeacherAccount, 
            typeTeacher: getTeacherStatus(detailTeacherAccount.superTeacher, detailTeacherAccount.volunteerTeacher)
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailTeacherAccount]);

  const updateTeacher = async (data, form) => {
    setLoading(true);

    switch (data.typeTeacher) {
        case "Premium Teacher":
            data.volunteerTeacher = false;
            data.superTeacher = false;
            break;
        
        case "Super Teacher": 
            data.volunteerTeacher = false;
            data.superTeacher = true;
            break;
        
        case "Volunteer Teacher":
            data.volunteerTeacher = true;
            data.superTeacher = false;
            break;
    
        default:
            break;
    }

    data.status = "Aktif";
    
    putData(`teachers/${data._id}`, data)
    .then(() => {
        setTimeout(() => {
            // refreshAction();
            let index = listTeachersUnverified.findIndex(teacher => teacher._id === detailTeacherAccount._id);
            listTeachersUnverified.splice(index, 1);
            setListTeacherUnverified(listTeachersUnverified);

            listTeacher.push(data);
            setListTeacher(listTeacher);


            form.restart();
            toast.current.show({
                severity: 'success',
                summary: 'Update Account Success',
                detail: 'Account successfully updated, Joss!.',
                life: 3000
            });

            visibleHandler();
            setLoading(false);
        }, 800);
    })
    .catch((error) => {
        toast.current.show({
            severity: 'error',
            summary: 'Update Failed',
            detail: error.response.data.message,
            life: 3000
        });
        setLoading(false);
    });
  }
  
  const validateVerified = async (data) => {
    let errors = {};
    if (!data.teacherType) errors.teacherType = "Type teacher is required";
    return errors;
  }

  return (
    <Dialog
      visible={visible}
      onHide={visibleHandler}
      header="Verified Teacher"
      style={{ width: '32rem'}}
      breakpoints={{ '960px': '75vm', '641px': '90vw' }}
    >
        <Form
            initialValues={initialValues}
            onSubmit={updateTeacher}
            validate={validateVerified}
            render={({handleSubmit}) => (
                <form onSubmit={handleSubmit} className='flex flex-column gap-3 p-fluid'>
                    <Field
                        name='typeTeacher'
                        render={({input, meta}) => (
                            <div>
                                <label className='font-bold block mb-2'>Teacher Type</label>
                                <Dropdown
                                    id='typeTeacher'
                                    name='typeTeacher'
                                    {...input}
                                    // itemTemplate={(e) => <TeacherTypeTemplate superTeacher={} volunteerTeacher={} />}
                                    options={teacherType}
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />
                    <div className='flex lg:justify-content-end md:justify-content-end sm:justify-content-end justify-content-center gap-2'>
                        <Button size='small' type='button' label='Cancel' severity='danger' className='w-max' outlined loading={loading} onClick={visibleHandler} />
                        <Button size='small' type='submit' label='Update' severity='primary' className='w-max' loading={loading} />
                    </div>
                </form>
            )}
        />
    </Dialog>
  )
}

export default VerifiedTeacher
