import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { putData } from '../../../../../Hooks/api';
import { getFormErrorMessage } from '../../../../../Hooks/helper';
import { Button } from 'primereact/button';

const GenerateSalary = ({
  visible,
  visibleHandler,
  refreshAction,
  toast,
  loading,
  setLoading,
  idSalary
}) => {
  const initialValues = {
    idSalary: idSalary,
    dataBulan: "",
  }

  const generateSalary = async (data, form) => {
    setLoading(true);

    putData('update-salary', data)
    .then(() => {
        setTimeout(() => {
            refreshAction();
            form.restart();
            toast.current.show({
                severity: 'success',
                summary: 'Add Success',
                detail: 'Salary successfully added, Yippii...',
                life: 3000
            });
            
            if (!loading) visibleHandler();
        }, 800);
    })
    .catch((error) => {
        toast.current.show({
            severity: 'error',
            summary: 'Add Failed',
            detail: error.response.data.message,
            life: 3000
        });
        setLoading(false);
    })
  }

  const validateGenerateSalary = (data) => {
    let errors = {};
    if (!data.dataBulan) errors.dataBulan = "Payday date is required";
    return errors;
  }

  return (
    <Dialog 
      visible={visible}
      onHide={visibleHandler}
      header="Generate Salary"
      style={{ width: '32rem' }}
      breakpoints={{ '960px': '75vm', '641px': '90vw' }}
    >
        <Form
            initialValues={initialValues}
            onSubmit={generateSalary}
            validate={validateGenerateSalary}
            render={({handleSubmit}) => (
                <form onSubmit={handleSubmit} className='flex flex-column gap-3 p-fluid'>
                    <Field
                        name='dataBulan'
                        render={({input, meta}) => (
                            <div className='flex-auto'>
                                <label className='font-bold block mb-2'>Payday Date</label>
                                <Calendar
                                    id='dataBulan'
                                    name='dataBulan'
                                    {...input}
                                    showIcon
                                    dateFormat='dd MM yy'
                                    disabled={loading}
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />
                    <div className='flex lg:justify-content-end md:justify-content-end sm:justify-content-end justify-content-center gap-2'>
                        <Button size='small' type='button' label='Cancel' severity='danger' className='w-max' outlined loading={loading} onClick={visibleHandler} />
                        <Button size='small' type='submit' label='Add' severity='primary' className='w-max' loading={loading} />
                    </div>
                </form>
            )}
        />
    </Dialog>
  )
}

export default GenerateSalary
