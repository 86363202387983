import { Tag } from 'primereact/tag'
import React, { useEffect, useState } from 'react'
import { getSeverity } from '../../Hooks/helper';

const TeacherTypeTemplate = ({superTeacher, volunteerTeacher}) => {
  const [ teacherStatus, setTeacherStatus ] = useState("");

  const getTeacherStatus = () => {
    if (superTeacher) setTeacherStatus("Super Teacher");
    else if (volunteerTeacher) setTeacherStatus("Volunteer Teacher");
    else setTeacherStatus("Premium Teacher")
  }
  
  useEffect(() => {
    getTeacherStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [superTeacher, volunteerTeacher]);
  
  return (
    <Tag value={teacherStatus} style={{color: 'white', backgroundColor: getSeverity("teacherType", teacherStatus)}} className='text-center'></Tag>
  )
}

export default TeacherTypeTemplate
