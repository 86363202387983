import React, { useRef, useState } from 'react';
import Jumbotron from './Jumbotron';
import OperationPanel from './OperationPanel';
import { Toast } from 'primereact/toast';
import FinancePanel from './FinancePanel';

const Dashboard = () => {
  const toast = useRef(null);
  const [loading, setLoading ] = useState(false);

  return (
    <>
      <Toast ref={toast} />
      <Jumbotron />
      <OperationPanel />
      <FinancePanel loading={loading} setLoading={setLoading} toast={toast}/>
    </>
  )
}

export default Dashboard
