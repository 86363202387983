import { Dialog } from 'primereact/dialog';
import { Panel } from 'primereact/panel';
import React, { useEffect, useState } from 'react';
import ClassTypeTemplate from '../../../Components/TagTemplate/ClassTypeTemplate';
import ClassStatusTemplate from '../../../Components/TagTemplate/ClassStatusTemplate';
import WALinkTemplate from '../../../Components/TagTemplate/WALinkTemplate';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';

const DetailClassCR = ({
  visible,
  visibleHandler,
  detailCR,
}) => {
  const [ detailClass, setDetailClass ] = useState({});
  const [ detailStudent, setDetailStudent ] = useState({});

  useEffect(() => {
    if (detailCR) { 
        if (detailCR.class) setDetailClass(detailCR.class);
        if (detailCR.student) setDetailStudent(detailCR.student); 
    }
  }, [detailCR]);

  const headerTemplate = (type) => {
    if (type === "CLASS") return (
        <div className='flex gap-2'>
            <Link to={`/class-management/list/${detailClass._id}`}><i className='pi pi-eye cursor-pointer'></i></Link>
            <p className='p-0 m-0'>Class</p>
        </div>
    )

    if (type === "STUDENT") return (
        <div className='flex gap-2'>
            <Link to={`/student-management/list/${detailStudent._id}`}><i className='pi pi-eye'></i></Link>
            <p className='p-0 m-0'>Student</p>
        </div>
    )
  }

  return (
    <Dialog
        visible={visible}
        onHide={visibleHandler}
        header="Active Registration"
        style={{ width: '45rem' }}
        breakpoints={{ '960px': '75vm', '641px': '90vw' }}
    >
        <div className='flex lg:flex-row md:flex-row flex-column gap-2'>
            <Panel header={headerTemplate("CLASS")} toggleable className='col'>
                <div className='flex flex-column gap-2 w-full'>
                    <div className='flex align-items-center gap-3'>
                        <i className='pi pi-briefcase text-4xl lg:block md:block sm:block hidden'></i>
                        <div>
                            <label className='flex align-items-center font-bold'><i className='pi pi-briefcase font-bold lg:hidden md:hidden sm:hidden mr-2'></i>Subject</label>
                            <p className='p-0 m-0 flex align-items-center gap-1'>{detailClass.kodeBatch}.{detailClass.kodeKelas} {detailClass.namaKelas} <ClassTypeTemplate data={detailClass.jenisKelas}/></p>
                        </div>
                    </div>
                    <div className='flex align-items-center gap-3'>
                        <i className='pi pi-tag text-4xl lg:block md:block sm:block hidden'></i>
                        <div>
                            <label className='flex align-items-center font-bold'><i className='pi pi-tag font-bold lg:hidden md:hidden sm:hidden mr-2'></i>Class Status</label>
                            <ClassStatusTemplate data={detailClass.statusKelas}/>
                        </div>
                    </div>
                    <div className='flex align-items-center gap-3'>
                        <i className='pi pi-graduation-cap text-4xl lg:block md:block sm:block hidden'></i>
                        <div>
                            <label className='flex align-items-center font-bold'><i className='pi pi-graduation-cap font-bold lg:hidden md:hidden sm:hidden mr-2'></i>Teachers</label>
                            <p className='p-0 m-0'>{detailClass.guru?.nama}</p>
                        </div>
                    </div>
                    <div className='flex align-items-center gap-3'>
                        <i className='pi pi-map text-4xl lg:block md:block sm:block hidden'></i>
                        <div>
                            <label className='flex align-items-center font-bold'><i className='pi pi-map font-bold lg:hidden md:hidden sm:hidden mr-2'></i>Class Level</label>
                            <p className='p-0 m-0'>Level {detailClass.level}</p>
                        </div>
                    </div>
                    <div className='flex align-items-center gap-3'>
                        <i className='pi pi-book text-4xl lg:block md:block sm:block hidden'></i>
                        <div>
                            <label className='flex align-items-center font-bold'><i className='pi pi-book font-bold lg:hidden md:hidden sm:hidden mr-2'></i>Course Level</label>
                            <p className='p-0 m-0'>Level {detailClass.materiKelas}</p>
                        </div>
                    </div>
                </div>
            </Panel>
            <div className='flex flex-column gap-2 col'>
                <Panel header={headerTemplate("STUDENT")} toggleable>
                    <div className='flex flex-column gap-2 w-full'>
                        <div className='flex align-items-center gap-3'>
                            <i className='pi pi-user text-4xl lg:block md:block sm:block hidden'></i>
                            <div>
                                <label className='flex align-items-center font-bold'><i className='pi pi-user font-bold lg:hidden md:hidden sm:hidden mr-2'></i>Student Name</label>
                                <p className='p-0 m-0 flex align-items-center gap-1'>{detailStudent.nama}</p>
                            </div>
                        </div>
                        <div className='flex align-items-center gap-3'>
                            <i className='pi pi-wave-pulse text-4xl lg:block md:block sm:block hidden'></i>
                            <div>
                                <label className='flex align-items-center font-bold'><i className='pi pi-wave-pulse font-bold lg:hidden md:hidden sm:hidden mr-2'></i>Age</label>
                                <p className='p-0 m-0'>{detailStudent.usia} years old</p>
                            </div>
                        </div>
                        <div className='flex align-items-center gap-3'>
                            <i className='pi pi-whatsapp text-4xl lg:block md:block sm:block hidden'></i>
                            <div>
                                <label className='flex align-items-center font-bold'><i className='pi pi-whatsapp font-bold lg:hidden md:hidden sm:hidden mr-2'></i>Contact</label>
                                <WALinkTemplate data={detailStudent.nomor}/>
                            </div>
                        </div>
                    </div>
                </Panel>
                <Button 
                label='Ok' 
                size='small' 
                className='align-self-center w-6' 
                onClick={visibleHandler}
                />
            </div>
            
        </div>
    </Dialog>
  )
}

export default DetailClassCR
