import { Dialog } from 'primereact/dialog'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form';
import { putData } from '../../../Hooks/api';
import { Dropdown } from 'primereact/dropdown';
import { getFormErrorMessage, teacherStatus, teacherType } from '../../../Hooks/helper';
import { Button } from 'primereact/button';
import TeacherStatusTemplate from '../../../Components/TagTemplate/TeacherStatusTemplate';

const FormUpdateAccount = ({
  visible,
  visibleHandler,
  detailTeacherAccount,
  loading,
  setLoading,
  toast,
  listTeachers, 
  setListTeachers
}) => {
  const [ initialValues, setInitialValues ] = useState({
    _id: '',
    nama: '',
    email: '',
    typeTeacher: '',
    status: ''
  });

  useEffect(() => {
    if (detailTeacherAccount._id) { 
        const getTeacherStatus = (superTeacher, volunteerTeacher) => {
            let typeTeacher;
            
            if (superTeacher) typeTeacher = "Super Teacher";
            else if (volunteerTeacher) typeTeacher = "Volunteer Teacher";
            else typeTeacher = "Premium Teacher";

            return typeTeacher
        }

        setInitialValues({
            ...detailTeacherAccount, 
            typeTeacher: getTeacherStatus(detailTeacherAccount.superTeacher, detailTeacherAccount.volunteerTeacher)
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailTeacherAccount]);

  const updateTeacher = async (data, form) => {
    setLoading(true);

    switch (data.typeTeacher) {
        case "Premium Teacher":
            data.volunteerTeacher = false;
            data.superTeacher = false;
            break;
        
        case "Super Teacher": 
            data.volunteerTeacher = false;
            data.superTeacher = true;
            break;
        
        case "Volunteer Teacher":
            data.volunteerTeacher = true;
            data.superTeacher = false;
            break;
    
        default:
            break;
    }
    
    putData(`teachers/${data._id}`, data)
    .then(() => {
        let index = listTeachers.findIndex((teacher) => teacher._id === detailTeacherAccount._id);
        listTeachers[index] = {
            ...detailTeacherAccount,
            superTeacher: data.superTeacher,
            volunteerTeacher: data.volunteerTeacher,
            status: data.status
        }

        setTimeout(() => {
            setListTeachers(listTeachers);
            form.restart();
            toast.current.show({
                severity: 'success',
                summary: 'Update Account Success',
                detail: 'Account successfully updated, Joss!.',
                life: 3000
            });

            setLoading(false);
            visibleHandler();
        }, 800);
    })
    .catch((error) => {
        toast.current.show({
            severity: 'error',
            summary: 'Update Failed',
            detail: error.response.data.message,
            life: 3000
        });
        setLoading(false);
    });
  }

  const validateUpdateTeacher = (data) => {
    let errors = {};

    if (!data.typeTeacher) errors.typeTeacher = "Type teacher is required";
    if (!data.status) errors.status = "Teacher status is required";

    return errors;
  }

  return (
    <Dialog
        visible={visible}
        onHide={visibleHandler}
        header="Update Account"
        style={{ width: '32rem' }}
        breakpoints={{ '960px': '75vm', '641px': '90vw' }}
    >
        <Form
            initialValues={initialValues}
            onSubmit={updateTeacher}
            validate={validateUpdateTeacher}
            render={({handleSubmit}) => (
                <form onSubmit={handleSubmit} className='flex flex-column gap-3 p-fluid'>
                    <Field
                        name='typeTeacher'
                        render={({input, meta}) => (
                            <div>
                                <label className='font-bold block mb-2'>Teacher Type</label>
                                <Dropdown
                                    id='typeTeacher'
                                    name='typeTeacher'
                                    {...input}
                                    // itemTemplate={(e) => <TeacherTypeTemplate superTeacher={} volunteerTeacher={} />}
                                    options={teacherType}
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />
                    <Field
                        name='status'
                        render={({input, meta}) => (
                            <div>
                                <label className='font-bold block mb-2'>Teacher Status</label>
                                <Dropdown
                                    id='status'
                                    name='status'
                                    {...input}
                                    options={teacherStatus}
                                    itemTemplate={(e) => <TeacherStatusTemplate teacherStatus={e} />}
                                    valueTemplate={(e) => <TeacherStatusTemplate teacherStatus={e} />}
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />
                    <div className='flex lg:justify-content-end md:justify-content-end sm:justify-content-end justify-content-center gap-2'>
                        <Button size='small' type='button' label='Cancel' severity='danger' className='w-max' outlined loading={loading} onClick={visibleHandler} />
                        <Button size='small' type='submit' label='Update' severity='primary' className='w-max' loading={loading} />
                    </div>
                </form>
            )}
        />
    </Dialog>
  )
}

export default FormUpdateAccount
