import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { Tag } from 'primereact/tag';
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { getData, deleteData } from '../../Hooks/api';
import { Card } from 'primereact/card';
import AddUpdateForm from './AddUpdateForm';
import DestroyDialog from '../../Components/ConfirmDialog/DestroyDialog';
import { Toast } from 'primereact/toast';
import { getSeverity } from '../../Hooks/helper';
import GenerateClassRoom from './GenerateClassRoom';
import DetailClassUser from './DetailClassUser';

const RoomManagement = () => {
  const room = useRef(null);
  const toast = useRef(null);

  // Data Handler
  const [ listRoom, setListRoom ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ detailRoom, setDetailRoom ] = useState({
    _id: null,
    namaRoom: '',
    linkRoom: '',
    meetingID: '',
    passCode: '',
    hostKey: '',
    status: ''
  });
  const [ detailClassUser, setDetailClassUser ] = useState({
    idClass: "",
    startSession: "",
    endSession: ""
  })

  // Dialog Handler 
  const [ addUpdateDialog, setAddUpdateDialog ] = useState(false);
  const [ destroyDialog, setDestroyDialog ] = useState(false);
  const [ generateDialog, setGenerateDialog ] = useState(false);
  const [ detailClassDialog, setDetailClassDialog ] = useState(false);

  const getAllRoom = async () => {
    setLoading(true)

    getData('rooms')
    .then((response) => {
        setListRoom(response.data)
        setLoading(false)
     })
     .catch((error) => {
        setLoading(false)
        toast.current.show({
          severity: 'error',
          summary: 'Load Failed',
          detail: error.response.data.message,
          life: 3000
      });
     })
  } 

  const destroyRoom = async () => {
    setLoading(true);

    deleteData(`rooms/${detailRoom._id}`, detailRoom)
    .then(() => {
      getAllRoom();
      toast.current.show({
        severity: 'success',
        summary: 'Delete Success',
        detail: 'Room successfully deleted.',
        life: 3000
      });

      if (!loading) hideDialog("DESTROY")
    })
    .catch((error) => {
      setLoading(false);
      toast.current.show({
        severity: 'error',
        summary: 'Delete Failed',
        detail: error.response.data.message,
        life: 3000
    });
    });
  }

  useEffect(() => {
    getAllRoom();
  }, []);

  // Filter Handler
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const headerTemplate = () => {
    return (
        <div className='flex lg:flex-row md:flex-row sm:flex-row flex-column align-items-center justify-content-between'>
          <h2 className='m-0 p-0 font-medium'>List Room</h2>
          <div className='flex lg:flex-row md:flex-row sm:flex-row flex-column lg:w-max md:w-max sm:w-max w-full align-items-center gap-2'>
            <IconField iconPosition="left" className='w-full'>
                <InputIcon className="pi pi-search"> </InputIcon>
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search Room" className="p-inputtext-sm w-full" />
            </IconField>
            <div className='flex gap-1 w-full'>
              <Button size='small' className='w-full flex justify-content-center' severity='success' onClick={() => showDialog(null, "ADD_UPDATE")}>Add Room +</Button>
              <Button size='small' severity='info' icon='pi pi-cog' onClick={() => showDialog(null, "GENERATE")}/>
            </div>
          </div>
        </div>
    );
  }

  const actionBodyTemplate = (rowdata) => {
    let listProperties = {}
    rowdata.status !== "Used" ? listProperties= {
      outlined: false,
      icon: "pi pi-eye-slash",
      disabled: true,
    } : listProperties = {
      outlined: true,
      icon: "pi pi-eye",
      disabled: false,
    }

    return (
      <React.Fragment>
        <Button 
          icon="pi pi-pencil" 
          rounded 
          outlined 
          severity='info' 
          className='mr-2' 
          onClick={() => showDialog(rowdata, "ADD_UPDATE")}
        />
        <Button 
          icon="pi pi-trash" 
          rounded 
          outlined 
          severity='danger'  
          onClick={() => showDialog(rowdata, "DESTROY")}
        />
        <Button 
          icon={listProperties.icon} 
          className='ml-2' 
          rounded 
          outlined={listProperties.outlined} 
          disabled={listProperties.disabled}
          severity='success' 
          onClick={() => showDialog(rowdata, "DETAIL_CLASS")}
        />
      </React.Fragment>
    )
  }

  const showDialog = (data, type) => {
    if (data && data !== null) { 
      if (type !== "DETAIL_CLASS") setDetailRoom(data);
      else setDetailClassUser({
        idClass: data.kelasPengguna,
        startSession: data.mulaiKelas,
        endSession: data.berakhirKelas
      });
    }

    switch (type) {
      case "ADD_UPDATE":
        setAddUpdateDialog(true);
        break;

      case "DESTROY":
        setDestroyDialog(true);
        break;
      
      case "GENERATE":
        setGenerateDialog(true);
        break;

      case "DETAIL_CLASS": 
        setDetailClassDialog(true);
        break;
    
      default:
        break;
    }
  }

  const hideDialog = (type) => {
    setDetailRoom({
      _id: null,
      namaRoom: '',
      linkRoom: '',
      meetingID: '',
      passCode: '',
      hostKey: '',
      status: ''
    });

    switch (type) {
      case "ADD_UPDATE":
        setAddUpdateDialog(false);
        break;
    
      case "DESTROY":
        setDestroyDialog(false);
        break;

      case "GENERATE":
        setGenerateDialog(false);
        break;

      case "DETAIL_CLASS":
        setDetailClassUser({
          idClass: "",
          startSession: "",
          endSession: ""
        });
        setDetailClassDialog(false);
        break;
        
      default:
        break;
    }
  }

  const linkRoomTemplate = (data) => (
    <Link to={data.linkRoom} target='_blank'><Tag value={data.linkRoom} severity='info' /></Link>
  );

  const statusLabelTemplate = (data) => ( 
    <Tag value={data.status} severity={getSeverity("roomStatus", data.status)}/>
  )

  const classUserLabel = (data) => (
    data.kelasPengguna === null ? <Tag value="Tidak Ada Pengguna" severity='success'/> :
    <Link to={`class-management/${data.kelasPengguna}`}>
      <Tag value={data.kelasPengguna} severity="warning"/>
    </Link>
  )

  const exportCSV = (selectionOnly) => {
    room.current.exportCSV({selectionOnly})
  }

  const paginatorLeft = <Button type="button" icon="pi pi-refresh" text onClick={getAllRoom} />;
  const paginatorRight = <Button type="button" icon="pi pi-download" text onClick={() => exportCSV(false)} />;

  return (
    <div>
      <Toast ref={toast} />

      <AddUpdateForm 
        visible={addUpdateDialog} 
        visibleHandler={() => hideDialog("ADD_UPDATE")} 
        toast={toast}
        detailRoom={detailRoom}
        loading={loading}
        setLoading={setLoading}
        refreshAction={() => getAllRoom()}
      />

      <DestroyDialog 
        visible={destroyDialog}
        visibleHandler={() => hideDialog("DESTROY")}
        acceptAction={() => destroyRoom()}
        rejectAction={() => hideDialog("DESTROY")}
        titleConfirmation="Destroy Room"
        messageConfirmation="Are you sure to destroy the room?"
        acceptLabelButton="Destroy"
        loading={loading}
      />

      <GenerateClassRoom
        visible={generateDialog}
        visibleHandler={() => hideDialog("GENERATE")}
        toast={toast}
        refreshAction={() => getAllRoom()}
        loading={loading}
        setLoading={setLoading}
      />

      <DetailClassUser
        visibility={detailClassDialog}
        visibleHandler={() => hideDialog("DETAIL_CLASS")}
        idClass={detailClassUser.idClass}
        startSession={detailClassUser.startSession}
        endSession={detailClassUser.endSession}
        toast={toast}
      />

      <Card>
        <DataTable 
          value={listRoom} 
          tablestyle={{minwidth:'50rem'}} 
          loading={loading}
          paginator 
          rows={5} 
          rowsPerPageOptions={[5, 10, 25, 50]} 
          scrollable
          removableSort
          filters={filters} 
          globalFilterFields={[
            'namaRoom', 
            'linkRoomm', 
            'meetingID', 
            'passCode', 
            'hostKey', 
            'mulaiKelas', 
            'berakhirKelas', 
            'status', 
            'kelasPengguna'
          ]}
          header={headerTemplate} 
          ref={room} 
          paginatorLeft={paginatorLeft} 
          paginatorRight={paginatorRight}
        >
          <Column field="namaRoom" sortable header="Nama Room" className='none-dragable' style={{ minWidth: '10rem' }}></Column>
          <Column field="linkRoom" sortable header="Link Room" body={linkRoomTemplate} className='none-dragable' style={{ minWidth: '15rem' }}></Column>
          <Column field="status" sortable header="Status" body={statusLabelTemplate} className='none-dragable' style={{ minWidth: '5rem' }}></Column>
          <Column field="meetingID" sortable header="Meeting ID" className='none-dragable' style={{ minWidth: '9rem' }}></Column>
          <Column field="passCode" sortable header="Pass Code" className='none-dragable' style={{ minWidth: '9rem' }}></Column>
          <Column field="hostKey" sortable header="Host Key" className='none-dragable' style={{ minWidth: '9rem' }}></Column>
          <Column field="kelasPengguna" sortable header="Kelas Pengguna" body={classUserLabel} className='none-dragable' style={{ minWidth: '12rem' }}></Column>
          <Column body={actionBodyTemplate} style={{minWidth: '12rem'}}></Column>
        </DataTable>
      </Card>
    </div>
  )
}

export default RoomManagement
