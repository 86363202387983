import React, { useEffect, useRef, useState } from 'react'
import { deleteData, getData } from '../../../Hooks/api'
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode } from 'primereact/api';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import TeacherTypeTemplate from '../../../Components/TagTemplate/TeacherTypeTemplate';
import TeacherStatusTemplate from '../../../Components/TagTemplate/TeacherStatusTemplate';
import ResetPassword from './ResetPassword';
import FormUpdateAccount from './FormUpdateAccount';
import { formattedDateWithOutTime, saveAsExcelFile } from '../../../Hooks/helper';
import { TabPanel, TabView } from 'primereact/tabview';
import DestroyDialog from '../../../Components/ConfirmDialog/DestroyDialog';
import VerifiedTeacher from './VerifiedTeacher';

const TeacherAccount = () => {
  const toast = useRef(null);
  const [ loading, setLoading ] = useState(false);

  // Data Handler
  const [ listTeachers, setListTeachers ] = useState([]);
  const [ listTeachersUnverified, setListTeacherUnverified ] = useState(false);
  const [ detailAccountTeacher, setDetailAccountTeacher ] = useState({});
  
  // Dialog Handler
  const [ resetPasswordDialog, setResetPasswordDialog ] = useState(false);
  const [ updateAccountDialog, setUpdateAccountDialog ] = useState(false);
  const [ rejectAccountDialog, setRejectAccountDialog ] = useState(false);
  const [ verifiedAccountDialog, setVerifiedAccountDialog ] = useState(false);

  // Filter Handler
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const getAllTeachers = async () => {
    setLoading(true);

    getData('teachers')
    .then((response) => {
      let teachers = response.data;

      teachers.map((teacher) => {
        teacher.teacherType = teacher.volunteerTeacher ? "Volunteer Teacher" : teacher.superTeacher ? "Super Teacher" : "Premium Teacher"
        return teacher;
      });

      setListTeacherUnverified(teachers.filter(teacher => teacher.status === "Belum terverifikasi"));
      setListTeachers(teachers.filter(teacher => teacher.status !== "Belum terverifikasi"));
      setLoading(false);
    })
    .catch((error) => {
      toast.current.show({
        severity: 'error',
        summary: 'Failed Load Data!',
        detail: error.response.data.message,
        life: 3000
      });
      setLoading(false);
    });
  };

  const rejectTeacherRegistration = async () => {
    setLoading(true);
    deleteData(`teachers/${detailAccountTeacher._id}`)
    .then(() => {
      setTimeout(() => {
        let index = listTeachersUnverified.findIndex(teacher => teacher._id === detailAccountTeacher._id);
        listTeachersUnverified.splice(index, 1);

        toast.current.show({
          severity: 'success',
          summary: 'Reject Account Success',
          detail: 'Account successfully rejected, Semangat cuy!.',
          life: 3000
        });
        setLoading(false);
        setRejectAccountDialog(false);
        setDetailAccountTeacher({});
      }, 1000);
    })
    .catch((error) => {
      toast.current.show({
        severity: 'error',
        summary: 'Reject Failed',
        detail: error.response.data.message,
        life: 3000
      });
      setLoading(false);
    })
  }

  useEffect(() => {
    getAllTeachers();
  }, []);

  const headerTableTemplate = () => (
    <div className='flex flex-column gap-2'>
        <div className='flex lg:flex-row md:flex-row sm:flex-row flex-column justify-content-between align-items-center gap-2'>
          <h2 className='m-0 p-0 lg:text-left md:text-left sm:text-left text-center'>Teacher Account</h2>
          <div className='flex lg:flex-row md:flex-row flex-column  gap-2'>
            <IconField iconPosition="left" className='lg:w-max md:w-max sm:w-max w-full'>
                <InputIcon className="pi pi-search"> </InputIcon>
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" className='p-inputtext-sm w-full'/>
            </IconField>
            {/* <Button label='Generate Salary +' severity='success' size='small' onClick={() => showDialog("GENERATE_SALARY", null)} /> */}
          </div> 
        </div>
    </div>
  );

  const actionBodyTemplate = (rowData) => (
    rowData.status !== 'Belum terverifikasi' ? <div className='flex gap-1'>
      <Button label='Reset Password' size='small' outlined severity='danger' onClick={() => {
        setDetailAccountTeacher(rowData);
        setResetPasswordDialog(true);
      }} />
      <Button icon='pi pi-wrench' size='small' severity='info' onClick={() => {
        setDetailAccountTeacher(rowData);
        setUpdateAccountDialog(true);
      }} />
    </div> : <div className='flex gap-1'>
      <Button icon='pi pi-verified' label='Accept' size='small' outlined severity='success' onClick={() => {
        setDetailAccountTeacher(rowData);
        setVerifiedAccountDialog(true);
      }}/>
      <Button icon='pi pi-eraser' label='Reject' size='small' severity='danger' onClick={() => {
        setDetailAccountTeacher(rowData);
        setRejectAccountDialog(true);
      }}/>
    </div>
  );

  const exportExcel = async (type="VERIFIED") => {
    let data;
    if (type === "VERIFIED") {
      data = listTeachers.map((teacher) => {
        let newFormat = {
          'Teacher Name': teacher.nama,
          Email: teacher.email,
          'Teacher Type': teacher.volunteerTeacher ? "Volunteer Teacher" : teacher.superTeacher ? "Super Teacher" : "Premium Teacher" ,
          Status: teacher.status
        }

        return newFormat;
      });
    }

    if (type === "UNVERIFIED") {
      data =  listTeachersUnverified.map((teacher) => {
        let newFormat = {
          'Teacher Name': teacher.nama,
          Email: teacher.email,
          Status: teacher.status,
          Education: teacher.pendidikan
        }

        return newFormat;
      });
    }

    import('xlsx').then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(data);   
             
        const workbook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(workbook, worksheet, "Teacher Account");
        // { Sheets: { data: worksheet }, SheetNames: ['WAITING CLASS'] };
        const excelBuffer = xlsx.write(workbook, {
            bookType: 'xlsx',
            type: 'array'
        });

        const fileName = `Teacher Account (${formattedDateWithOutTime(new Date())})`;

        saveAsExcelFile(excelBuffer, fileName);
    });
  };

  const paginatorRight = (
    <Button text size="small" icon='pi pi-download' onClick={() => exportExcel()}></Button>
  );

  const paginatorRightUnverified = (
    <Button text size="small" icon='pi pi-download' onClick={() => exportExcel("UNVERIFIED")}></Button>
  ) 

  const paginatorLeft = (
    <Button text size='small' icon='pi pi-refresh' onClick={() => getAllTeachers()}></Button>
  );

  return (
    <>
      <Toast ref={toast} />
      
      <ResetPassword
        visible={resetPasswordDialog}
        visibleHandler={() => {
          setResetPasswordDialog(false); 
          setDetailAccountTeacher({});
        }}
        detailTeacherAccount={detailAccountTeacher}
        loading={loading}
        setLoading={setLoading}
        refreshAction={() => getAllTeachers()}
        toast={toast}
      />

      <FormUpdateAccount
        visible={updateAccountDialog}
        visibleHandler={() => {
          setUpdateAccountDialog(false);
          setDetailAccountTeacher({});
        }}
        detailTeacherAccount={detailAccountTeacher}
        loading={loading}
        setLoading={setLoading}
        refreshAction={() => getAllTeachers()}
        toast={toast}
        listTeachers={listTeachers} 
        setListTeachers={setListTeachers}
      />

      <DestroyDialog
        visible={rejectAccountDialog}
        visibleHandler={() => {
          setRejectAccountDialog(false);
          setDetailAccountTeacher({})
        }}
        acceptAction={() => rejectTeacherRegistration()}
        rejectAction={() => {
          setRejectAccountDialog(false);
          setDetailAccountTeacher({})
        }}
        titleConfirmation="Reject Account"
        messageConfirmation="Are you sure reject teacher registration?"
        acceptLabelButton="Reject"
        loading={loading}
      />

      <VerifiedTeacher
        visible={verifiedAccountDialog}
        visibleHandler={() => {
          setDetailAccountTeacher({});
          setVerifiedAccountDialog(false);
        }}
        detailTeacherAccount={detailAccountTeacher}
        refreshAction={() => getAllTeachers()}
        toast={toast}
        loading={loading}
        setLoading={setLoading}
        listTeacher={listTeachers}
        setListTeacher={setListTeachers}
        listTeachersUnverified={listTeachersUnverified}
        setListTeacherUnverified={setListTeacherUnverified}
      />

      <Card>
        <TabView>
          <TabPanel header="Verified Teacher">
            <DataTable
              value={listTeachers}
              scrollable
              removableSort
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25, 50]}
              loading={loading}
              header={headerTableTemplate}
              tableStyle={{minWidth: '50rem'}}
              size='small'
              filters={filters}
              globalFilterFields={[
                'nama',
                'email',
                'status',
                'panggilan',
                'teacherType'
              ]}
              className='lg:text-sm md:text-sm sm:text-sm text-xs p-datatable-sm'
              paginatorLeft={paginatorLeft}
              paginatorRight={paginatorRight}
            >
              <Column header="No" body={(data, Options) => Options.rowIndex + 1} />
              <Column 
                field='nama' 
                header="Nama" 
                sortable
                className='none-dragable'
                style={{minWidth: '12rem'}}
              />
              <Column 
                field='email' 
                header="Email" 
                sortable
                className='none-dragable'
              />
              <Column 
                header="Tipe Guru"
                body={(e) => <TeacherTypeTemplate superTeacher={e.superTeacher} volunteerTeacher={e.volunteerTeacher} />} 
                sortable
                className='none-dragable'
                style={{minWidth: '8rem'}}
                align='center'
              />
              <Column 
                field='status' 
                header="Status"
                body={(e) => <TeacherStatusTemplate teacherStatus={e.status}/>}
                sortable
                className='none-dragable'
                align='center'
              />
              <Column body={actionBodyTemplate} style={{minWidth: '14rem'}}/>
            </DataTable>
          </TabPanel>
          <TabPanel header="unverified Teacher">
            <DataTable
              value={listTeachersUnverified}
              scrollable
              removableSort
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25, 50]}
              loading={loading}
              header={headerTableTemplate}
              tableStyle={{minWidth: '50rem'}}
              size='small'
              filters={filters}
              globalFilterFields={[
                'nama',
                'email',
                'status',
                'panggilan',
                'teacherType'
              ]}
              className='lg:text-sm md:text-sm sm:text-sm text-xs p-datatable-sm'
              paginatorLeft={paginatorLeft}
              paginatorRight={paginatorRightUnverified}
            >
              <Column header="No" body={(data, Options) => Options.rowIndex + 1} />
              <Column 
                field='nama' 
                header="Nama" 
                sortable
                className='none-dragable'
                style={{minWidth: '12rem'}}
              />
              <Column 
                field='email' 
                header="Email" 
                sortable
                className='none-dragable'
              />
              <Column 
                field='pendidikan' 
                header="Education" 
                sortable
                className='none-dragable'
                style={{minWidth: '14rem'}}
              />
              <Column 
                field='status' 
                header="Status"
                body={(e) => <TeacherStatusTemplate teacherStatus={e.status}/>}
                sortable
                className='none-dragable'
                align='center'
              />
              <Column body={actionBodyTemplate} style={{minWidth: '15rem'}}/>
            </DataTable>
          </TabPanel>
        </TabView>
        
      </Card>
    </>
  )
}

export default TeacherAccount
