import { Button } from 'primereact/button'
import { Card } from 'primereact/card'
import React, { useState } from 'react'
import { getTimeRangeString } from '../../../Hooks/helper'
import MoodBoosterDialog from './MoodBoosterDialog'

const Jumbotron = () => {
  const [ moodBoosterDialog, setMoodBoosterDialog ] = useState(false);

  return (
    <>
        <MoodBoosterDialog visible={moodBoosterDialog} visibleHandler={() => setMoodBoosterDialog(false)} />
        <Card>
            <div className='flex lg:flex-row md:flex-row flex-column justify-conten-center align-items-center gap-2'>
            <img src='/svg/welcome-admin.svg' alt='welcome' className='max-w-25rem'/>
            <div>
                <h2 className='m-0 p-0'>Assalamualaikum Wr. Wb.</h2>
                <p className='m-0 p-0 lg:text-lg lg:text-left md:text-left text-justify text-md'>Selamat {getTimeRangeString(new Date())}, Admin. Awali hari dengan senyuman dan jangan lupa dengan bacaan bismillah. Kadang hari-harimu berjalan berat dan tidak sesuai ekspetasi, ketawain aja yuk dengan klik tombol dibawah</p>
                <div className='flex justify-content-end mt-4'>
                    <Button label='Mood Booster' size='small' className='py-1' onClick={() => setMoodBoosterDialog(true)}/>
                </div>
            </div>
            </div>
        </Card>
    </>
  )
}

export default Jumbotron
