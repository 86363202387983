import { Tag } from 'primereact/tag';
import React from 'react';
import { getSeverity } from '../../Hooks/helper';

const ClassStatusTemplate = ({data}) => {
  return (
    <Tag value={data} severity={getSeverity("classStatus", data)}></Tag>
  )
}

export default ClassStatusTemplate
