import React, { useState } from 'react'
import SidebarMain from '../Components/SidebarMain'
import NavbarMain from '../Components/NavbarMain'
import { Outlet } from 'react-router-dom'
import FooterMain from '../Components/FooterMain'

const MainLayout = () => {
  const [ sidebarVisibility, setSidebarVisibility ] = useState(true);
  const [ visibility, setVisibility ] = useState(false);
  
  const onClickBarButton = () => {
    setSidebarVisibility(!sidebarVisibility);
    if (window.innerWidth <= 1200) setVisibility(!visibility);  
  }
  return (
    <div className='flex w-100'>
      <SidebarMain sidebarVisibility={sidebarVisibility} visibility={visibility} setVisibility={setVisibility} />
      <div className='edu-main w-full h-screen overflow-y-auto'>
        <div className='flex flex-column h-full'>
          <NavbarMain sidebarVisibility={sidebarVisibility} setSidebarVisibility={onClickBarButton} />
          <div className='lg:p-4 md:p-4 p-3'>
            <Outlet />
          </div>
          <FooterMain />
        </div>
      </div>
    </div>
  )
}

export default MainLayout
