import { Carousel } from 'primereact/carousel'
import React, { useEffect, useRef, useState } from 'react'
import { deleteData, getData, putData } from '../../../Hooks/api';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Tag } from 'primereact/tag';
import { Card } from 'primereact/card';
import { getAgeByDate, getSeverity, subjectClass } from '../../../Hooks/helper';
import FormTeacherUpdate from './FormTeacherUpdate';
import DestroyDialog from '../../../Components/ConfirmDialog/DestroyDialog';
import FormCreatePlot from './FormCreatePlot';
import { Dropdown } from 'primereact/dropdown';
import FormCreateClass from './FormCreateClass';

const PlotClassCarousel = ({
  selectAble,
  listPlotClassFilter,
  setListPlotClassFilter,
  listPlotClass,
  setListPlotClass,
  subjectFilter,
  onSubjectFilterValue,
  idPlotClass,
  setIdPlotClass,
  choosePlotClassHandler,
  addStudentRegist,
  toast,
  refreshTableWaiting,
  refreshListPlot,
  generalRefresh
}) => {
  const carousel = useRef(null);

  // Dialog Handler
  const [ loading, setLoading ] = useState(false);
  const [ addTeacherDialog, setAddTeacherDialog ] = useState(false); 
  const [ destroyPlotDialog, setDestroyDialog ] = useState(false);
  const [ createPlotDialog, setCreatePlotDialog ] = useState(false);
  const [ createClassDialog, setCreateClassDialog ] = useState(false);

  // Data Handler
  const [ currentPage, setCurrentPage ] = useState(0);
  const [ listTeacher, setListTeacher ] = useState([]);
  const [ detailPlotClass, setDetailPlotClass ] = useState({});
 
  const getListTeacher = () => {
    setLoading(true);

    getData('teachers')
    .then((response) => {
      setListTeacher(response.data);
      setLoading(false);
    })
    .catch((error) => {
      toast.current.show({
        severity:'error', 
        summary: "Load Failed", 
        detail: error.response.data.message, 
        life: 3000,
      });
      setLoading(false);
    })
  }

  const destroyPlotClass = async (id) => {
    setLoading(true);
    
    deleteData(`plotclasses/${id}/delete`, {})
    .then(() => {
      setTimeout(() => {
        refreshAction("DESTROY", {_id: id});
        refreshTableWaiting();
        setDestroyDialog(false);
        setLoading(false);
      }, 1000);
    })
    .catch((error) => {
      toast.current.show({
        severity: 'error',
        summary: 'Destroy Failed!',
        detail: error,
        life: 3000
      });      
      setLoading(false);
    })
  }

  const removeStudentRegist = async (id, data) => {
    setLoading(true);

    putData(`plotclasses/${id}/remove-studentregist`, {studentRegistId: data._id})
    .then(() => {
      refreshAction("REMOVE_STUDENT", id, data);
      setLoading(false);
    })
    .catch((error) => {
      toast.current.show({
        severity: 'error',
        summary: 'Add Student Failed!',
        detail: error.response.data.message,
        life: 3000
      });
      setLoading(false);
    })
  }

  const refreshAction = (type, plot=null, data={}) => {
    let index = listPlotClassFilter.findIndex(plotClass => plotClass._id === plot._id);
    let indexPlot = listPlotClass.findIndex(plotClass => plotClass._id === plot._id);
    
    if (type === "DESTROY") {
        listPlotClass.splice(indexPlot, 1);
        setListPlotClass(listPlotClass);
        if (subjectFilter !== null && subjectFilter) setListPlotClassFilter(listPlotClass.filter(plot => plot.subject === subjectFilter));
        
        setCurrentPage(0);
    }

    if (type === "CREATE") {
        listPlotClass.push(data);
        setListPlotClass(listPlotClass);
        if (subjectFilter !== null && subjectFilter) setListPlotClassFilter(listPlotClass.filter(plot => plot.subject === subjectFilter));
    }

    if (type === "REMOVE_STUDENT") {
        generalRefresh(type, data, plot);
    }

    if (type === "CREATE_CLASS") {
        listPlotClassFilter.splice(index, 1);
        listPlotClass.splice(indexPlot, 1);
        setListPlotClassFilter(listPlotClassFilter);
        setListPlotClass(listPlotClass);
        setCurrentPage(0);
    }
  }

  useEffect(() => {
    getListTeacher();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const responsiveOptions = [
    {
        breakpoint: '1400px',
        numVisible: 3,
        numScroll: 1
    },
    {
        breakpoint: '1200px',
        numVisible: 2,
        numScroll: 1
    },
    {
        breakpoint: '760px',
        numVisible: 1,
        numScroll: 1
    }
  ];

  const CardCarousel = (data) => (
    <Card className='h-27rem mx-1'>
      <div className='flex flex-column gap-1'>
        <div className='flex align-items-center justify-content-between'>
          <h5 className='m-0 p-0 font-bold'>{data.subject} (Lvl. {data.classLevel})</h5>
          <Tag value={data.classType} className='lg:text-md md:text-md sm:text-sm text-sm' severity={getSeverity("classType", data.classType)}/>
        </div>

        <div>
          <p className='p-0 m-0 text-center'>{data.day} - {data.time}</p>
          <label className='font-bold'>Course Level : {data.courseLevel}</label>
        </div>
        
        <div className='flex flex-column gap-2'>
          <div>
            <label className='font-bold block mb-1'>Teacher : </label>
            { data.teacher === null ? <div className='flex justify-content-between'>
              <Tag value="none" severity='danger' className='text-lg' /> 
              <Button label='Find Teacher ?' severity='info' outlined size='small' className='py-1 border-round-2xl' onClick={() => {
                setIdPlotClass(data._id);
                setAddTeacherDialog(true);
              }} />
            </div> : <div className='flex align-items-center justify-content-between'>
              <p className='m-0 p-0'>{data.teacher?.nama}</p> 
              <Button label='change' severity='secondary' outlined size='small' className='py-0 border-round-2xl' onClick={() => {
                setIdPlotClass(data._id);
                setAddTeacherDialog(true);
              }} />
            </div> }
          </div>
        </div>

        <div className='w-full mt-2'>
          <DataTable
            scrollable
            value={data.studentRegist}
            removableSort
            header={headerTablePlotClass(data)}
            size='small'
            scrollHeight='150px'
            className='text-xs'
          >
            <Column 
              field='student.nie' 
              header="NIE"
            />
            <Column 
              field='student.nama' 
              header="Nama"
            />
            <Column 
              field='student.tanggalLahir' 
              header="Usia" 
              body={(e) => `${getAgeByDate(e.student.tanggalLahir)} tahun`}
              align="center"
            />
            <Column
              body={(e) => cardActionTemplate(e, data._id)}
            />
          </DataTable>
        </div>
      </div>  
      { selectAble._id ? <div className='flex justify-content-center'>
          <Button 
            label='Choose Plot' 
            size='small' 
            severity='success' 
            className='border-round-lg mt-3'
            onClick={() => addStudentRegist(data._id, selectAble)}
          />
        </div> : 
        <div className='flex justify-content-center gap-2 mt-3'>
          <Button
            icon='pi pi-trash'
            label='Destroy Plot'
            size='small'
            severity='danger'
            outlined
            className='p-1 border-round-lg text-sm'
            onClick={() => {
                setIdPlotClass(data._id);
                setDestroyDialog(true);
            }}
            loading={loading}
          />
          { (data.studentRegist.length > 0 && data.teacher !== null) && <Button 
                label='Create Class' 
                severity='success' 
                className='p-1 border-round-lg text-sm' 
                loading={loading} 
                size='small'
                onClick={() => {
                    setDetailPlotClass(data);
                    setCreateClassDialog(true);
                }}
            /> 
          }
        </div>
      }  
    </Card>
  );

  const headerTablePlotClass = (data) => (
    <div className='flex justify-content-between align-items-center'>
      <p className='p-0 m-0'>Students</p>
      { idPlotClass === null ? 
        <Button label='add' className='py-1 border-round-2xl text-xs' severity='success' onClick={() => choosePlotClassHandler(data, true)} /> :  
        <Button label='cancel' className='py-1 border-round-2xl text-xs' severity='success' onClick={() => choosePlotClassHandler(data, false)} />
      }
    </div>
  );

  const cardActionTemplate = (data, id) => (
    <i className='pi pi-trash text-red-500 cursor-pointer' onClick={() => {removeStudentRegist(id, data)}}></i>
  );

  return (
    <>
        <FormTeacherUpdate
            visible={addTeacherDialog}
            visibleHandler={() => {
                setIdPlotClass(null);
                setAddTeacherDialog(false);
            }}
            idPlot={idPlotClass}
            listTeacher={listTeacher}
            loading={loading}
            setLoading={setLoading}
            refreshAction={refreshListPlot}
            toast={toast}
        />

        <FormCreatePlot
          visible={createPlotDialog}
          visibleHandler={() => setCreatePlotDialog(false)}
          listTeacher={listTeacher}
          loading={loading}
          setLoading={setLoading}
          toast={toast}
          refreshAction={refreshAction}
        />

        <DestroyDialog
            visible={destroyPlotDialog}
            visibleHandler={() => {
                setIdPlotClass(null);
                setDestroyDialog(false);
            }}
            acceptAction={() => destroyPlotClass(idPlotClass)}
            rejectAction={() => {
                setIdPlotClass(null);
                setDestroyDialog(false);
            }}
            titleConfirmation="Destroy Plot"
            messageConfirmation="Are you sure destroy this plot class?"
            acceptLabelButton="Destroy"
            loading={loading}
        />

        <FormCreateClass 
            visible={createClassDialog}
            visibleHandler={() => {
                setDetailPlotClass({});
                setCreateClassDialog(false);
            }}
            loading={loading}
            setLoading={setLoading}
            refreshAction={refreshAction}
            data={detailPlotClass}
            listTeacher={listTeacher}
            toast={toast}
        />

        <div className='flex lg:flex-row md:flex-row sm:flex-row flex-column justify-content-between align-items-center gap-1'>
          <h3 className='m-0 p-0'>Plotting Kelas</h3>
          <div className='flex lg:flex-row md:flex-row sm:flex-row flex-column gap-1 lg:w-max sm:w-max md:w-max w-full'>
            <Dropdown value={subjectFilter} options={subjectClass} onChange={onSubjectFilterValue} placeholder='Choose subject' showClear className='w-full' />
            <Button label="Create Plot" size='small' onClick={() => setCreatePlotDialog(true)} className='w-full' />
          </div>
        </div>

        <Carousel
            ref={carousel}
            value={listPlotClassFilter}
            numVisible={3}
            showNavigators={false}
            itemTemplate={CardCarousel}
            responsiveOptions={responsiveOptions}
            page={currentPage}
            onPageChange={(e) => setCurrentPage(e.page)}
        />
    </>
  )
}

export default PlotClassCarousel