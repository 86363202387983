import React, { useEffect, useState } from 'react';
import { getData, putData } from '../../../../Hooks/api';
import { Field, Form } from 'react-final-form';
import { Dropdown } from 'primereact/dropdown';
import { getFormErrorMessage } from '../../../../Hooks/helper';
import { Button } from 'primereact/button';

const TransferClass = ({toast}) => {
  const [ loading, setLoading ] = useState(false);
  const [ listClass , setListClass ] = useState([]);
  const [ listTeacher, setListTeacher ] = useState([]);
  const initialValues = { classId: '', guruBaru: '' };

  const getListClass = () => {
    getData('class')
    .then((response) => {
        let dataClass = response.data;

        dataClass.forEach((kelas) => {
            kelas.subject = `${kelas.kodeBatch}.${kelas.kodeKelas} ${kelas.namaKelas} [${kelas.jenisKelas}] Lvl. ${kelas.level} - ${kelas.guru.nama}`
        });

        setListClass(dataClass);
        setLoading(false);
    })
    .catch((error) => {
        toast.current.show({
            severity: 'error',
            summary: 'Load Failed!',
            detail: error.response.data.message,
            life: 3000
        });
        setLoading(false);
    });
  }

  const getListTeacher = () => {
    setLoading(true);

    getData('teachers')
    .then((response) => {
        setListTeacher(response.data);
        setLoading(false);
    })
    .catch((error) => {
        toast.current.show({
            severity: 'error',
            summary: 'Load Failed!',
            detail: error.response.data.message,
            life: 3000
        });
        setLoading(false);
    });
  }

  useEffect(() => {
    getListClass();
    getListTeacher();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const transferClass = (data, form) => {
    setLoading(true);

    putData('transfer-class', data)
    .then(() => {
        setTimeout(() => {
            form.restart();
            getListClass();
            toast.current.show({
                severity: 'success',
                summary: 'Transfer Success',
                detail: 'Class successfully transfered to new teacher, congratss!!!',
                life: 3000
            });
        }, 1000);
    })
    .catch((error) => {
        toast.current.show({
            severity: 'error',
            summary: 'Transfer Failed!',
            detail: error.response.data.message,
            life: 3000
        });
        setLoading(false);
    })
  }

  const validateTransferClass = (data) => {
    let errors = {};

    if (!data.classId) errors.classId = 'Class is required';
    if (!data.guruBaru) errors.guruBaru = 'New teachers is required';

    return errors;
  }

  return (
    <Form
        initialValues={initialValues}
        validate={validateTransferClass}
        onSubmit={transferClass}
        render={({handleSubmit, form}) => (
            <form onSubmit={handleSubmit} className='flex flex-column gap-3 p-fluid'>
                <Field
                    name='classId'
                    render={({input, meta}) => (
                        <div className='flex-auto'>
                            <label className='font-bold block mb-2'>Kelas</label>
                            <Dropdown
                                id='classId'
                                name='classId'
                                {...input}
                                filter
                                options={listClass}
                                optionValue='_id'
                                optionLabel='subject'
                                placeholder='Choose class'
                                loading={loading}
                            />
                            {getFormErrorMessage(meta)}
                        </div>
                    )}
                />
                <Field
                    name='guruBaru'
                    render={({input, meta}) => (
                        <div className='flex-auto'>
                            <label className='font-bold block mb-2'>Kelas</label>
                            <Dropdown
                                id='guruBaru'
                                name='guruBaru'
                                {...input}
                                filter
                                options={listTeacher}
                                optionValue='_id'
                                optionLabel='nama'
                                placeholder='Choose New Teacher'
                                loading={loading}
                            />
                            {getFormErrorMessage(meta)}
                        </div>
                    )}
                />
                <div className='flex lg:justify-content-end md:justify-content-end sm:justify-content-end justify-content-center gap-2'>
                    <Button size='small' type='button' label='Cancel' severity='danger' className='w-max' outlined loading={loading} onClick={() => form.reset()} />
                    <Button size='small' type='submit' label='Update' severity='primary' className='w-max' loading={loading} />
                </div>
            </form>
        )}
    />
  )
}

export default TransferClass
