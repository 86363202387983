import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useRef, useState } from 'react';
import { postData, putData } from '../../../../../Hooks/api';
import { useNavigate, useParams } from 'react-router-dom';
import { formatIDRCurrency, formatMonth, formattedDateWithOutTime } from '../../../../../Hooks/helper';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import StatusSalaryTemplate from '../../../../../Components/TagTemplate/StatusSalaryTemplate';
import { Panel } from 'primereact/panel';
import WALinkTemplate from '../../../../../Components/TagTemplate/WALinkTemplate';
import { Divider } from 'primereact/divider';
import TeacherTypeTemplate from '../../../../../Components/TagTemplate/TeacherTypeTemplate';
import DestroyDialog from '../../../../../Components/ConfirmDialog/DestroyDialog';
import ListSession from './ListSession';
import { IconField } from 'primereact/iconfield';
import { InputText } from 'primereact/inputtext';
import { InputIcon } from 'primereact/inputicon';
import { FilterMatchMode } from 'primereact/api';
import GenerateSalary from './GenerateSalary';
import { Toast } from 'primereact/toast';
import EditDetailSalary from './EditDetailSalary';

const DetailTeacherSalary = () => {
  const {id} = useParams();
  const toast = useRef(null);
  const navigate = useNavigate();
  
  // Data Handler
  const [ listDetailTeacherSalary, setListDetailTeacherSalary ] = useState([]);
  const [ detailTeacher, setDetailTeacher ] = useState({});
  const [ listSession, setListSession ] = useState([]);
  const [ detailSalary, setDetailSalary ] = useState({
    jumlahDibayarkan: 0,
    indexGaji: '',
    idSalary: '',
    buktiTransfer: null,
    statusGaji: '',
    keterangan: ''
  });

  // Dialog Handler 
  const [ loading, setLoading ] = useState(false);
  const [ destroySalaryDialog, setDestroySalaryDialog ] = useState(false);
  const [ listSessionDialog, setListSessionDialog ] = useState(false);
  const [ generateSalaryDialog, setGenerateSalaryDialog ] = useState(false);
  const [ editDetailSalaryDialog, setEditDetailSalaryDialog ] = useState(false);

  // Filter Handler
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const getDetailTeacherSalary = async () => {
    setLoading(true);

    postData('detail-salary', {idSalary: id})
    .then((response) => {
        let listSalary = response.data.listGaji;
        setDetailTeacher(response.data.guru);

        listSalary.map((report, index) => {
            report["index"] = index+1;
            report['bulanGajiText'] = formattedDateWithOutTime(report.bulanGaji);
            report['keterangan'] = report.keterangan === undefined ? "-" : report.keterangan;
            return report;
        });

        setListDetailTeacherSalary(listSalary);
        setLoading(false);
    })
    .catch((error) => {
        toast.current.show({
          severity: 'error',
          summary: 'Failed Load Data!',
          detail: error.response.data.message,
          life: 3000
        });
        setLoading(false);
    })
  }

  const destroyDetailSalary = async () => {
    setLoading(true);

    putData(`delete-listgaji`, {
        idSalary: detailSalary.idSalary, 
        indexGaji: detailSalary.indexGaji
    }).then(() => {
        getDetailTeacherSalary();
        setLoading(false);
    })
    .catch((error) => {
        setLoading(false);
        toast.current.show({
            severity: 'error',
            summary: 'Failed Destroy Data!',
            detail: error.response.data.message,
            life: 3000
        });
    })
  }

  useEffect(() => {
    getDetailTeacherSalary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const modifyDetailSession = (data) => {
    const listResult = [];

    if (data !== null && data.sesi.length > 0) {
        const sesi = data.sesi
        sesi.map((detailData) => {
            const kelas = detailData.kelas;
            const subjekKelas = `${kelas.namaKelas} - ${kelas.kodeBatch}.${kelas.kodeKelas} (${kelas.jenisKelas}) - Lvl.${kelas.level}`;
            const listSession = [];
            const bulanGaji =  formatMonth(data.bulanGaji);
            const totalGaji = data.jumlahGaji;
            
    
            if (detailData.indexSesi.length > 0) {
                const newIndexSesi = detailData.indexSesi.split(",");
                
                newIndexSesi.map((index) => {
                    listSession.push(parseInt(index) + 1);
                    return index;
                })
            }
    
            const formatData = {
                _id: detailData._id,
                subjectClass: subjekKelas,
                qtySession: detailData.jumlahSesi,
                listSession: listSession,
                bulanGaji: bulanGaji,
                nominal: data.jumlahGaji / data.jumlahSesi * detailData.jumlahSesi,
                totalGaji: totalGaji
            }
    
            listResult.push(formatData);
            return detailData;
        }) 
    }

    setListSession(listResult);
    return listResult;
  }

  const showDialog = (type, data) => {
    if (type === "DESTROY_SALARY") {
        setDetailSalary({
          ...detailSalary,
          idSalary: id,
          indexGaji: data.index - 1
        });
  
        setDestroySalaryDialog(true);
    }

    if (type === "LIST_SESSION") {
        modifyDetailSession(data);
        setListSessionDialog(true);
    }

    if (type === "GENERATE_SALARY") {
        setGenerateSalaryDialog(true);
    }

    if (type === "EDIT_DETAIL") {
        setDetailSalary({
            ...detailSalary,
            jumlahDibayarkan: data.dibayar,
            indexGaji: data.index - 1,
            idSalary: id,
            buktiTransfer: data.buktiTransfer,
            statusGaji: data.statusGaji,
            keterangan: data.keterangan
        });

        setEditDetailSalaryDialog(true);
    }
  }

  const hideDialog = (type) => {
    if (type === "DESTROY_SALARY") {
        setDetailSalary({
          ...detailSalary,
          idSalary: '',
          indexGaji: ''
        });

        setDestroySalaryDialog(false);
    }

    if (type === "LIST_SESSION") {
        setListSessionDialog ();
    }

    if (type === "GENERATE_SALARY") {
        setGenerateSalaryDialog(false);
    }

    if (type === "EDIT_DETAIL") {
        setDetailSalary({
          ...detailSalary,
          jumlahDibayarkan: 0,
          indexGaji: '',
          buktiTransfer: null,
          statusGaji: '',
          keterangan: ''
        });
        setEditDetailSalaryDialog(false);
    }
  }

  const actionBodyTemplate = (rowData) => {
    return (
        <div className='flex gap-1'>
            <Button icon='pi pi-trash' size='small' outlined severity='danger' onClick={() => showDialog("DESTROY_SALARY", rowData)} />
            <Button icon='pi pi-eye' size='small' severity='info' onClick={() => showDialog("LIST_SESSION", rowData)} />
            <Button icon='pi pi-pencil' size='small' outlined severity='help' onClick={() => showDialog("EDIT_DETAIL", rowData)} />
            <Button label='Slip' icon='pi pi-file-pdf' size='small' severity='danger' onClick={() => {
              navigate('/finance-management/teacher-salary/report', {state: {sesi: modifyDetailSession(rowData), guru: detailTeacher}})
            }}/>
        </div>
    )
  }

  const headerTableTemplate = () => (
    <div className='flex flex-column gap-2'>
        <div className='flex lg:flex-row md:flex-row sm:flex-row flex-column justify-content-between align-items-center gap-2'>
          <h2 className='m-0 p-0 lg:text-left md:text-left sm:text-left text-center'>Detail Salary</h2>
          <div className='flex lg:flex-row md:flex-row flex-column  gap-2'>
            <IconField iconPosition="left" className='lg:w-max md:w-max sm:w-max w-full'>
                <InputIcon className="pi pi-search"> </InputIcon>
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" className='p-inputtext-sm w-full'/>
            </IconField>
            <Button label='Generate Salary +' severity='success' size='small' onClick={() => showDialog("GENERATE_SALARY", null)} />
          </div> 
        </div>
    </div>
  );

  return (
    <>
        <Panel header="Detail Teacher" className='mb-3' toggleable>
            <div className='flex lg:flex-row md:flex-row sm:flex-row flex-column justify-content-around'>
                <div className='flex flex-column gap-2 lg:w-max md:w-max sm:w-max w-full'>
                    <div className='flex align-items-center gap-3'>
                        <i className='pi pi-user text-4xl lg:block md:block sm:block hidden'></i>
                        <div>
                            <label className='flex align-items-center font-bold'><i className='pi pi-graduation-cap font-bold lg:hidden md:hidden sm:hidden mr-2'></i>Teachers</label>
                            <p className='p-0 m-0'>{detailTeacher.nama}</p>
                        </div>
                    </div>
                    <div className='flex align-items-center gap-3'>
                        <i className='pi pi-whatsapp text-4xl lg:block md:block sm:block hidden'></i>
                        <div>
                            <label className='flex align-items-center font-bold'><i className='pi pi-calendar-clock font-bold lg:hidden md:hidden sm:hidden mr-2'></i>Contact</label>
                            {/* <p className='p-0 m-0'></p> */}
                            <WALinkTemplate data={detailTeacher.nomor}/>
                        </div>
                    </div>
                    <div className='flex align-items-center gap-3'>
                        <i className='pi pi-graduation-cap text-4xl lg:block md:block sm:block hidden'></i>
                        <div>
                            <label className='flex align-items-center font-bold'><i className='pi pi-map font-bold lg:hidden md:hidden sm:hidden mr-2'></i>Education</label>
                            <p className='p-0 m-0'>{detailTeacher.pendidikan}</p>
                        </div>
                    </div>
                </div>
                <Divider layout='vertical' className='lg:block md:block sm:block hidden' />
                <div className='flex flex-column gap-2 lg:w-max md:w-max sm:w-max w-full'>
                    <div className='flex align-items-center gap-3'>
                        <i className='pi pi-crown text-4xl lg:block md:block sm:block hidden'></i>
                        <div>
                            <label className='flex align-items-center font-bold'><i className='pi pi-graduation-cap font-bold lg:hidden md:hidden sm:hidden mr-2'></i>Status</label>
                            <p className='p-0 m-0'><TeacherTypeTemplate volunteerTeacher={detailTeacher.volunteerTeacher} superTeacher={detailTeacher.superTeacher}/></p>
                        </div>
                    </div>
                    <div className='flex align-items-center gap-3'>
                        <i className='pi pi-credit-card text-4xl lg:block md:block sm:block hidden'></i>
                        <div>
                            <label className='flex align-items-center font-bold'><i className='pi pi-calendar-clock font-bold lg:hidden md:hidden sm:hidden mr-2'></i>Bank Account</label>
                            <p className='p-0 m-0'>{detailTeacher.namaBank} - {detailTeacher.rekening}</p>
                        </div>
                    </div>
                    <div className='flex align-items-center gap-3'>
                        <i className='pi pi-map-marker text-4xl lg:block md:block sm:block hidden'></i>
                        <div>
                            <label className='flex align-items-center font-bold'><i className='pi pi-map font-bold lg:hidden md:hidden sm:hidden mr-2'></i>Domicile</label>
                            <p className='p-0 m-0'>{detailTeacher.domisili}</p>
                        </div>
                    </div>
                </div>
            </div>
        </Panel>

        <DestroyDialog
            visible={destroySalaryDialog}
            visibleHandler={() => setDestroySalaryDialog(false)}
            acceptAction={() => destroyDetailSalary()}
            rejectAction={() => hideDialog("DESTROY_SALARY")}
            titleConfirmation="Delete Salary"
            messageConfirmation="Are you sure to delete the salary?"
            acceptLabelButton="Delete"
            loading={loading}
        />

        <ListSession
            visible={listSessionDialog}
            visibleHandler={() => hideDialog("LIST_SESSION")}
            listSession={listSession}
        />

        <GenerateSalary
            visible={generateSalaryDialog}
            visibleHandler={() => hideDialog("GENERATE_SALARY")}
            toast={toast}
            loading={loading}
            setLoading={setLoading}
            refreshAction={() => getDetailTeacherSalary()}
            idSalary={id}
        />

        <EditDetailSalary
            visible={editDetailSalaryDialog}
            visibleHandler={() => hideDialog("EDIT_DETAIL")}
            detailSalary={detailSalary}
            refreshAction={() => getDetailTeacherSalary()}
            toast={toast}
            loading={loading}
            setLoading={setLoading}
        />

        <Toast ref={toast}/>

        <Card>
            <DataTable
                value={listDetailTeacherSalary}
                scrollable
                removableSort
                paginator
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50]}
                loading={loading}
                header={headerTableTemplate}
                tableStyle={{ minWidth: '50rem'}}
                size='small'
                filters={filters}
                globalFilterFields={[
                    'bulanGaji', 
                    'bulanGajiText',
                    'jumlahSesi',
                    'jumlahGaji',
                    'dibayar',
                    'keterangan'
                ]}
                className='lg:text-sm md:text-sm sm:text-sm text-xs p-datatable-sm'
            >
                <Column header="No" body={(data, Options) => Options.rowIndex + 1} className='none-dragable' />
                <Column 
                  field='bulanGaji' 
                  header="Bulan Gaji" 
                  sortable 
                  body={(e) => formattedDateWithOutTime(e.bulanGaji)}
                  className='none-dragable'
                  align="right"
                  style={{minWidth: '10rem'}} 
                />
                <Column 
                  field='jumlahSesi' 
                  header="Qty Sesi" 
                  sortable 
                  className='none-dragable'
                  align="center"
                  style={{minWidth: '5rem'}} 
                />
                <Column 
                  field='jumlahGaji' 
                  header="Nominal Gaji" 
                  sortable 
                  body={(e) => formatIDRCurrency(e.jumlahGaji)} 
                  className='none-dragable'
                  align="right"
                  style={{minWidth: '9rem'}} 
                />
                <Column 
                  field='dibayar' 
                  header="Terbayar" 
                  sortable 
                  body={(e) => formatIDRCurrency(e.dibayar)} 
                  className='none-dragable'
                  align="right"
                  style={{minWidth: '9rem'}} 
                />
                <Column 
                  field='statusGaji' 
                  header="Status" 
                  sortable 
                  body={(e) => <StatusSalaryTemplate data={e.statusGaji}/>}
                  className='none-dragable'
                  align="center"
                  style={{minWidth: '10rem'}} 
                />
                <Column 
                  field='keterangan' 
                  header="Berita Acara" 
                  sortable 
                  className='none-dragable'
                  style={{minWidth: '12rem'}} 
                />
                <Column body={actionBodyTemplate} style={{minWidth: '12rem'}}></Column>
            </DataTable>
        </Card>
    </>
  )
}

export default DetailTeacherSalary
