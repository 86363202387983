import { Dialog } from 'primereact/dialog'
import React, { useEffect, useState } from 'react'
import { postData } from '../../../Hooks/api';
import { Field, Form } from 'react-final-form';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { getFormErrorMessage } from '../../../Hooks/helper';
import { InputText } from 'primereact/inputtext';

const ResetPassword = ({
  visible,
  visibleHandler,
  detailTeacherAccount,
  loading,
  setLoading,
  refreshAction,
  toast
}) => {
  const [ initialValues, setInitialValues ] = useState({
    idTeacher: '',
    newPassword: ''
  });

  useEffect(() => {
    if (detailTeacherAccount._id) setInitialValues({
        ...initialValues, 
        idTeacher: detailTeacherAccount._id
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailTeacherAccount]);

  const resetPassword = async (data, form) => {
    setLoading(true);

    postData('set-new-password', data)
    .then(() => {
        setTimeout(() => {
            refreshAction();
            form.restart();
            toast.current.show({
                severity: 'success',
                summary: 'Reset Password Success',
                detail: 'Password successfully reseted, clear!.',
                life: 3000
            });

            if (!loading) visibleHandler();
        }, []);
    })
    .catch((error) => {
        toast.current.show({
            severity: 'error',
            summary: 'Reset Password Failed',
            detail: error.response.data.message,
            life: 3000
        });
        setLoading(false);
    });
  }

  const validateResetPassword = (data) => {
    let errors = {};

    if (!data.newPassword) errors.newPassword = "New password is required";
    return errors;
  }

  return (
    <Dialog
        visible={visible}
        onHide={visibleHandler}
        header="Reset Password"
        style={{ width: '32rem' }}
        breakpoints={{ '960px': '75vm', '641px': '90vw' }}
    >
      <Form
        initialValues={initialValues}
        onSubmit={resetPassword}
        validate={validateResetPassword}
        render={({handleSubmit}) => (
            <form onSubmit={handleSubmit} className='flex flex-column gap-3 p-fluid'>
                <div>
                    <label className='font-bold block mb-2'>Teacher</label>
                    <InputText id='nama' name='nama' value={detailTeacherAccount.nama} />
                </div>
                <div>
                    <label className='font-bold block mb-2'>Email</label>
                    <InputText id='email' name='email' value={detailTeacherAccount.email} />
                </div>
                <Field
                    name='newPassword'
                    render={({input, meta}) => (
                        <div>
                            <label className='font-bold block mb-2'>New Password</label>
                            <Password 
                                id='newPassword'
                                name='newPassword'
                                {...input}
                                toggleMask
                                feedback={false}
                            />
                            {getFormErrorMessage(meta)}
                        </div>
                    )}
                />
                <div className='flex lg:justify-content-end md:justify-content-end sm:justify-content-end justify-content-center gap-2'>
                    <Button size='small' type='button' label='Cancel' severity='primary' className='w-max' outlined loading={loading} onClick={visibleHandler} autoFocus />
                    <Button size='small' type='submit' label='Reset' severity='danger' className='w-max' loading={loading} />
                </div>
            </form>
        )}
      />
    </Dialog>
  )
}

export default ResetPassword
