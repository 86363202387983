import { Card } from 'primereact/card';
import { Panel } from 'primereact/panel';
import React, { useEffect, useState } from 'react';
import EduChart from '../../../Components/Chart/ChartJS';

const Overview = ({listTeacher}) => {
  const [ overviewTeacher, setOverviewTeacher ] = useState({
    'Premium Teacher': {
        total: 0,
        status: {
            Aktif: 0,
            Pasif: 0,
            Berhenti: 0
        }
    },
    'Super Teacher': {
        total: 0,
        status: {
            Aktif: 0,
            Pasif: 0,
            Berhenti: 0
        }
    },
    'Volunteer Teacher': {
        total: 0,
        status: {
            Aktif: 0,
            Pasif: 0,
            Berhenti: 0
        }
    }
  });

  useEffect(() => {
    if (listTeacher && listTeacher.length > 0) classificationTeacher(listTeacher);
  }, [listTeacher]);

  const classificationTeacher = (data) => {
    let premiumTeacher = {
        total: 0,
        status:  {
            Aktif: 0,
            Pasif: 0,
            Berhenti: 0
        }
    };
    let superTeacher = {
        total: 0,
        status:  {
            Aktif: 0,
            Pasif: 0,
            Berhenti: 0
        }
    };
    let volunteerTeacher = {
        total: 0,
        status:  {
            Aktif: 0,
            Pasif: 0,
            Berhenti: 0
        }
    };
    
    data.forEach((teacher) => {
        if (teacher.volunteerTeacher) { 
            volunteerTeacher.total += 1;
            volunteerTeacher.status[teacher.status] += 1;
        }

        if (teacher.superTeacher) { 
            superTeacher.total++;
            superTeacher.status[teacher.status] += 1;
        }

        if (!teacher.superTeacher && !teacher.volunteerTeacher) {
            premiumTeacher.total++;
            premiumTeacher.status[teacher.status] += 1;
        }
    });
    
    setOverviewTeacher({
        'Premium Teacher': premiumTeacher,
        'Super Teacher': superTeacher,
        'Volunteer Teacher': volunteerTeacher
    });
  }

  const chartDataModify = (data) => {
    let dataModify =  Object.entries(data).map(([key, value]) => ({
        label: key,
        value: value
    }));
    
    return dataModify;
  }

  return (
    <>
      <div className='lg:flex md:flex flex-wrap hidden gap-2 mb-3'>
        <Card className='col'>
          <div className='flex flex-column gap-2'>
            <h3 className='m-0 p-0'>Super Teacher</h3>
            <div className='flex justify-content-between mt-2'>
              <div className='bg-yellow-300 flex justify-content-center align-items-center border-round-xl w-3rem'>
                  <i className='pi pi-star text-4xl text-white'></i>
              </div>
              <div className='text-right'>
                  <p className='m-0 p-0'>Total</p>
                  <p className='m-0 p-0 font-bold lg:text-2xl md:text-lg text-base'>{overviewTeacher['Super Teacher'].total} Orang</p>
              </div>
            </div>
            <Panel header="Detail Status" toggleable collapsed>
                <div className='flex justify-content-center'>
                    <EduChart chartTitle='Status'  data={chartDataModify(overviewTeacher['Super Teacher'].status)} className='w-12rem max-w-full min-w-0' />
                </div>
            </Panel>
          </div>
        </Card>
        <Card className='col'>
          <div className='flex flex-column gap-2'>
            <h3 className='m-0 p-0'>Premium Teacher</h3>
            <div className='flex justify-content-between mt-2'>
                <div className='bg-green-700 flex justify-content-center align-items-center border-round-xl w-3rem'>
                    <i className='pi pi-verified text-4xl text-white'></i>
                </div>
                <div className='text-right'>
                    <p className='m-0 p-0'>Total</p>
                    <p className='m-0 p-0 font-bold lg:text-2xl md:text-lg text-base'>{overviewTeacher['Premium Teacher'].total} Orang</p>
                </div>
            </div>
            <Panel header="Detail Status" toggleable collapsed>
                <div className='flex justify-content-center'>
                    <EduChart chartTitle='Status'  data={chartDataModify(overviewTeacher['Premium Teacher'].status)} className='w-12rem max-w-full min-w-0' chartClassName='w-full w-min-0' />
                </div>
            </Panel>
          </div>
        </Card>
        <Card className='col'>
          <div  className='flex flex-column gap-2'>
            <h3 className='m-0 p-0'>Volunteer Teacher</h3>
            <div className='flex justify-content-between mt-2'>
                <div className='bg-blue-400 flex justify-content-center align-items-center border-round-xl w-3rem'>
                    <i className='pi pi-users text-4xl text-white'></i>
                </div>
                <div className='text-right'>
                    <p className='m-0 p-0'>Total</p>
                    <p className='m-0 p-0 font-bold lg:text-2xl md:text-lg text-base'>{overviewTeacher['Volunteer Teacher'].total} Orang</p>
                </div>
            </div>
            <Panel header="Detail Status" toggleable collapsed>
                <div className='flex justify-content-center'>
                    <EduChart chartTitle='Status' data={chartDataModify(overviewTeacher['Volunteer Teacher'].status)} className='w-12rem max-w-full min-w-0' />
                </div>
            </Panel>
          </div>
        </Card>
      </div>

      <Panel header="Overview Teacher" toggleable collapsed className='lg:hidden md:hidden mb-3'>
        <div className='flex flex-column gap-2'>
            <Card className='col'>
            <div className='flex flex-column gap-2'>
                <h3 className='m-0 p-0'>Super Teacher</h3>
                <div className='flex justify-content-between mt-2'>
                <div className='bg-yellow-300 flex justify-content-center align-items-center border-round-xl w-3rem'>
                    <i className='pi pi-star text-4xl text-white'></i>
                </div>
                <div className='text-right'>
                    <p className='m-0 p-0'>Total</p>
                    <p className='m-0 p-0 font-bold lg:text-2xl md:text-lg text-base'>{overviewTeacher['Super Teacher'].total} Orang</p>
                </div>
                </div>
                <Panel header="Detail Status" toggleable collapsed>
                    <div className='flex justify-content-center'>
                        <EduChart chartTitle='Status' data={chartDataModify(overviewTeacher['Super Teacher'].status)} className='w-12rem min-w-0' chartClassName='w-full w-min-0' />
                    </div>
                </Panel>
            </div>
            </Card>
            <Card className='col'>
            <div className='flex flex-column gap-2'>
                <h3 className='m-0 p-0'>Premium Teacher</h3>
                <div className='flex justify-content-between mt-2'>
                    <div className='bg-green-700 flex justify-content-center align-items-center border-round-xl w-3rem'>
                        <i className='pi pi-verified text-4xl text-white'></i>
                    </div>
                    <div className='text-right'>
                        <p className='m-0 p-0'>Total</p>
                        <p className='m-0 p-0 font-bold lg:text-2xl md:text-lg text-base'>{overviewTeacher['Premium Teacher'].total} Orang</p>
                    </div>
                </div>
                <Panel header="Detail Status" toggleable collapsed>
                    <div className='flex justify-content-center'>
                        <EduChart chartTitle='Status'  data={chartDataModify(overviewTeacher['Premium Teacher'].status)} className='w-12rem min-w-0' chartClassName='w-full w-min-0' />
                    </div>
                </Panel>
            </div>
            </Card>
            <Card className='col'>
            <div  className='flex flex-column gap-2'>
                <h3 className='m-0 p-0'>Volunteer Teacher</h3>
                <div className='flex justify-content-between mt-2'>
                    <div className='bg-blue-400 flex justify-content-center align-items-center border-round-xl w-3rem'>
                        <i className='pi pi-users text-4xl text-white'></i>
                    </div>
                    <div className='text-right'>
                        <p className='m-0 p-0'>Total</p>
                        <p className='m-0 p-0 font-bold lg:text-2xl md:text-lg text-base'>{overviewTeacher['Volunteer Teacher'].total} Orang</p>
                    </div>
                </div>
                <Panel header="Detail Status" toggleable collapsed>
                    <div className='flex justify-content-center'>
                        <EduChart chartTitle='Status'  data={chartDataModify(overviewTeacher['Volunteer Teacher'].status)} className='w-12rem min-w-0' />
                    </div>
                </Panel>
            </div>
            </Card>
        </div>
      </Panel>
    </>
  )
}

export default Overview
