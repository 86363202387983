import { Dialog } from 'primereact/dialog'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form';
import { BE_API_URL, postFormData } from '../../../../../Hooks/api';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { getFormErrorMessage, salaryStatus } from '../../../../../Hooks/helper';
import { FileUpload } from 'primereact/fileupload';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { Image } from 'primereact/image';

const EditDetailSalary = ({
  visible,
  visibleHandler,
  refreshAction,
  toast,
  loading,
  setLoading,
  detailSalary
}) => {
  const [ initialValues, setInitialValues ] = useState({
    jumlahDibayarkan: 0,
    indexGaji: '',
    idSalary: '',
    bttf: null,
    keterangan: '',
    statusGaji: ''
  });
  const [ fileUpload, setFileUpload ] = useState(null); 
  const [ proofOfPayment, setProofOfPayment ] = useState(false);

  useEffect(() => {
    if (detailSalary.idSalary){ 
        setInitialValues({
            jumlahDibayarkan: detailSalary.jumlahDibayarkan,
            indexGaji: detailSalary.indexGaji,
            idSalary: detailSalary.idSalary,
            keterangan: detailSalary.keterangan,
            statusGaji: detailSalary.statusGaji,
            btff: detailSalary.buktiTransfer
        });
        
        if (detailSalary.buktiTransfer && detailSalary.buktiTransfer !== "") setProofOfPayment(true);
    }
  }, [detailSalary]);

  const editDetailSalary = async (data, form) => {
    setLoading(true);

    data.bttf = fileUpload;
    const formData = new FormData();
    formData.append('jumlahDibayarkan', data.jumlahDibayarkan);
    formData.append('indexGaji', data.indexGaji);
    formData.append('idSalary', data.idSalary);
    formData.append('keterangan', data.keterangan);
    formData.append('statusGaji', data.statusGaji);

    if (fileUpload) formData.append('bttf', data.bttf);
    
    postFormData('edit-salary', formData)
    .then(() => {
        setTimeout(() => {
            refreshAction();
            form.restart();
            toast.current.show({
                severity: 'success',
                summary: "Edit Success",
                detail: "Salary successfully edited, yesss...",
                life: 3000
            });

            if (!loading) visibleHandler();
        }, 800); 
    })
    .catch((error) => {
        toast.current.show({
            severity: 'error',
            summary: "Edit Failed",
            detail: error.response.data.message,
            life: 3000
        });
        setLoading(false);
    })
  }

  const validateEditDetailSalary = (data) => {
    let errors = {};

    if (!data.jumlahDibayarkan) errors.jumlahDibayarkan = "Amount is required";
    if (!data.statusGaji) errors.statusGaji = "Salary status is required";
    return errors;
  }

  const uploadFileHandler = (e, input, type) => {    
    if (type === "SELECT") setFileUpload(e.files[0]);
    else setFileUpload(null);
  }

  const headerUploadTemplate = (options) => {
    const {chooseButton, cancelButton } = options;
    return (
        <div className='flex lg:justify-content-start md:justify-content-start sm:justify-content-start justify-content-center gap-2 mb-2'>
            {chooseButton}
            {cancelButton}
        </div>
    );
  }; 

  return (
    <Dialog
      visible={visible}
      onHide={visibleHandler}
      header="Edit Salary"
      style={{ width: '32rem' }}
      breakpoints={{ '960px': '75vm', '641px': '90vw' }}
    >
        <Form
            initialValues={initialValues}
            validate={validateEditDetailSalary}
            onSubmit={editDetailSalary}
            render={({handleSubmit}) => (
                <form onSubmit={handleSubmit} className='flex flex-column gap-3 p-fluid'>
                    <Field 
                        name='jumlahDibayarkan'
                        render={({input, meta}) => (
                            <div className='flex-auto'>
                                <label className='font-bold block mb-2'>Amount Paid</label>
                                <div className='p-inputgroup flex-1'>
                                    <span className='p-inputgroup-addon'>Rp</span>
                                    <InputText
                                        id='jumlahDibayarkan'
                                        name='jumlahDibayarkan'
                                        {...input}
                                        keyfilter="int"
                                        placeholder='...'
                                    />
                                </div>
                                
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />
                    <div className={detailSalary.buktiTransfer !== "" ? 'block' : 'hidden'}>
                        <div className='flex justify-content-between align-items-center'>
                            <label className='block font-bold'>Proof of Payment</label>
                            <Button label='Edit' type='button' size='small' text className={proofOfPayment? 'w-max' :  'hidden'} onClick={() => setProofOfPayment(false)}/>
                            <Button label='Cancel' type='button' size='small' text className={proofOfPayment? 'hidden' : 'w-max'} onClick={() => setProofOfPayment(true)}/>
                        </div>
                        <div className='flex justify-content-center'>
                            <Image src={`${BE_API_URL}/uploads/${detailSalary.buktiTransfer}`} alt="Image" height='300' preview />
                        </div>
                    </div>
                    <Field
                        name='bttf'
                        render={({input, meta}) => (
                            <div className='flex-auto' hidden={proofOfPayment}>
                                <label className='font-bold block mb-2'>Proof of Payment</label>
                                <FileUpload
                                    id='bttf'
                                    name="bttf" 
                                    accept="image/*" 
                                    maxFileSize={500000} 
                                    emptyTemplate={
                                        <p className="m-0">Drag and drop files to here to upload.</p>
                                    }
                                    {...input}
                                    headerTemplate={headerUploadTemplate}
                                    chooseOptions={{ icon: "pi pi-folder-open", label: "Browse", className: 'p-2' }}
                                    cancelOptions={{ icon: "pi pi-trash", label: "Clear", className: 'p-2 bg-red-500 border-red-500' }}
                                    customUpload
                                    // uploadHandler={(e) => uploadFileHandler(e, input)}
                                    onSelect={(e) => uploadFileHandler(e, input, "SELECT")}  
                                    onClear={(e) => uploadFileHandler(e, input, "CLEAR")}
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />
                    <Field 
                        name='statusGaji'
                        render={({input, meta}) => (
                            <div className='flex-auto'>
                                <label className='font-bold block mb-2'>Status</label>
                                <Dropdown
                                    id='statusGaji'
                                    name='statusGaji'
                                    {...input}
                                    options={salaryStatus}
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />
                    <Field 
                        name='keterangan'
                        render={({input, meta}) => (
                            <div className='flex-auto'>
                                <label className='font-bold block mb-2'>Note</label>
                                <InputTextarea
                                    id='keterangan'
                                    name='keterangan'
                                    {...input}
                                    rows={2}
                                    placeholder='Berita acara'
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />
                    <div className='flex lg:justify-content-end md:justify-content-end sm:justify-content-end justify-content-center gap-2'>
                        <Button size='small' type='button' label='Cancel' severity='danger' className='w-max' outlined loading={loading} onClick={visibleHandler} />
                        <Button size='small' type='submit' label='Edit' severity='primary' className='w-max' loading={loading} />
                    </div>
                </form>
            )}
        />
    </Dialog>
  )
}

export default EditDetailSalary
