import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import React, { useEffect, useState } from 'react'
import { copyLink } from '../../Hooks/helper'
// import { copyLink } from '../../../Hooks/helper'

const RoomInfo = ({
  visibility,
  visibleHandler,
  detailRoom,
  toast
}) => {
  const [ detailBroadCast, setDetailBroadCast ] = useState("");
  
  useEffect(() => {
    if (detailRoom.namaRoom) 
        setDetailBroadCast(`*Detail Room*\nNama Room\t: ${detailRoom.namaRoom}\nLink Room:\n• Main\t\t: ${detailRoom.linkRoom}\nMeeting ID\t: ${detailRoom.meetingID}\nPassCode\t: ${detailRoom.passCode}\n\nUntuk data dibawah ini hanya untuk teachers, ya\nHost Key\t: ${detailRoom.hostKey}`);
  }, [detailRoom]);

  return (
    <Dialog
      header="Detail Class Room"
      visible={visibility}
      onHide={visibleHandler}
      style={{ width: '32rem' }} breakpoints={{ '960px': '75vm', '641px': '90vw' }}
    >
      <div className='flex flex-column gap-3 p-fluid'>
        <div className='flex-auto'>
            <label className='block font-bold'>Nama Room</label>
            <InputText value={detailRoom.namaRoom} />
        </div>
        <div className='flex-auto'>
            <label className='block font-bold'>meetingID</label>
            <InputText value={detailRoom.meetingID} />
        </div>
        <div className='flex-auto'>
            <label className='block font-bold'>Passcode</label>
            <InputText value={detailRoom.passCode} />
        </div>
        <div className='flex-auto'>
            <label className='block font-bold'>Host Key</label>
            <InputText value={detailRoom.hostKey} />
        </div>
        <div className='flex-auto'>
            <label className='block font-bold'>Start Session</label>
            <Calendar value={new Date(detailRoom.startSession)} showIcon showTime dateFormat='dd MM yy -' disabled/>
        </div>
        <div className='flex-auto'>
            <label className='block font-bold'>End Session</label>
            <Calendar value={new Date(detailRoom.endSession)} showIcon showTime dateFormat='dd MM yy -' disabled/>
        </div>
        <div className='flex lg:justify-content-end md:justify-content-end sm:justify-content-end justify-content-center gap-2'>
          <Button type='button' label='Close' severity='danger' className='w-max' outlined onClick={() => visibleHandler()}></Button>
          <Button type='submit' label='Copy' icon="pi pi-copy" severity='secondary' className='w-max' autoFocus onClick={() => copyLink(detailBroadCast, toast)}></Button>
        </div>  
      </div>
    </Dialog>
  )
}


export default RoomInfo