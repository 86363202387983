import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { getData, postData } from '../../../../Hooks/api';
import { Dropdown } from 'primereact/dropdown';
import { getFormErrorMessage } from '../../../../Hooks/helper';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

const FormAddTeacherSalary = ({
  visible,
  visibleHandler,
  loading,
  setLoading,
  toast,
  refreshAction
}) => {
  const [ detailTeacher, setDetailTeacher ] = useState({
    _id: '',
    nama: '',
    rekening: '',
    domisili: '',
    pendidikan: ''
  });
  const [ listTeacher, setListTeacher ] = useState([]);

  const getAllTeacher = async () => {
    setLoading(true);

    getData('teachers')
    .then((response) => {
        setListTeacher(response.data);
        setLoading(false);    
    })
    .catch((error) => {
        toast.current.show({
            severity: 'error',
            summary: 'Failed Load Data!',
            detail: error.response.data.message,
            life: 3000
        });
        setLoading(false);
    });
  }

  useEffect(() => {
    getAllTeacher();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addTeacherSalary = async (data, form) => {
    setLoading(true);

    postData('new-salary', {idTeacher: data._id})
    .then(() => {
        setTimeout(() => {
            refreshAction();
            form.restart();

            toast.current.show({
                severity: 'success',
                summary: 'Add Success',
                detail: 'Teacher salary successfully added, good job guyss!.',
                life: 3000
            });

            if (!loading) visibleHandler();
        }, 800);
    })
    .catch((error) => {
        toast.current.show({
            severity: 'error',
            summary: 'Update Failed',
            detail: error.response.data.message,
            life: 3000
        });
        setLoading(false);
    });
  }

  const validateTeacherSalary = (data) => {
    let errors = {};

    if (!data._id) errors._id = "Teacher is required";

    return errors;
  }

  const changeTeacherHandler = (e, input) => {
    let teacher = listTeacher.find(teacher => teacher._id === e.target.value);

    setDetailTeacher({
        _id: teacher._id,
        nama: teacher.nama,
        rekening: `${teacher.namaBank} - ${teacher.rekening}`,
        domisili: teacher.domisili,
        pendidikan: teacher.pendidikan
    });
  }

  return (
    <Dialog
      visible={visible}
      onHide={() => {
        visibleHandler();
        setDetailTeacher({
            _id: '',
            nama: '',
            rekening: '',
            domisili: '',
            pendidikan: ''
        })
      }}
      header="Add Teacher Salary"
      style={{ width: '32rem' }}
      breakpoints={{ '960px': '75vm', '641px': '90vw' }}
    >
        <Form
          initialValues={detailTeacher}
          validate={validateTeacherSalary}
          onSubmit={addTeacherSalary}
          render={({handleSubmit}) => (
            <form onSubmit={handleSubmit} className='flex flex-column gap-3 p-fluid'>
                <Field
                    name='_id'
                    render={({input, meta}) => (
                        <div className='flex-auto'>
                            <label className='font-bold block mb-2'>Teacher</label>
                            <Dropdown 
                                id='nama' 
                                name='nama' 
                                {...input}
                                options={listTeacher}
                                optionLabel='nama'
                                optionValue='_id'
                                filter
                                placeholder='Choose teacher'
                                onChange={(e) => changeTeacherHandler(e, input)}
                                disabled={loading}
                            />
                            {getFormErrorMessage(meta)}
                        </div>
                    )}
                />
                <div className='flex-auto'>
                    <label className='font-bold block mb-2'>Domicile</label>
                    <InputText id='domisili' name='domisili' value={detailTeacher.domisili} disabled={loading} />
                </div>
                <div className='flex-auto'>
                    <label className='font-bold block mb-2'>Bank Account</label>
                    <InputText id='rekening' name='rekening' value={detailTeacher.rekening} disabled={loading} />
                </div>
                <div className='flex lg:justify-content-end md:justify-content-end sm:justify-content-end justify-content-center gap-2'>
                    <Button size='small' type='button' label='Cancel' severity='danger' className='w-max' outlined loading={loading} onClick={() => {
                        visibleHandler();
                        setDetailTeacher({
                            _id: '',
                            nama: '',
                            rekening: '',
                            domisili: '',
                            pendidikan: ''
                        })
                    }} />
                    <Button size='small' type='submit' label='Update' severity='primary' className='w-max' loading={loading} />
                </div>
            </form>
          )}
        />
    </Dialog>
  )
}

export default FormAddTeacherSalary
