import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { postData, putData } from '../../../Hooks/api';
import ArrayMutators from "final-form-arrays";
import { InputText } from 'primereact/inputtext';
import { classType, FUNNEL_OPTIONS, getFormErrorMessage, listDay, listTime, studentStatus, subjectClass } from '../../../Hooks/helper';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { SelectButton } from 'primereact/selectbutton';
import { Divider } from 'primereact/divider';
import { Tag } from 'primereact/tag';
import { FieldArray } from 'react-final-form-arrays';
import { Button } from 'primereact/button';

const FormAddUpdateStudent = ({
  visibility,
  visibleHandler,
  data=undefined,
  titleDialog,
  toast,
  loading,
  setLoading,
  refreshAction
}) => {
  const [ initialValues, setInitialValues ] = useState({});

  const updateStudent = async (data, form) => {
    setLoading(true);

    putData(`student/${data._id}`, data)
    .then(() => {
        setTimeout(() => {
            refreshAction(data);
            toast.current.show({
                severity: 'success',
                summary: 'Update Success',
                detail: 'Student successfully updated, Horay!',
                life: 3000
            });
            form.restart();
            setLoading(false);
            visibleHandler();
        }, 800);
    })
    .catch((error) => { 
        toast.current.show({
            severity: 'error',
            summary: 'Update Failed',
            detail: error.response.data.message,
            life: 3000
        });

        setLoading(false);
    });
  }

  const addStudent = async (data, form) => {
    setLoading(true);

    postData('students', data)
    .then((response) => {
        setTimeout(() => {
            refreshAction(response.data);
            toast.current.show({
                severity: 'success',
                summary: 'Add Success',
                detail: 'New student successfully added, Marvelous',
                life: 3000
            });
            form.restart();
            setLoading(false);
            visibleHandler();
        }, 800);
    })
    .catch((error) => {
        toast.current.show({
            severity: 'error',
            summary: 'Add Failed',
            detail: error.response.data.message,
            life: 3000
        });

        setLoading(false);
    });
  }

  const saveHandler = (data, form) => {
    if (initialValues._id) updateStudent(data, form);
    else addStudent(data, form);
  }

  const validateStudent = (data) => {
    let errors = {};

    if (!data.nama) errors.nama = 'Student name is required';
    if (!data.nomor) errors.nomor = 'Contact is required';
    if (!data.tanggalLahir) errors.tanggalLahir = 'Born date is required';
    if (!data.domisili) errors.domisili = 'Domicile is required';
    if (!data.sekolah) errors.sekolah = 'School is required';
    if (!data.ibu) errors.ibu = "Mother's name is required";
    if (!data.pekerjaanIbu) errors.pekerjaanIbu = "Mother's job is required";
    if (!data.pekerjaanAyah) errors.pekerjaanAyah = "Father's job is required";
    if (!data.waktuMendaftar) errors.waktuMendaftar = "Registered time is required";
    if (!data.funnel) errors.funnel = 'Funnel is required';
    if (!data.status) errors.status = "Student status is required";
    
    if (data.classRegistration) {
        if (!data.subject) errors.subject = "Subjek kelas is required";
        if (!data.classType) errors.classType = "Type kelas is required";
        if (!data.classLevel) errors.classLevel = "Level kelas is required";
        if (!data.courseLevel) errors.courseLevel = "Level materi is required";
        if (!data.invoice) errors.invoice = "Invoice value is required";
        if (!data.timePref || (Array.isArray(data.timePref) && data.timePref.length === 0)) errors.data = "Time preference is required";
    }

    return errors;
  }

  useEffect(() => {
    if (data._id) {
        data.tanggalLahir = new Date(data.tanggalLahir);
        data.waktuMendaftar = new Date(data.waktuMendaftar);
    }
    setInitialValues(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Dialog
        visible={visibility}
        onHide={visibleHandler}
        header={titleDialog}
        style={{width: '32rem'}}
        breakpoints={{'960px': '75vw', '640px': '95vw'}}
    >  
        <Form
            mutators={{...ArrayMutators}}
            initialValues={initialValues}
            onSubmit={saveHandler}
            validate={validateStudent}
            render={({handleSubmit, form: {mutators: {push}}, values}) => (
                <form onSubmit={handleSubmit} className='flex flex-column gap-2 p-fluid'>
                    <Field
                        name='nie'
                        render={({input, meta}) => (
                            <div className='flex-auto' hidden={initialValues._id === null}>
                                <label htmlFor="nomerInduk" className='font-bold block mb-2'>NIE</label>
                                <InputText 
                                    id='nie' 
                                    name='nie' 
                                    {...input}  
                                    disabled
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />
                    <div className='flex lg:flex-row md:flex-row sm:flex-row flex-column gap-2'>
                        <Field
                            name='nama'
                            render={({input, meta}) => (
                                <div className='flex-auto'>
                                    <label htmlFor="nama" className='font-bold block mb-2'>Name</label>
                                    <InputText 
                                        id='nama' 
                                        name='nama' 
                                        {...input}  
                                        disabled={loading}
                                    />
                                    {getFormErrorMessage(meta)}
                                </div>
                            )}
                        />
                        <Field
                            name='nomor'
                            render={({input, meta}) => (
                                <div className='flex-auto'>
                                    <label htmlFor="nomor" className='font-bold block mb-2'>Contact</label>
                                    <InputText 
                                        id='nomor' 
                                        name='nomor' 
                                        {...input} 
                                        keyfilter="num" 
                                        placeholder='628xxx-xxxx-xxxx'
                                        disabled={loading}
                                    />
                                    {getFormErrorMessage(meta)}
                                </div>
                            )}
                        />
                    </div>
                    <div className='flex lg:flex-row md:flex-row sm:flex-row flex-column gap-2'>
                        <Field
                            name='sekolah'
                            render={({input, meta}) => (
                                <div className='flex-auto'>
                                    <label htmlFor="sekolah" className='font-bold block mb-2'>School</label>
                                    <InputText 
                                        id='sekolah' 
                                        name='sekolah' 
                                        {...input}
                                        disabled={loading}
                                    />
                                    {getFormErrorMessage(meta)}
                                </div>
                            )}
                        />
                        <Field
                            name='tanggalLahir'
                            render={({input, meta}) => (
                                <div className='flex-auto'>
                                    <label htmlFor="tanggalLahir" className='font-bold block mb-2'>Born Date</label>
                                    <Calendar 
                                        id='tanggalLahir' 
                                        name='tanggalLahir' 
                                        {...input}
                                        showIcon 
                                        dateFormat='dd-mm-yy'
                                        disabled={loading}
                                    />
                                    {getFormErrorMessage(meta)}
                                </div>
                            )}
                        />
                    </div>
                    <Field
                        name='domisili'
                        render={({input, meta}) => (
                            <div className='flex-auto'>
                                <label htmlFor="tanggalLahir" className='font-bold block mb-2'>Domicile</label>
                                <InputText 
                                    id='domisili' 
                                    name='domisili' 
                                    {...input}
                                    disabled={loading}
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />
                    <div className="flex lg:flex-row md:flex-row sm:flex-row flex-column gap-2">
                        <Field
                            name='ibu'
                            render={({input, meta}) => (
                                <div className='flex-auto'>
                                    <label htmlFor="ibu" className='font-bold block mb-2'>Mother's Name</label>
                                    <InputText 
                                        id='ibu' 
                                        name='ibu' 
                                        {...input}
                                        disabled={loading}
                                    />
                                    {getFormErrorMessage(meta)}
                                </div>
                            )}
                        />
                        <Field
                            name='pekerjaanIbu'
                            render={({input, meta}) => (
                                <div className='flex-auto'>
                                    <label htmlFor="pekerjaanIbu" className='font-bold block mb-2'>Mother's Job</label>
                                    <InputText 
                                        id='pekerjaanIbu' 
                                        name='pekerjaanIbu' 
                                        {...input}
                                        disabled={loading}
                                    />
                                    {getFormErrorMessage(meta)}
                                </div>
                            )}
                        />
                    </div>
                    <div className="flex lg:flex-row md:flex-row sm:flex-row flex-column gap-2">
                        <Field
                            name='pekerjaanAyah'
                            render={({input, meta}) => (
                                <div className='flex-auto'>
                                    <label htmlFor="pekerjaanAyah" className='font-bold block mb-2'>Father's Job</label>
                                    <InputText 
                                        id='pekerjaanAyah' 
                                        name='pekerjaanAyah' 
                                        {...input}
                                        disabled={loading}
                                    />
                                    {getFormErrorMessage(meta)}
                                </div>
                            )}
                        />
                        <Field
                            name='funnel'
                            render={({input, meta}) => (
                            <div className='flex-auto'>
                                <label htmlFor="funnel" className='font-bold block mb-2'>Funnel</label>
                                <Dropdown 
                                    id='funnel' 
                                    name="funnel" 
                                    options={FUNNEL_OPTIONS} 
                                    {...input}
                                    filter 
                                    placeholder="Choose Funnel" 
                                    className='w-full'
                                    disabled={loading}
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                            )}
                        />
                    </div>
                    <div className="flex lg:flex-row md:flex-row sm:flex-row flex-column gap-2">
                        <Field
                            name='waktuMendaftar'
                            render={({input, meta}) => (
                                <div className='flex-auto'>
                                    <label htmlFor="waktuMendaftar" className='font-bold block mb-2'>Registered Date</label>
                                    <Calendar 
                                        id='waktuMendaftar' 
                                        name="waktuMendaftar" 
                                        {...input}
                                        className='w-full'
                                        dateFormat='dd-mm-yy'
                                        showIcon
                                        disabled={loading}
                                    />
                                    {getFormErrorMessage(meta)}
                                </div>
                            )}
                        />
                        <Field
                            name='status'
                            render={({input, meta}) => (
                                <div className='flex-auto'>
                                    <label htmlFor="status" className='font-bold block mb-2'>Status</label>
                                    <Dropdown 
                                        id='status' 
                                        name="status" 
                                        {...input}
                                        className='w-full'
                                        options={studentStatus}
                                        placeholder='Choose'
                                        disabled={loading}
                                    />
                                    {getFormErrorMessage(meta)}
                                </div>
                            )}
                        />
                    </div>
                    <Field
                        name='classRegistration'
                        render={({input, meta}) => (
                            <div className='mb-2' hidden={initialValues._id !== null}>
                                <div className='flex lg:flex-row md:flex-row sm:flex-row flex-column justify-content-between align-items-center gap-2'>
                                    <label className='font-bold' htmlFor="classRegistration">Daftar kelas ?</label>
                                    <SelectButton 
                                        id='classRegistration' 
                                        name='classRegistration' 
                                        {...input}
                                        options={[{value: true, label: "YES"}, {value: false, label: "NO"}]}
                                        optionLabel='label'
                                        optionValue='value'
                                        className='lg:w-max md:w-max sm:w-max w-full'
                                        disabled={loading}
                                    />
                                </div>
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />
                    { values.classRegistration && <div className='flex flex-column gap-2'>
                        <Divider type="solid" align='center'>Class Detail</Divider>
                        <div className='flex lg:flex-row md:flex-row sm:flex-row flex-column gap-2'>
                            <Field
                                name='classType'
                                render={({input, meta}) => (
                                    <div className='w-full'>
                                        <label htmlFor='classType' className='font-bold block mb-2'>Class Type</label>
                                        <Dropdown 
                                            id='classType' 
                                            name='classType'
                                            options={classType}
                                            {...input}
                                            placeholder='Choose class type'
                                            className='w-full'
                                            disabled={loading}
                                        />
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )}
                            />
                            <Field
                                name='subject'
                                render={({input, meta}) => (
                                    <div className='w-full'>
                                        <label htmlFor='subject' className='font-bold block mb-2'>Class Subject</label>
                                        <Dropdown 
                                            id='subject' 
                                            name='subject' 
                                            {...input}
                                            options={subjectClass}
                                            placeholder='Choose Subject'
                                            className='w-full'
                                            disabled={loading}
                                        />
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )}
                            />
                        </div>
                        <div className='flex gap-2'>
                            <Field
                                name='classLevel'
                                render={({input, meta}) => (
                                    <div className='w-full'>
                                        <label htmlFor='classLevel' className='font-bold block mb-2'>Class Level</label>
                                        <InputText 
                                            id='classLevel' 
                                            name='classLevel' 
                                            {...input}
                                            keyfilter="num"
                                            disabled={loading}
                                        />
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )}
                            />
                            <Field
                                name='courseLevel'
                                render={({input, meta}) => (
                                    <div className='w-full'>
                                        <label htmlFor='courseLevel' className='font-bold block mb-2'>Course Level</label>
                                        <InputText 
                                            id='courseLevel' 
                                            name='courseLevel' 
                                            {...input}
                                            keyfilter="num"
                                            disabled={loading}
                                        />
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )}
                            />
                        </div>
                        
                        <div className='flex justify-content-between'>
                            <label className='font-bold font block'>Time Preference</label>
                            <Tag value="+" className='px-2 py-0 text-lg cursor-pointer' onClick={() => push("timePref", {day: "", time: ""})}/>
                        </div>

                        <FieldArray name='timePref'>
                            {({fields}) => fields.map((name, index) => (
                                <div className='flex gap-2 align-items-center mb-1' key={name}>
                                    <i className='pi pi-times' onClick={() => fields.remove(index)}></i>
                                    <Field
                                        name={`${name}.day`}
                                        render={({input, meta}) => (
                                            <div className='w-full'>
                                                <Dropdown 
                                                    id={`${name}.day`}
                                                    name={`${name}.day`}  
                                                    options={listDay}
                                                    {...input}
                                                    placeholder='Pilih hari'
                                                />
                                            </div>
                                        )}
                                    />
                                    <Field
                                        name={`${name}.time`}
                                        render={({input, meta}) => (
                                            <div className='w-full'>
                                                <Dropdown 
                                                    id={`${name}.time`}
                                                    name={`${name}.time`}  
                                                    options={listTime}
                                                    {...input}
                                                    placeholder='Pilih waktu'
                                                />
                                            </div>
                                        )}
                                    />
                                </div>
                            ))}
                        </FieldArray>
                        {(values.classRegistration && (!values.timePref || values.timePref === null || values.timePref?.length === 0)) && (<small className='p-error'>Time preference is required at least 1</small>)}

                        <Field
                            name='invoice'
                            render={({input, meta}) => (
                            <div className='w-full'>
                                <label htmlFor='invoice' className='font-bold block mb-2'>Invoice</label>
                                <div className="flex p-inputgroup">
                                <span className="p-inputgroup-addon">Rp</span>
                                <InputText 
                                    id='invoice' 
                                    name='invoice' 
                                    {...input}
                                    keyfilter="num"
                                    disabled={loading}
                                />
                                </div>
                                {getFormErrorMessage(meta)}
                            </div>
                            )}
                        />
                    </div> }
                    <div className='flex lg:justify-content-end md:justify-content-end sm:justify-content-end justify-content-center mt-3 gap-2'>
                        <Button type='button' label="Cancel" icon="pi pi-times" outlined onClick={visibleHandler} className='w-max' loading={loading}/>
                        <Button type='submit' label="Save" icon="pi pi-check" className='w-max' loading={loading}/>
                    </div>
                </form>
            )}
        />
    </Dialog>
  )
}

export default FormAddUpdateStudent
