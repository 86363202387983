import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { getData, postData, putData } from '../../../Hooks/api';
import { classType, subjectClass } from '../../../Hooks/helper';

const FormAddUpdateClass = ({
  visiblity, 
  setVisibility, 
  data=undefined, 
  titleDialog, 
  toast, 
  refreshAction,
  loading, 
  setLoading
}) => {
  const [ initialValues, setInitialValues ] = useState({});
  const [ listTeacher, setListTeacher ] = useState([]);

  useEffect(() => {
    if (titleDialog === "EDIT CLASS") { 
        getAllTeachers();
        setInitialValues({
            ...data,
            guru: data.guru?._id
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visiblity]);
    
  const saveButton = async (data, form) => {
    setLoading(true);

    if (titleDialog === 'EDIT CLASS') {
      setTimeout(() => {
        updateClass(data, form)
      }, 300)
    } else {
      setTimeout(() => {
        addClass(data, form)
      }, 300)
    }
  }

  const updateClass = async (dataBody, form) => {
    putData(`class/${dataBody._id}`, dataBody)
    .then(() => {
        setTimeout(() => {
            dataBody.guru = data.guru;
            refreshAction(dataBody);
            setLoading(false);
            setVisibility();

            toast.current.show({
                severity: 'info',
                summary: 'Update Success',
                detail: 'Class successfully updated, make your day happy.',
                life: 3000
            });
            form.restart();
        }, 1000);
    })
    .catch((error) => {
        if (error.response && error.response.status !== 403 && error.response.data.message !== "Unauthorized: Invalid token") {
            toast.current.show({
                severity: 'error',
                summary: 'Update Failed!',
                detail: error.response.data.message,
                life: 3000
            });
        }
        setLoading(false);
    })
  }

  const addClass = async (dataBody, form) => {
    const dataPost = {
        kodeBatch: dataBody.kodeBatch,
        kodeKelas: dataBody.kodeKelas,
        namaKelas: dataBody.namaKelas,
        jenisKelas: dataBody.jenisKelas,
        jadwal: dataBody.jadwal,
        level: dataBody.level,
        materiKelas: dataBody.materiKelas,
        guru: dataBody.guru,
        noteSubjek:  dataBody.noteSubjek
    }

    postData(`newclass`, dataPost)
    .then(() => {
        refreshAction();
        setVisibility();
        toast.current.show({
            severity: 'info',
            summary: 'Update Success',
            detail: 'Preference successfully updated, thanks for your attention.',
            life: 3000
        });
        form.restart();
        setLoading(false);
    })
    .catch((error) => {
        if (error.response && error.response.status !== 403 && error.response.data.message !== "Unauthorized: Invalid token") {
            toast.current.show({
                severity: 'error',
                summary: 'Add Failed!',
                detail: error.response.data.message,
                life: 3000
            });
        }
        setLoading(false);
    })
  }

  const getAllTeachers = async () => {
    getData('teachers')
    .then((response) => {
        setListTeacher(response.data);
    })
    .catch((error) => {
        toast.current.show({
            severity:'error', 
            summary: error.response.data.title, 
            detail: error.response.data.message, 
            life: 3000,
        });
    })
  }
  
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);

  const getFormErrorMessage = (meta) => {  
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  const validateAddUpdateClass = (data) => {
    let errors = {};

    if (!data.kodeBatch) errors.kodeBatch = 'Kode Batch is required';
    if (!data.kodeKelas) errors.kodeKelas = 'Kode Kelas is required';
    if (!data.jenisKelas) errors.jenisKelas = 'Tipe Kelas is required';
    if (!data.namaKelas) errors.namaKelas = 'Subject Kelas is required';
    if (!data.level) errors.level = 'Level kelas is required';
    if (!data.materiKelas) errors.materiKelas = 'Level Materi is required';
    if (!data.jadwal) errors.jadwal = 'Jadwal mulai is required';
    if (!data.guru) errors.guru = "Teachers is requried";

    return errors
  }
  
  return (
    <Dialog
        visible={visiblity}
        onHide={setVisibility}
        header={titleDialog}
        style={{width: '32rem'}}
        breakpoints={{'960px': '75vw', '640px': '95vw'}}
    >
        <Form
            initialValues={initialValues}
            validate={validateAddUpdateClass}
            onSubmit={saveButton}
            render={({handleSubmit}) => (
                <form onSubmit={handleSubmit} className='flex flex-wrap gap-2 p-fluid mb-3'>
                    <div className='flex-auto'>
                        <label htmlFor="kodeBatch" className='font-bold block mb-2'>Kode Kelas</label>
                        <div className='flex gap-1'>
                            <Field
                                name='kodeBatch'
                                render={({input, meta}) => (
                                <div className='w-full'>
                                    <InputText 
                                        id='kodeBatch'
                                        name='kodeBatch' 
                                        {...input}
                                        keyfilter="int"
                                    />
                                    {getFormErrorMessage(meta)}
                                </div>
                                )}
                            />
                            <span className='font-bold text-2xl'>.</span>
                            <Field
                                name='kodeKelas'
                                render={({input, meta}) => (
                                <div className='w-full'>
                                    <InputText 
                                    id='kodeKelas' 
                                    name='kodeKelas'
                                    {...input}
                                    keyfilter="int"
                                    />
                                    {getFormErrorMessage(meta)}
                                </div>
                                )}
                            />
                        </div>
                    </div>
                    <Field
                        name='jenisKelas'
                        render={({input, meta}) => (
                        <div className='flex-auto'>
                            <label htmlFor="jenisKelas" className='font-bold block mb-2'>Tipe Kelas</label>
                            <Dropdown 
                                id='jenisKelas' 
                                name='jenisKelas'
                                {...input}
                                options={classType}  
                                placeholder="Masukkan tipe kelas" 
                                className='w-full'
                            />
                            {getFormErrorMessage(meta)}
                        </div>
                        )}
                    />
                    <Field
                        name='namaKelas'
                        render={({input, meta}) => (
                        <div className='flex-auto mb-3'>
                            <label htmlFor="namaKelas" className='font-bold block mb-2'>Subjek Kelas</label>
                            <Dropdown 
                            id='namaKelas' 
                            name='namaKelas'
                            {...input}
                            options={subjectClass} 
                            filter
                            placeholder="Choose Subject Class" 
                            className="w-full" 
                            />
                            {getFormErrorMessage(meta)}
                        </div>
                        )}
                    />
                    <Field
                        name='noteSubjek'
                        render={({input, meta}) => (
                        <div className='flex-auto mb-3'>
                            <div className='flex gap-1 justify-content-start mb-2'>
                            <label htmlFor="noteSubjek" className='font-bold block'>Catatan</label>
                            <Button 
                                id="noteSubjek"
                                severity="help" 
                                outlined 
                                type='button'
                                label="?"
                                className="ms-1 p-1 py-0 border-circle text-xs noteSubjek w-max"
                            />
                            <Tooltip target=".noteSubjek" position='top' className='mx-1'>
                                Data opsional seperti Bahasa pengantar dan catatan penting lainnya, contoh: Full English
                            </Tooltip>
                            </div>
                            <InputText
                            id='noteSubjek'
                            name='noteSubjek'
                            {...input}
                            className='w-full'
                            />
                            {getFormErrorMessage(meta)}
                        </div>
                        )}
                    />
                    <div className='flex gap-2 w-full'>
                        <Field
                            name='level'
                            render={({input, meta}) => (
                                <div className='flex-auto mb-3 w-full'>
                                    <label htmlFor="level" className='font-bold block mb-2'>Level Kelas</label>
                                    <InputText 
                                        id='level'
                                        name='level' 
                                        {...input}
                                        keyfilter="num"
                                        className='w-full' 
                                    />
                                    {getFormErrorMessage(meta)}
                                </div>
                            )}
                        />
                        <Field
                            name='materiKelas'
                            render={({input, meta}) => (
                                <div className='flex-auto mb-3 w-full'>
                                    <div className='flex gap-1 justify-content-start mb-2'>
                                        <label htmlFor="materiKelas" className='font-bold block'>Level Materi</label>
                                        <Button 
                                            id="levelMateri"
                                            severity="help" 
                                            outlined 
                                            type='button'
                                            label="?"
                                            className="ms-1 p-1 py-0 border-circle text-xs levelMateri w-max"
                                        />
                                        <Tooltip target=".levelMateri" position='top' className='mx-1'>
                                            Khusus kelas IE (Materi 1a = 1.1/Materi 1b = 1.2)
                                        </Tooltip>
                                    </div>
                                    
                                    <InputText 
                                        id='materiKelas' 
                                        name='materiKelas'
                                        {...input}
                                        keyfilter="num"
                                        className='w-full'
                                    />
                                    {getFormErrorMessage(meta)}
                                </div>
                            )}
                        />
                    </div>
                    <Field
                        name='jadwal'
                        render={({input, meta}) => (
                        <div className='flex-auto mb-3'>
                            <label htmlFor="jadwalRilis" className='font-bold block mb-2'>Jadwal Mulai</label>
                            <Calendar 
                                id='jadwal'
                                name='jadwal'
                                {...input}
                                className='w-full'
                                showTime 
                                hourFormat='24' 
                                showIcon 
                                dateFormat='dd MM yy -'
                            />
                            {getFormErrorMessage(meta)}
                        </div>
                        )}
                    />
                    <Field
                        name='guru'
                        render={({input, meta}) => (
                        <div className='flex-auto mb-3'>
                            <label htmlFor="guru" className='font-bold block mb-2'>Guru</label>
                            <Dropdown 
                                id='guru' 
                                name='guru'
                                {...input}
                                options={listTeacher} 
                                optionLabel='nama'
                                optionValue='_id'
                                filter
                                disabled={titleDialog === "EDIT CLASS"}
                                placeholder="Choose teacher" 
                                className="w-full" 
                            />
                            {getFormErrorMessage(meta)}
                        </div>
                        )}
                    />
                    <div className='flex gap-2 lg:justify-content-end md:justify-content-end sm:justify-content-end justify-content-center w-full'>
                        <Button label="Cancel" icon="pi pi-times" type='button' outlined onClick={() => setVisibility()} loading={loading} className='w-max'/>
                        <Button label="Save" icon="pi pi-check" type='submit' className='w-max' loading={loading}/>
                    </div>
                </form>
            )}
        />
    </Dialog>
  )
}

export default FormAddUpdateClass