import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useRef, useState } from 'react';
import { deleteData, getData } from '../../../Hooks/api';
import { Toast } from 'primereact/toast';
import { FilterMatchMode } from 'primereact/api';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { formattedDateWithOutTime, getSeverity, saveAsExcelFile } from '../../../Hooks/helper';
import { Button } from 'primereact/button';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';
import DestroyDialog from '../../../Components/ConfirmDialog/DestroyDialog';

const TeacherPreference = () => {
  const toast = useRef(null);
  
  // Data Handler
  const [ listPreference, setListPreference ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ detailPreference, setDetailPreference ] = useState({});

  // Dialog Handler
  const [ destroyDialog, setDestroyDialog ] = useState(false);

  // Filter Handler
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    guru: { value: null, matchMode: FilterMatchMode.CONTAINS },
    hari: { value: null, matchMode: FilterMatchMode.CONTAINS },
    subjek: { value: null, matchMode: FilterMatchMode.CONTAINS },
    jamAwalFilter: { value: null, matchMode: FilterMatchMode.CONTAINS },
    jamAkhirFilter: { value: null, matchMode: FilterMatchMode.CONTAINS },
    jenisKelas: { value: null, matchMode: FilterMatchMode.CONTAINS },
    usia: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  
  const getAllPreference = async () => {
    setLoading(true);

    getData('get-available-schedule')
    .then((response) => {
      let preferences = response.data;

      setListPreference(preferences.flatMap(item =>
        item.jadwal.map(jadwal => {
          const _start = new Date(jadwal.jamAwal).toLocaleTimeString('it-IT', {hour: "2-digit", minute: "2-digit"})
          const _end = new Date(jadwal.jamAkhir).toLocaleTimeString('it-IT', {hour: "2-digit", minute: "2-digit"})

          let jenisKelas = item.jenisKelas
          if (Array.isArray(jenisKelas)) {
            jenisKelas.sort()
          }

          let usia = item.usia
          if (Array.isArray(usia)) {
              usia.sort()
          }

          let subjek = item.subjek;
          if (Array.isArray(subjek)) {
            subjek.sort();
          }

          return ({
            _id: item._id,
            usia,
            jenisKelas,
            subjek,
            hari: jadwal.hari,
            jamAwal: jadwal.jamAwal,
            jamAwalFilter: _start,
            jamAkhir: jadwal.jamAkhir,
            jamAkhirFilter: _end,
            jadwal_id: jadwal._id,
            guru: item.guru.nama
          })
        })
      ))
      setLoading(false);
    })
    .catch((error) => {
      toast.current.show({
        severity: 'error',
        summary: 'Failed Load Data!',
        detail: error.response.data.message,
        life: 3000
      });
      setLoading(false);
    });
  } 

  const destroyPreference = async (data) => {
    setLoading(true);

    deleteData('remove-time-available-schedule', {scheduleId: data._id, timeId: data.jadwal_id })
    .then(() => {
      getAllPreference();
      if (!loading) hideDialog("DESTROY");
    })
    .catch((error) => {
      setLoading(false);
      toast.current.show({
          severity: 'error',
          summary: 'Update Failed',
          detail: error.response.data.message,
          life: 3000
      });
    })
  }

  useEffect(() => {
    getAllPreference();
  }, []);

  const usiaBodyTemplate = (rowData) => {
    return <div className='flex flex-wrap gap-1'>
      {rowData.usia.map((age)=><Tag value={age} key={age} className="mr-2" severity={getSeverity('ageRange', age)} />)}
    </div>
  };

  const subjekBodyTemplate = (rowData) => {
    return <div className='flex flex-wrap gap-1'>
      {rowData.subjek.map((item)=><Tag value={item} key={item} className="mr-2" severity={getSeverity('subject', item)} />)}
    </div>
  };

  const kelasBodyTemplate = (rowData) => {
    return <div className='flex flex-wrap gap-1'>
      {rowData.jenisKelas.map((item)=><Tag value={item} key={item} className="mr-2" severity={getSeverity('classType', item)} />)}
    </div>
  };

  const actionBodyTemplate = (rowData) => (
    <>
      <Button 
        icon='pi pi-trash' 
        severity='danger' 
        outlined 
        size='small' 
        onClick={() => showDialog("DESTROY", rowData)}
      />
    </>
  )

  const headerTableTemplate = () => (
    <div className='flex flex-column gap-2'>
        <div className='flex lg:flex-row md:flex-row sm:flex-row flex-column justify-content-between align-items-center gap-2'>
          <h2 className='m-0 p-0 lg:text-left md:text-left sm:text-left text-center'>Teacher Preference</h2>
          <div className='flex lg:flex-row md:flex-row flex-column  gap-2'>
            <IconField iconPosition="left" className='lg:w-max md:w-max sm:w-max w-full'>
                <InputIcon className="pi pi-search"> </InputIcon>
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" className='p-inputtext-sm w-full'/>
            </IconField>
          </div> 
        </div>
    </div>
  );

  const showDialog = (type, rowData) => {
    if (type === "DESTROY") {
      setDetailPreference(rowData);
      setDestroyDialog(true);
    }
  }

  const hideDialog = (type) => {
    if (type === "DESTROY") {
      setDestroyDialog(false);
      setDetailPreference({});
    }
  }

  const exportExcel = async () => {
    let data = listPreference.map((preference) => {
        let newFormat = {
            Teacher: preference.guru,
            Subject: preference.subjek.join(", "),
            Day: preference.hari,
            'Class Type': preference.jenisKelas.join(", "),
            'Age Range': preference.usia.join(", "),
            'Start Hours': `${preference.jamAwalFilter} WIB`,
            'End Hours': `${preference.jamAkhirFilter} WIB`
        }

        return newFormat;
    });

    import('xlsx').then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(data);   
             
        const workbook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(workbook, worksheet, "Teacher Preference");
        // { Sheets: { data: worksheet }, SheetNames: ['WAITING CLASS'] };
        const excelBuffer = xlsx.write(workbook, {
            bookType: 'xlsx',
            type: 'array'
        });

        const fileName = `Teacher Preference (${formattedDateWithOutTime(new Date())})`;

        saveAsExcelFile(excelBuffer, fileName);
    });
  }
  
  const paginatorLeft = (
    <Button text size='small' icon='pi pi-refresh' onClick={() => getAllPreference()}></Button>
  );

  const paginatorRight = (
    <Button text size="small" icon='pi pi-download' onClick={() => exportExcel()}></Button>
  );

  return (
    <>
      <Toast ref={toast} />
      <DestroyDialog
        visible={destroyDialog}
        visibleHandler={() => hideDialog("DESTROY")}
        acceptAction={() => destroyPreference(detailPreference)}
        rejectAction={() => hideDialog("DESTROY")}
        titleConfirmation="Remove Schedule"
        messageConfirmation="Are sure to remove preference schedule?"
        acceptLabelButton="Destroy"
        loading={loading}
      />

      <Card>
        <DataTable
          value={listPreference}
          scrollable
          removableSort
          loading={loading}
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          filters={filters}
          globalFilterFields={['guru', 'hari', 'subjek', 'jamAwalFilter', 'jamAkhirFilter', 'usia','jenisKelas']}
          filterDisplay='row'
          size='small'
          header={headerTableTemplate}
          tableStyle={{minWidth: '50rem'}}
          className='lg:text-sm md:text-sm sm:text-sm text-xs p-datatable-sm'
          paginatorLeft={paginatorLeft}
          paginatorRight={paginatorRight}
        >
          <Column header="No" body={(data, options) => options.rowIndex + 1} />
          <Column
            field='guru'
            header='Teacher' 
            filter
            showFilterMenu={false}
            filterHeaderStyle={{padding: 0}}
            filterPlaceholder='Search'
            sortable
            style={{minWidth: '12rem'}}
          />
          <Column
            field='subjek'
            header='Subjek'
            filter
            showFilterMenu={false}
            filterPlaceholder='Search'
            sortable
            body={subjekBodyTemplate}
            align='center'
            style={{minWidth: '12rem'}}
          />
          <Column
            field='hari'
            header='Hari'
            filter
            showFilterMenu={false}
            filterPlaceholder='Search'
            sortable
            align='center'
            style={{minWidth: '7rem'}}
          />
          <Column
            field='jamAwalFilter'
            header='Jam Mulai'
            filter
            showFilterMenu={false}
            filterPlaceholder='...'
            sortable
            body={(e) => `${e.jamAwalFilter} WIB`}
            align='center'
            style={{minWidth: '6rem'}}
          />
          <Column
            field='jamAkhirFilter'
            header='Jam Selesai'
            filter
            showFilterMenu={false}
            filterPlaceholder='...'
            sortable
            body={(e) => `${e.jamAkhirFilter} WIB`}
            align='center'
            style={{minWidth: '6rem'}}
          />
          <Column
            field='jenisKelas'
            header='Jenis Kelas'
            filter
            showFilterMenu={false}
            filterPlaceholder='Search'
            sortable
            body={kelasBodyTemplate}
            style={{minWidth: '10rem'}}
          />
          <Column
            field='usia'
            header='Rentang Usia'
            filter
            showFilterMenu={false}
            filterPlaceholder='Search'
            sortable
            body={usiaBodyTemplate}
            style={{minWidth: '12rem'}}
          />
          <Column body={actionBodyTemplate}/>
        </DataTable>
      </Card>
    </>
  )
}

export default TeacherPreference
