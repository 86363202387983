import { Chart } from 'primereact/chart';
import React, { useEffect, useState } from 'react';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { List_CHART_COLOR } from '../../Hooks/helper';

const EduChart = ({ 
  chartTitle, 
  chartType = "pie", 
  data, 
  legendPosition="bottom",
  style = {width: '100%'},
  labelFormatter = () => {},
  className = 'flex justify-content-center align-items-center',
  chartClassName = '',
  displayLabel=true
}) => {
  const documentStyle = getComputedStyle(document.documentElement);
  const [ dataChart, setDataChart ] = useState({
    labels: [],
    datasets: []
  });

  const chartOptions = {
    cutout: chartType === "pie" ? '0%' : '40%',
    plugins: {
        // tooltips: {
        //   mode: 'index',
        //   intersect: false
        // },
        legend: {
            labels: {
                usePointStyle: true
            },
            position: legendPosition
        },
        title: {
            display: true,
            text: chartTitle,
            font: {
              size: 18,
              weight: 'bold',
              color: 'black'
            }
        },
        datalabels: {
            color: "#FFFFFF",
            font: {
              size: '14px'
            },
            anchor: 'end',
            align: 'start',
            formatter: labelFormatter,
            display: function(context) {
                // Hide the label if the value is 0    
                return context.dataset.data[context.dataIndex] !== 0 && displayLabel;
            },
        }
    },
    responsive: true,
    maintainAspectRatio: false,
  }

  if (chartType === "bar") {
    chartOptions.aspectRatio= 0.8;
    chartOptions.scales= {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      }
    };
  } 
  
  useEffect(() => {
    if (data && chartType === "pie") {
        const label = [];
        const datasets = {
            data: [],
            backgroundColor: []
        };

        data.map((detail, index) => {
            label.push(detail.label);
            datasets.data.push(detail.value);
            datasets.backgroundColor.push(documentStyle.getPropertyValue(List_CHART_COLOR[index]))
            
            return detail;
        });

        setDataChart({
            labels: label,
            datasets: [datasets]
        });   
    }

    if (data && chartType === "bar") {
      const label = data.label;
      const datasets = [];

      Object.entries(data.datasets).forEach(([key, value], index) => {
        let datasetFormat = {
          type: 'bar',
          label: key,
          backgroundColor: documentStyle.getPropertyValue(List_CHART_COLOR[index]),
          data: value
        }

        datasets.push(datasetFormat);
      });

      setDataChart({
        labels: label,
        datasets: datasets
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  
  return (
    <div style={style} className={className}>
      <Chart type={chartType} data={dataChart} options={chartOptions} plugins={[ChartDataLabels]} className={chartClassName}/>
    </div>
  )
}

export default EduChart
