import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { putData } from '../../../Hooks/api';
import { Dropdown } from 'primereact/dropdown';
import { getFormErrorMessage, listDay, listTime, subjectClass } from '../../../Hooks/helper';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { FieldArray } from 'react-final-form-arrays';
import ArrayMutators from "final-form-arrays";

const FormEditCR = ({
  visible,
  visibleHandler,
  detailCR,
  loading,
  setLoading,
  toast,
  refreshAction
}) => {
  const [ initialValues, setInitialValues ] = useState({
    classType: "", 
    classLevel: "",
    courseLevel: "", 
    classStatus: "",
    subject: "",
    timePref: []
  });

  useEffect(() => {
    if (detailCR && detailCR._id) setInitialValues(detailCR); 
  }, [detailCR]);

  const editCR = async (data, form) => {
    setLoading(true);

    putData(`class-registration/${data._id}/cancel-and-or-update`, data)
    .then((response) => {
        toast.current.show({
            severity:'success', 
            summary: response.data.title, 
            detail: response.data.message, 
            life: 3000,
        });
        visibleHandler();
        refreshAction(data);
        form.restart();
        setLoading(false);
    })
    .catch((error) => {
        setLoading(false);
        toast.current.show({
            severity:'error', 
            summary: error.response.data.title, 
            detail: error.response.data.message, 
            life: 3000,
        });
    });
  }

  const validateCR = (data) => {
    let errors = {}

    if (!data.courseLevel) errors.courseLevel = "Course Level is required";
    if (!data.classType) errors.classType = "Class Type is required";
    if (!data.classStatus) errors.classStatus = "Class Status is required";
    if (!data.timePref || (Array.isArray(data.timePref) && data.timePref.length === 0)) errors.data = "Time preference is required";

    return errors;
  }
  
  return (
    <Dialog
        visible={visible}
        onHide={visibleHandler}
        header="Edit Registration"
        style={{ width: '32rem' }}
        breakpoints={{ '960px': '75vm', '641px': '90vw' }}
    >
        <Form
            mutators={{...ArrayMutators}}
            initialValues={initialValues}
            onSubmit={editCR}
            validate={validateCR}
            render={({handleSubmit, values, form: {mutators: {push}}}) => (
                <form onSubmit={handleSubmit} className='flex flex-column gap-3 p-fluid'>
                    <div className='flex gap-2'>
                        <Field
                            name="subject"
                            render={({input, meta}) => (
                                <div className='w-full mb-3'>
                                    <label htmlFor='subject' className='font-bold block mb-2'>Subject Kelas</label>
                                    <Dropdown
                                        id='subject'
                                        name='subject'
                                        {...input}
                                        filter
                                        options={subjectClass}
                                        disabled={loading}
                                    />
                                    {getFormErrorMessage(meta)}
                                </div>
                            )}
                        />
                        <Field
                            name="classType"
                            render={({input, meta}) => (
                                <div className='w-full mb-3'>
                                    <label htmlFor='classType' className='font-bold block mb-2'>Tipe Kelas</label>
                                    <Dropdown
                                        id='classType'
                                        name='classType'
                                        {...input}
                                        options={['Small', 'Private']}
                                        disabled={loading}
                                    />
                                    {getFormErrorMessage(meta)}
                                </div>
                            )}
                        />
                    </div>
                    <div className="flex gap-2">
                        <Field
                            name='classLevel'
                            render={({input, meta}) => (
                                <div className='mb-3'>
                                    <label htmlFor='classLevel' className='font-bold block mb-2'>Level Kelas</label>
                                    <InputText
                                        id='classLevel'
                                        name='classLevel'
                                        {...input}
                                        keyfilter="num"
                                        disabled={loading}
                                    />
                                    {getFormErrorMessage(meta)}
                                </div>
                            )}
                        />
                        <Field
                            name='courseLevel'
                            render={({input, meta}) => (
                                <div className='mb-3'>
                                    <label htmlFor='courseLevel' className='font-bold block mb-2'>Level Materi</label>
                                    <InputText
                                        id='courseLevel'
                                        name='courseLevel'
                                        {...input}
                                        keyfilter="num"
                                        disabled={loading}
                                    />
                                    {getFormErrorMessage(meta)}
                                </div>
                            )}
                        />
                    </div>
                    <Field
                        name='classStatus'
                        render={({input, meta}) => (
                            <div className='mb-3'>
                                <label htmlFor='classStatus' className='font-bold block mb-2'>Status Kelas</label>
                                <Dropdown
                                    id='classStatus'
                                    name='classStatus'
                                    {...input}
                                    options={detailCR.classStatus ? ['CANCEL', detailCR.classStatus] : ['CANCEL'] }
                                    disabled={loading}
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />
                    <div className='flex justify-content-between'>
                        <label className='font-bold font block'>Waktu Preferensi</label>
                        <Tag value="+" className='px-2 py-0 text-lg cursor-pointer' onClick={() => push("timePref", {day: "", time: ""})}/>
                    </div>
                    <FieldArray name='timePref'>
                        {({fields}) => fields.map((name, index) => (
                            <div className='flex gap-2 align-items-center mb-1' key={name}>
                                <i className='pi pi-times' onClick={() => fields.remove(index)}></i>
                                <Field
                                    name={`${name}.day`}
                                    render={({input, meta}) => (
                                        <div className='w-full'>
                                            <Dropdown 
                                                id={`${name}.day`}
                                                name={`${name}.day`}  
                                                options={listDay}
                                                {...input}
                                                placeholder='Pilih hari'
                                            />
                                        </div>
                                    )}
                                />
                                <Field
                                    name={`${name}.time`}
                                    render={({input, meta}) => (
                                        <div className='w-full'>
                                            <Dropdown 
                                                id={`${name}.time`}
                                                name={`${name}.time`}  
                                                options={listTime}
                                                {...input}
                                                placeholder='Pilih waktu'
                                            />
                                        </div>
                                    )}
                                />
                            </div>
                        ))}
                    </FieldArray>
                    {(!values.timePref || values.timePref === null || values.timePref?.length === 0) && (<small className='p-error'>Time preference is required at least 1</small>)}
                    <div className='flex justify-content-end gap-2'>
                        <Button type='button' label='Batal' severity='danger' className='w-max' outlined onClick={visibleHandler} loading={loading}/>
                        <Button type='submit' label='Update' severity='info' className='w-max' loading={loading} />
                    </div>
                </form>
            )}
        />

    </Dialog>
  )
}

export default FormEditCR
