import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form'
import { putData } from '../../../../../Hooks/api';
import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { getSeverity, PTCAttendance } from '../../../../../Hooks/helper';

const FormDetailPTC = ({
  visibility, 
  setVisibility, 
  toast, 
  refreshAction, 
  data, 
  loading, 
  setLoading
}) => {
  const [ initialValues, setInitialValues ] = useState({
    indexPTC: "",
    statusPTC: "",
    reviewPTC: "",
    attendanceId: ""
  });

  useEffect(() => {
    if (data) setInitialValues(data);
  }, [data]);

  const validateUpdateDetailPTC = (data) => {
    let errors = {};
    
    if (!data.reviewPTC) errors.reviewPTC = "Review is required";
    if (!data.statusPTC) errors.statusPTC = "Status PTC is required";

    return errors;
  };

  const updateDetailPTC = async (data, form) => {
    setLoading(true);

    putData(`edit-review-status-ptc`, data)
    .then(() => {
        setTimeout(() => {
            refreshAction();
            toast.current.show({
                severity: 'info',
                summary: 'Update Success',
                detail: 'PTC successfully updated, keep your spirits up.',
                life: 3000
            });
            form.restart();

            if (!loading) setVisibility();
        }, 1000);
    })
    .catch((error) => {
        if (error.response && error.response.status !== 403 && error.response.data.message !== "Unauthorized: Invalid token") {
            toast.current.show({
                severity: 'error',
                summary: 'Update Failed!',
                detail: error.response.data.message,
                life: 3000
            });
        }
    })
  };

  const PTCAttendanceTemplate = (data) => (
    <Tag value={data} severity={getSeverity("attendancePTC", data)}/>
  );

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);

  const getFormErrorMessage = (meta) => {  
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  return (
    <Dialog
        visible={visibility}
        onHide={setVisibility}
        header="Update PTC Detail"
        style={{ width: '32rem' }} 
        breakpoints={{'960px': '75vw', '640px': '95vw'}}
    >
        <Form
            initialValues={initialValues}
            validate={validateUpdateDetailPTC}
            onSubmit={updateDetailPTC}
            render={({handleSubmit}) => (
                <form onSubmit={handleSubmit} className='flex flex-column gap-3 p-fluid'>
                    <div className='flex-auto'>
                        <label className='font-bold block mb-2'>Student Name</label>
                        <InputText
                            id='studentName'
                            name='studentName'
                            value={data.studentName}
                        />
                    </div>
                    <div className='flex-auto'>
                        <label className='font-bold block mb-2'>PTC Schedule</label>
                        <Calendar
                            id='jadwal'
                            name='jadwal'
                            value={new Date(data.jadwal)}
                            showIcon
                            showTime
                            hourFormat='24'
                            dateFormat='dd MM yy -'
                            disabled
                        />
                    </div>
                    <Field 
                        name='statusPTC'
                        render={({input, meta}) => (
                            <div className='flex-auto'>
                                <label className='font-bold block mb-2'>Attendance Status</label>
                                <Dropdown
                                    id='statusPTC'
                                    name='statusPTC'
                                    options={PTCAttendance}
                                    {...input}
                                    itemTemplate={PTCAttendanceTemplate}
                                    valueTemplate={PTCAttendanceTemplate}
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />
                    <Field 
                        name='reviewPTC'
                        render={({input, meta}) => (
                            <div className='flex-auto'>
                                <label className='font-bold block mb-2'>Note</label>
                                <InputTextarea
                                    id='statusPTC'
                                    name='statusPTC'
                                    rows={3}
                                    {...input}
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />
                    <div className='flex gap-2 lg:justify-content-end md:justify-content-end sm:justify-content-end justify-content-center w-full'>
                        <Button label="Cancel" icon="pi pi-times" type='button' outlined onClick={() => setVisibility()} loading={loading} className='w-max'/>
                        <Button label="Save" icon="pi pi-check" type='submit' className='w-max' loading={loading}/>
                    </div>
                </form>
            )}
        />
    </Dialog>
  )
}

export default FormDetailPTC