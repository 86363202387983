import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import ClassTypeTemplate from '../../../Components/TagTemplate/ClassTypeTemplate';
import { classStatus, classType, formattedDateWithDay, listDay, subjectClass } from '../../../Hooks/helper';
import { Button } from 'primereact/button';
import ClassStatusTemplate from '../../../Components/TagTemplate/ClassStatusTemplate';
import FormAddUpdateClass from './FormAddUpdateClass';
import DestroyDialog from '../../../Components/ConfirmDialog/DestroyDialog';
import { deleteData } from '../../../Hooks/api';
import { Dropdown } from 'primereact/dropdown';
import ClassSubjectTemplate from '../../../Components/TagTemplate/ClassSubjectTemplate';
import { InputNumber } from 'primereact/inputnumber';

const TableClass = ({
  listClass,
  loading, 
  setLoading, 
  toast, 
  headerTableTemplate, 
  filters, 
  hiddenColumn=[], 
  setListClass,
  refreshTable=()=>{},
  downLoadTable=()=>{}
}) => {
  // Dialog Handler
  const [ updateClassDialog, setUpdateClassDialog ] = useState(false);
  const [ destroyClassDialog, setDestroyClassDialog ] = useState(false);

  // Data Handler
  const [ detailClass, setDetailClass ] = useState({});

  const actionBodyTemplate = (rowData) => (
    <div className='flex gap-1'>
      <Button icon='pi pi-pencil' severity='info' size='small' outlined onClick={() => {
        rowData.jadwal = new Date(rowData.jadwal);
        setDetailClass(rowData);
        setUpdateClassDialog(true);
      }}/>
      <Button icon='pi pi-trash' severity='danger' size='small' disabled={rowData.jumlahSiswa > 0} onClick={()  => {
        setDetailClass(rowData);
        setDestroyClassDialog(true);
      }}/>
      <Link to={`/class-management/list/${rowData._id}`}><Button icon='pi pi-eye' size='small' severity='success' outlined/></Link>
    </div>
  );

  const refreshAction = (data, type="UPDATE") => {
    let index = listClass.findIndex(kelas => kelas._id === data._id);
    
    if (index !== -1) {
        if (type === "UPDATE") {
            listClass[index] = data;
            
            setListClass((currentData) => {
                if (Array.isArray(currentData)) {
                    return listClass;
                } else {
                    return {
                        ...currentData,
                        data
                    }
                }
            })
        } 

        if (type === "DESTROY") {
            listClass.splice(index, 1);
            setListClass((currentData) => {
                if (Array.isArray(currentData)) {
                    return listClass;
                } else {
                    return {
                        ...currentData,
                        listClass
                    }
                }
            })
        }
    }
  }

  const destroyClass = async () => {
    setLoading(true);

    deleteData(`class/${detailClass._id}`)
    .then(() => {
        refreshAction(detailClass, "DESTROY");
        setDestroyClassDialog(false);
        setDetailClass({});
        toast.current.show({
            severity: 'success',
            summary: 'Destroy Success',
            detail: "Class successfully destroyed, joss!",
            life: 3000
        });
        setLoading(false);
    })
    .catch((error) => {
        toast.current.show({
            severity: 'error',
            summary: 'Close Room Failed!',
            detail: error.response.data.message,
            life: 3000
        });
        setLoading(false);
    });
  }

  const subjectRowFilterTemplate = (options) => (
    <Dropdown 
      value={options.value}
      options={subjectClass} 
      onChange={(e) => options.filterApplyCallback(e.value)} 
      itemTemplate={(e) => <ClassSubjectTemplate data={e}/>} 
      placeholder="Choose" 
      className="p-column-filter" 
      showClear 
    />
  );

  const classTypeRowFilterTemplate = (options) => (
    <Dropdown 
      value={options.value}
      options={classType} 
      onChange={(e) => options.filterApplyCallback(e.value)} 
      itemTemplate={(e) => <ClassTypeTemplate data={e}/>} 
      placeholder="Choose" 
      className="p-column-filter" 
      showClear 
    />
  );

  const classStatusRowFilterTemplate = (options) => (
    <Dropdown 
      value={options.value}
      options={classStatus} 
      onChange={(e) => options.filterApplyCallback(e.value)} 
      itemTemplate={(e) => <ClassStatusTemplate data={e}/>} 
      placeholder="Choose" 
      className="p-column-filter" 
      showClear 
    />
  );

  const dayRowFilterTemplate = (options) => (
    <Dropdown 
      value={options.value}
      options={listDay} 
      onChange={(e) => options.filterApplyCallback(e.value)} 
      placeholder="Choose" 
      className="p-column-filter" 
      showClear 
    />
  );

  const sessionRangeRowFilterTemplate = (options) => {
    const [from, to] = options.value ?? [null, null];

    return (
        <div className="flex gap-1 align-items-center">
            <InputNumber value={from} onChange={(e) => options.filterApplyCallback([e.value, to])} className="w-full" placeholder="from"/>
            <p style={{margin: '0'}}>-</p>
            <InputNumber value={to} onChange={(e) => options.filterApplyCallback([from, e.value])} className="w-full" placeholder="to"/>
        </div>
    );
  };

  const paginatorRight = (<Button text icon='pi pi-download' size='small' onClick={downLoadTable}/>);
  const paginatorLeft = (<Button text icon='pi pi-refresh' size='small' onClick={refreshTable}/>);

  return (
    <>
        <DestroyDialog
            visible={destroyClassDialog}
            visibleHandler={() => {
                setDetailClass({});
                setDestroyClassDialog(false);
            }}
            acceptAction={destroyClass}
            rejectAction={() => {
                setDetailClass({});
                setDestroyClassDialog(false);
            }}
            titleConfirmation="Destroy Class"
            messageConfirmation="Are you sure destroy this class?"
            acceptLabelButton="Destroy"
            loading={loading}
        />

        <FormAddUpdateClass 
            visiblity={updateClassDialog}
            setVisibility={() => {
                setUpdateClassDialog(false);
                setDetailClass({});
            }}
            data={detailClass}
            titleDialog="EDIT CLASS"
            loading={loading}
            setLoading={setLoading}
            toast={toast}
            refreshAction={refreshAction}
        />

        <DataTable
            value={listClass}
            scrollable
            removableSort
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            filters={filters}
            filterDisplay="row"
            globalFilterFields={[
                'subject',
                'jenisKelas',
                'statusKelas',
                'level',
                'sesiBerakhir',
                'jadwalText',
                'materiKelas',
                'jumlahSiswa',
                'statusKelas',
                'guru.nama',
                'sesiTerlaksana',
                'sesiBelumTerlaksana'
            ]}
            loading={loading}
            className='lg:text-sm md:text-sm sm:text-sm text-xs p-datatable-sm'
            size='small'
            header={headerTableTemplate}
            paginatorLeft={paginatorLeft}
            paginatorRight={paginatorRight}
        >
            <Column header="No" body={(data, Options) => Options.rowIndex + 1} />
            <Column 
                field='subject'
                header="Subject"
                sortable
                filter
                showFilterMenu={false}
                filterPlaceholder='Choose'
                filterElement={subjectRowFilterTemplate}
                className='none-dragable'
                hidden={hiddenColumn.includes("subject")}
            />
            <Column 
                field='guru.nama'
                header="Teacher"
                sortable
                filter
                showFilterMenu={false}
                filterPlaceholder='Search'
                style={{ minWidth: '12rem' }}
                className='none-dragable'
                hidden={hiddenColumn.includes("guru.nama")}
            />
            <Column 
                field='jenisKelas'
                header="Class Type"
                body={(e) => <ClassTypeTemplate data={e.jenisKelas}/>}
                sortable
                filter
                showFilterMenu={false}
                filterPlaceholder='Choose'
                filterElement={classTypeRowFilterTemplate}
                align='center'
                className='none-dragable'
                hidden={hiddenColumn.includes("jenisKelas")}
            />
            <Column 
                field='level'
                header="Class Lvl."
                sortable
                align='center'
                filter
                showFilterMenu={false}
                className='none-dragable'
                hidden={hiddenColumn.includes("level")}
            />
            <Column 
                field='materiKelas'
                header="Course Lvl."
                body={(e) => e.materiKelas??e.level}
                sortable
                filter
                showFilterMenu={false}
                align='center'
                className='none-dragable'
                hidden={hiddenColumn.includes("materiKelas")}
            />
            <Column 
                field='jadwal'
                header="Schedule"
                sortable
                body={(e) => formattedDateWithDay(e.jadwal)}
                filter
                showFilterMenu={false}
                filterElement={dayRowFilterTemplate}
                alignHeader='center'
                className='none-dragable'
                hidden={hiddenColumn.includes("jadwal")}
            />
            <Column 
                field='statusKelas'
                header="Status"
                body={(e) => <ClassStatusTemplate data={e.statusKelas}/>}
                sortable
                filter
                showFilterMenu={false}
                filterElement={classStatusRowFilterTemplate}
                align='center'
                className='none-dragable'
                hidden={hiddenColumn.includes("statusKelas")}
            />
            <Column 
                field='sesiTerlaksana'
                header="Session Complete"
                sortable
                filter
                showFilterMenu={false}
                filterClear
                filterElement={sessionRangeRowFilterTemplate}
                align='center'
                style={{ minWidth: '11rem' }}
                className='none-dragable'
                hidden={hiddenColumn.includes("sesiTerlaksana")}
            />
            <Column 
                field='sesiBelumTerlaksana'
                header="Session Coming Soon"
                sortable
                filter
                showFilterMenu={false}
                filterElement={sessionRangeRowFilterTemplate}
                align='center'
                style={{ minWidth: '11rem' }}
                className='none-dragable'
                hidden={hiddenColumn.includes("sesiBelumTerlaksana")}
            />
            <Column 
                field='jumlahSiswa'
                header="Students"
                body={(e) => `${e.jumlahSiswa} Orang`}
                sortable
                filter
                showFilterMenu={false}
                align='center'
                className='none-dragable'
                hidden={hiddenColumn.includes("jumlahSiswa")}
            />
            <Column body={actionBodyTemplate}/>
        </DataTable>
    </>
  )
}

export default TableClass
