import React, { useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import OverviewCR from './OverviewCR';
import ClassRegistrationTable from './ClassRegistrationTable';

const ClassRegistration = () => {
  const toast = useRef(null);
  const [ loading, setLoading ] = useState(false);

  return (
    <>
      <Toast ref={toast}/>    
      <div className='flex flex-column gap-2'>
        <OverviewCR loading={loading} setLoading={setLoading} toast={toast}/>
        <ClassRegistrationTable loading={loading} setLoading={setLoading} toast={toast} />
      </div>
    </>
  )
}

export default ClassRegistration
