import React, { useEffect, useState } from 'react'
import { getData, postData } from '../../../Hooks/api';
import { Dialog } from 'primereact/dialog';
import { Field, Form } from 'react-final-form';
import { Dropdown } from 'primereact/dropdown';
import { classType, getFormErrorMessage, listDay, listTime, subjectClass } from '../../../Hooks/helper';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { FieldArray } from 'react-final-form-arrays';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import ArrayMutators from "final-form-arrays";

const FormAddClassRegistration = ({
  visible,
  visibleHandler,
  loading,
  setLoading,
  toast,
  refreshAction
}) => {
  const [ loadingForm, setLoadingForm ] = useState(false); 
  const [listStudent, setListStudent] = useState([]);
  const dataRegistration = {
    student: "", 
    subject: "", 
    classLevel: null,  
    courseLevel: null, 
    timePref: [],
    registrationDate: new Date()
  }

  const getAllStudent = () => {
    setLoadingForm(true);
    
    getData('class-registration/students')
    .then((response) => {
        setListStudent(response.data);
        setLoadingForm(false)
    })
    .catch((error) => {
        toast.current.show({
            severity:'error', 
            summary: 'Load Failed!', 
            detail: error.response.data.message, 
            life: 3000,
        });
        setLoadingForm(false)
    });
  }

  const registrationSubmit = (data, form) => {
    setLoading(true);
    
    postData('class-registration', data)
    .then(() => {
        toast.current.show({
            severity:'success', 
            summary: 'Registration Success', 
            detail: 'Resgitration form successfully created, farming terus!!!', 
            life: 3000,
        });
        setLoading(false);
        form.restart();
        visibleHandler(false);
        refreshAction();
    })
    .catch((error) => {        
        toast.current.show({
            severity:'error', 
            summary: 'Registration Failed!', 
            detail: error.response.data.message, 
            life: 3000,
        });
        setLoading(false);
    });
  }

  useEffect(() => {
    if (visible) getAllStudent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const validateRegistration = (data) => {
    let errors = {};

    if (!data.student) errors.student = "Student is required";
    if (!data.subject) errors.subject = "Subject is required";
    if (!data.classLevel) errors.classLevel = "Level is required";
    if (!data.courseLevel) errors.courseLevel = "Course level is required";
    if (Array.isArray(data.timePref) && data.timePref.length === 0) errors.data = "Time preference is required";

    return errors;
  }

  return (
    <Dialog 
        visible={visible} 
        header="Pendaftaran Kelas"
        onHide={visibleHandler}
        style={{ width: '32rem' }} 
        breakpoints={{ '960px': '75vm', '641px': '90vw' }}
    >
        <Form
            mutators={{...ArrayMutators}}
            onSubmit={registrationSubmit}
            initialValues={dataRegistration}
            validate={validateRegistration}
            render={({handleSubmit, form: {mutators: {push}}, values, errors}) => (
                <form onSubmit={handleSubmit} className='flex flex-column gap-3 p-fluid'>
                    <Field
                        name='student'
                        render={({input, meta}) => (
                            <div>
                                <label htmlFor='student' className='font-bold block mb-2'>Siswa</label>
                                <Dropdown 
                                    id='student' 
                                    name='student'
                                    options={listStudent}
                                    optionValue='_id'
                                    optionLabel='nama'
                                    filter 
                                    {...input}
                                    disabled={loading || loadingForm}
                                    placeholder='Pilih siswa'
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />
                    <Field
                        name='classType'
                        render={({input, meta}) => (
                            <div>
                                <label htmlFor='classType' className='font-bold block mb-2'>Jenis Kelas</label>
                                <Dropdown 
                                    id='classType' 
                                    name='classType'
                                    options={classType}
                                    {...input}
                                    disabled={loading}
                                    placeholder='Pilih Jenis Kelas'
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />
                    <Field
                        name='subject'
                        render={({input, meta}) => (
                            <div>
                                <label htmlFor='subject' className='font-bold block mb-2'>Subjek Kelas</label>
                                <Dropdown 
                                    id='subject' 
                                    name='subject' 
                                    {...input}
                                    options={subjectClass}
                                    disabled={loading}
                                    placeholder='pilih subjek'
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />
                    <div className='flex gap-2'>
                        <Field
                            name='classLevel'
                            render={({input, meta}) => (
                                <div>
                                    <label htmlFor='classLevel' className='font-bold block mb-2'>Level Kelas</label>
                                    <InputText 
                                        id='classLevel' 
                                        name='classLevel' 
                                        {...input}
                                        keyfilter="num"
                                        disabled={loading}
                                    />
                                    {getFormErrorMessage(meta)}
                                </div>
                            )}
                        />
                        <Field
                            name='courseLevel'
                            render={({input, meta}) => (
                                <div>
                                    <label htmlFor='courseLevel' className='font-bold block mb-2'>Level Materi</label>
                                    <InputText 
                                        id='courseLevel' 
                                        name='courseLevel' 
                                        {...input}
                                        keyfilter="num"
                                        disabled={loading}
                                    />
                                    {getFormErrorMessage(meta)}
                                </div>
                            )}
                        />
                    </div>
                    <Field
                        name='registrationDate'
                        render={({input, meta}) => (
                            <div>
                                <label htmlFor='registrationDate' className='font-bold block mb-2'>Tgl Pendaftaran</label>
                                <Calendar
                                    id='registrationDate'
                                    name='registrationDate'  
                                    {...input}
                                    dateFormat="dd/mm/yy"
                                    showIcon 
                                    disabled={loading}
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />
                    <div className='flex justify-content-between'>
                        <label className='font-bold font block'>Waktu Preferensi</label>
                        <Tag value="+" className='px-2 py-0 text-lg cursor-pointer' onClick={() => push("timePref", {day: "", time: ""})}/>
                    </div>
                    <FieldArray name='timePref'>
                        {({fields}) => fields.map((name, index) => (
                            <div className='flex gap-2 align-items-center mb-1' key={name}>
                                <i className='pi pi-times' onClick={() => fields.remove(index)}></i>
                                <Field
                                    name={`${name}.day`}
                                    render={({input, meta}) => (
                                        <div className='w-full'>
                                            <Dropdown 
                                                id={`${name}.day`}
                                                name={`${name}.day`}  
                                                options={listDay}
                                                {...input}
                                                placeholder='Pilih hari'
                                            />
                                        </div>
                                    )}
                                />
                                <Field
                                    name={`${name}.time`}
                                    render={({input, meta}) => (
                                        <div className='w-full'>
                                            <Dropdown 
                                                id={`${name}.time`}
                                                name={`${name}.time`}  
                                                options={listTime}
                                                {...input}
                                                placeholder='Pilih waktu'
                                            />
                                        </div>
                                    )}
                                />
                            </div>
                        ))}
                    </FieldArray>
                    {(!values.timePref || values.timePref === null || values.timePref?.length === 0) && (<small className='p-error'>Time preference is required at least 1</small>)}
                    <div className='flex gap-2 justify-content-end'>
                        <Button type='button' label="Batal" severity='danger' outlined className='w-max' onClick={visibleHandler} loading={loading} />
                        <Button type='submit' label="Daftar" severity='info' autoFocus className='w-max' loading={loading} />
                    </div>
                </form>
            )}
        />
    </Dialog>
  )
}

export default FormAddClassRegistration
