import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form';
import { postData } from '../../../../../Hooks/api';
import { copyLink } from '../../../../../Hooks/helper';
import { Link } from 'react-router-dom';

const FormLinkAlias = ({
  visible,
  visibleHandler,
  loading,
  setLoading,
  toast,
  refreshAction,
  linkAlias,
  detailClass,
  type
}) => {
  const [ initialValues, setInitialValues ] = useState({
    aliasClass: ""
  });

  useEffect(() => {
    if (linkAlias) setInitialValues({aliasClass: linkAlias});
  }, [linkAlias]);

  const validateLinkAlias = (data) => {
    let errors = {}

    if (!data.aliasClass) errors.aliasClass = "Class alias is required";
   
    return errors;
  }
  
  const updateLinkAlias = (data, form) => {
    setLoading(true);

    postData(`create-aliasclass/${detailClass._id}`, data)
    .then(() => {
      setTimeout(() => {
        refreshAction();
        toast.current.show({
          severity: 'success',
          summary: 'Update Success',
          detail: "Link alias successfully updated",
          life: 3000
        });
        form.restart();
              
        if (!loading) visibleHandler();
      }, 1000);
    })
    .catch((error) => {
      if (error.response && error.response.status !== 403 && error.response.data.message !== "Unauthorized: Invalid token") {
        toast.current.show({
          severity: 'error',
          summary: 'Update Failed!',
          detail: error.response.data.message,
          life: 3000
        });
      }
      setLoading(false);
    });
  }
  
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {  
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  const isUrl = (data, input) => {  
    let pattern = /[/?$%^&*)(><#@!., ]/g;
    let result = data.replace(pattern, "-");
    input.onChange(result);
  }
  
  return (
    type === "MAIN" ? 
    <Dialog
      header="Link Utama"
      visible={visible}
      onHide={visibleHandler}
      style={{ width: '40rem' }} breakpoints={{ '960px': '75vm', '641px': '90vw' }}
    >
      <Link to={`/edufic-room/${detailClass._id}`} target='_blank' className='text-blue-500 P-0 M-0'>{window.location.host}/edufic-room/{detailClass._id}</Link>
      <div className='flex lg:justify-content-end md:justify-content-end sm:justify-content-end justify-content-center mt-3'>
        <Button type='button' label='Close & Copy' severity='primary' className='w-max' outlined onClick={() => { visibleHandler(); copyLink(`${window.location.host}/edufic-room/${detailClass._id}`, toast)} }></Button>
      </div>
    </Dialog>
    : <Dialog
      header="Link Alias"
      visible={visible}
      onHide={visibleHandler}
      style={{ width: '40rem' }} breakpoints={{ '960px': '75vm', '641px': '90vw' }}
    >
      <Form 
        initialValues={initialValues}
        validate={validateLinkAlias}
        onSubmit={updateLinkAlias}
        render={({handleSubmit}) => (
          <form onSubmit={handleSubmit} className='flex flex-column gap-3 p-fluid'>
            <Field
              name='aliasClass'
              render={({input, meta}) => (
                <div className='flex-auto'>
                  <label htmlFor="aliasClass" className='font-bold block mb-1'>Classlink Alias</label>
                  <p className='p-0 m-0'>Link:</p>
                  <Link to={`/edufic-room/${initialValues.aliasClass}`} target='_blank' className='text-blue-500'>{window.location.host}/edufic-room/{initialValues.aliasClass}</Link>
                  <div className='flex gap-1 mt-3'>
                    <InputText 
                      id="aliasClass"
                      name='aliasClass' 
                      {...input} 
                      type='text' 
                      className='w-full'
                      onChange={(e) => isUrl(e.target.value, input)}
                    />
                    <Button type='button' icon="pi pi-copy w-max" size='small' severity='secondary' onClick={() => copyLink(`${window.location.host}/edufic-room/${initialValues.aliasClass}`, toast)}/>
                  </div>
                  
                  {getFormErrorMessage(meta)}
                </div>
              )}
            />
            <div className='flex lg:justify-content-end md:justify-content-end sm:justify-content-end justify-content-center gap-2'>
              <Button type='button' label='Cancel' severity='danger' className='w-max' outlined onClick={() => visibleHandler()} loading={loading}></Button>
              <Button type='submit' label='Update' severity='success' className='w-max' loading={loading} autoFocus disabled={detailClass.statusKelas === "Selesai"}></Button>
            </div>  
          </form>
        )}
      />
      
    </Dialog>
  )
}

export default FormLinkAlias
