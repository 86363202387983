import React, { useEffect, useState } from 'react'
import { getData, putData } from '../../Hooks/api'
import { Dialog } from 'primereact/dialog'
import { Field, Form } from 'react-final-form';
import { Dropdown } from 'primereact/dropdown';
import { getFormErrorMessage } from '../../Hooks/helper';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import RoomInfo from './RoomInfo';

const GenerateClassRoom = ({
  visible,
  visibleHandler,
  refreshAction,
  loading, 
  setLoading,
  toast
}) => {
  // Data Handler
  const initialValues = {
    idKelas: null,
    mulaiKelas: new Date(),
    durasi: 1.5,
  };
  const [ detailRoom, setDetailRoom ] = useState({
    _id: null,
    namaRoom: "",
    linkRoom: "",
    meetingID: "",
    passCode: "",
    hostKey: "",
    startSession: null,
    endSession: null
  });
  const [ listClass, setListClass ] = useState([]); 

  // Dialog Handler
  const [ assignmentRoom, setAssignmentRoom ] = useState(false);

  const getAllClass = async () => {
    setLoading(true);

    getData('class')
    .then((response) => {
        const classes = response.data;

        classes.map((kelas) => {
          kelas['subject'] = `[${kelas.guru.nama}] ${kelas.namaKelas} - ${kelas.kodeBatch}.${kelas.kodeKelas} (${kelas.jenisKelas}) - Lvl. ${kelas.level}`
          return kelas;
        });

        setListClass(classes);
        setLoading(false);
    })
    .catch((error) => {
        setLoading(false);
        toast.current.show({
            severity: 'error',
            summary: 'Load Failed',
            detail: error.response.data.message,
            life: 3000
        });
    })
  }

  useEffect(() => {
    getAllClass();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateClassRoom = async (data, form) => {
    putData('booking-room', data)
    .then((response) => {
        const room = response.data.newUpdateRoom;

        refreshAction();
        setDetailRoom({
          _id: room._id,
          namaRoom: room.namaRoom,
          linkRoom: room.linkRoom,
          meetingID: room.meetingID,
          passCode: room.passCode,
          hostKey: room.hostKey,
          startSession: new Date(room.mulaiKelas),
          endSession: new Date(room.berakhirKelas)
        });

        form.restart();
        toast.current.show({
            severity: 'success',
            summary: 'Generate Success',
            detail: 'Room successfully generated, keep your spirit up.',
            life: 3000
        });
        if (!loading) visibleHandler();
        setAssignmentRoom(true);
    })
    .catch((error) => {
        setLoading(false);
        toast.current.show({
            severity: 'error',
            summary: 'Generate Failed',
            detail: error.response.data.message,
            life: 3000
        });
    })
  }

  const validateGenerateClassRoom = (data) => {
    let errors = {};
    
    if (!data.idKelas) errors.idKelas = "Class Name is required";

    if (!data.durasi) errors.durasi = "Duration is required";
    else if (parseFloat(data.durasi) < 1.5) errors.durasi = "Min duration is 1.5 hours";

    if (!data.mulaiKelas) errors.mulaiKelas = "Start Class is required";
    
    return errors;
  }

  return (
    <>
        <Dialog
            visible={visible}
            onHide={visibleHandler}
            header="Generate Class Room"
            style={{ width: '50rem' }} 
            breakpoints={{ '960px': '75vm', '641px': '90vw' }}
        >
            <Form
                initialValues={initialValues}
                validate={validateGenerateClassRoom}
                onSubmit={generateClassRoom}
                render={({handleSubmit}) => (
                  <form onSubmit={handleSubmit} className='flex flex-column gap-3 p-fluid'>
                    <Field
                        name='idKelas'
                        render={({input, meta}) => (
                            <div className='flex-auto'>
                                <label className='font-bold block mb-2'>Class Name</label>
                                <Dropdown 
                                  id='idKelas' 
                                  name='idKelas' 
                                  className='w-full' 
                                  {...input}
                                  options={listClass}
                                  optionValue='_id'
                                  optionLabel='subject' 
                                  filter 
                                  placeholder='choose class'
                                  size="small"
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />
                    <Field
                        name='durasi'
                        render={({input, meta}) => (
                            <div className='flex-auto'>
                                <label className='font-bold block mb-2'>Duration</label>
                                <div className='p-inputgroup'> 
                                    <InputText
                                      id='durasi'
                                      name='durasi'
                                      {...input}
                                      placeholder='input duration in hour'
                                      keyfilter="num"
                                    />
                                    <span className='p-inputgroup-addon'>Hour</span>
                                </div>
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />
                    <div className='flex-auto'>
                      <label htmlFor="mulaiKelas" className='font-bold block mb-2'>Start Class</label>
                      <Calendar 
                        id='mulaiKelas'
                        name='mulaiKelas' 
                        className='w-full' 
                        value={initialValues.mulaiKelas}
                        showTime 
                        hourFormat='24' 
                        showIcon 
                        dateFormat='dd MM yy -'
                        disabled
                      />
                    </div>
                    <div className='flex gap-2 lg:justify-content-end md:justify-content-end sm:justify-content-end justify-content-center w-full'>
                        <Button label="Cancel" type='button' severity='danger' outlined onClick={visibleHandler} loading={loading} className='w-max'/>
                        <Button label="Generate" type='submit' severity='primary' className='w-max' loading={loading}/>
                    </div>
                  </form>
                )}
            />
        </Dialog>
        <RoomInfo 
          visibility={assignmentRoom} 
          visibleHandler={() => {
            setDetailRoom({
              _id: null,
              namaRoom: "",
              linkRoom: "",
              meetingID: "",
              passCode: "",
              hostKey: "",
            })
            setAssignmentRoom(false);
          }} 
          detailRoom={detailRoom}
          toast={toast}
        />
    </>
  )
}

export default GenerateClassRoom
