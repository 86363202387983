import { Button } from 'primereact/button'
import { Card } from 'primereact/card'
import React from 'react'
import { Link } from 'react-router-dom'

const NameCard = ({name, note, wa}) => {
  return (
    <Card className='lg:w-4 md:w-5 sm:w-8 w-full'>
        <div>
          <label className='font-bold block'>Name</label>
          <span className='p-0 m-0'>{name}</span>
        </div>
        <div>
          <label className='font-bold block'>Note</label>
          <p className='p-0 m-0 text-sm'>{note}</p>
        </div>
        <div className='flex justify-content-end mt-4'>
          <Link to={wa} target='_blank'>
            <Button label='Whatsapp' icon='pi pi-whatsapp' severity='success' size='small'/>
          </Link>
        </div>
    </Card>
  )
}

export default NameCard
