import React from 'react';
import { getSeverity } from '../../Hooks/helper';
import { Tag } from 'primereact/tag';

const StatusSalaryTemplate = ({data}) => {
  return (
    <Tag value={data} severity={getSeverity("Salary", data)} />
  )
}

export default StatusSalaryTemplate
