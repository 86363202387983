import { TabPanel, TabView } from 'primereact/tabview';
import React, { useEffect, useState } from 'react';
import { getData } from '../../../Hooks/api';
import { formattedDateWithOutTime, getAgeByDate, getDiffDate, saveAsExcelFile } from '../../../Hooks/helper';
import TableClassRegistration from './TableClassRegistration';
import { FilterMatchMode } from 'primereact/api';
import { IconField } from 'primereact/iconfield';
import { InputText } from 'primereact/inputtext';
import { InputIcon } from 'primereact/inputicon';
import { Button } from 'primereact/button';
import FormAddClassRegistration from './FormAddClassRegistration';

const ClassRegistrationTable = ({toast, loading, setLoading}) => {
  const [ loadingActiveClass, setLoadingActiveClass ] = useState(false);
  const [lazyParams, setLazyParams] = useState({
    first: 0,  // The index of the first row
    rows: 5,  // Number of rows to display per page
    page: 0,   // The current page
    sortField: 'registrationDate', // The field to sort by
    sortOrder: 1,  // 1 = ascending, -1 = descending
    filters: {
        'student.nama': { value: null },
        'student.nomor': { value: null },
        'student.usia': { value: null },
        subject: { value: null },
        courseLevel: { value: null },
        classLevel: { value: null },
        classStatus: { value: null },
        paymentStatus: { value: null },
        payment: { value: null },
        classType: { value: null },
        invoice: { value: null }
    }
  });
  
  // Data Handler
  const [ totalRecords, setTotalRecords ] = useState(0);
  const [ listCR, setListCR ] = useState({
    WAITING: [],
    MOVECLASS: [],
    CANCEL: [],
    ACTIVE: [],
  }); 
  const [ listAllActiveCR, setListAllActiveCR ] = useState([]);

  // Dialog Handler
  const [ addCRDialog, setAddCRDialog ] = useState(false);

  // Filter Handler
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    'student.nama': {value: null, matchMode:  FilterMatchMode.CONTAINS },
    'student.nomor': {value: null, matchMode: FilterMatchMode.CONTAINS },
    'student.usia': {value: null, matchMode: FilterMatchMode.CUSTOM},
    subject: {value: null, matchMode: FilterMatchMode.CONTAINS },
    courseLevel: {value: null, matchMode: FilterMatchMode.EQUALS },
    classLevel: { value: null, matchMode: FilterMatchMode.EQUALS },
    classStatus: { value: null, matchMode: FilterMatchMode.EQUALS },
    paymentStatus: { value: null, matchMode: FilterMatchMode.EQUALS },
    payment: { value: null, matchMode: FilterMatchMode.CONTAINS },
    classType: { value: null, matchMode: FilterMatchMode.CONTAINS },
    invoice: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const getActiveCR = () => {
    setLoadingActiveClass(true);

    getData(`class-registration/active?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&sortField=${lazyParams.sortField}&sortOrder=${lazyParams.sortOrder}&filters=${JSON.stringify(lazyParams.filters)}`)
    .then((response) => {
        response.data.record.map((register) => {
            register.student.usia = getAgeByDate(register.student.tanggalLahir);
            return register;
        });

        setListCR((currentData) => ({
            ...currentData,
            ACTIVE: response.data.record
        }));

        setTotalRecords(response.data.total);
        setLoadingActiveClass(false)
    })
    .catch((error) => {
        toast.current.show({
            severity: 'error',
            summary: 'Load Failed!',
            detail: error.response.data.message,
            life: 3000
        });
        setLoadingActiveClass(false);
    })
  }

  const getNonActiveCR = () => {
    setLoading(true);
    getData('class-registration/non-active')
    .then((response) => {
        const today = new Date();
        
        response.data.forEach((register) => {    
            register.student.usia = getAgeByDate(register.student.tanggalLahir);
            register.agingDayWaiting = getDiffDate(register.registrationDate, today);
            register.schedule = register.timePref.map((pref) => `${pref.day} - ${pref.time}`).join(", ");
            return register;
        });

        setListCR((currentData) => ({
            ...currentData,
            WAITING: response.data.filter((classRegistration) => classRegistration.classStatus === "WAITING"),
            CANCEL: response.data.filter((classRegistration) => classRegistration.classStatus === "CANCEL"),
            MOVECLASS: response.data.filter((classRegistration) => classRegistration.classStatus === "MOVECLASS")
        }));
        setLoading(false);
    })
    .catch((error) => {
        toast.current.show({
            severity: 'error',
            summary: 'Load Failed!',
            detail: error.response.data.message,
            life: 3000
        });
        setLoading(false);
    })
  }

  const getAllActiveCR = () => {
    getData('class-registration/all-active')
    .then((response) => {
      response.data.forEach((cr) => {
        cr.student.usia = getAgeByDate(cr.student.tanggalLahir);
      });
      setListAllActiveCR(response.data);
    })
    .catch((error) => {
      toast.current.show({
        severity: 'error',
        summary: 'Load Failed!',
        detail: error.response.data.message,
        life: 3000
      });
      setLoading(false);
    })
  }

  useEffect(() => {
    getActiveCR();
    getNonActiveCR();
    getAllActiveCR();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getActiveCR();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lazyParams]);

  const onLazyLoad = (event) => {
    setLazyParams({
        ...lazyParams,
        ...event
    });
  };

  const headerTableTemplate = () => (
    <div className='flex flex-column gap-2'>
        <div className='flex lg:flex-row md:flex-row sm:flex-row flex-column justify-content-between align-items-center gap-2'>
          <h2 className='m-0 p-0 lg:text-left md:text-left sm:text-left text-center'>Class Registration</h2>
          <div className='flex lg:flex-row md:flex-row sm:flex-row flex-column gap-1'>
            <IconField iconPosition="left" className='lg:w-max md:w-max sm:w-max w-full'>
                <InputIcon className="pi pi-search"> </InputIcon>
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" className='p-inputtext-sm w-full'/>
            </IconField>
            <Button label='Register +' size='small' onClick={() => setAddCRDialog(true)} />
          </div>
        </div>
    </div>
  );

  const exportExcel = async (listCR, titleFile) => {
    let data = listCR.map((cr) => {
      let newFormat;

      newFormat = {
        _id: cr._id,
        "Nama Siswa": cr.student.nama,
        "NIE": cr.student.nie,
        "Usia": cr.student.usia,
        Status: cr.classStatus,
        'WA': cr.student.nomor,
        'Class Subject': cr.subject,
        'Class Type': cr.classType,
        'Class Level': cr.classLevel,
        'Course Level': cr.courseLevel,
        "Registration Date": formattedDateWithOutTime(cr.registrationDate),
      }

      return newFormat;
    });

    import('xlsx').then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(data);   
      
        const workbook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(workbook, worksheet, "List Class");
        // { Sheets: { data: worksheet }, SheetNames: ['WAITING CLASS'] };
        const excelBuffer = xlsx.write(workbook, {
            bookType: 'xlsx',
            type: 'array'
        });

        const fileName = `${titleFile} (${formattedDateWithOutTime(new Date())})`;

        saveAsExcelFile(excelBuffer, fileName);
    });
  }

  return (
    <>
        <FormAddClassRegistration
            visible={addCRDialog}
            visibleHandler={() => setAddCRDialog(false)}
            loading={loading}
            setLoading={setLoading}
            toast={toast}
            refreshAction={() => getNonActiveCR()}
        />
        <TabView>
            <TabPanel header='Waiting'>
                <TableClassRegistration 
                    listCR={listCR.WAITING} 
                    setListCR={setListCR}
                    loading={loading}
                    setLoading={setLoading}
                    toast={toast}
                    filters={filters}
                    refreshTable={getNonActiveCR}
                    headerTableTemplate={headerTableTemplate}
                    hiddenColumn={['noteClass']}
                    downLoadTable={() => exportExcel(listCR.WAITING, "Waiting")}
                />
            </TabPanel>
            <TabPanel header='Move Class'>
                <TableClassRegistration 
                    listCR={listCR.MOVECLASS} 
                    setListCR={setListCR}
                    loading={loading}
                    setLoading={setLoading}
                    toast={toast}
                    filters={filters}
                    refreshTable={getNonActiveCR}
                    headerTableTemplate={headerTableTemplate}
                    downLoadTable={() => exportExcel(listCR.MOVECLASS, "Move Class")}
                />
            </TabPanel>
            <TabPanel header='Cancel'>
                <TableClassRegistration 
                    listCR={listCR.CANCEL} 
                    setListCR={setListCR}
                    loading={loading}
                    setLoading={setLoading}
                    toast={toast}
                    filters={filters}
                    refreshTable={getNonActiveCR}
                    headerTableTemplate={headerTableTemplate}
                    hiddenColumn={['noteClass', 'schedule']}
                    downLoadTable={() => exportExcel(listCR.CANCEL, "Cancel")}
                />
            </TabPanel>
            <TabPanel header='Active'>
                <TableClassRegistration 
                    listCR={listCR.ACTIVE} 
                    setListCR={setListCR}
                    loading={loadingActiveClass}
                    setLoading={setLoadingActiveClass}
                    toast={toast}
                    filters={filters}
                    totalRecords={totalRecords}
                    typeTable='ACTIVE'
                    lazyParams={lazyParams}
                    onLazyLoad={onLazyLoad}
                    refreshTable={getActiveCR}
                    downLoadTable={() => exportExcel(listAllActiveCR, "Active")}
                />
            </TabPanel>
        </TabView>
    </>
  )
}

export default ClassRegistrationTable
