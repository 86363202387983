import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable'
import React, { useState } from 'react'
import WALinkTemplate from '../../../Components/TagTemplate/WALinkTemplate';
import ClassSubjectTemplate from '../../../Components/TagTemplate/ClassSubjectTemplate';
import ClassTypeTemplate from '../../../Components/TagTemplate/ClassTypeTemplate';
import PaymentStatusTemplate from '../../../Components/TagTemplate/PaymentStatusTemplate';
import { classRegistrationStatus, formatIDRCurrency, formattedDateWithOutTime, paymentStatus, subjectClass } from '../../../Hooks/helper';
import ClassStatusRegistrationTemplate from '../../../Components/TagTemplate/ClassStatusRegistrationTemplate';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import FormEditCR from './FormEditCR';
import { deleteData } from '../../../Hooks/api';
import DestroyDialog from '../../../Components/ConfirmDialog/DestroyDialog';
import FormUpdatePayment from '../../FinanceManagement/DelayedPaymentCR/FormUpdatePayment';
import DetailClassCR from './DetailClassCR';
import FormAssignClass from './FormAssignClass';

const TableClassRegistration = ({
  listCR, 
  setListCR,
  loading, 
  setLoading, 
  toast, 
  headerTableTemplate, 
  filters,
  totalRecords=0,
  typeTable="NONACTIVE",
  lazyParams={},
  hiddenColumn=[],
  onLazyLoad=()=>{},
  refreshTable=()=>{},
  downLoadTable=()=>{}
}) => {
  // Data Handler
  const [ detailCR, setDetailCR ] = useState({});

  // Dialog Handler
  const [ editCRDialog, setEditCRDialog ] = useState(false);
  const [ destroyCRDialog, setDestroyCRDialog ] = useState(false);
  const [ updatePaymentDialog, setUpdatePaymentDialog ] = useState(false);
  const [ detailCRDialog, setDetailCRDialog ] = useState(false);
  const [ assignClassDialog, setAssignClassDialog ] = useState(false);

  const paginatorRight = (<Button text icon='pi pi-download' size='small' onClick={downLoadTable}/>);
  const paginatorLeft = (<Button text icon='pi pi-refresh' size='small' onClick={refreshTable}/>); 
  
  const actionTemplate = (data) => {
    return (
        <div className='flex gap-1'>
            { ((data.classStatus === "WAITING" ||  data.classStatus === "MOVECLASS") && <Button icon="pi pi-pencil" severity='primary' outlined size='small' onClick={() => {
                setDetailCR(data);
                setEditCRDialog(true);
            }}/>) }
            { ((data.classStatus === "WAITING" || data.classStatus === "CANCEL") && data.paymentStatus === "UNPAID") && <Button icon="pi pi-trash" severity='danger' size='small' onClick={() => {
                setDetailCR(data);
                setDestroyCRDialog(true);
            }}/> }
            <Button icon="pi pi-dollar" severity='success' outlined size='small' onClick={() => {
                setDetailCR(data);
                setUpdatePaymentDialog(true);
            }}/>
            { (["MOVECLASS", "WAITING"].includes(data.classStatus)) && <Button icon="pi pi-sitemap" size='small' onClick={() => {
                setDetailCR(data);
                setAssignClassDialog(true);
            }}/> }
            { (["MOVECLASS", "ACTIVE"].includes(data.classStatus)) && <Button icon="pi pi-eye" label='Detail' severity='info' size='small' onClick={() => {
                setDetailCRDialog(true);
                setDetailCR(data);
            }} /> }
        </div>
    )
  }

  const ClassStatusRowFilterTemplate = (options) => {
    return (
        <Dropdown 
          value={options.value} 
          options={classRegistrationStatus} 
          onChange={(e) => options.filterApplyCallback(e.value)} 
          itemTemplate={(e) => <ClassStatusRegistrationTemplate data={e}/>} 
          placeholder="Choose" 
          className="p-column-filter" 
          style={{maxWidth: '10rem' }} 
          showClear 
        />
    );
  };

  const PaymentStatusRowFilterTemplate = (options) => (
    <Dropdown 
      value={options.value}
      options={paymentStatus} 
      onChange={(e) => options.filterApplyCallback(e.value)} 
      itemTemplate={(e) => <PaymentStatusTemplate data={e} />} 
      placeholder="Choose" 
      className="p-column-filter" 
      style={{maxWidth: '10rem' }} 
      showClear 
    />
  );

  const SubjectRowFilterTemplate = (options) => (
    <Dropdown 
      value={options.value}
      options={subjectClass} 
      onChange={(e) => options.filterApplyCallback(e.value)} 
      itemTemplate={(e) => <ClassSubjectTemplate data={e}/>} 
      placeholder="Choose" 
      className="p-column-filter" 
      style={{maxWidth: '10rem' }} 
      showClear 
    />
  );

  const AgeRowFilterTemplate = (options) => {
    const [from, to] = options.value ?? [null, null];

    return (
        <div className="flex gap-1 align-items-center">
            <InputNumber value={from} onChange={(e) => options.filterApplyCallback([e.value, to])} className="w-full" placeholder="from" />
            <p style={{margin: '0'}}>-</p>
            <InputNumber value={to} onChange={(e) => options.filterApplyCallback([from, e.value])} className="w-full" placeholder="to" />
        </div>
    );
  };

  const ClassTypeRowFilterTemplate = (options) => (
    <Dropdown 
      value={options.value}
      options={["Private", "Small"]} 
      onChange={(e) => options.filterApplyCallback(e.value)} 
      itemTemplate={(e) => <ClassTypeTemplate data={e}/>} 
      placeholder="Choose" 
      className="p-column-filter" 
      style={{maxWidth: '10rem' }} 
      showClear 
    />
  );

  const refreshAction = (data) => {
    let index = listCR.findIndex(cr => cr._id === data._id);
    listCR[index] = data;
    setListCR((currentData) => ({
        ...currentData, listCR
    }));
  };

  const destroyClassRegistration = () => {
    setLoading(true);

    deleteData(`class-registration/${detailCR._id}`, {})
    .then(() => {
        toast.current.show({
            severity:'success', 
            summary: 'Class registration deleted', 
            detail: 'Class registration successfully deleted, enjoy your day.', 
            life: 3000,
        });
        
        let index = listCR.findIndex(cr => cr._id === detailCR._id);
        listCR.splice(index, 1);
        setListCR((currentData) => ({
            ...currentData,
            ACTIVE: listCR
        }));

        setDestroyCRDialog(false);
        setLoading(false);
    })
    .catch((error) => {
        setLoading(false);
        toast.current.show({
            severity:'error', 
            summary: error.response.data.title, 
            detail: error.response.data.message, 
            life: 3000,
        });
    })
  }
  
  return (
    <>
        <FormEditCR
            visible={editCRDialog}
            visibleHandler={() => {
                setDetailCR({});
                setEditCRDialog(false);
            }}
            detailCR={detailCR}
            loading={loading}
            setLoading={setLoading}
            toast={toast}
            refreshAction={refreshAction}
        />

        <DestroyDialog
            visible={destroyCRDialog}
            visibleHandler={() => {
                setDetailCR({});
                setDestroyCRDialog(false);
            }}
            acceptAction={destroyClassRegistration}
            rejectAction={() => {
                setDetailCR({});
                setDestroyCRDialog(false);
            }}
            titleConfirmation="Destroy Registration"
            messageConfirmation="Are you sure destroy this registration?"
            acceptLabelButton="Destroy"
            loading={loading}
        />

        <FormUpdatePayment
            visible={updatePaymentDialog}
            visibleHandler={() => {
                setUpdatePaymentDialog(false);
                setDetailCR({});
            }}
            detailPayment={detailCR}
            loading={loading}
            setLoading={setLoading}
            refreshAction={refreshTable}
            toast={toast}
        />

        <DetailClassCR 
            visible={detailCRDialog}
            visibleHandler={() => {
                setDetailCR({});
                setDetailCRDialog(false);
            }}
            detailCR={detailCR}
        />

        <FormAssignClass
            visible={assignClassDialog}
            visibleHandler={() => {
                setDetailCR({});
                setAssignClassDialog(false);
            }}
            detailCR={detailCR}
            loading={loading}
            setLoading={setLoading}
            toast={toast}
            refreshAction={refreshTable}
        />

        {typeTable === "ACTIVE" ? <DataTable
            value={listCR}
            scrollable
            removableSort
            paginator
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            onSort={onLazyLoad} 
            onFilter={onLazyLoad}
            filters={lazyParams.filters} 
            filterDisplay='row'
            totalRecords={totalRecords}
            lazy
            rows={lazyParams.rows}
            first={lazyParams.first}
            onPage={onLazyLoad}
            loading={loading}
            className='lg:text-sm md:text-sm sm:text-sm text-xs p-datatable-sm'
            size='small'
            header="Active Class Registration"
            paginatorLeft={paginatorLeft}
            paginatorRight={paginatorRight}
        >
            <Column 
                frozen 
                header="No" 
                body={(data, Options) => Options.rowIndex + 1}
            />
            <Column 
                header="Siswa" 
                field='student.nama' 
                frozen 
                filter
                filterPlaceholder='Search student'
                showFilterMenu={false}
                style={{ minWidth: '12rem' }}
                className='none-dragable'
            />
            <Column 
                header="Usia"
                field='student.usia' 
                body={(data) => (`${data.student.usia} Tahun`)} 
                style={{ minWidth: '5rem' }}
                className='none-dragable'
            />
            <Column 
                header="Kontak" 
                field='student.nomor' 
                body={(e) => <WALinkTemplate data={e.student.nomor}/>}
                style={{ minWidth: '10rem' }}
            />
            <Column 
                header="Subjek" 
                field='subject'
                sortable
                filter
                filterElement={SubjectRowFilterTemplate}
                showFilterMenu={false}
                align='center'
                style={{ minWidth: '5rem' }}
                className='none-dragable'
                body={(e) => <ClassSubjectTemplate data={e.subject}/>}
            />
            <Column 
                header="Tipe" 
                field='classType'
                sortable
                filter
                filterElement={ClassTypeRowFilterTemplate}
                showFilterMenu={false}
                align='center'
                style={{ minWidth: '5rem' }}
                className='none-dragable'
                body={(e) => <ClassTypeTemplate data={e.classType}/>}
            />
            <Column 
                header="Class Level" 
                field='classLevel'
                sortable
                filter
                showFilterMenu={false}
                align='center'
                style={{ minWidth: '5rem' }}
                className='none-dragable'
            />
            <Column 
                header="Course Level" 
                field='courseLevel'
                sortable
                filter
                showFilterMenu={false}
                align='center'
                style={{ minWidth: '5rem' }}
                className='none-dragable'
            />
            <Column 
                header="Registration Status" 
                field='classStatus'
                sortable
                filterElement={ClassStatusRowFilterTemplate}
                align='center'
                style={{ minWidth: '8rem' }}
                className='none-dragable'
                body={(e) => <ClassStatusRegistrationTemplate data={e.classStatus}/>}
            />
            <Column 
                header="Status Bayar" 
                field='paymentStatus'
                sortable
                filter
                filterElement={PaymentStatusRowFilterTemplate}
                showFilterMenu={false}
                align='center'
                style={{ minWidth: '7rem' }}
                className='none-dragable'
                body={(e) => <PaymentStatusTemplate data={e.paymentStatus} />}
            />
            <Column 
                header="Invoice" 
                field='invoice'
                sortable
                filter
                showFilterMenu={false}
                body={(e) => formatIDRCurrency(e.invoice)}
                align='right'
                style={{ minWidth: '8rem' }}
                className='none-dragable'
            />
            <Column 
                header="Terbayar" 
                field='payment'
                filter
                showFilterMenu={false}
                sortable
                body={(e) => formatIDRCurrency(e.payment)}
                align='right'
                style={{ minWidth: '8rem' }}
                className='none-dragable'
            />
            <Column 
                header="Register Date" 
                field='registrationDate'
                body={(e) => formattedDateWithOutTime(e.registrationDate)}
                sortable
                align='center'
                style={{ minWidth: '10rem' }}
                className='none-dragable'
                hidden={hiddenColumn.includes("registrationDate")}
            />
            <Column body={actionTemplate}></Column>
        </DataTable> : <DataTable
            value={listCR}
            scrollable
            removableSort
            paginator
            rows={5}
            rowsPerPageOptions={[5, 10, 25, 50]}
            filters={filters}
            filterDisplay='row'
            globalFilterFields={[
                'student.nama', 
                'student.usia', 
                'student.nomor', 
                'subject', 
                'classType', 
                'classLevel', 
                'courseLevel', 
                'classStatus', 
                'paymentStatus', 
                'invoice', 
                'payment'
            ]}
            loading={loading}
            className='lg:text-sm md:text-sm sm:text-sm text-xs p-datatable-sm'
            size='small'
            header={headerTableTemplate}
            paginatorLeft={paginatorLeft}
            paginatorRight={paginatorRight}
        >
            <Column 
                frozen 
                header="No" 
                body={(data, Options) => Options.rowIndex + 1}
            />
            <Column 
                header="Student" 
                field='student.nama' 
                frozen 
                filter
                filterPlaceholder='Search student'
                showFilterMenu={false}
                style={{ minWidth: '12rem' }}
                className='none-dragable'
                hidden={hiddenColumn.includes("student.nama")}
            />
            <Column 
                header="Age"
                field='student.usia' 
                filter
                showFilterMenu={false}
                filterElement={AgeRowFilterTemplate}
                body={(data) => (`${data.student.usia} Tahun`)} 
                align='center'
                style={{ minWidth: '10rem' }}
                className='none-dragable'
                hidden={hiddenColumn.includes("student.usia")}
            />
            <Column 
                header="Contact" 
                field='student.nomor'
                filter
                showFilterMenu={false} 
                body={(e) => <WALinkTemplate data={e.student.nomor}/>}
                style={{ minWidth: '10rem' }}
                hidden={hiddenColumn.includes("student.nomor")}
            />
            <Column 
                header="Subject" 
                field='subject'
                sortable
                filter
                filterElement={SubjectRowFilterTemplate}
                showFilterMenu={false}
                align='center'
                style={{ minWidth: '5rem' }}
                className='none-dragable'
                body={(e) => <ClassSubjectTemplate data={e.subject}/>}
                hidden={hiddenColumn.includes("subject")}
            />
            <Column 
                header="Class Type" 
                field='classType'
                sortable
                filter
                filterElement={ClassTypeRowFilterTemplate}
                showFilterMenu={false}
                align='center'
                style={{ minWidth: '5rem' }}
                className='none-dragable'
                body={(e) => <ClassTypeTemplate data={e.classType}/>}
                hidden={hiddenColumn.includes("classType")}
            />
            <Column 
                header="Class Level" 
                field='classLevel'
                sortable
                filter
                showFilterMenu={false}
                align='center'
                style={{ minWidth: '5rem' }}
                className='none-dragable'
                hidden={hiddenColumn.includes("classLevel")}
            />
            <Column 
                header="Course Level" 
                field='courseLevel'
                sortable
                filter
                showFilterMenu={false}
                align='center'
                style={{ minWidth: '5rem' }}
                className='none-dragable'
                hidden={hiddenColumn.includes("courseLevel")}
            />
            <Column 
                header="Registration Status" 
                field='classStatus'
                sortable
                filter
                showFilterMenu={false}
                filterElement={ClassStatusRowFilterTemplate}
                align='center'
                style={{ minWidth: '8rem' }}
                className='none-dragable'
                body={(e) => <ClassStatusRegistrationTemplate data={e.classStatus}/>}
                hidden={hiddenColumn.includes("classStatus")}
            />
            <Column 
                header="Payment Status" 
                field='paymentStatus'
                sortable
                filter
                filterElement={PaymentStatusRowFilterTemplate}
                showFilterMenu={false}
                align='center'
                style={{ minWidth: '7rem' }}
                className='none-dragable'
                body={(e) => <PaymentStatusTemplate data={e.paymentStatus} />}
                hidden={hiddenColumn.includes("paymentStatus")}
            />
            <Column 
                header="Invoice" 
                field='invoice'
                sortable
                filter
                showFilterMenu={false}
                body={(e) => formatIDRCurrency(e.invoice)}
                align='right'
                style={{ minWidth: '8rem' }}
                className='none-dragable'
                hidden={hiddenColumn.includes("invoice")}
            />
            <Column 
                header="Paid" 
                field='payment'
                filter
                showFilterMenu={false}
                sortable
                body={(data) => formatIDRCurrency(data.payment)}
                align='right'
                style={{ minWidth: '8rem' }}
                className='none-dragable'
                hidden={hiddenColumn.includes("payment")}
            />
            <Column 
                header="Aging Day" 
                field='agingDayWaiting'
                body={(e) => `${e.agingDayWaiting} Hari`}
                sortable
                align='center'
                style={{ minWidth: '5rem' }}
                className='none-dragable'
                hidden={hiddenColumn.includes("agingDayWaiting")}
            />
            <Column 
                header="Register Date" 
                field='registrationDate'
                body={(e) => formattedDateWithOutTime(e.registrationDate)}
                sortable
                align='center'
                style={{ minWidth: '10rem' }}
                className='none-dragable'
                hidden={hiddenColumn.includes("registrationDate")}
            />
            <Column 
                header="Time Preference" 
                field='schedule'
                sortable
                filter
                showFilterMenu={false}
                style={{ minWidth: '15rem' }}
                className='none-dragable'
                hidden={hiddenColumn.includes("schedule")}
            />
            <Column 
                header="Reason" 
                field='noteClass'
                sortable
                filter
                showFilterMenu={false}
                style={{ minWidth: '15rem' }}
                className='none-dragable'
                hidden={hiddenColumn.includes("noteClass")}
            />
            <Column body={actionTemplate}/>
        </DataTable>}
    </>
  ); 
}

export default TableClassRegistration
