import React from 'react'
import CanvasJSReact from '@canvasjs/react-charts';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const CanvasChart = ({
  data, 
  chartName, 
  graphLabel, 
  typeLine="MultiLine",
  titleAxisY="Rupiah",
  limitData=undefined
}) => {
  var dataChart = [];

  if (Object.keys(data).length > 0 && graphLabel.length > 1) {
    graphLabel.forEach((label) => {
        var dataSeries = {type: 'line', name: label.label, includeInLimits: true};
        var dataPoints = [];

        data[label.name].forEach((oldData) => {
            dataPoints.push({
                x: new Date(oldData.dateTrans),
                y: oldData.value
            });
        });

        dataSeries.dataPoints = dataPoints;
        dataChart.push(dataSeries);
    });
  }
    
  const options = {
    zoomEnabled: true,
    scrollEnabled: true,
    animationEnabled: true,
    title : {
      text: chartName
    },
    toolTip: {
      shared: true
    },
    axisY: {
      title: titleAxisY,
      tickLength: 0,
    },
    axisX: {
        valueFormatString: "DD MMM YYYY",
        title: "Date Transaction",
    },
    data: dataChart
  }

  if (limitData) {
    options.axisX.viewportMinimum = limitData.start;
    options.axisX.viewportMaximum = limitData.end;
  }

  return (
    <div className='w-full'>
        <CanvasJSChart options={options} />
    </div>
  )
}

export default CanvasChart
