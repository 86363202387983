import { DataTable } from 'primereact/datatable'
import React, { useEffect, useRef, useState } from 'react'
import { getData, putData } from '../../../Hooks/api';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import ClassTypeTemplate from '../../../Components/TagTemplate/ClassTypeTemplate';
import ClassStatusTemplate from '../../../Components/TagTemplate/ClassStatusTemplate';
import TeacherTypeTemplate from '../../../Components/TagTemplate/TeacherTypeTemplate';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import { Card } from 'primereact/card';
import { FilterMatchMode } from 'primereact/api';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';
import AcceptDialog from '../../../Components/ConfirmDialog/AcceptDialog';
import { formattedDateWithOutTime, saveAsExcelFile } from '../../../Hooks/helper';

const DelayedClass = () => {
  const toast = useRef(null);
  const [ loading, setLoading ] = useState(false);
  
  // Data Handler
  const [ listDelayedClass, setListDelayedClass ] = useState([]);
  const [ indexGuru, setIndexGuru ] = useState(null);

  // Dialog Handler
  const [ followUpStatusDialog, setFollowUpStatusDialog ] = useState(false);

  // Filter Handler
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const getListDelayedClass = async () => {
    setLoading(true);

    getData('get-last-delayed')
    .then((response) => {
      let dataDelayedClass = response.data.detail;

      dataDelayedClass.forEach((dc) => {
        dc.subject = `${dc.kelas.kodeBatch}.${dc.kelas.kodeKelas} ${dc.kelas.namaKelas}`;
        dc.sesi = dc.sesi.split(',').join(", ");
      });

      setListDelayedClass(response.data.detail);
      setLoading(false);
    })
    .catch((error) => {
      toast.current.show({
        severity: 'error',
        summary: 'Load Failed!',
        detail: error.response.data.message,
        life: 3000
      });

      setLoading(false);
    })
  }

  const calculateDelayedClassTotal = (namaGuru) => {
    let total = 0;

    if (listDelayedClass) {
      for (let kelas of listDelayedClass) {
        if (kelas.namaGuru === namaGuru) total++;
      }
    }

    return total;
  };

  const followUp = async () => {
    setLoading(true);

    putData('change-status', {indexGuru: indexGuru})
    .then(() => {
      getListDelayedClass();
      toast.current.show({
        severity: 'success',
        summary: 'Update Success',
        detail: 'status delayed successfully followed',
        life: 3000
      });
      setFollowUpStatusDialog(false);
      setLoading(false);
    })
    .catch((error) => {
      toast.current.show({
        severity: 'error',
        summary: 'Load Failed!',
        detail: error.response.data.message,
        life: 3000
      });

      setLoading(false);
    })
  }

  useEffect(() => {
    getListDelayedClass();
  }, []);

  const exportExcel = async (listDelayedClass, titleFile) => {
    let data = listDelayedClass.map((dc) => {
        let newFormat = {
          Subject: dc.subject,
          Teacher: dc.namaGuru,
          'Class Type': dc.kelas.jenisKelas,
          'Class Level': dc.kelas.level,
          Status: dc.kelas.statusKelas,
          Session: dc.sesi,
          FollowUp: dc.statusGuruSelesai
        }

        return newFormat;
    });

    import('xlsx').then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(data);   
      
        const workbook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(workbook, worksheet, "Delayed Class");
        // { Sheets: { data: worksheet }, SheetNames: ['WAITING CLASS'] };
        const excelBuffer = xlsx.write(workbook, {
            bookType: 'xlsx',
            type: 'array'
        });

        const fileName = `${titleFile} (${formattedDateWithOutTime(new Date())})`;

        saveAsExcelFile(excelBuffer, fileName);
    });
  }

  const headerTableTemplate = () => (
    <div className='flex flex-column gap-2'>
        <div className='flex lg:flex-row md:flex-row sm:flex-row flex-column justify-content-between align-items-center gap-2'>
          <h2 className='m-0 p-0 lg:text-left md:text-left sm:text-left text-center'>List Delayed Class</h2>
          <div className='flex lg:flex-row md:flex-row flex-column  gap-2'>
            <IconField iconPosition="left" className='lg:w-max md:w-max sm:w-max w-full'>
              <InputIcon className="pi pi-search"> </InputIcon>
              <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" className='p-inputtext-sm w-full'/>
            </IconField>
          </div> 
        </div>
    </div>
  );

  const rowGroupHeaderTemplate = (data) => (
    <div className='flex align-items-center justify-content-between'>
      <div className='flex gap-2 align-items-center'>
        <Link to={`https://wa.me/${data.nomorGuru}`} target='_blank'><i className='pi pi-whatsapp text-lg text-green-500 cursor-pointer'></i></Link>
        <span className='text-base font-bold'>{data.namaGuru}</span>
        <TeacherTypeTemplate superTeacher={data.idGuru.superTeacher} volunteerTeacher={data.idGuru.volunteerTeacher}/>
      </div>
      {
        data.statusGuruSelesai !== "Selesai" ? <Button label='Follow Up?' size='small' severity='help' onClick={() => {
          setIndexGuru(data.indexGuru);
          setFollowUpStatusDialog(true);
        }}/> : <Button icon='pi pi-check' iconPos='right' label="Followed Up" severity='success' size='small' outlined />
      }
    </div>
  );

  const rowGroupFooterTemplate = (data) => (
    <React.Fragment>
      <td colSpan={7}>
        <div className="flex justify-content-start align-items-start gap-1 font-bold w-full">
          <p className='m-0 p-0'>Class Total : </p>
          <span> {calculateDelayedClassTotal(data.namaGuru)}</span>
        </div>
      </td>
    </React.Fragment>
  );

  const actionBodyTemplate = (rowData) => (
    <Link to={`/class-management/list/${rowData.kelas._id}`}>
      <Button icon='pi pi-eye' severity='success' outlined size='small'/>
    </Link>
  );

  const paginatorRight = (<Button icon='pi pi-download' text onClick={() => exportExcel(listDelayedClass, "Delayed Class")} size='small'/>);
  const paginatorLeft = (<Button icon='pi pi-refresh' text onClick={getListDelayedClass} size='small'/>);

  return (
    <>
      <Toast ref={toast} />
      <AcceptDialog 
        visible={followUpStatusDialog}
        visibleHandler={() => {
          setFollowUpStatusDialog(false);
          setIndexGuru(null);
        }}
        acceptAction={followUp}
        rejectAction={() => {
          setFollowUpStatusDialog(false);
          setIndexGuru(null);
        }}
        titleConfirmation="Followed Up"
        messageConfirmation="Are you sure to change follow up status?"
        acceptLabelButton="Update"
        loading={loading}
      />
      <Card>
        <DataTable
          value={listDelayedClass}
          scrollable
          removableSort
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          loading={loading}
          filters={filters}
          globalFilterFields={['subject', 'namaGuru', 'jenisKelas', 'kelas.level', 'kelas.statusKelas', 'sesi', 'namaGuru']}
          className='lg:text-sm md:text-sm sm:text-sm text-xs p-datatable-sm'
          size='small'
          rowGroupMode='subheader'
          groupRowsBy='namaGuru'
          sortMode='single'
          sortField='namaGuru'
          header={headerTableTemplate}
          rowGroupHeaderTemplate={rowGroupHeaderTemplate}
          rowGroupFooterTemplate={rowGroupFooterTemplate}
          paginatorLeft={paginatorLeft}
          paginatorRight={paginatorRight}
        >
          <Column header="No" body={(data, Options) => Options.rowIndex + 1} align='center' />
          <Column 
            header="Subject"
            field='subject'
            sortable
            className='none-dragable'
            style={{minWidth: '5rem'}}
          />
          <Column 
            header="Class Type"
            field='jenisKelas'
            body={(e) => <ClassTypeTemplate data={e.kelas.jenisKelas}/>}
            sortable
            align='center'
            className='none-dragable'
            style={{minWidth: '8rem'}}
          />
          <Column 
            header="Class Level"
            field='kelas.level'
            sortable
            align='center'
            className='none-dragable'
            style={{minWidth: '5rem'}}
          />
          <Column 
            header="Class Status"
            field='kelas.statusKelas'
            body={(e) => <ClassStatusTemplate data={e.kelas.statusKelas}/>}
            sortable
            align='center'
            className='none-dragable'
            style={{minWidth: '5rem'}}
          />
          <Column 
            header="Delayed Session"
            field='sesi'
            sortable
            align='center'
            className='none-dragable'
            style={{minWidth: '5rem'}}
          />
          <Column body={actionBodyTemplate}/>
        </DataTable>
      </Card>
    </>
  )
}

export default DelayedClass
