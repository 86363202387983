import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getData } from '../../../../Hooks/api';
import { Toast } from 'primereact/toast';
import OverviewDetailTeacher from './Overview';
import { Card } from 'primereact/card';
import { formattedDateWithDay } from '../../../../Hooks/helper';
import { TabPanel, TabView } from 'primereact/tabview';
import TableClass from './TableClass';
import { FilterMatchMode } from 'primereact/api';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';

const DetailTeacher = () => {
  const { id } = useParams();
  const toast = useRef(null);

  const [ loading, setLoading ] = useState(false);
  const [ detailTeacher, setDetailTeacher ] = useState({});
  const [ listClass, setListClass ] = useState([]);
  const [ listActiveClass, setListActiveClass ] = useState([]);
  const [ listNeedPTCClass, setListNeedPTCClass ] = useState([]);
  const [ listNeedRRClass, setListNeedRRClass ] = useState([]);
  const [ listNeedEndClass, setListNeedEndClass ] = useState([]);
  const [ listFinishClass, setListFinishClass ] = useState([]);

  // Filter Handler
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [ filters, setFilters ] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const onGlobalFilterChange = (e) => {
    const value = e.target.value
    let _filters = {...filters}

    _filters['global'].value = value
    setFilters(_filters)
    setGlobalFilterValue(value)
  };

  const getDetailTeacher = async () => {
    setLoading(true);

    getData(`teachers/${id}`)
    .then((response) => {
      setLoading(false);
      setDetailTeacher(response.data);
    })
    .catch((error) => {
      toast.current.show({
        severity: 'error',
        summary: 'Failed Load Data!',
        detail: error.response.data.message,
        life: 3000
      });
      
      setLoading(false);
    });
  }

  const getTeacherClass = async () => {
    getData(`teacher-class/${id}`)
    .then((response) => {
      let listClass = response.data;

      listClass.forEach((kelas) => {
        kelas.subject = `${kelas.kodeBatch}.${kelas.kodeKelas} ${kelas.namaKelas}`;
        kelas.jadwalText = formattedDateWithDay(kelas.jadwal);
        kelas.endSchedule = formattedDateWithDay(kelas.sesiBerakhir);
      });

      setListClass(listClass);
      setListActiveClass(listClass.filter(kelas => kelas.statusKelas === "Aktif"));
      setListNeedPTCClass(listClass.filter(kelas => kelas.statusKelas === "Belum PTC"));
      setListNeedRRClass(listClass.filter(kelas => kelas.statusKelas === "Belum RR"));
      setListNeedEndClass(listClass.filter(kelas => kelas.statusKelas === "Belum Diselesaikan"));
      setListFinishClass(listClass.filter(kelas => kelas.statusKelas === "Selesai"));
      setLoading(false);
    })
    .catch((error) => {
      toast.current.show({
        severity: 'error',
        summary: 'Failed Load Data!',
        detail: error.response.data.message,
        life: 3000
      });
      setLoading(false);
    });
  }

  useEffect(() => {
    getDetailTeacher();
    getTeacherClass();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headerTableTemplate = () => (
    <div className='flex flex-column gap-2'>
        <div className='flex lg:flex-row md:flex-row sm:flex-row flex-column justify-content-between align-items-center gap-2'>
          <h2 className='m-0 p-0 lg:text-left md:text-left sm:text-left text-center'>List Class</h2>
          <div className='flex lg:flex-row md:flex-row flex-column  gap-2'>
            <IconField iconPosition="left" className='lg:w-max md:w-max sm:w-max w-full'>
                <InputIcon className="pi pi-search"> </InputIcon>
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" className='p-inputtext-sm w-full'/>
            </IconField>
          </div> 
        </div>
    </div>
  );

  return (
    <>
      <Toast ref={toast}/>
      
      <OverviewDetailTeacher 
        detailTeacher={detailTeacher}
        loading={loading} 
        setLoading={setLoading} 
        toast={toast}
        setDetailTeacher={setDetailTeacher}
      />
      <Card className='mt-2'>
        <TabView>
          <TabPanel header="All Class">
            <TableClass 
              listClass={listClass} 
              loading={loading} 
              statusKelas='ALL'
              headerTableTemplate={headerTableTemplate}
              filters={filters}
            />
          </TabPanel>
          <TabPanel header="Active Class">
            <TableClass 
              listClass={listActiveClass} 
              loading={loading} 
              statusKelas='ACTIVE'
              headerTableTemplate={headerTableTemplate}
              filters={filters}
            />
          </TabPanel>
          <TabPanel header="Need PTC">
            <TableClass 
              listClass={listNeedPTCClass} 
              loading={loading} 
              statusKelas='NESS PTC'
              headerTableTemplate={headerTableTemplate}
              filters={filters}
            />
          </TabPanel>
          <TabPanel header="Need RR">
            <TableClass 
              listClass={listNeedRRClass} 
              loading={loading} 
              statusKelas='NEED RR'
              headerTableTemplate={headerTableTemplate}
              filters={filters}
            />
          </TabPanel>
          <TabPanel header="Need End">
            <TableClass 
              listClass={listNeedEndClass} 
              loading={loading} 
              statusKelas='NEED END'
              headerTableTemplate={headerTableTemplate}
              filters={filters}
            />
          </TabPanel>
          <TabPanel header="Finish Class">
            <TableClass 
              listClass={listFinishClass} 
              loading={loading} 
              statusKelas='FINISH'
              headerTableTemplate={headerTableTemplate}
              filters={filters}
            />
          </TabPanel>
        </TabView>
      </Card>
    </>
  )
}

export default DetailTeacher
