import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { putData } from '../../../Hooks/api';
import { InputText } from 'primereact/inputtext';
import { getFormErrorMessage, paymentStatus } from '../../../Hooks/helper';
import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';

const FormUpdatePayment = ({
  visible,
  visibleHandler,
  detailPayment,
  loading,
  setLoading,
  refreshAction,
  toast
}) => {
  const [ initialValues, setInitialValues ] = useState({
    _id: "",
    paymentInvoice: 0,
    paymentStatus: "",
    notePayment: "",
    paymentHistory: []
  });

  useEffect(() => {
    if (detailPayment) {
        if (!detailPayment.paymentInvoice && detailPayment.invoice) detailPayment.paymentInvoice = detailPayment.invoice;
        if (detailPayment.paymentHistory && detailPayment.paymentHistory.length > 0) {
            detailPayment.paymentHistory.map((payment) => {
                payment.paymentDate = new Date(payment.paymentDate);
                return payment;
            });
        }
        setInitialValues(detailPayment);
    }
  }, [detailPayment]);

  const updatePayment = async (data, form) => {
    setLoading(true);
    if (!data.paymentHistory) data.paymentHistory = [];

    putData(`class-registration/${data._id}/payment-update`, data)
    .then(() => {
        setTimeout(() => {
            refreshAction();
            form.restart();
            toast.current.show({
                severity: 'success',
                summary: 'Update Success',
                detail: 'Payment successfully updated, ganbatte kudasai!.',
                life: 3000
            });
            if (!loading) visibleHandler();
        }, 800);
    })
    .catch((error) => {
        setLoading(false);
        toast.current.show({
            severity: 'error',
            summary: 'Update Failed',
            detail: error.response.data.message,
            life: 3000
        });
    })
  }

  const validatePayment = (data) => {
    let errors = {};
    
    if (!data.paymentStatus) errors.paymentStatus = "Status is required";
    if (!data.paymentInvoice) errors.paymentInvoice = "Nominal bill is requried";

    return errors;
  }

  return (
    <Dialog
        visible={visible}
        onHide={visibleHandler}
        header="Update Payment"
        style={{ width: '32rem' }}
        breakpoints={{ '960px': '75vm', '641px': '90vw' }}
    >
        <Form
            initialValues={initialValues}
            validate={validatePayment}
            onSubmit={updatePayment}
            mutators={{...arrayMutators}}
            render={({handleSubmit, form: { mutators: {push} }}) => (
                <form onSubmit={handleSubmit} className='flex flex-column gap-3 p-fluid'>
                    <Field
                        name='paymentInvoice'
                        render={({input, meta}) => (
                            <div className='flex-auto'>
                                <label htmlFor='paymentInvoice' className='font-bold block mb-2'>Invoice</label>
                                <InputText 
                                    id='paymentInvoice' 
                                    name='paymentInvoice' 
                                    {...input}
                                    keyfilter="num"
                                    placeholder='Rp. '
                                    disabled={loading}
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />
                    <Field
                        name='paymentStatus'
                        render={({input, meta}) => (
                            <div className='flex-auto'>
                                <label htmlFor='paymentStatus' className='font-bold block mb-2'>Payment Status</label>
                                <Dropdown 
                                    id='paymentStatus' 
                                    name='paymentStatus' 
                                    options={paymentStatus}
                                    {...input}
                                    placeholder='Pilih status pembayaran'
                                    disabled={loading}
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />
                    <div className='flex justify-content-between mb-3'>
                        <label className='font-bold font block'>Payment History</label>
                        <Tag value="+" className='px-2 py-0 text-lg' style={{cursor: 'pointer'}} onClick={() => push("paymentHistory", {nominal: "", time: ""})}/>
                    </div>
                    <FieldArray name='paymentHistory'>
                        {({fields}) => fields.map((name, index) => (
                            <div className='flex gap-2 align-items-center mb-1' key={name}>
                                <i className='pi pi-times' onClick={() => fields.remove(index)}></i>
                                <Field
                                    name={`${name}.nominal`}
                                    render={({input, meta}) => (
                                        <div className='w-full'>
                                            <InputText 
                                                id={`${name}.nominal`}
                                                name={`${name}.nominal`}
                                                {...input}
                                                placeholder='Rp. '
                                                disabled={loading}
                                            />
                                        </div>
                                    )}
                                />
                                <Field
                                    name={`${name}.paymentDate`}
                                    render={({input, meta}) => (
                                        <div className='w-full'>
                                            <Calendar
                                                id={`${name}.paymentDate`}
                                                name={`${name}.paymentDate`}  
                                                {...input}
                                                dateFormat="dd/mm/yy" 
                                                mask="99/99/9999"
                                                showIcon 
                                                disabled={loading}
                                            />
                                        </div>
                                    )}
                                />
                            </div>
                        ))}
                    </FieldArray>
                    <Field
                        name='notePayment'
                        render={({input, meta}) => (
                            <div className='flex-auto'>
                                <label htmlFor='student' className='font-bold block mb-2'>Catatan</label>
                                <InputTextarea 
                                    id='notePayment' 
                                    name='notePayment' 
                                    rows={2}
                                    {...input}
                                    placeholder='catatan ...'
                                    disabled={loading}
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />
                    <div className='flex lg:justify-content-end md:justify-content-end sm:justify-content-end justify-content-center gap-2'>
                        <Button size='small' type='button' label='Cancel' severity='danger' className='w-max' outlined loading={loading} onClick={visibleHandler} />
                        <Button size='small' type='submit' label='Update' severity='primary' className='w-max' loading={loading} />
                    </div>
                </form>
            )}
        />
    </Dialog>
  )
}

export default FormUpdatePayment
