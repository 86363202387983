import { Tag } from 'primereact/tag'
import React from 'react'
import { getSeverity } from '../../Hooks/helper'

const RRClusterTemplate = ({rr}) => {
  return (
    <Tag value={`${rr} %`} style={{backgroundColor: getSeverity('RR', rr), color: 'white'}} className='text-sm text-center'/>
  )
}

export default RRClusterTemplate
