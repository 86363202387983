import { Toast } from 'primereact/toast';
import React, { useRef } from 'react'
import FormLogin from './FormLogin';

const Login = () => {
  const toast = useRef(null);
  return (
    <div>
      <Toast ref={toast} />
      <div className='flex lg:flex-row flex-column align-items-center lg:h-screen pt-5 gap-2'>
        <div className='lg:w-7 flex lg:flex-order-1 flex-order-0 flex-column align-items-center gap-1 text-center lg:mt-3 lg:pt-3 mt-5 pt-5'>
          <h1 className='m-0 p-0'>Edufic Admin Portal</h1>
          <img src='../img/admin-floor.png' alt='Edufic Teacher' className='w-full max-w-30rem'/>
        </div>
        <div className='flex lg:flex-order-0 flex-order-1 justify-content-center lg:w-5 md:w-12 sm:w-12 w-12 lg:mb-0 mb-5'>
          <FormLogin toast={toast} />
        </div>
      </div>
    </div>
  )
}

export default Login
