import { Tag } from 'primereact/tag'
import React from 'react'
import { getSeverity } from '../../Hooks/helper'

const StudentStatusTemplate = ({data}) => {
  return (
    <Tag value={data} severity={getSeverity("studentStatus", data)}></Tag>
  )
}

export default StudentStatusTemplate
