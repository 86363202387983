import { FilterMatchMode } from 'primereact/api';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import { deleteData, getData } from '../../../Hooks/api';
import { copyLink, formattedDateWithOutTime, saveAsExcelFile } from '../../../Hooks/helper';
import { Card } from 'primereact/card';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import FormCreateUpdateConversion from './FormCreateUpdateConversion';
import DestroyDialog from '../../../Components/ConfirmDialog/DestroyDialog';

const StudentConversion = () => {
  const toast = useRef(null);
  const [ loading, setLoading ] = useState(false);
  
  // Data Handler
  const [ listStudentConversion, setListStudentConversion ] = useState([]);
  const [ detailConversion, setDetailConversion ] = useState({
    _id: null,
    waktu: new Date(),
    chatOrganikIG: 0,
    daftarOrganikIG: 0,
    chatOrganikFB: 0,
    daftarOrganikFB: 0,
    chatOrganikWA: 0,
    daftarOrganikWA: 0,
    chatAdsIG: 0,
    daftarAdsIG: 0,
    chatAdsFB: 0,
    daftarAdsFB: 0
  });

  // Dialog Handler
  const [ createUpdateDialog, setCreateUpdateDialog ] = useState(false);
  const [ destroyDataDialog, setDestroyDataDialog ] = useState(false);

  // Filter Handler
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [ filters, setFilters ] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const onGlobalFilterChange = (e) => {
    const value = e.target.value
    let _filters = {...filters}

    _filters['global'].value = value
    setFilters(_filters)
    setGlobalFilterValue(value)
  };

  const getListStudentConversion = async () => {
    setLoading(true);

    getData('konversi-monitor')
    .then((response) => {
        let result = response.data.data;
        result.forEach((data) => {
            data.tgl = formattedDateWithOutTime(data.waktu);
        });
        
        setListStudentConversion(result);
        setLoading(false);
    })
    .catch((error) => {
        toast.current.show({
            severity: 'error',
            summary: 'Load Failed',
            detail: error.response.data.message,
            life: 3000
        });
        setLoading(false);
    })
  }

  const destroyStudentConvertion = async () => {
    setLoading(true);

    deleteData(`konversi-monitor/${detailConversion._id}`)
    .then(() => {
        toast.current.show({
            severity: 'warn',
            summary: 'Destroy success',
            detail: "Student convertion data successfully destroyed, be carefull.",
            life: 3000
        });
        setDestroyDataDialog(false);
        refreshAction("DESTROY", detailConversion);
        setLoading(false);
    })
    .catch((error) => {
        toast.current.show({
            severity: 'error',
            summary: 'Destroy Failed',
            detail: error.response.data.message,
            life: 3000
        });
        setLoading(false);
    });
  }

  useEffect(() => {
    getListStudentConversion();
  }, []);

  const headerTableTemplate = () => {
    return (
        <div className='flex lg:flex-row md:flex-row sm:flex-row flex-column align-items-center justify-content-between gap-1 w-full'>
          <h2 className='m-0 p-0 font-bold'>Student Conversion</h2>
          <div className='flex lg:flex-row md:flex-row sm:flex-row flex-column align-items-center gap-1'>
            <IconField iconPosition="left" className='lg:w-max md:w-max sm:w-max w-full'>
                <InputIcon className="pi pi-search"> </InputIcon>
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search data" className="p-inputtext-sm w-full" />
            </IconField>
            <Button label='Data +' size='small' severity='success' className='lg:w-max md:w-max sm:w-max w-full' onClick={() => {
                setDetailConversion({});
                setCreateUpdateDialog(true);
            }}/>
          </div>
        </div>
    );
  };

  const headerGroup =  (
    <ColumnGroup>
        <Row>
            <Column header="No" rowSpan={2} field='No'/>
            <Column header="Tgl Konversi" rowSpan={2} field='waktu' sortable style={{minWidth: '10rem'}} />
            <Column header="Organik" colSpan={9}/>
            <Column header="Ads" colSpan={6}/>
            <Column colSpan={1} rowSpan={2}/>
        </Row>
        <Row>
            {/* Organik  */}
            <Column header="Chat IG" field='chatOrganikIG' sortable style={{minWidth: '7rem'}} />
            <Column header="Register IG" field='daftarOrganikIG' sortable style={{minWidth: '8rem'}} />
            <Column header="IG" style={{minWidth: '5rem'}} alignHeader="center"/>
            <Column header="Chat FB" field='chatOrganikFB' sortable style={{minWidth: '7rem'}}/>
            <Column header="Register FB" field='daftarOrganikFB' sortable style={{minWidth: '8rem'}}/>
            <Column header="Conversion FB" style={{minWidth: '5rem'}} alignHeader="center"/>
            <Column header="Chat WA" field='chatOrganikWA' sortable style={{minWidth: '7rem'}}/>
            <Column header="Register WA" field='daftarOrganikWA' sortable style={{minWidth: '8rem'}}/>
            <Column header="Conversion WA" style={{minWidth: '5rem'}} alignHeader="center"/>
            {/* Ads  */}
            <Column header="Chat IG" field='chatAdsIG' sortable style={{minWidth: '7rem'}}/>
            <Column header="Register IG" field='daftarAdsIG' sortable style={{minWidth: '8rem'}} />
            <Column header="Conversion IG" style={{minWidth: '5rem'}} alignHeader="center"/>
            <Column header="Chat FB" field='chatAdsFB' sortable style={{minWidth: '7rem'}} />
            <Column header="Register FB" field='daftarAdsFB' sortable style={{minWidth: '8rem'}} />
            <Column header="Conversion FB" style={{minWidth: '5rem'}} alignHeader="center"/>
        </Row>
    </ColumnGroup>
  );

  const actionTableTemplate = (data) => (
    <div className='flex gap-1'>
        <Button 
          icon="pi pi-pencil" 
          size='small' 
          severity='info' 
          outlined
          onClick={()  => {
            setDetailConversion(data);
            setCreateUpdateDialog(true);
          }}
        />
        <Button
            icon="pi pi-trash"
            size='small'
            severity='danger'
            onClick={() => {
                setDetailConversion(data);
                setDestroyDataDialog(true);
            }}
        />
        <Button 
          icon="pi pi-copy" 
          size='small' 
          severity='secondary'
          onClick={() => copyLink(reportCopy(data), toast)} 
        />
    </div>
  );

  const refreshAction = (type, data) => {
    if (type === "CREATE") {
        listStudentConversion.push(data);
    }

    if (type === "EDIT") {
        let index = listStudentConversion.findIndex(konversi => konversi._id === data._id);
        listStudentConversion[index] = data;
    }

    if (type === "DESTROY") {
        let index = listStudentConversion.findIndex(konversi => konversi._id === data._id);
        listStudentConversion.splice(index, 1);
    }

    setListStudentConversion(listStudentConversion);
  }

  const exportExcel = async () => {
    let data = listStudentConversion.map((konversi, index) => {
        let newFormat = {
           Time: konversi.tgl,
           
           "Chat WA (Organik)": konversi.chatOrganikWA, 
           "Register WA (Organik)": konversi.daftarOrganikWA,
           "Conversion WA (Organik)": `${(konversi.daftarOrganikWA/konversi.chatOrganikWA*100).toFixed(2)} %`,
           
           "Chat IG (Organik)": konversi.chatOrganikIG, 
           "Register IG (Organik)": konversi.daftarOrganikIG,
           "Conversion IG (Organik)": `${(konversi.daftarOrganikIG/konversi.chatOrganikIG*100).toFixed(2)} %`,
           
           "Chat FB (Organik)": konversi.chatOrganikFB, 
           "Register FB (Organik)": konversi.daftarOrganikFB,
           "Conversion FB (Organik)": `${(konversi.daftarOrganikFB/konversi.chatOrganikFB*100).toFixed(2)} %`,

           "Chat IG (Ads)": konversi.chatAdsIG, 
           "Register IG (Ads)": konversi.daftarAdsIG,
           "Conversion IG (Ads)": `${(konversi.daftarAdsIG/konversi.chatAdsIG*100).toFixed(2)} %`,
           
           "Chat FB (Ads)": konversi.chatAdsFB, 
           "Register FB (Ads)": konversi.daftarAdsFB,
           "Conversion FB (Ads)": `${(konversi.daftarAdsFB/konversi.chatAdsFB*100).toFixed(2)} %`
        }

        return newFormat;
    });

    import('xlsx').then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(data);   
                
        const workbook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
        // { Sheets: { data: worksheet }, SheetNames: ['WAITING CLASS'] };
        const excelBuffer = xlsx.write(workbook, {
            bookType: 'xlsx',
            type: 'array'
        });

        const fileName = `Student Conversion (${formattedDateWithOutTime(new Date())})`;

        saveAsExcelFile(excelBuffer, fileName);
    });
  }

  const reportCopy = (rowData) => {
    const text = `*Student Conversion (${formattedDateWithOutTime(rowData.waktu)})*
- _*Organik*_
  • Chat WA\t: ${rowData.chatOrganikWA}
  • Register WA\t: ${rowData.daftarOrganikWA}
  • Chat IG\t: ${rowData.chatOrganikIG}
  • Register IG\t: ${rowData.daftarOrganikIG}
  • Chat FB\t: ${rowData.chatOrganikFB}
  • Register FB\t: ${rowData.daftarOrganikFB}

- _*Ads*_
  • Chat IG\t: ${rowData.chatAdsIG}
  • Register IG\t: ${rowData.daftarAdsIG}
  • Chat FB\t: ${rowData.chatAdsFB}
  • Register FB\t: ${rowData.daftarAdsFB}`;

    return text;
  }

  const paginatorLeft = <Button type="button" icon="pi pi-refresh" text onClick={getListStudentConversion} />;
  const paginatorRight = <Button type="button" icon="pi pi-download" text onClick={exportExcel}/>;

  return (
    <>
        <Toast ref={toast}/>
        <FormCreateUpdateConversion 
            visible={createUpdateDialog}
            visibleHandler={() => {
                setDetailConversion({
                    _id: null,
                    waktu: new Date(),
                    chatOrganikIG: 0,
                    daftarOrganikIG: 0,
                    chatOrganikFB: 0,
                    daftarOrganikFB: 0,
                    chatOrganikWA: 0,
                    daftarOrganikWA: 0,
                    chatAdsIG: 0,
                    daftarAdsIG: 0,
                    chatAdsFB: 0,
                    daftarAdsFB: 0
                });
                setCreateUpdateDialog(false);
            }}
            data={detailConversion}
            loading={loading}
            setLoading={setLoading}
            toast={toast}
            refreshAction={refreshAction}
        />
        <DestroyDialog
            visible={destroyDataDialog}
            visibleHandler={() => {
                setDetailConversion({
                    _id: null,
                    waktu: new Date(),
                    chatOrganikIG: 0,
                    daftarOrganikIG: 0,
                    chatOrganikFB: 0,
                    daftarOrganikFB: 0,
                    chatOrganikWA: 0,
                    daftarOrganikWA: 0,
                    chatAdsIG: 0,
                    daftarAdsIG: 0,
                    chatAdsFB: 0,
                    daftarAdsFB: 0
                });
                setDestroyDataDialog(false);
            }}
            acceptAction={destroyStudentConvertion}
            rejectAction={() => {
                setDetailConversion({
                    _id: null,
                    waktu: new Date(),
                    chatOrganikIG: 0,
                    daftarOrganikIG: 0,
                    chatOrganikFB: 0,
                    daftarOrganikFB: 0,
                    chatOrganikWA: 0,
                    daftarOrganikWA: 0,
                    chatAdsIG: 0,
                    daftarAdsIG: 0,
                    chatAdsFB: 0,
                    daftarAdsFB: 0
                });
                setDestroyDataDialog(false);
            }}
            acceptLabelButton="Destroy"
            titleConfirmation="Destroy Student Convertion"
            messageConfirmation="Are you sure destroy this record?"
            loading={loading}
        />

        <Card>
            <DataTable
                value={listStudentConversion}
                scrollable
                removableSort
                loading={loading}
                paginator
                rows={5}
                rowsPerPageOptions={[5, 10, 25, 50]}
                filters={filters}
                globalFilterFields={['tgl']}
                header={headerTableTemplate}
                headerColumnGroup={headerGroup}
                size='small'
                className='lg:text-sm md:text-sm sm:text-sm text-xs p-datatable-sm'
                paginatorLeft={paginatorLeft}
                paginatorRight={paginatorRight}
            >
                <Column header="No" body={(data, Options) => Options.rowIndex + 1} />
                <Column header="Tgl Konversi" field='waktu' body={(e) => formattedDateWithOutTime(e.waktu)} />
                <Column field='chatOrganikIG' align="center" alignHeader="center" />
                <Column field='daftarOrganikIG' align="center" alignHeader="center" />
                <Column body={(e) => `${(e.daftarOrganikIG/e.chatOrganikIG*100).toFixed(2)} %`} align="center" />
                <Column field='chatOrganikFB' align="center" alignHeader="center" />
                <Column field='daftarOrganikFB' align="center" alignHeader="center" />
                <Column body={(e) => `${(e.daftarOrganikFB/e.chatOrganikFB*100).toFixed(2)} %`} align="center" />
                <Column field='chatOrganikWA' align="center" alignHeader="center" />
                <Column field='daftarOrganikWA' align="center" alignHeader="center" />
                <Column body={(e) => `${(e.daftarOrganikWA/e.chatOrganikWA*100).toFixed(2)} %`} align="center" />

                <Column field='chatAdsIG' align="center" alignHeader="center" />
                <Column field='daftarAdsIG'align="center" alignHeader="center" />
                <Column body={(e) => `${(e.daftarAdsIG/e.chatAdsIG*100).toFixed(2)} %`} align="center" />
                <Column field='chatAdsFB'  align="center" alignHeader="center" />
                <Column field='daftarAdsFB' align="center" alignHeader="center" />
                <Column body={(e) => `${(e.daftarAdsFB/e.chatAdsFB*100).toFixed(2)} %`} align="center" />

                <Column body={actionTableTemplate} />
            </DataTable>
        </Card>
    </>
  )
}

export default StudentConversion
