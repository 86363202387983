import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import React from 'react';

const NavbarMain = ({
    sidebarVisibility, 
    setSidebarVisibility
}) => {
  return (
    <div className='w-full'>
      <div className='flex justify-content-between align-items-center px-3 py-3 bg-primary w-full'>
        <Button 
            icon="pi pi-bars" 
            outlined 
            className='text-white' 
            size='small'
            onClick={() => setSidebarVisibility(!sidebarVisibility)}
        />
        <div className='flex gap-2 align-items-center cursor-pointer'>
            <h3 className='m-0 p-0'>Admin</h3>
            <Avatar 
                icon='pi pi-graduation-cap'
                shape='circle' 
                className='bg-white text-primary'
            />
        </div>
      </div>
    </div>
  )
}

export default NavbarMain
