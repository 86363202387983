import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable'
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useRef, useState } from 'react'
import { deleteData, DOMAIN, getData } from '../../../Hooks/api';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { Link } from 'react-router-dom';
import { copyLink, formattedDateWithOutTime, saveAsExcelFile } from '../../../Hooks/helper';
import { Card } from 'primereact/card';
import DestroyDialog from '../../../Components/ConfirmDialog/DestroyDialog';
import FormAddUpdateShortLink from './FormAddUpdateShortLink';

const Shortlink = () => {
  const toast = useRef(null);
  const [ loading, setLoading ] = useState(false);
  
  // Data Handler
  const [ listShortlink, setListShortLink ] = useState([]);
  const [ detailLink, setDetailLink ] = useState({});

  // Dialog Handler 
  const [ destroyDialog, setDestroyDialog ] = useState(false);
  const [ createUpdateDialog, setCreateUpdateDialog ] = useState(false);
  
  // Filter Handler
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const getListShortlink = async () => {
    setLoading(true);

    getData('short-links')
    .then((response) => {
        setListShortLink(response.data);
        setLoading(false);
    })
    .catch((error) => {
        toast.current.show({
            severity: 'error',
            summary: 'Load Failed',
            detail: error.response.data.message,
            life: 3000
        });
        setLoading(false);
    });
  }

  const destroyShortLink = async () => {
    setLoading(true);

    deleteData(`short-link/${detailLink._id}`)
    .then(() => {
        refreshAction("DESTROY", detailLink)
        toast.current.show({
            severity: 'info',
            summary: 'Destroy Success',
            detail: 'Shortlink successfully destroyed, Yeaay!!!',
            life: 3000
        });
        setDestroyDialog(false);
        setLoading(false);
    })
    .catch((error) => {
        toast.current.show({
            severity: 'error',
            summary: 'Destroy Failed',
            detail: error.response.data.message,
            life: 3000
        });
        setLoading(false);
    });
  }

  useEffect(() => {
    getListShortlink();
  }, []);

  const refreshAction = (type, data) => {
    let index = listShortlink.findIndex(sl => sl._id === data._id);

    if (type === "DESTROY") {
        listShortlink.splice(index, 1);
        setListShortLink(listShortlink);    
    }

    if (type === "EDIT") {
        listShortlink[index] = data;
        setListShortLink(listShortlink);
    }

    if (type === "CREATE") {
        listShortlink.push(data);
        setListShortLink(listShortlink);
    }
  }

  const exportExcel = async () => {
    let data = listShortlink.map((CR, index) => {
        let newFormat = {
            No: index+1,
            Shortlink: `${DOMAIN}/web/${CR.shortString}`,
            Link: CR.link
        }

        return newFormat;
    });

    import('xlsx').then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(data);   
                
        const workbook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
        // { Sheets: { data: worksheet }, SheetNames: ['WAITING CLASS'] };
        const excelBuffer = xlsx.write(workbook, {
            bookType: 'xlsx',
            type: 'array'
        });

        const fileName = `Shortlink Edufic Portal (${formattedDateWithOutTime(new Date())})`;

        saveAsExcelFile(excelBuffer, fileName);
    });
  }

  const headerTableTemplate = () => {
    return (
        <div className='flex lg:flex-row md:flex-row sm:flex-row flex-column align-items-center justify-content-between gap-1 w-full'>
          <h2 className='m-0 p-0 font-bold'>List Shortlink</h2>
          <div className='flex lg:flex-row md:flex-row sm:flex-row flex-column align-items-center gap-1'>
            <IconField iconPosition="left" className='lg:w-max md:w-max sm:w-max w-full'>
                <InputIcon className="pi pi-search"> </InputIcon>
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search Shortlink" className="p-inputtext-sm w-full" />
            </IconField>
            <Button label='Shortlink +' size='small' severity='success' className='lg:w-max md:w-max sm:w-max w-full' onClick={() => {
                setDetailLink({});
                setCreateUpdateDialog(true);
            }}/>
          </div>
        </div>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
        <div className='flex gap-1'>
            <Button icon="pi pi-pencil" severity='info' size='small' outlined onClick={() => {
                setDetailLink(rowData);
                setCreateUpdateDialog(true);
            }}/>
            <Button icon="pi pi-trash" severity='danger' size='small' onClick={() => {
                setDetailLink(rowData);
                setDestroyDialog(true);
            }}/>
            <Button icon="pi pi-copy" severity='secondary' size='small' onClick={() => copyLink(`${DOMAIN}/web/${rowData.shortString}`, toast)}/>
        </div>
    );
  };

  const linkTemplate = (rowData) => (
    <Link to={rowData.link} target='_blank'>
        <div className='border-1 border-round border-primary cursor-pointer p-1'>
            <p className='m-0 p-0 font-italic text-blue-500'>{rowData.link}</p>
        </div>
    </Link>
  )

  const paginatorLeft = <Button type="button" icon="pi pi-refresh" text onClick={getListShortlink} />;
  const paginatorRight = <Button type="button" icon="pi pi-download" text onClick={exportExcel}/>;

  return (
    <>
      <Toast ref={toast} />
      <FormAddUpdateShortLink
        visible={createUpdateDialog}
        visibleHandler={() => {
            setDetailLink({});
            setCreateUpdateDialog(false);
        }}
        data={detailLink}
        titleDialog={detailLink._id ? "Edit Shortlink" : "Create Shortlink"}
        loading={loading}
        setLoading={setLoading}
        toast={toast}
        refreshAction={refreshAction}
        listShortLink={listShortlink}
      />
      <DestroyDialog  
        visible={destroyDialog}
        visibleHandler={() => {
            setDestroyDialog(false);
            setDetailLink({});
        }}
        acceptAction={destroyShortLink}
        rejectAction={() => {
            setDestroyDialog(false);
            setDetailLink({});
        }}
        acceptLabelButton="Destroy"
        titleConfirmation="Destroy Shortlink"
        messageConfirmation="Are you sure destroy this shortlink?"
        loading={loading}
      />

      <Card>
        <DataTable
            value={listShortlink}
            loading={loading}
            paginator
            rows={5}
            rowsPerPageOptions={[5, 10, 25, 50]}
            scrollable
            removableSort
            filters={filters}
            globalFilterFields={['link', 'shortString']}
            header={headerTableTemplate}
            paginatorRight={paginatorRight}
            paginatorLeft={paginatorLeft}
            size='small'
            className='lg:text-sm md:text-sm sm:text-sm text-xs p-datatable-sm'
        >
            <Column header="No" body={(data, Options) => Options.rowIndex + 1} />
            <Column 
                header="Shorten Link"
                field='shortString'
                body={(e) => `/${e.shortString}`}
                sortable
                className='none-dragable'
                style={{ minWidth: '12rem' }} 
            />
            <Column 
                header="URL"
                field='link'
                body={linkTemplate}
                sortable
                align='center'
                style={{ minWidth: '12rem' }} 
            />
            <Column 
                body={actionBodyTemplate}
                style={{ minWidth: '12rem' }} 
            />
        </DataTable>
      </Card>
    </>
  )
}

export default Shortlink
