import { Tag } from 'primereact/tag'
import React from 'react'
import { getSeverity } from '../../Hooks/helper'

const TeacherStatusTemplate = ({teacherStatus}) => {
  return (
    <Tag value={teacherStatus} severity={getSeverity("teacherStatus", teacherStatus)}></Tag>
  )
}

export default TeacherStatusTemplate