import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { StyleClass } from 'primereact/styleclass';
import React, { useRef } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

const SidebarMain = ({
  sidebarVisibility, 
  visibility, 
  setVisibility
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const btnRefClassManagement = useRef(null);
  const btnRefTeacherManagement = useRef(null);
  const btnRefStudentManagement = useRef(null);
  const btnRefFinanceManagement = useRef(null);
  const btnRefSupportManagement = useRef(null);

  const locationHierarki = location.pathname.split('/');
  const mainLocation = locationHierarki[1];
  const currentLocation = locationHierarki.at(-1);
  const parentLocation = locationHierarki.at(-2);
  const logout = async () => {
    localStorage.removeItem('accessToken');
    navigate('/auth/login');
  }

  return (
    <>
      <div className={`card flex justify-content-center ${sidebarVisibility ? 'edu-sidebar' : 'edu-sidebar-hidden'}`}>
        <div className="surface-section h-screen block flex-shrink-0 absolute lg:static left-0 top-0 border-right-1 surface-border select-none w-full">
          <div className="flex flex-column h-full">
            <div className="flex align-items-center justify-content-between px-4 pt-3 flex-shrink-0">
              <span className="inline-flex align-items-center justify-content-center">
                <img src='/img/edufic-logo.png' alt='Logo Edufic' className='w-9'/>
              </span>
            </div>
            <div className="overflow-y-auto text-sm">
              <ul className="list-none px-2 py-2 m-0">
                <li>
                  <Link to='/'>
                    <div className={`flex align-items-center cursor-pointer px-3 py-2 border-round text-700 transition-duration-150 transition-colors w-full ${(currentLocation === "" || currentLocation === "profile") ? "bg-primary text-white hover:bg-orange-300" : "text-color-secondary hover:surface-100"}`}>
                      <i className="pi pi-home mr-2"></i>
                      <span className="font-medium">Dashboard</span>
                    </div>
                  </Link>
                </li>
                <li>
                  <StyleClass 
                    nodeRef={btnRefClassManagement}
                    selector='@next'
                    enterClassName='hidden'
                    enterActiveClassName='slidedown'
                    leaveToClassName='hidden'
                    leaveActiveClassName='slideup'
                  >
                    <div ref={btnRefClassManagement} className={`flex align-items-center justify-content-between cursor-pointer px-3 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full text-color-secondary hover:surface-100`}>
                      <div className='flex align-items-center'>
                        <i className="pi pi-sitemap mr-2"></i>
                        <span className="font-medium">Class Management</span>
                      </div>
                      <i className="pi pi-chevron-down"></i>
                    </div>
                  </StyleClass>
                  <ul className='list-none py-0 pl-4 pr-0 m-0 overflow-hidden'>
                    <li>
                      <Link to='/class-management/list'>
                        <div className={`flex align-items-center cursor-pointer px-3 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full ${(mainLocation === "class-management" && (currentLocation === "list" || parentLocation === "list")) ? "bg-primary text-white hover:bg-orange-300" : "text-color-secondary hover:surface-100"}`}>
                          <span className="font-medium">List Class</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to='/class-management/class-operation'>
                        <div className={`flex align-items-center cursor-pointer px-3 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full ${currentLocation === "class-operation" ? "bg-primary text-white hover:bg-orange-300" : "text-color-secondary hover:surface-100"}`}>
                          <span className="font-medium">Class Operation</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to='/class-management/delayed-class'>
                        <div className={`flex align-items-center cursor-pointer px-3 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full ${currentLocation === "delayed-class" ? "bg-primary text-white hover:bg-orange-300" : "text-color-secondary hover:surface-100"}`}>
                          <span className="font-medium">Delayed Class</span>
                        </div>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <StyleClass 
                    nodeRef={btnRefStudentManagement}
                    selector='@next'
                    enterClassName='hidden'
                    enterActiveClassName='slidedown'
                    leaveToClassName='hidden'
                    leaveActiveClassName='slideup'
                  >
                    <div ref={btnRefStudentManagement} className={`flex align-items-center justify-content-between cursor-pointer px-3 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full text-color-secondary hover:surface-100`}>
                      <div className='flex align-items-center'>
                        <i className="pi pi-users mr-2"></i>
                        <span className="font-medium">Student Management</span>
                      </div>
                      <i className="pi pi-chevron-down"></i>
                    </div>
                  </StyleClass>
                  <ul className='list-none py-0 pl-4 pr-0 m-0 overflow-hidden'>
                    <li>
                      <Link to='/student-management/list'>
                        <div className={`flex align-items-center cursor-pointer px-3 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full ${(mainLocation === "student-management" && (currentLocation === "list" || parentLocation === "list")) ? "bg-primary text-white hover:bg-orange-300" : "text-color-secondary hover:surface-100"}`}>
                          <span className="font-medium">List Student</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to='/student-management/class-registration'>
                        <div className={`flex align-items-center cursor-pointer px-3 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full ${currentLocation === "class-registration" ? "bg-primary text-white hover:bg-orange-300" : "text-color-secondary hover:surface-100"}`}>
                          <span className="font-medium">Class Registration</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to='/student-management/class-plotting'>
                        <div className={`flex align-items-center cursor-pointer px-3 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full ${currentLocation === "class-plotting" ? "bg-primary text-white hover:bg-orange-300" : "text-color-secondary hover:surface-100"}`}>
                          <span className="font-medium">Class Plotting</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to='/student-management/conversion'>
                        <div className={`flex align-items-center cursor-pointer px-3 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full ${currentLocation === "conversion" ? "bg-primary text-white hover:bg-orange-300" : "text-color-secondary hover:surface-100"}`}>
                          <span className="font-medium">Student Conversion</span>
                        </div>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <StyleClass 
                    nodeRef={btnRefTeacherManagement}
                    selector='@next'
                    enterClassName='hidden'
                    enterActiveClassName='slidedown'
                    leaveToClassName='hidden'
                    leaveActiveClassName='slideup'
                  >
                    <div ref={btnRefTeacherManagement} className={`flex align-items-center justify-content-between cursor-pointer px-3 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full text-color-secondary hover:surface-100`}>
                      <div className='flex align-items-center'>
                        <i className="pi pi-graduation-cap mr-2"></i>
                        <span className="font-medium">Teacher Management</span>
                      </div>
                      <i className="pi pi-chevron-down"></i>
                    </div>
                  </StyleClass>
                  <ul className='list-none py-0 pl-4 pr-0 m-0 overflow-hidden'>
                    <li>
                      <Link to='/teacher-management/list'>
                        <div className={`flex align-items-center cursor-pointer px-3 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full ${(mainLocation === "teacher-management" && (currentLocation === "list" || parentLocation === "list")) ? "bg-primary text-white hover:bg-orange-300" : "text-color-secondary hover:surface-100"}`}>
                          <span className="font-medium">List Teacher</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to='/teacher-management/preference'>
                        <div className={`flex align-items-center cursor-pointer px-3 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full ${currentLocation === "preference" ? "bg-primary text-white hover:bg-orange-300" : "text-color-secondary hover:surface-100"}`}>
                          <span className="font-medium">Teacher Preference</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to='/teacher-management/teacher-account'>
                        <div className={`flex align-items-center cursor-pointer px-3 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full ${currentLocation === "teacher-account" ? "bg-primary text-white hover:bg-orange-300" : "text-color-secondary hover:surface-100"}`}>
                          <span className="font-medium">Teacher Account</span>
                        </div>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <StyleClass 
                    nodeRef={btnRefFinanceManagement}
                    selector='@next'
                    enterClassName='hidden'
                    enterActiveClassName='slidedown'
                    leaveToClassName='hidden'
                    leaveActiveClassName='slideup'
                  >
                    <div ref={btnRefFinanceManagement} className={`flex align-items-center justify-content-between cursor-pointer px-3 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full text-color-secondary hover:surface-100`}>
                      <div className='flex align-items-center'>
                        <i className="pi pi-dollar mr-2"></i>
                        <span className="font-medium">Finance Management</span>
                      </div>
                      <i className="pi pi-chevron-down"></i>
                    </div>
                  </StyleClass>
                  <ul className='list-none py-0 pl-4 pr-0 m-0 overflow-hidden'>
                    <li>
                      <Link to='/finance-management/teacher-salary'>
                        <div className={`flex align-items-center cursor-pointer px-3 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full ${(currentLocation === "teacher-salary" || parentLocation === "teacher-salary") ? "bg-primary text-white hover:bg-orange-300" : "text-color-secondary hover:surface-100"}`}>
                          <span className="font-medium">Teacher Salary</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to='/finance-management/delayed-payment'>
                        <div className={`flex align-items-center cursor-pointer px-3 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full ${currentLocation === "delayed-payment" ? "bg-primary text-white hover:bg-orange-300" : "text-color-secondary hover:surface-100"}`}>
                          <span className="font-medium">Delayed Payment</span>
                        </div>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <StyleClass
                    nodeRef={btnRefSupportManagement}
                    selector='@next'
                    enterClassName='hidden'
                    enterActiveClassName='slidedown'
                    leaveToClassName='hidden'
                    leaveActiveClassName='slideup'
                  >
                    <div ref={btnRefSupportManagement} className={`flex align-items-center justify-content-between cursor-pointer px-3 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full text-color-secondary hover:surface-100`}>
                      <div className='flex align-items-center'>
                        <i className="pi pi-box mr-2"></i>
                        <span className="font-medium">Support</span>
                      </div>
                      <i className="pi pi-chevron-down"></i>
                    </div>
                  </StyleClass>
                  <ul className='list-none py-0 pl-4 pr-0 m-0 overflow-hidden'>
                    <li>
                      <Link to='/support/schedule'>
                        <div className={`flex align-items-center cursor-pointer px-3 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full ${currentLocation === "schedule" ? "bg-primary text-white hover:bg-orange-300" : "text-color-secondary hover:surface-100"}`}>
                          <span className="font-medium">Schedule</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to='/support/shortlink'>
                        <div className={`flex align-items-center cursor-pointer px-3 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full ${currentLocation === "shortlink" ? "bg-primary text-white hover:bg-orange-300" : "text-color-secondary hover:surface-100"}`}>
                          <span className="font-medium">Shortlink</span>
                        </div>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to='/room-management'>
                    <div className={`flex align-items-center cursor-pointer px-3 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full ${currentLocation === "room-management" ? "bg-primary text-white hover:bg-orange-300" : "text-color-secondary hover:surface-100"}`}>
                      <i className="pi pi-video mr-2"></i>
                      <span className="font-medium">Room Management</span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="mt-auto mb-2 overflow-hidden">
              <hr className="mb-1 mx-3 border-top-1 border-none surface-border" />
              <div className='p-3'>
                <Button label='Logout' severity='danger' size='small' className='w-full' onClick={() => logout()}/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Sidebar visible={visibility} onHide={() => setVisibility(false)} content={
        <div className="surface-section h-screen block flex-shrink-0 absolute lg:static left-0 top-0 border-right-1 surface-border select-none">
          <div className="flex flex-column h-full">
            <div className="flex align-items-center justify-content-between px-3 pt-3 flex-shrink-0">
              <span className="inline-flex align-items-center justify-content-between">
                <img src='/img/edufic-logo.png' alt='Logo Edufic' className='w-8'/>
                <i className="pi pi-times border-circle" onClick={() => setVisibility(false)}/>
              </span>
            </div>
            <div className="overflow-y-auto text-sm">
              <ul className="list-none px-2 py-2 m-0">
                <li>
                  <Link to='/' onClick={() => setVisibility(false)}>
                    <div className={`flex align-items-center cursor-pointer px-3 py-2 border-round text-700 transition-duration-150 transition-colors w-full ${(currentLocation === "" || currentLocation === "profile") ? "bg-primary text-white hover:bg-orange-300" : "text-color-secondary hover:surface-100"}`}>
                      <i className="pi pi-home mr-2"></i>
                      <span className="font-medium">Dashboard</span>
                    </div>
                  </Link>
                </li>
                <li>
                  <StyleClass 
                    nodeRef={btnRefClassManagement}
                    selector='@next'
                    enterClassName='hidden'
                    enterActiveClassName='slidedown'
                    leaveToClassName='hidden'
                    leaveActiveClassName='slideup'
                  >
                    <div ref={btnRefClassManagement} className={`flex align-items-center justify-content-between cursor-pointer px-3 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full text-color-secondary hover:surface-100`}>
                      <div className='flex align-items-center'>
                        <i className="pi pi-sitemap mr-2"></i>
                        <span className="font-medium">Class Management</span>
                      </div>
                      <i className="pi pi-chevron-down"></i>
                    </div>
                  </StyleClass>
                  <ul className='list-none py-0 pl-4 pr-0 m-0 overflow-hidden'>
                    <li>
                      <Link to='/class-management/list' onClick={() => setVisibility(false)}>
                        <div className={`flex align-items-center cursor-pointer px-3 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full ${(mainLocation === "class-management" && (currentLocation === "list" || parentLocation === "list")) ? "bg-primary text-white hover:bg-orange-300" : "text-color-secondary hover:surface-100"}`}>
                          <span className="font-medium">List Class</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to='/class-management/class-operation' onClick={() => setVisibility(false)}>
                        <div className={`flex align-items-center cursor-pointer px-3 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full ${currentLocation === "class-operation" ? "bg-primary text-white hover:bg-orange-300" : "text-color-secondary hover:surface-100"}`}>
                          <span className="font-medium">Class Operation</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to='/class-management/delayed-class' onClick={() => setVisibility(false)}>
                        <div className={`flex align-items-center cursor-pointer px-3 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full ${currentLocation === "delayed-class" ? "bg-primary text-white hover:bg-orange-300" : "text-color-secondary hover:surface-100"}`}>
                          <span className="font-medium">Delayed Class</span>
                        </div>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <StyleClass 
                    nodeRef={btnRefStudentManagement}
                    selector='@next'
                    enterClassName='hidden'
                    enterActiveClassName='slidedown'
                    leaveToClassName='hidden'
                    leaveActiveClassName='slideup'
                  >
                    <div ref={btnRefStudentManagement} className={`flex align-items-center justify-content-between cursor-pointer px-3 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full text-color-secondary hover:surface-100`}>
                      <div className='flex align-items-center'>
                        <i className="pi pi-users mr-2"></i>
                        <span className="font-medium">Student Management</span>
                      </div>
                      <i className="pi pi-chevron-down"></i>
                    </div>
                  </StyleClass>
                  <ul className='list-none py-0 pl-4 pr-0 m-0 overflow-hidden'>
                    <li>
                      <Link to='/student-management/list' onClick={() => setVisibility(false)}>
                        <div className={`flex align-items-center cursor-pointer px-3 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full ${(mainLocation === "student-management" && (currentLocation === "list" || parentLocation === "list")) ? "bg-primary text-white hover:bg-orange-300" : "text-color-secondary hover:surface-100"}`}>
                          <span className="font-medium">List Student</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to='/student-management/class-registration' onClick={() => setVisibility(false)}>
                        <div className={`flex align-items-center cursor-pointer px-3 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full ${currentLocation === "class-registration" ? "bg-primary text-white hover:bg-orange-300" : "text-color-secondary hover:surface-100"}`}>
                          <span className="font-medium">Class Registration</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to='/student-management/class-plotting' onClick={() => setVisibility(false)}>
                        <div className={`flex align-items-center cursor-pointer px-3 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full ${currentLocation === "class-plotting" ? "bg-primary text-white hover:bg-orange-300" : "text-color-secondary hover:surface-100"}`}>
                          <span className="font-medium">Class Plotting</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to='/student-management/conversion' onClick={() => setVisibility(false)}>
                        <div className={`flex align-items-center cursor-pointer px-3 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full ${currentLocation === "conversion" ? "bg-primary text-white hover:bg-orange-300" : "text-color-secondary hover:surface-100"}`}>
                          <span className="font-medium">Student Conversion</span>
                        </div>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <StyleClass 
                    nodeRef={btnRefTeacherManagement}
                    selector='@next'
                    enterClassName='hidden'
                    enterActiveClassName='slidedown'
                    leaveToClassName='hidden'
                    leaveActiveClassName='slideup'
                  >
                    <div ref={btnRefTeacherManagement} className={`flex align-items-center justify-content-between cursor-pointer px-3 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full text-color-secondary hover:surface-100`}>
                      <div className='flex align-items-center'>
                        <i className="pi pi-graduation-cap mr-2"></i>
                        <span className="font-medium">Teacher Management</span>
                      </div>
                      <i className="pi pi-chevron-down"></i>
                    </div>
                  </StyleClass>
                  <ul className='list-none py-0 pl-4 pr-0 m-0 overflow-hidden'>
                    <li>
                      <Link to='/teacher-management/list' onClick={() => setVisibility(false)}>
                        <div className={`flex align-items-center cursor-pointer px-3 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full ${((currentLocation === "list" || parentLocation === "list") && mainLocation === "teacher-management") ? "bg-primary text-white hover:bg-orange-300" : "text-color-secondary hover:surface-100"}`}>
                          <span className="font-medium">List Teacher</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to='/teacher-management/preference' onClick={() => setVisibility(false)}>
                        <div className={`flex align-items-center cursor-pointer px-3 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full ${currentLocation === "preference" ? "bg-primary text-white hover:bg-orange-300" : "text-color-secondary hover:surface-100"}`}>
                          <span className="font-medium">Teacher Preference</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to='/teacher-management/teacher-account' onClick={() => setVisibility(false)}>
                        <div className={`flex align-items-center cursor-pointer px-3 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full ${currentLocation === "teacher-account" ? "bg-primary text-white hover:bg-orange-300" : "text-color-secondary hover:surface-100"}`}>
                          <span className="font-medium">Teacher Account</span>
                        </div>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <StyleClass 
                    nodeRef={btnRefFinanceManagement}
                    selector='@next'
                    enterClassName='hidden'
                    enterActiveClassName='slidedown'
                    leaveToClassName='hidden'
                    leaveActiveClassName='slideup'
                  >
                    <div ref={btnRefFinanceManagement} className={`flex align-items-center justify-content-between cursor-pointer px-3 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full text-color-secondary hover:surface-100`}>
                      <div className='flex align-items-center'>
                        <i className="pi pi-dollar mr-2"></i>
                        <span className="font-medium">Finance Management</span>
                      </div>
                      <i className="pi pi-chevron-down"></i>
                    </div>
                  </StyleClass>
                  <ul className='list-none py-0 pl-4 pr-0 m-0 overflow-hidden'>
                    <li>
                      <Link to='/finance-management/teacher-salary' onClick={() => setVisibility(false)}>
                        <div className={`flex align-items-center cursor-pointer px-3 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full ${(currentLocation === "teacher-salary" || parentLocation === "teacher-salary") ? "bg-primary text-white hover:bg-orange-300" : "text-color-secondary hover:surface-100"}`}>
                          <span className="font-medium">Teacher Salary</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to='/finance-management/delayed-payment' onClick={() => setVisibility(false)}>
                        <div className={`flex align-items-center cursor-pointer px-3 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full ${currentLocation === "delayed-payment" ? "bg-primary text-white hover:bg-orange-300" : "text-color-secondary hover:surface-100"}`}>
                          <span className="font-medium">Delayed Payment</span>
                        </div>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <StyleClass
                    nodeRef={btnRefSupportManagement}
                    selector='@next'
                    enterClassName='hidden'
                    enterActiveClassName='slidedown'
                    leaveToClassName='hidden'
                    leaveActiveClassName='slideup'
                  >
                    <div ref={btnRefSupportManagement} className={`flex align-items-center justify-content-between cursor-pointer px-3 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full text-color-secondary hover:surface-100`}>
                      <div className='flex align-items-center'>
                        <i className="pi pi-box mr-2"></i>
                        <span className="font-medium">Support</span>
                      </div>
                      <i className="pi pi-chevron-down"></i>
                    </div>
                  </StyleClass>
                  <ul className='list-none py-0 pl-4 pr-0 m-0 overflow-hidden'>
                    <li>
                      <Link to='/support/schedule' onClick={() => setVisibility(false)}>
                        <div className={`flex align-items-center cursor-pointer px-3 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full ${currentLocation === "schedule" ? "bg-primary text-white hover:bg-orange-300" : "text-color-secondary hover:surface-100"}`}>
                          <span className="font-medium">Schedule</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to='/support/shortlink' onClick={() => setVisibility(false)}>
                        <div className={`flex align-items-center cursor-pointer px-3 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full ${currentLocation === "shortlink" ? "bg-primary text-white hover:bg-orange-300" : "text-color-secondary hover:surface-100"}`}>
                          <span className="font-medium">Shortlink</span>
                        </div>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to='/room-management' onClick={() => setVisibility(false)}>
                    <div className={`flex align-items-center cursor-pointer px-3 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full ${currentLocation === "room-management" ? "bg-primary text-white hover:bg-orange-300" : "text-color-secondary hover:surface-100"}`}>
                      <i className="pi pi-video mr-2"></i>
                      <span className="font-medium">Room Management</span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="mt-auto overflow-wrap mb-5">
              <hr className="mb-3 mx-3 border-top-1 border-none surface-border" />
              <div className='p-3 mb-5'>
                <Button label='Logout' severity='danger' size='small' className='w-full' onClick={() => logout()}/>
              </div>
            </div>
          </div>
        </div>
      }></Sidebar>
    </>
  )
}

export default SidebarMain
