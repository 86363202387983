import { Dialog } from 'primereact/dialog'
import React, { useEffect, useState } from 'react'
import { generateMoodBooster } from '../../../Hooks/helper';

const MoodBoosterDialog = ({
  visible,
  visibleHandler,
}) => {
  const [ content, setContent ] = useState({
    text: ""
  });
  const [ nextTextToogle, setNextTextToogle ] = useState(false);
  
  useEffect(() => {
    if (visible) { 
        setNextTextToogle(false);
        setContent(generateMoodBooster);
    }
  }, [visible]);

  return (
    <Dialog
        visible={visible}
        onHide={visibleHandler}
        header="Mood Booster"
        style={{ width: '32rem' }} 
        breakpoints={{ '960px': '75vm', '641px': '90vw' }}
    >
        <p className='m-0 p-0'>
            {content.text} 
            <span hidden={!content.nextText || nextTextToogle} className='cursor-pointer text-primary' onClick={() => setNextTextToogle(true)}> next ...</span>
            <span hidden={!nextTextToogle}> {content.nextText}</span>
        </p>
        <p className='mt-5 mb-0 p-0 font-italic text-xs text-center'>Jangan dimasukkan hati ya, ini hanya bercanda</p>
    </Dialog>
  )
}

export default MoodBoosterDialog
