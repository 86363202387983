import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog'
import { Tag } from 'primereact/tag';
import React from 'react'
import { Link } from 'react-router-dom';
import { formattedDate } from '../../../Hooks/helper';

const DetailSchedule = ({
  visible,
  visibleHandler,
  dataSchedule
}) => {
  const bodyScheduleType = (e) => (
    <Tag value={e.data.status === "PTC" ? "PTC" : "CLASS SESSION"} severity={ e.data.status === "PTC" ? "danger" : "warning" }/>
  );
    
  const actionTableTemplate = (e) => (
    <>
      <Link to={`/classes/${e.data.idKelas}`}>
        <Button icon="pi pi-eye" severity='info' size='small' outlined />
      </Link>
    </>
  );

  return (
    <Dialog
      visible={visible}
      onHide={visibleHandler}
      header="Detail Event"
      style={{ width: '50rem' }} 
      breakpoints={{'960px': '75vw', '640px': '95vw'}}
    >
      <DataTable
        value={dataSchedule}
        scrollable
        removableSort
        className='lg:text-sm md:text-sm sm:text-sm text-xs'
        size='small'
      >
        <Column field='title' header='Title' style={{minWidth: '12rem'}} />
        <Column field='data.guru' header='Guru' style={{minWidth: '10rem'}} />
        <Column field='start' header='Start Event' body={(e) => formattedDate(e.start)} style={{minWidth: '9rem'}} />
        <Column field='data.status' header='Type' body={(e) => bodyScheduleType(e)} style={{minWidth: '8rem'}} />    
        <Column body={(e) => actionTableTemplate(e)} />
      </DataTable>
    </Dialog>
  )
}

export default DetailSchedule
