import React, { useEffect, useRef, useState } from 'react';
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { FilterMatchMode } from 'primereact/api';
import { getData } from '../../../Hooks/api';
import { classRegistrationStatus, classType, formatIDRCurrency, formattedDateWithOutTime, getAgeByDate, paymentStatus, saveAsExcelFile, subjectClass } from '../../../Hooks/helper';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import ClassTypeTemplate from '../../../Components/TagTemplate/ClassTypeTemplate';
import ClassSubjectTemplate from '../../../Components/TagTemplate/ClassSubjectTemplate';
import { Column } from 'primereact/column';
import PaymentStatusTemplate from '../../../Components/TagTemplate/PaymentStatusTemplate';
import ClassStatusRegistrationTemplate from '../../../Components/TagTemplate/ClassStatusRegistrationTemplate';
import WALinkTemplate from '../../../Components/TagTemplate/WALinkTemplate';
import FormUpdatePayment from './FormUpdatePayment';

const DelayedPayment = () => {
  const toast = useRef(null);
  const [ loading, setLoading ] = useState(false);
  
  // Data Handler
  const [ detailPaymentCR, setDetailPaymentCR ] = useState({
    _id: "",
    paymentInvoice: 0,
    paymentStatus: "",
    notePayment: "",
    paymentHistory: []
  });
  const [ listDelayedPaymentCR, setListDelayedPaymentCR ] = useState([]);
  const [ retainedValue, setRetainedValue ] = useState(0);

  // Dialog Handler
  const [ showPaymentModal, setShowPaymentModal ] = useState(false);
  
  // Filter Handler
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [ filters, setFilters ] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    'student.nama': {value: null, matchMode:  FilterMatchMode.CONTAINS },
    'student.nie': {value: null, matchMode: FilterMatchMode.CONTAINS},
    'student.nomor': {value: null, matchMode: FilterMatchMode.CONTAINS },
    'student.usia': {value: null, matchMode: FilterMatchMode.CUSTOM},
    subject: {value: null, matchMode: FilterMatchMode.CONTAINS },
    courseLevel: {value: null, matchMode: FilterMatchMode.EQUALS },
    classLevel: { value: null, matchMode: FilterMatchMode.EQUALS },
    classStatus: { value: null, matchMode: FilterMatchMode.EQUALS },
    paymentStatus: { value: null, matchMode: FilterMatchMode.EQUALS },
    payment: { value: null, matchMode: FilterMatchMode.CONTAINS },
    classType: { value: null, matchMode: FilterMatchMode.CONTAINS },
    invoice: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const onGlobalFilterChange = (e) => {
    const value = e.target.value
    let _filters = {...filters}

    _filters['global'].value = value
    setFilters(_filters)
    setGlobalFilterValue(value)
  };

  const getDelayedPaymentCR = async () => {
    setLoading(true);

    getData('class-registration/delayed-payment')
    .then((response) => {
        let tagihan = 0;
        let payment = 0;

        response.data.map((register) => {
            register.student.usia = getAgeByDate(register.student.tanggalLahir);
            register.daftar = formattedDateWithOutTime(register.registrationDate);
            tagihan += register.invoice;
            payment += register.payment;
            return register;
        });

        let retained = tagihan - payment;
        setRetainedValue(retained);
        setListDelayedPaymentCR(response.data);
        setLoading(false);
    })
    .catch((error) => {
        toast.current.show({
            severity: 'error',
            summary: 'Failed Load Data!',
            detail: error.response.data.message,
            life: 3000
        });

        setLoading(false);
    });
  }

  useEffect(() => {
    getDelayedPaymentCR();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ageRowFilterTemplate = (options) => {
    const [from, to] = options.value ?? [null, null];

    return (
        <div className="flex gap-1 align-items-center">
            <InputNumber value={from} onChange={(e) => options.filterApplyCallback([e.value, to])} className="w-full" placeholder="from" />
            <p style={{margin: '0'}}>-</p>
            <InputNumber value={to} onChange={(e) => options.filterApplyCallback([from, e.value])} className="w-full" placeholder="to" />
        </div>
    );
  };

  const classTypeRowFilterTemplate = (options) => (
    <Dropdown 
      value={options.value}
      options={classType} 
      onChange={(e) => options.filterApplyCallback(e.value)} 
      itemTemplate={(e) => <ClassTypeTemplate data={e}/>} 
      placeholder="Choose" 
      className="p-column-filter" 
      style={{maxWidth: '10rem' }} 
      showClear 
    />
  );

  const subjectRowFilterTemplate = (options) => (
    <Dropdown 
      value={options.value}
      options={subjectClass} 
      onChange={(e) => options.filterApplyCallback(e.value)} 
      itemTemplate={(e) => <ClassSubjectTemplate data={e}/>} 
      placeholder="Choose" 
      className="p-column-filter" 
      style={{maxWidth: '10rem' }} 
      showClear 
    />
  );

  const paymentStatusRowFilterTemplate = (options) => (
    <Dropdown 
      value={options.value}
      options={paymentStatus} 
      onChange={(e) => options.filterApplyCallback(e.value)} 
      itemTemplate={(e) => <PaymentStatusTemplate data={e}/>} 
      placeholder="Choose" 
      className="p-column-filter" 
      style={{maxWidth: '10rem' }} 
      showClear 
    />
  );

  const classStatusRowFilterTemplate = (options) => {
    return (
        <Dropdown 
          value={options.value} 
          options={classRegistrationStatus} 
          onChange={(e) => options.filterApplyCallback(e.value)} 
          itemTemplate={(e) => <ClassStatusRegistrationTemplate data={e}/>} 
          placeholder="Choose" 
          className="p-column-filter" 
          style={{maxWidth: '10rem' }} 
          showClear 
        />
    );
  };

  const headerTableTemplate = () => (
    <div className='flex flex-column gap-2'>
        <div className='flex lg:flex-row md:flex-row sm:flex-row flex-column justify-content-between align-items-center gap-2'>
          <h2 className='m-0 p-0 lg:text-left md:text-left sm:text-left text-center'>Billing and Repayment</h2>
          <IconField iconPosition="left" className='lg:w-max md:w-max sm:w-max w-full'>
              <InputIcon className="pi pi-search"> </InputIcon>
              <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" className='p-inputtext-sm w-full'/>
          </IconField>
        </div>
        <h3 className='m-0 p-0 lg:text-left md:text-left sm:text-left text-center'>Retained: <span className='font-bold text-red-500'>{formatIDRCurrency(retainedValue)}</span></h3>
    </div>
  );
  
  const actionTemplate = (data) => {
    return (
        <div className='flex gap-1'>
            <Button icon="pi pi-dollar" severity='success' outlined onClick={() =>{ 
                setShowPaymentModal(true);
                setDetailPaymentCR({
                  _id: data._id,
                  paymentInvoice: data.invoice,
                  paymentStatus: data.paymentStatus,
                  notePayment: data.notePayment,
                  paymentHistory: data.paymentHistory
                });
            }}/>
        </div>
    )
  }

  const exportExcel = async () => {
    let data = listDelayedPaymentCR.map((CR) => {
        let newFormat = {
            NIE: CR.student.nie,
            Name: CR.student.nama,
            Usia: getAgeByDate(CR.student.tanggalLahir),
            CP: CR.student.nomor,
            'Class Type': CR.classType,
            Subject: CR.subject,
            'Class Level': CR.classLevel,
            'Course Level': CR.courseLevel,
            Registered: formattedDateWithOutTime(CR.registrationDate),
            Invoice: formatIDRCurrency(CR.invoice),
            Payment: formatIDRCurrency(CR.payment)
        }

        return newFormat;
    });

    import('xlsx').then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(data);   
                
        const workbook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(workbook, worksheet, "Delayed Payment");
        // { Sheets: { data: worksheet }, SheetNames: ['WAITING CLASS'] };
        const excelBuffer = xlsx.write(workbook, {
            bookType: 'xlsx',
            type: 'array'
        });

        const fileName = `CR Delayed Payment (${formattedDateWithOutTime(new Date())})`;

        saveAsExcelFile(excelBuffer, fileName);
    });
  }

  const paginatorRight = (
    <Button text size="small" icon='pi pi-download' onClick={() => exportExcel()}></Button>
  );

  const paginatorLeft = (
    <Button text size='small' icon='pi pi-refresh' onClick={() => getDelayedPaymentCR()}></Button>
  );

  return (
    <>
      <Toast ref={toast}/>
      
      <FormUpdatePayment 
        visible={showPaymentModal}
        visibleHandler={() => {
          setShowPaymentModal(false);
          setDetailPaymentCR({
            _id: "",
            paymentInvoice: 0,
            paymentStatus: "",
            notePayment: "",
            paymentHistory: []
          });
        }}
        detailPayment={detailPaymentCR}
        refreshAction={() => getDelayedPaymentCR()}
        loading={loading}
        setLoading={setLoading}
        toast={toast}
      />

      <Card>
        <DataTable 
          value={listDelayedPaymentCR}
          scrollable
          removableSort
          paginator
          rows={10}
          rowsPerPageOptions={[10, 25, 50]}
          filters={filters}
          globalFilterFields={[
            'student.nie',
            'student.nama', 
            'student.nomor', 
            'student.usia',
            'student.nie',
            'classType',
            'subject', 
            'classLevel', 
            'courseLevel', 
            'classStatus', 
            'paymentStatus', 
            'invoice',
            'payment',
            'registrationDate',
            'notePayment',
            'daftar'
          ]}
          filterDisplay="row"
          loading={loading}
          header={headerTableTemplate}
          className='lg:text-sm md:text-sm sm:text-sm text-xs p-datatable-sm'
          paginatorLeft={paginatorLeft}
          paginatorRight={paginatorRight}
        >
          <Column header="No" body={(data, Options) => Options.rowIndex + 1} />
          <Column 
            header="Siswa" 
            field='student.nama'  
            sortable 
            filter 
            filterPlaceholder='Search' 
            style={{ minWidth: '15rem' }} 
            showFilterMenu={false}
          />
          <Column 
            header="NIE" 
            field='student.nie'  
            sortable 
            filter 
            filterPlaceholder='Search' 
            style={{ minWidth: '12rem' }} 
            showFilterMenu={false}
          />
          <Column 
            header="Usia" 
            field='student.usia' 
            body={(data) => (`${data.student.usia} Tahun`)} 
            sortable
            filter
            filterElement={ageRowFilterTemplate}
            showFilterMenu={false} 
            style={{ minWidth: '13rem' }}
          />
          <Column 
            header="Kontak" 
            field='student.nomor' 
            body={(data) => <WALinkTemplate data={data.student.nomor}/>}
            sortable
            filter
            filterPlaceholder='Search'
            style={{ minWidth: '10rem' }}
            showFilterMenu={false}
          />
          <Column 
            header="Subjek" 
            field='subject'
            sortable
            filter
            filterElement={subjectRowFilterTemplate}
            style={{ minWidth: '5rem' }}
            body={(data) => <ClassSubjectTemplate data={data.subject}/>}
            showFilterMenu={false}
          />
          <Column 
            header="Tipe" 
            field='classType'
            sortable
            filter
            filterElement={classTypeRowFilterTemplate}
            style={{ minWidth: '5rem' }}
            body={(data) => <ClassTypeTemplate data={data.classType}/>}
            showFilterMenu={false}
          />
          <Column 
            header="Level" 
            field='classLevel'
            sortable
            filter
            style={{ minWidth: '9rem' }}
            showFilterMenu={false}
          />
          <Column 
            header="Materi" 
            field='courseLevel'
            sortable
            filter
            style={{ minWidth: '9rem' }}
            showFilterMenu={false}
          />
          <Column 
            header="Status Registrasi" 
            field='classStatus'
            sortable
            filter
            filterElement={classStatusRowFilterTemplate}
            style={{ minWidth: '5rem' }}
            body={(data) => <ClassStatusRegistrationTemplate data={data.classStatus} />}
            showFilterMenu={false}
          />
          <Column 
            header="Status Bayar" 
            field='paymentStatus'
            sortable
            filter
            filterElement={paymentStatusRowFilterTemplate}
            style={{ minWidth: '7rem' }}
            body={(data) => <PaymentStatusTemplate data={data.paymentStatus} />}
            showFilterMenu={false}
          />
          <Column 
            header="Tagihan" 
            field='invoice'
            sortable
            filter
            body={(data) => formatIDRCurrency(data.invoice)}
            style={{ minWidth: '12rem' }}
            showFilterMenu={false}
          />
          <Column 
            header="Terbayar" 
            field='payment'
            filter
            sortable
            body={(data) => formatIDRCurrency(data.payment)}
            style={{ minWidth: '12rem' }}
            showFilterMenu={false}
          />
          <Column 
            header="Tgl Daftar" 
            field='registrationDate'
            sortable
            body={(data) => formattedDateWithOutTime(data.registrationDate)}
            style={{ minWidth: '12rem' }}
          />
          <Column body={actionTemplate}/>
        </DataTable>
      </Card>
    </>
  )
}

export default DelayedPayment
