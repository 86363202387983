import React from 'react';
import { Document, Page, PDFViewer, StyleSheet, View } from '@react-pdf/renderer';

const PDFA4 = (props) => {
  const pdfStyle = StyleSheet.create({
    viewer: {
        width: '100%',
        height: '80vh'
    },

    pageStyle: {
        backgroundColor: 'white',
        color: 'black',
        padding: '40px',
        fontFamily: 'Times-Roman',
        fontSize: 12,
    },
  });

  return (
    <PDFViewer style={pdfStyle.viewer}>
        <Document>
            <Page size="A4" style={pdfStyle.pageStyle} wrap>
              <View wrap>
                {props.children}
              </View>
            </Page>
        </Document>
    </PDFViewer>
  )
}

export default PDFA4;
