import { Dialog } from 'primereact/dialog'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { postData, putData } from '../../../Hooks/api'
import { formattedDateWithDay, getFormErrorMessage } from '../../../Hooks/helper'
import { Dropdown } from 'primereact/dropdown'
import { Button } from 'primereact/button'

const FormAssignClass = ({
  visible,
  visibleHandler,
  detailCR,
  loading,
  setLoading,
  toast,
  refreshAction
}) => {
  const [ listClassAvailable, setListClassAvailable ] = useState([]);

  const getListClass = async (filterClass) => {
    setLoading(true);

    postData('class-registration/class', filterClass)
    .then((response) => {
        let listClass = response.data;
        listClass.forEach((kelas) => {
            kelas.subject = `${kelas.kodeBatch}.${kelas.kodeKelas} ${kelas.namaKelas} - Lvl.${kelas.level} | ${kelas.guru.nama} (${formattedDateWithDay(kelas.jadwal)})`;
        });

        setListClassAvailable(listClass);
        setLoading(false);
    })
    .catch((error) => {
        setLoading(false);
    });
  }

  const assignClass = async (data, form) => {
    setLoading(true);

    putData(`class-registration/${detailCR._id}/add-class`, data)
    .then(() => {
        visibleHandler();
        form.restart();
        toast.current.show({
            severity:'success', 
            summary: "Success", 
            detail: "Berhasil menetapkan kelas", 
            life: 3000,
        });
        refreshAction();
        setLoading(false);
    })
    .catch((error) => {
        toast.current.show({
            severity:'error', 
            summary: error.response.data.title, 
            detail: error.response.data.message, 
            life: 3000,
        });
        setLoading(false);
    })
  }
  
  useEffect(() => {
    if (detailCR && detailCR._id) { 
        let filterClass = {
            namaKelas: detailCR.subject,
            level: detailCR.classLevel,
            levelMateri: detailCR.courseLevel,
            jenisKelas: detailCR.classType,
        }
        getListClass(filterClass);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailCR]);

  const validateAssignClass = (data) => {
    let errors = {};

    if (!data.classId) errors.classId = "Class is required"
    return errors;
  }

  return (
    <Dialog
        visible={visible}
        onHide={visibleHandler}
        header="Assign Class"
        style={{ width: '40rem' }}
        breakpoints={{ '960px': '75vm', '641px': '90vw' }}
    >
        <Form
            initialValues={{classId: null}}
            onSubmit={assignClass}
            validate={validateAssignClass}
            render={({handleSubmit}) => (
                <form onSubmit={handleSubmit} className='flex flex-column gap-3 p-fluid'>
                    <Field 
                        name='classId'
                        render={({input, meta}) => (
                            <div className='w-full flex-auto'>
                                <label className='font-bold block mb-2'>Class Available</label>
                                <Dropdown
                                    id='classId'
                                    name='classId'
                                    {...input}
                                    filter
                                    options={listClassAvailable}
                                    optionLabel='subject'
                                    optionValue='_id'
                                    placeholder='Choose'
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />
                    <div className='flex lg:justify-content-end md:justify-content-end sm:justify-content-end justify-content-center gap-2 w-full'>
                        <Button type='button' label='Cancel' severity='danger' className='w-max' outlined onClick={visibleHandler} loading={loading}/>
                        <Button type='submit' label='Assign' severity='primary' className='w-max' loading={loading} />
                    </div>
                </form>
            )}
        />
    </Dialog>
  )
}

export default FormAssignClass
