import React, { useEffect, useState } from 'react'
import GeneralInfoSalary from '../../FinanceManagement/SalaryManagement/Teacher/GeneralInfoSalary'
import { ProgressSpinner } from 'primereact/progressspinner'
import CanvasChart from '../../../Components/Chart/Canvas';
import { getData } from '../../../Hooks/api';

const FinancePanel = ({
  loading,
  setLoading,
  toast
}) => {
  const [ revenueData, setRevenueData ] = useState({});
  const currentDate = new Date();
  
  const getRevenueData = async () => {
    setLoading(true);

    getData('revenue')
    .then((response) => {
      setRevenueData(response.data);
      setLoading(false);
    })
    .catch((error) => {
      toast.current.show({
        severity: 'error',
        summary: 'Load Failed!',
        detail: error.response.data.message,
        life: 3000
      });
      setLoading(false);
    })
  }

  useEffect(() => {
    getRevenueData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
        <GeneralInfoSalary toast={toast} setLoading={setLoading} title='Finance Panel'/>
        {   loading ? <ProgressSpinner />  : <CanvasChart 
                data={revenueData} 
                chartName="Revenue Graph" 
                graphLabel={[
                    {label: "Acrued Revenue", name: "graphAcrual"}, 
                    {label: "Cash Revenue", name: "graphCash"}
                ]} 
                limitData={{
                    start: new Date(currentDate.setDate(currentDate.getDate() - 30)), 
                    end: new Date()
                }} 
            />
        }
    </>
  )
}

export default FinancePanel
