import { Dialog } from 'primereact/dialog'
import React, { useEffect, useState } from 'react'
import { DOMAIN, postData, putData } from '../../../Hooks/api';
import { Field, Form } from 'react-final-form';
import { InputText } from 'primereact/inputtext';
import { getFormErrorMessage } from '../../../Hooks/helper';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';

const FormAddUpdateShortLink = ({
  visible,
  visibleHandler,
  data=undefined,
  titleDialog,
  toast,
  loading,
  setLoading,
  refreshAction,
  listShortLink
}) => { 
  const [ initialValues, setInitialValues ] = useState({});
  const [message, setMessage] = useState({
    visibility: false,
    status: false,
    severity: 'warning',
    text: ''
  });

  useEffect(() => {
    setMessage({
        visibility: false,
        status: false,
        severity: 'warning',
        text: ''
    });

    if (titleDialog === "Edit Shortlink") {
      setInitialValues(data);
    } else {
        setInitialValues({
            link: '',
            shortString: ''
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const onChangeUrl = (e, input) => {
    let data = e.target.value;
    let pattern = /[/?$%^&*)(><#@!., ]/g;
    let result = data.replace(pattern, "-");
    input.onChange(result);

    if (listShortLink.find(link => link.shortString === result)) {
        setMessage({
            visibility: true,
            severity: 'error',
            text: 'Ooops, link is already available.',
            status: false
        });
    } else {
        if (result !== "" || result !== null) {
            setMessage({
                visibility: true,
                severity: 'success',
                text: 'Yeay, link can be used.',
                status: true
            });
        }
    }

    input.onChange(result);
  }

  const saveHandler = (data, form) => {
    if (titleDialog === "Edit Shortlink") updateShortLink(data, form); 
    if (titleDialog === "Create Shortlink") createShortLink(data, form);
  }

  const updateShortLink = async (data, form) => {
    setLoading(true);

    putData(`short-link/${data._id}`, data)
    .then((response) => {
        refreshAction("EDIT", response.data);
        toast.current.show({
            severity: 'success',
            summary: 'Update Success',
            detail: "Shortlink successfully updated, congratss.",
            life: 3000
        });
        setLoading(false);
        visibleHandler();
        form.restart();
    })
    .catch((error) => {
        toast.current.show({
            severity: 'error',
            summary: 'Update Failed',
            detail: error.response.data.message,
            life: 3000
        });
        setLoading(false);
    })
  }

  const createShortLink = async (data, form) => {
    setLoading(true);

    postData('short-link', data)
    .then((response) => {
        refreshAction("CREATE", response.data);
        toast.current.show({
            severity: 'success',
            summary: 'Create Success',
            detail: "Shortlink successfully created, Yippy....",
            life: 3000
        });
        setLoading(false);
        visibleHandler();
        form.restart();
    })
    .catch((error) => {
        toast.current.show({
            severity: 'error',
            summary: 'Create Failed',
            detail: error.response.data.message,
            life: 3000
        });
        setLoading(false)
    })
  }

  const validateShortLink = (data) => {
    let errors = {};

    if (!data.link) errors.link = "Link is required";
    if (!data.shortString) errors.shortString = "Shortlink is required";
    if (!message.status) errors.status = "Shortlink is currently exist";

    return errors;
  }

  return (
    <Dialog
        visible={visible}
        onHide={visibleHandler}
        header={titleDialog}
        style={{width: '32rem'}}
        breakpoints={{'960px': '75vw', '640px': '95vw'}}
    >
        <Form
            initialValues={initialValues}
            validate={validateShortLink}
            onSubmit={saveHandler}
            render={({handleSubmit}) => (
                <form onSubmit={handleSubmit} className='flex flex-column gap-3 p-fluid'>
                    <Field
                        name='shortString'
                        render={({input, meta}) => (
                            <div className="flex-auto">
                                <label className='font-bold block mb-1'>Short String</label>
                                <div className="p-inputgroup flex-1">
                                    <span className="p-inputgroup-addon">{DOMAIN}/</span>
                                    <InputText 
                                        id="shortString" 
                                        name='shortString'
                                        {...input}
                                        onChange={(e) => onChangeUrl(e, input)} 
                                    />
                                </div>
                                {getFormErrorMessage(meta)}
                                {message.visibility && <Message severity={message.severity} text={message.text} className='mt-2 p-1'/>}
                            </div>
                        )}
                    />
                    <Field
                        name='link'
                        render={({input, meta}) => (
                            <div className="flex-auto">
                                <label className='font-bold block mb-1'>Link</label>
                                <InputText 
                                    id="link" 
                                    name='link'
                                    {...input}
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />
                    <div className='flex lg:justify-content-end md:justify-content-end sm:justify-content-end justify-content-center gap-2'>
                        <Button type='button' label='Cancel' severity='danger' className='w-max' outlined onClick={visibleHandler} loading={loading}></Button>
                        <Button type='submit' label={titleDialog === "Edit Shortlink" ? "Update" : "Create"} className='w-max' loading={loading} autoFocus></Button>
                    </div>  
                </form>
            )}
        />
    </Dialog>
  )
}

export default FormAddUpdateShortLink
