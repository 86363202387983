import { Button } from 'primereact/button';
import { Card } from 'primereact/card'
import { DataTable } from 'primereact/datatable'
import React, { useEffect, useRef, useState } from 'react'
import { deleteData, getData } from '../../../Hooks/api';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import WALinkTemplate from '../../../Components/TagTemplate/WALinkTemplate';
import StudentStatusTemplate from '../../../Components/TagTemplate/StudentStatusTemplate';
import { formattedDateWithOutTime, saveAsExcelFile } from '../../../Hooks/helper';
import { FilterMatchMode } from 'primereact/api';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';
import FormAddUpdateStudent from './FormAddUpdateStudent';
import DestroyDialog from '../../../Components/ConfirmDialog/DestroyDialog';
import { Link } from 'react-router-dom';
import Overview from './Overview';

const ListStudent = () => {
  const toast = useRef(null);
  const [ loading, setLoading ] = useState(false);

  // Data Handler
  const [ addUpdateTitleDialog, setAddUpdateTitleDialog ] = useState('');
  const [ listStudent, setListStudent ] = useState([]);
  const [ detailStudent, setDetailStudent ] = useState({});

  // Dialog Handler
  const [ addUpdateStudentDialog, setAddUpdateStudentDialog ] = useState(false);
  const [ destroyStudentDialog, setDestroyStudentDialog ] = useState(false);

  // Filter Handler
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const getListStudent = async () => {
    setLoading(true);

    getData('students')
    .then((response) => {
      setListStudent(response.data);
      setLoading(false);
    })
    .catch((error) => {
      toast.current.show({
        severity: 'error',
        summary: 'Load Failed!',
        detail: error.response.data.message,
        life: 3000
      });

      setLoading(false);
    });
  }

  const destroyStudent = async () => {
    setLoading(true);

    deleteData(`student/${detailStudent._id}`)
    .then(() => {
      setTimeout(() => {
        let index = listStudent.findIndex(student => student._id === detailStudent._id);
        listStudent.splice(index, 1);
        setListStudent(listStudent);

        toast.current.show({
            severity: 'success',
            summary: 'Destroy Success',
            detail: 'Student successfully destroyed, Marvelous',
            life: 3000
        });
        hideDialog();
        setLoading(false);
      }, 800);
    })
    .catch((error) => {
      toast.current.show({
        severity: 'error',
        summary: 'Destroy Failed',
        detail: error.response.data.message,
        life: 3000
      });

      setLoading(false);
    })

  }

  useEffect(() => {
    getListStudent();
  }, []);

  const actionBodyTemplate = (rowData) => (
    <div className='flex gap-1'>
      <Button icon='pi pi-pencil' severity='info' outlined size='small' onClick={() => showDialog(rowData, "UPDATE_STUDENT")}/>
      <Button icon='pi pi-trash' severity='danger' size='small' onClick={() => showDialog(rowData, "DESTROY_STUDENT")}/>
      <Link to={`/student-management/list/${rowData._id}`}><Button icon='pi pi-eye' severity='success' outlined/></Link>
    </div>
  );

  const headerTableTemplate = () => (
    <div className='flex flex-column gap-2'>
        <div className='flex lg:flex-row md:flex-row sm:flex-row flex-column justify-content-between align-items-center gap-2'>
          <h2 className='m-0 p-0 lg:text-left md:text-left sm:text-left text-center'>List Student</h2>
          <div className='flex lg:flex-row md:flex-row flex-column  gap-2'>
            <IconField iconPosition="left" className='lg:w-max md:w-max sm:w-max w-full'>
              <InputIcon className="pi pi-search"> </InputIcon>
              <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search student" className='p-inputtext-sm w-full'/>
            </IconField>
            <Button label='Student +' severity='success' size='small' onClick={() => showDialog({}, "ADD_STUDENT")}/>
          </div> 
        </div>
    </div>
  );

  const showDialog = (data, type) => {
    if (type === "ADD_STUDENT") {
      setDetailStudent({
        _id: null,
        nie : null,
        nama: '',
        nomor: null,
        tanggalLahir: null,
        domisili: '',
        sekolah: '',
        ibu: '',
        pekerjaanIbu: '',
        pekerjaanAyah: '',
        funnel: '',
        waktuMendaftar: null,
        status: "",
        classRegistration: true,
        subject: "",
        classType: "",
        classLevel: "",
        courseLevel: "",
        timePref: null,
        invoice: 0
      });
      setAddUpdateTitleDialog("Add Student");
      setAddUpdateStudentDialog(true);
    } 

    if (type === "UPDATE_STUDENT") {
      setDetailStudent(data);
      setAddUpdateTitleDialog("Update Student");
      setAddUpdateStudentDialog(true);
    }

    if (type === "DESTROY_STUDENT") {
      setDetailStudent(data);
      setDestroyStudentDialog(true);
    }
  }

  const hideDialog = () => {
    setDetailStudent({});
    setDestroyStudentDialog(false);

    if (addUpdateTitleDialog === "Add Student" || addUpdateTitleDialog  === "Update Student") setAddUpdateStudentDialog(false);
  }

  const refreshAction = (data) => {
    if (detailStudent._id) {
      let index = listStudent.findIndex( student => student._id === data._id);
      listStudent[index] = data;
      setListStudent(listStudent);
    } else {
      listStudent.push(data);
      setListStudent(listStudent);
    }
  }

  const exportExcel = async (type="VERIFIED") => {
    let data = listStudent.map((student) => {
      let newFormat = {
        NIE: student.nie,
        Name: student.nama,
        Contact: student.nomor,
        Domicile: student.domisili,
        "Born Date": formattedDateWithOutTime(student.tanggalLahir),
        School:  student.sekolah,
        "Mother's Name": student.ibu,
        "Mother's Job": student.pekerjaanIbu,
        "Father's Job": student.pekerjaanAyah,
        Funnel: student.funnel,
        Status: student.status,
        "Registered": formattedDateWithOutTime(student.waktuMendaftar)
      }

      return newFormat;
    });
    

    import('xlsx').then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(data);   
             
        const workbook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(workbook, worksheet, "List Student");
        // { Sheets: { data: worksheet }, SheetNames: ['WAITING CLASS'] };
        const excelBuffer = xlsx.write(workbook, {
          bookType: 'xlsx',
          type: 'array'
        });

        const fileName = `List Student (${formattedDateWithOutTime(new Date())})`;

        saveAsExcelFile(excelBuffer, fileName);
    });
  };

  const paginatorLeft = (<Button icon='pi pi-refresh' text size='small' onClick={getListStudent}/>);
  const paginatorRight = (<Button icon='pi pi-download' text size='small' onClick={exportExcel}/>);

  return (
    <>
      <Toast ref={toast}/>

      <FormAddUpdateStudent
        visibility={addUpdateStudentDialog}
        visibleHandler={() => hideDialog()}
        data={detailStudent}
        titleDialog={addUpdateTitleDialog}
        toast={toast}
        loading={loading}
        setLoading={setLoading}
        refreshAction={refreshAction}
      />

      <DestroyDialog
        visible={destroyStudentDialog}
        visibleHandler={hideDialog}
        acceptAction={destroyStudent}
        rejectAction={hideDialog}
        acceptLabelButton="Delete"
        titleConfirmation="Delete Student"
        messageConfirmation="Are you sure delete this student?"
        loading={loading}
      />
      
      <Overview listStudent={listStudent}/>

      <Card>
        <DataTable
          value={listStudent}
          scrollable
          removableSort
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          loading={loading}
          filters={filters}
          globalFilterFields={[
            'nie', 
            'nama',
            'domisili',
            'sekolah',
            'nomor',
            'ibu',
            'pekerjaanIbu',
            'pekerjaanAyah',
            'funnel',
            'status'
          ]}
          className='lg:text-sm md:text-sm sm:text-sm text-xs p-datatable-sm'
          size='small'
          header={headerTableTemplate}
          paginatorRight={paginatorRight}
          paginatorLeft={paginatorLeft}
        >
          <Column header="No" body={(data, Options) => Options.rowIndex + 1} />
          <Column
            field='nie'
            header='NIE'
            sortable
            align='center'
            className='none-dragable'
            style={{minWidth: '5rem'}}
          />
          <Column 
            header="Student"
            field='nama'
            sortable
            className='none-dragable'
            style={{minWidth: '12rem'}}
          />
          <Column 
            header="Contact"
            field='nomor'
            sortable
            body={(e) => <WALinkTemplate data={e.nomor}/>}
            className='none-dragable'
            style={{minWidth: '9rem'}}
          />
          <Column 
            header="Status"
            field='status'
            sortable
            body={(e) => <StudentStatusTemplate data={e.status}/>}
            align='center'
            className='none-dragable'
            style={{minWidth: '5rem'}}
          />
          <Column 
            header="Domicile"
            field='domisili'
            sortable
            className='none-dragable'
            style={{minWidth: '8rem'}}
          />
          <Column 
            header="Born Date"
            field='tanggalLahir'
            sortable
            body={(e) => formattedDateWithOutTime(e.tanggalLahir)}
            align='center'
            className='none-dragable'
            style={{minWidth: '10rem'}}
          />
          <Column 
            header="School"
            field='sekolah'
            sortable
            className='none-dragable'
            style={{minWidth: '11rem'}}
          />
          <Column 
            header="Mother's Name"
            field='ibu'
            sortable
            className='none-dragable'
            style={{minWidth: '10rem'}}
          />
          <Column 
            header="Mother's Job"
            field='pekerjaanIbu'
            sortable
            className='none-dragable'
            style={{minWidth: '9rem'}}
          />
          <Column 
            header="Father's Job"
            field='pekerjaanAyah'
            sortable
            className='none-dragable'
            style={{minWidth: '9rem'}}
          />
          <Column 
            header="Funnel"
            field='funnel'
            sortable
            className='none-dragable'
            style={{minWidth: '10rem'}}
          />
          <Column 
            header="Registered"
            field='waktuMendaftar'
            sortable
            body={(e) => formattedDateWithOutTime(e.waktuMendaftar)}
            align='center'
            className='none-dragable'
            style={{minWidth: '10rem'}}
          />
           <Column body={actionBodyTemplate} style={{minWidth: '11rem'}}/>
        </DataTable>
      </Card>
    </>
  )
}

export default ListStudent
