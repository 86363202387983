import { Card } from 'primereact/card'
import React, { useEffect, useState } from 'react'
import { Badge } from 'primereact/badge';
import { getData } from '../../../Hooks/api';
import EduChart from '../../../Components/Chart/ChartJS';

const OperationPanel = () => {
  const [ dataOperationPanel, setDataOperationalPanel ] = useState({
    unverifiedTeacher: 0,
    unverifiedClass: 0,
    roomPanel: {
        used: 0,
        idle: 0,
        maintenance: 0,
        total: 0
    },
    salaryLastMonth: 0
  });
  const [ teacherRegisterGraph, setTeacherRegisterGraph ] = useState({
    label: [],
    datasets: []
  });

  const getDataOperational = () => {
    const result = {
        unverifiedTeacher: 0,
        unverifiedClass: 0,
        roomPanel: {
            used: 0,
            idle: 0,
            maintenance: 0,
            total: 0
        },
        salaryLastMonth: 0
    };

    Promise.all([
        getData('unverifiedTeachers').then((response) => result.unverifiedTeacher = response.data.length),
        getData('unverifiedClass').then((response) => result.unverifiedClass = response.data.length),
        getData('rooms-detail').then((response) => {
            let data = response.data;

            result.roomPanel.idle = data.idleRoom;
            result.roomPanel.maintenance = data.maintenanceRoom;
            result.roomPanel.used = data.usedRoom;
            result.roomPanel.total = data.idleRoom + data.maintenanceRoom + data.usedRoom;
        })
    ]).then(() => setDataOperationalPanel(result));
  }

  const getDataTeacherGraph = () => {
    getData('teachers-graph')
    .then((response) => {
        let dataGraph = response.data;
        let dataGraphFormat = {
            label: [],
            datasets: {
                Volunteer: [],
                Super: [],
                Premium: []
            }
        }

        dataGraph.forEach((data) => {
            dataGraphFormat.label.push(`${data.month} ${data.year}`);
            dataGraphFormat.datasets.Premium.push(data.premium);
            dataGraphFormat.datasets.Volunteer.push(data.volunteer);
            dataGraphFormat.datasets.Super.push(data.super)
        });

        setTeacherRegisterGraph(dataGraphFormat);
    })
    .catch((error) => {

    })
  }

  useEffect(() => {
    getDataOperational();
    getDataTeacherGraph();
  }, [])

  return (
    <div className='my-3'>
        <h3 className='m-0 p-0 mb-2'>Operational Panel</h3>
        <div className='flex lg:flex-row md:flex-row flex-column align-items-center justify-content-center gap-2'>
            <EduChart 
                chartTitle="Teacher Register" 
                chartType='bar' 
                data={teacherRegisterGraph}
                legendPosition='top'
                displayLabel={false}
                className='lg:w-6 md:w-6 w-12'
            />
            <div className='flex flex-wrap gap-2'>
                <Card className='col'>
                    <div className='flex flex-column gap-2'>
                        <h3 className='m-0 p-0 w-max'>Unverified Teacher</h3>
                        <div className='flex justify-content-between mt-2'>
                        <div className='bg-blue-500 flex justify-content-center align-items-center border-round-xl w-3rem'>
                            <i className='pi pi-users text-4xl text-white'></i>
                        </div>
                        <div className='text-right'>
                            <p className='m-0 p-0'>Total</p>
                            <p className='m-0 p-0 font-bold lg:text-lg md:text-lg text-base'>{dataOperationPanel.unverifiedTeacher} Account</p>
                        </div>
                        </div>
                    </div>
                </Card>
                <Card className='col'>
                    <div className='flex flex-column gap-2'>
                        <h3 className='m-0 p-0 w-max'>Unverified Class</h3>
                        <div className='flex justify-content-between mt-2'>
                        <div className='bg-blue-500 flex justify-content-center align-items-center border-round-xl w-3rem'>
                            <i className='pi pi-sitemap text-4xl text-white'></i>
                        </div>
                        <div className='text-right'>
                            <p className='m-0 p-0'>Total</p>
                            <p className='m-0 p-0 font-bold lg:text-lg md:text-lg text-base'>{dataOperationPanel.unverifiedClass} Class</p>
                        </div>
                        </div>
                    </div>
                </Card>
                <Card className='col'>
                    <div className='flex flex-column gap-2'>
                        <div className='flex justify-content-between align-items-center'>
                            <h3 className='m-0 p-0 w-max'>Room Panel</h3>
                            <Badge value={`${dataOperationPanel.roomPanel.total} Room`}/>
                        </div>
                        <div className='lg:flex md:flex sm:flex block justify-content-between gap-4 mt-2'>
                            <div className='bg-blue-500 lg:flex md:flex sm:flex hidden justify-content-center align-items-center border-round-xl w-3rem'>
                                <i className='pi pi-video text-4xl text-white'></i>
                            </div>
                            <div className='flex justify-content-between gap-4'>
                                <div className='text-right'>
                                    <p className='m-0 p-0'>Used</p>
                                    <div className='flex gap-1 align-items-center justify-content-end'>
                                        <p className='m-0 p-0 font-bold lg:text-lg md:text-lg text-base w-full'>{dataOperationPanel.roomPanel.used}</p>
                                        <i className='pi pi-video'></i>
                                    </div>
                                </div>
                                <div className='text-right'>
                                    <p className='m-0 p-0'>Idle</p>
                                    <div className='flex gap-1 align-items-center justify-content-end'>
                                        <p className='m-0 p-0 font-bold lg:text-lg md:text-lg text-base w-full'>{dataOperationPanel.roomPanel.idle}</p>
                                        <i className='pi pi-video'></i>
                                    </div>
                                </div>
                                <div className='text-right'>
                                    <p className='m-0 p-0'>Maintenance</p>
                                    <div className='flex gap-1 align-items-center justify-content-end'>
                                        <p className='m-0 p-0 font-bold lg:text-lg md:text-lg text-base w-full'>{dataOperationPanel.roomPanel.maintenance}</p>
                                        <i className='pi pi-video'></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    </div>
  )
}

export default OperationPanel
