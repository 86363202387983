import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { SelectButton } from 'primereact/selectbutton';
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { Button } from 'primereact/button';
import { postData } from '../../../../../Hooks/api';
import { StopReason } from '../../../../../Hooks/helper';

const FormContinuation = ({
  visibility, 
  setVisibility, 
  data, 
  refreshAction,
  toast,
  loading,
  setLoading
}) => {
  const [ initialValues, setInitialValues ] = useState({
    attendanceId: "",
    studentName: "",
    kelanjutanSiswa: "",
    alasan: "",
    klasifikasiBerhenti: ""
  });

  useEffect(() => {
    if (data) setInitialValues(data)
  },  [data]);

  const validateUpdateContinuation = (data) => {
    let errors = {}

    if (!data.kelanjutanSiswa) errors.kelanjutanSiswa = "Continuation is required";
    if (data.kelanjutanSiswa === "Berhenti" && !data.alasan) errors.alasan = "Stop Reason is required";
    if (data.kelanjutanSiswa === "Berhenti" && !data.klasifikasiBerhenti) errors.klasifikasiBerhenti = "Classification Reason is required";
    return errors;
  }

  const updateContinuation = async (data, form) => {
    setLoading(true);

    postData('lanjut-siswa', data)
    .then(() => {
        setTimeout(() => {
            refreshAction();
            toast.current.show({
                severity: 'info',
                summary: 'Update Success',
                detail: 'Continuation successfully updated, keep your spirits up.',
                life: 3000
            });
            form.restart();

            if (!loading) setVisibility();
        }, 1000)
    })
    .catch((error) => {
        if (error.response && error.response.status !== 403 && error.response.data.message !== "Unauthorized: Invalid token") {
            toast.current.show({
                severity: 'error',
                summary: 'Update Failed!',
                detail: error.response.data.message,
                life: 3000
            });
        }
    })
  }

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);

  const getFormErrorMessage = (meta) => {  
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  return (
    <Dialog
        visible={visibility}
        onHide={setVisibility}
        header="Update Continuation Status"
        style={{ width: '32rem' }} 
        breakpoints={{'960px': '75vw', '640px': '95vw'}}
    >
        <Form
            initialValues={initialValues}
            validate={validateUpdateContinuation}
            onSubmit={updateContinuation}
            render={({handleSubmit, values}) => (
                <form onSubmit={handleSubmit} className='flex flex-column gap-3 p-fluid'>
                    <div className='flex-auto'>
                        <label className='font-bold block mb-2'>Nama Siswa</label>
                        <InputText
                            id='studentName'
                            name='studentName'
                            value={initialValues.studentName}
                        />
                    </div>
                    <Field
                        name='kelanjutanSiswa'
                        render={({input, meta}) => (
                            <div className='flex-auto'>
                                <label className='font-bold block mb-2'>Status Kehadiran</label>
                                <SelectButton 
                                  name='kelanjutanSiswa' 
                                  id='kelanjutanSiswa' 
                                  options={["Berhenti", "Lanjut", "Lulus"]} 
                                  {...input}
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />
                    <div className='flex-auto'>
                        <label className='font-bold block mb-2'>Catatan</label>
                        <div className='flex flex-column gap-2'>
                            <Field
                                name='klasifikasiBerhenti'
                                render={({input, meta}) => (
                                    <div hidden={values.kelanjutanSiswa !== "Berhenti"}>
                                        <Dropdown
                                            id='klasifikasiBerhenti'
                                            name='klasifikasiBerhenti'
                                            {...input}
                                            options={StopReason}
                                            placeholder='Choose one'
                                        />
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )}
                            />
                            <Field
                                name='alasan'
                                render={({input, meta}) => (
                                    <div>
                                        <InputTextarea
                                            id='alasan'
                                            name='alasan'
                                            rows={3}
                                            {...input}
                                        />
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                    <div className='flex gap-2 lg:justify-content-end md:justify-content-end sm:justify-content-end justify-content-center w-full'>
                        <Button label="Cancel" icon="pi pi-times" type='button' outlined onClick={() => setVisibility()} loading={loading} className='w-max'/>
                        <Button label="Save" icon="pi pi-check" type='submit' className='w-max' loading={loading}/>
                    </div>
                </form>
            )}
        />
    </Dialog>
  )
}

export default FormContinuation
