import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { classType, getFormErrorMessage, subjectClass } from '../../../Hooks/helper';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { postData } from '../../../Hooks/api';

const FormCreateClass = ({
  visible,
  visibleHandler,
  loading,
  setLoading,
  refreshAction,
  toast,
  data,
  listTeacher
}) => {
  const [ initialValues, setInitialValues ] = useState({
    _id: "",
    namaKelas: "",
    kodeBatch: "",
    kodeKelas: "",
    jadwal: "",
    level: "",
    materiKelas: "",
    tipeKelas: "",
    guru: null
  });

  const createClass = async (data, form) => {
    setLoading(true);

    postData(`plotclasses/${data._id}/create-class`, data)
    .then(() => {
        refreshAction("CREATE_CLASS", data._id);
        toast.current.show({
            severity: 'success',
            summary: 'Create Class Success',
            detail: "Class successfully created, good job Bro!",
            life: 3000
        });
        form.restart();
        visibleHandler();
        setLoading(false);
    })
    .catch((error) => {
        toast.current.show({
            severity: 'error',
            summary: 'Create Data Failed!',
            detail: error.response.data.message,
            life: 3000
        });
        setLoading(false);  
    });
  }
  
  const validateCreateClass = (data) => {
    let errors = {};

    if (!data.kodeBatch) errors.kodeBatch = "Required";
    if (!data.kodeKelas) errors.kodeKelas = "Required";
    if (!data.jadwal) errors.jadwal = "Schedule is required";
    if (!data.guru) errors.guru = "Teacher is required";

    return errors;
  }

  useEffect(() => {    
    if (data && data._id) {
        setInitialValues({
            _id: data._id,
            namaKelas: data.subject,
            kodeBatch: null,
            kodeKelas: null,
            jadwal: new Date(),
            level: data.classLevel,
            materiKelas: data.courseLevel,
            tipeKelas: data.classType,
            guru: data.teacher?._id ?? null
        });
    } else {
        setInitialValues({
            _id: "",
            namaKelas: "",
            kodeBatch: "",
            kodeKelas: "",
            jadwal: "",
            level: "",
            materiKelas: "",
            tipeKelas: "",
            guru: null
        });
    }
  }, [data]);

  return (
    <Dialog
        visible={visible}
        onHide={visibleHandler}
        header="Create Class"
        style={{width: '32rem'}}
        breakpoints={{'960px': '75vw', '640px': '95vw'}}
    >
        <Form
            initialValues={initialValues}
            validate={validateCreateClass}
            onSubmit={createClass}
            render={({handleSubmit}) => (
                <form onSubmit={handleSubmit} className='flex flex-wrap gap-3 p-fluid'>
                    <div className='flex-auto'>
                        <label htmlFor="kodeBatch" className='font-bold block mb-2'>Kode Kelas</label>
                        <div className='flex gap-1'>
                            <Field
                                name='kodeBatch'
                                render={({input, meta}) => (
                                <div className='w-full'>
                                    <InputText 
                                        id='kodeBatch'
                                        name='kodeBatch' 
                                        {...input}
                                        keyfilter="int"
                                    />
                                    {getFormErrorMessage(meta)}
                                </div>
                                )}
                            />
                            <span className='font-bold text-2xl'>.</span>
                            <Field
                                name='kodeKelas'
                                render={({input, meta}) => (
                                <div className='w-full'>
                                    <InputText 
                                        id='kodeKelas' 
                                        name='kodeKelas'
                                        {...input}
                                        keyfilter="int"
                                    />
                                    {getFormErrorMessage(meta)}
                                </div>
                                )}
                            />
                        </div>
                    </div>
                    <Field
                        name='tipeKelas'
                        render={({input, meta}) => (
                        <div className='flex-auto'>
                            <label htmlFor="tipeKelas" className='font-bold block mb-2'>Tipe Kelas</label>
                            <Dropdown 
                                id='tipeKelas' 
                                name='tipeKelas'
                                {...input}
                                options={classType}  
                                placeholder="Masukkan tipe kelas" 
                                className='w-full'
                                disabled
                            />
                            {getFormErrorMessage(meta)}
                        </div>
                        )}
                    />
                    <Field
                        name='namaKelas'
                        render={({input, meta}) => (
                        <div className='flex-auto mb-3'>
                            <label htmlFor="namaKelas" className='font-bold block mb-2'>Subjek Kelas</label>
                            <Dropdown 
                                id='namaKelas' 
                                name='namaKelas'
                                {...input}
                                options={subjectClass} 
                                filter
                                placeholder="Choose Subject Class" 
                                className="w-full"
                                disabled 
                            />
                            {getFormErrorMessage(meta)}
                        </div>
                        )}
                    />
                    <Field
                        name='noteSubjek'
                        render={({input, meta}) => (
                            <div className='flex-auto mb-3'>
                                <label htmlFor="noteSubjek" className='font-bold block mb-2'>Catatan</label>
                                <InputText
                                    id='noteSubjek'
                                    name='noteSubjek'
                                    {...input}
                                    className='w-full'
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />
                    <div className='flex gap-2 w-full'>
                        <Field
                            name='level'
                            render={({input, meta}) => (
                                <div className='flex-auto mb-3 w-full'>
                                    <label htmlFor="level" className='font-bold block mb-2'>Level Kelas</label>
                                    <InputText 
                                        id='level'
                                        name='level' 
                                        {...input}
                                        keyfilter="num"
                                        className='w-full' 
                                    />
                                    {getFormErrorMessage(meta)}
                                </div>
                            )}
                        />
                        <Field
                            name='materiKelas'
                            render={({input, meta}) => (
                                <div className='flex-auto mb-3 w-full'>
                                    <label htmlFor="materiKelas" className='font-bold block mb-2'>Level Materi</label>
                                    <InputText 
                                        id='materiKelas' 
                                        name='materiKelas'
                                        {...input}
                                        keyfilter="num"
                                        className='w-full'
                                    />
                                    {getFormErrorMessage(meta)}
                                </div>
                            )}
                        />
                    </div>
                    <Field
                        name='jadwal'
                        render={({input, meta}) => (
                        <div className='flex-auto mb-3'>
                            <label htmlFor="jadwalRilis" className='font-bold block mb-2'>Jadwal Mulai</label>
                            <Calendar 
                                id='jadwal'
                                name='jadwal'
                                {...input}
                                className='w-full'
                                showTime 
                                hourFormat='24' 
                                showIcon 
                                dateFormat='dd MM yy -'
                            />
                            {getFormErrorMessage(meta)}
                        </div>
                        )}
                    />
                    <Field
                        name='guru'
                        render={({input, meta}) => (
                        <div className='flex-auto mb-3'>
                            <label htmlFor="guru" className='font-bold block mb-2'>Guru</label>
                            <Dropdown 
                                id='guru' 
                                name='guru'
                                {...input}
                                options={listTeacher} 
                                optionLabel='nama'
                                optionValue='_id'
                                filter
                                placeholder="Choose teacher" 
                                className="w-full" 
                                disabled
                            />
                            {getFormErrorMessage(meta)}
                        </div>
                        )}
                    />
                    <div className='flex gap-2 lg:justify-content-end md:justify-content-end sm:justify-content-end justify-content-center w-full'>
                        <Button label="Cancel" icon="pi pi-times" type='button' outlined onClick={visibleHandler} loading={loading} className='w-max'/>
                        <Button label="Save" icon="pi pi-check" type='submit' className='w-max' loading={loading}/>
                    </div>
                </form>
            )}
        />
    </Dialog>
  )
}

export default FormCreateClass
