import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form';
import { putData } from '../../../Hooks/api';
import { listFinanceAccount } from '../../../Constant/bank';
import { getFormErrorMessage } from '../../../Hooks/helper';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import WALinkTemplate from '../../../Components/TagTemplate/WALinkTemplate';

const FormUpdateTeacher = ({
  visible,
  visibleHandler,
  detailTeacherAccount,
  loading,
  setLoading,
  toast,
  listTeachers=[],
  setListTeachers=()=>{},
  setDetailTeacher
}) => {
  const [ initialValues, setInitialValues ] = useState({
    _id: '',
    namaBank: '',
    rekening: '',
    atasNamaRekening: ''
  });

  useEffect(() => {
    if (detailTeacherAccount._id) { 
        if (!detailTeacherAccount.atasNamaRekening) detailTeacherAccount.atasNamaRekening = detailTeacherAccount.nama;
        setInitialValues(detailTeacherAccount);
    }
  }, [detailTeacherAccount]);

  const updateTeacher = async (data, form) => {
    setLoading(true);
    
    putData(`teachers/${data._id}`, data)
    .then(() => {
        setTimeout(() => {
            if (listTeachers.length > 0) {
                let index = listTeachers.findIndex((teacher) => teacher._id === detailTeacherAccount._id);
                listTeachers[index] = {
                    ...detailTeacherAccount,
                    namaBank: data.namaBank,
                    rekening: data.rekening,
                    atasNamaRekening: data.atasNamaRekening
                }
                setListTeachers(listTeachers);
            } else {
                setDetailTeacher(data);
            }

            form.restart();
            toast.current.show({
                severity: 'success',
                summary: 'Update Teacher Success',
                detail: 'Teacher successfully updated, very clear!.',
                life: 3000
            });

            setLoading(false);
            visibleHandler();
        }, 800);
    })
    .catch((error) => {
        toast.current.show({
            severity: 'error',
            summary: 'Update Failed',
            detail: error.response.data.message,
            life: 3000
        });
        setLoading(false);
    });
  }

  const validateUpdateTeacher = (data) => {
    let errors = {};

    if (!data.namaBank) errors.namaBank = "Bank name is required";
    if (!data.rekening) errors.rekening = "Bank account is required";
    if (!data.atasNamaRekening) errors.atasNamaRekening = "Bank username is required";
    
    return errors;
  }

  return (
    <Dialog
        visible={visible}
        onHide={visibleHandler}
        header="Update Teacher"
        style={{ width: '32rem' }}
        breakpoints={{ '960px': '75vm', '641px': '90vw' }}
    >
        <Form
            initialValues={initialValues}
            onSubmit={updateTeacher}
            validate={validateUpdateTeacher}
            render={({handleSubmit}) => (
                <form onSubmit={handleSubmit} className='flex flex-column gap-3 p-fluid'>
                    <div className='flex-auto'>
                        <label className='font-bold block mb-2'>Teacher Name</label>
                        <InputText 
                            id='atasNamaRekening'
                            name='atasNamaRekening'
                            value={initialValues.nama} 
                            className='none-dragable'
                        />
                    </div>
                    <div className='flex flex-wrap gap-3'>
                        <div className='flex-auto'>
                            <label className='font-bold block mb-2'>Contact</label>
                            <WALinkTemplate data={initialValues.nomor}/>
                        </div>
                        <div className='flex-auto'>
                            <label className='font-bold block mb-2'>Education</label>
                            <InputText 
                                id='pendidikan'
                                name='pendidikan'
                                value={initialValues.pendidikan} 
                            />
                        </div>
                    </div>
                    <Field
                        name='namaBank'
                        render={({input, meta}) => (
                            <div className='flex-auto'>
                                <label className='font-bold block mb-2'>Bank Name</label>
                                <Dropdown
                                    id='namaBank'
                                    name='namaBank'
                                    {...input}
                                    options={listFinanceAccount}
                                    filter
                                    optionLabel='name'
                                    optionValue='name'
                                    placeholder='Choose bank'
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />
                    <Field
                        name='rekening'
                        render={({input, meta}) => (
                            <div className='flex-auto'>
                                <label className='font-bold block mb-2'>Bank Account</label>
                                <InputText 
                                    id='rekening'
                                    name='rekening'
                                    {...input}
                                    placeholder='Input bank account'
                                    keyfilter='num'
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />
                    <Field
                        name='atasNamaRekening'
                        render={({input, meta}) => (
                            <div className='flex-auto'>
                                <label className='font-bold block mb-2'>Bank Username</label>
                                <InputText 
                                    id='atasNamaRekening'
                                    name='atasNamaRekening'
                                    {...input}
                                    placeholder='Input bank username'
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />
                    <div className='flex lg:justify-content-end md:justify-content-end sm:justify-content-end justify-content-center gap-2'>
                        <Button size='small' type='button' label='Cancel' severity='danger' className='w-max' outlined loading={loading} onClick={visibleHandler} />
                        <Button size='small' type='submit' label='Update' severity='primary' className='w-max' loading={loading} />
                    </div>
                </form>
            )}
        />
    </Dialog>
  )
}

export default FormUpdateTeacher
