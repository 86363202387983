import { FilterMatchMode } from 'primereact/api';
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useRef, useState } from 'react';
import { getData } from '../../../../Hooks/api';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import { formatIDRCurrency, getSeverity } from '../../../../Hooks/helper';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import GeneralInfoSalary from './GeneralInfoSalary';
import { Toast } from 'primereact/toast';
import FormAddTeacherSalary from './FormAddTeacherSalary';

const TeacherSalary = () => {
  const toast = useRef(null);
  
  // Dialog Handler
  const [ loading, setLoading ] = useState(false);
  const [ teacherSalaryDialog, setTeachersalaryDialog ] = useState(false);
  
  // Data Handler
  const [ listTeacherSalary, setListTeacherSalary ] = useState([]);

  // Filter Handler
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const getAllListSalary = async () => {
    setLoading(true);

    getData('getSalary')
    .then((response) => {
      let listData = [];
      let data = response.data;

      if (data !== null || !data) {
        data.map((rowData, index) => {
          let lastIndexGaji = rowData.listGaji.length > 0 ? rowData.listGaji.length - 1 : -1
          let lastMonthGaji = rowData.listGaji.length > 0 ? rowData.listGaji[lastIndexGaji].jumlahGaji : 0

          rowData['index'] = index + 1
          rowData['qtyKelas'] = rowData.guru.kelas.length
          rowData['rekening'] = `${rowData.guru.namaBank} - ${rowData.guru.rekening}`
          rowData['lastIndexGaji'] = lastIndexGaji
          rowData['lastMonthSalary'] = lastMonthGaji
          listData.push(rowData);

          return rowData;
        });
      }

      setListTeacherSalary(listData);
      setLoading(false);
    })
    .catch((error) => {
      toast.current.show({
        severity: 'error',
        summary: 'Failed Load Data!',
        detail: error.response.data.message,
        life: 3000
      });
      setLoading(false);
    })
  }

  useEffect(() => {
    getAllListSalary();
  }, []);

  const statusSalaryLabel = (rowData) => {
    const gaji = rowData.lastMonthSalary
    const dibayar = rowData.lastIndexGaji === -1 ? 0 : rowData.listGaji[rowData.lastIndexGaji].dibayar
    let label
  
    if (gaji > 0 && dibayar === gaji) label = 'Terbayar'
    if (gaji > 0 && dibayar < gaji && dibayar > 0) label = 'Diangsur'
    if (gaji > 0 && dibayar === 0) label = 'Belum dibayar'
    if (gaji > 0 && dibayar > gaji) label = 'Data Spesial'
    if (gaji === 0) label = 'No Action'
  
    return <Tag value={label} severity={getSeverity('Salary', label)}></Tag>
  }

  const headerTableTemplate = () => (
    <div className='flex flex-column gap-2'>
        <div className='flex lg:flex-row md:flex-row sm:flex-row flex-column justify-content-between align-items-center gap-2'>
          <h2 className='m-0 p-0 lg:text-left md:text-left sm:text-left text-center'>Teacher Salary</h2>
          <div className='flex lg:flex-row md:flex-row flex-column  gap-2'>
            <IconField iconPosition="left" className='lg:w-max md:w-max sm:w-max w-full'>
                <InputIcon className="pi pi-search"> </InputIcon>
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" className='p-inputtext-sm w-full'/>
            </IconField>
            <Button label='Teacher Salary +' severity='success' size='small' onClick={() => setTeachersalaryDialog(true)} />
          </div> 
        </div>
    </div>
  );

  const actionBodyTemplate = (rowData) => (
    <React.Fragment>
      <Link to={`/finance-management/teacher-salary/${rowData._id}`}>
        <Button icon="pi pi-eye" outlined severity='success' size='small'/>
      </Link>
    </React.Fragment>
  )

  return (
    <>
      <Toast ref={toast} />
      <GeneralInfoSalary setLoading={setLoading} toast={toast}/>
      <FormAddTeacherSalary 
        visible={teacherSalaryDialog}
        visibleHandler={() => setTeachersalaryDialog(false)}
        loading={loading}
        setLoading={setLoading}
        toast={toast}
        refreshAction={() => getAllListSalary()}
      />
      
      <Card>
        <DataTable
          value={listTeacherSalary}
          scrollable
          removableSort
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          filters={filters}
          globalFilterFields={[
            'guru.nama',
            'qtyKelas',
            'rekening',
            'lastMonthSalary',
            'lastIndexGaji'
          ]}
          loading={loading}
          header={headerTableTemplate}
          className='lg:text-sm md:text-sm sm:text-sm text-xs p-datatable-sm'
        >
          <Column header="No" body={(data, Options) => Options.rowIndex + 1} className='none-dragable'/>
          <Column header="Teacher Name" field='guru.nama' sortable style={{ minWidth: '12rem' }} className='none-dragable'/>
          <Column header="Qty Class" field='qtyKelas' sortable style={{ minWidth: '6rem' }} alignHeader="center" align="center" className='none-dragable'/>
          <Column header="Bank Account" field='rekening' sortable style={{ minWidth: '12rem' }} className='none-dragable'/>
          <Column 
            header="Latest salary" 
            field='lastMonthSalary' 
            sortable 
            body={(e) => formatIDRCurrency(e.lastMonthSalary)} 
            style={{ minWidth: '9rem' }} 
            align="right"
            className='none-dragable'
          />
          <Column 
            header="Status" 
            field='lastIndexGaji' 
            sortable 
            body={statusSalaryLabel} 
            style={{ minWidth: '9rem' }}
            align="center" 
            className='none-dragable'
          />
          <Column body={actionBodyTemplate}/>
        </DataTable>
      </Card>
    </>
  )
}

export default TeacherSalary
