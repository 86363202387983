import { Card } from 'primereact/card';
import { Panel } from 'primereact/panel';
import { Tag } from 'primereact/tag';
import React, { useEffect, useState } from 'react';
import { formattedDate, getSeverity } from '../../../../../Hooks/helper';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import { getData, postData } from '../../../../../Hooks/api';
import { Divider } from 'primereact/divider';
import FormLinkAlias from './FormLinkAlias';
import GenerateRoom from './GenerateRoom';
import RoomInfo from './RoomInfo';
import FormCreatePTC from '../PTC/FormCreatePTC';
import EduChart from '../../../../../Components/Chart/ChartJS';
import DestroyDialog from '../../../../../Components/ConfirmDialog/DestroyDialog';
import FormAddUpdateClass from '../../FormAddUpdateClass';

const OverviewClass = ({ 
  toast, 
  detailClass, 
  setDetailClass,
  dataAttendance, 
  lengtSession,
  lengthPTC,
  loading,
  setLoading,
  getDataAttendance,
}) => {
  const [ detailClassRoom, setDetailClassRoom ] = useState({
    namaRoom: "",
    linkRoom: "",
    passCode: "",
    meetingID: "",
    hostKey: "",
    mulaiKelas: null,
    berakhirKelas: null
  });
  const [ detailKelas, setDetailKelas ] = useState({});
  const [ aliasLink, setAliasLink ] = useState("");
  const [ dataChartAttendance, setDataChartAttendance ] = useState([]);
  const [ dataChartSession, setDataChartSession ] = useState([]);
  const [ dataChartMood, setDataChartMood ] = useState([]);
  const [ totalMoodCount, setTotalMoodCount ] = useState(0);
  const [ listAttendance, setListAttendance ] = useState([]);
  
  // Dialog handler
  const [ linkAliasDialog, setLinkAliasDialog ] = useState(false);
  const [ typeLinkDialog, setTypeLinkDialog ] = useState("MAIN");
  const [ generateRoomDialog, setGenerateRoomDialog ] = useState(false);
  const [ roomInfoDialog, setRoomInfoDialog ] = useState(false);
  const [ createPTCDialog, setCreatePTCDialog ] = useState(false);
  const [ closeRoomDialog, setCloseRoomDialog ] = useState(false);
  const [ updateClassDialog, setUpdateClassDialog ] =  useState(false);

  const getDetailClassRoom = async () => {
    getData(`rooms/find/${detailClass._id}`)
    .then((response) => {
      if (response.data.classRoom[0]) {
        setDetailClassRoom({
          namaRoom: response.data.classRoom[0].namaRoom,
          linkRoom: response.data.classRoom[0].linkRoom,
          passCode: response.data.classRoom[0].passCode,
          meetingID: response.data.classRoom[0].meetingID,
          hostKey: response.data.classRoom[0].hostKey,
          mulaiKelas: response.data.classRoom[0].mulaiKelas,
          berakhirKelas: response.data.classRoom[0].berakhirKelas
        });
      } else {
        setDetailClassRoom({
          namaRoom: "",
          linkRoom: "",
          passCode: "",
          meetingID: "",
          hostKey: "",
          mulaiKelas: null,
          berakhirKelas: null
        })
      }
      setLoading(false);
    });
  }

  const getAliasClassRoom = async () => {
    getData(`alias-class/${detailClass._id}`)
    .then((response) => {
      if (!response.data.error) setAliasLink(response.data);
      // setAliasLink(response.data);
      setLoading(false);
    })
    .catch((error) => {
      setLoading(false);
    })
  }

  useEffect(() => {
    if (detailClass._id) {
      setDetailKelas({...detailClass, jadwal: new Date(detailClass.jadwal)});
      getDetailClassRoom();
      getAliasClassRoom();

      if (dataAttendance.length  >  0) {
        dataChartModify(dataAttendance);
        setListAttendance(detailClass.absensi);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailClass, dataAttendance]);

  const closeRoom = async () => {
    setLoading(true);

    postData(`checkout-room`, {idKelas: detailClass._id})
    .then(() => {
      setTimeout(() => {
        setDetailClassRoom({
          namaRoom: "",
          linkRoom: "",
          passCode: "",
          meetingID: "",
          hostKey: "",
          mulaiKelas: null,
          berakhirKelas: null
        })
        toast.current.show({
          severity: 'success',
          summary: 'Close Room Success',
          detail: "Room successfully closed, good job for you!",
          life: 3000
        });
        setCloseRoomDialog(false);
        setLoading(false)
      }, 1000);
    })
    .catch((error) => {
      if (error.response && error.response.status !== 403 && error.response.data.message !== "Unauthorized: Invalid token") {
        toast.current.show({
          severity: 'error',
          summary: 'Close Room Failed!',
          detail: error.response.data.message,
          life: 3000
        });
      }
      setLoading(false);
    });
  }

  const dataChartModify = (dataAttendance) => {
    let sesi = [];
    let terlaksana = 0;
    let belumTerlaksana = 0;
    let moodIncreased = 0;
    let moodDecreased = 0;
    let moodSteady = 0;
    let countMood = 0;

    let statusAttendance = {
      Sakit: 0,
      Izin: 0,
      "Tidak Hadir": 0,
      Hadir: 0,
      "Belum Diabsen": 0,
    };
    
    dataAttendance.forEach((attendance) => {
      if (attendance.moodIncreased) {
        moodIncreased += attendance.moodIncreased;
        countMood += attendance.moodIncreased;
      }
      if (attendance.moodDecreased) { 
        moodDecreased += attendance.moodDecreased;
        countMood += attendance.moodDecreased;
      }
      if (attendance.moodSteady) {
        moodSteady += attendance.moodSteady;
        countMood += attendance.moodSteady;
      }

      for(let i = 1; i <= lengtSession; i++) {
        if (attendance[`sesi${i}`] !== "Belum diabsen" && !sesi.find((data) => data === i )) {
          terlaksana++
          sesi.push(i)
        }

        switch (attendance[`sesi${i}`]) {
          case "Hadir":
            statusAttendance['Hadir'] = statusAttendance['Hadir'] + 1
            break

          case "Tidak hadir":
            statusAttendance['Tidak Hadir'] = statusAttendance['Tidak Hadir'] + 1
            break
          
          case "Sakit":
            statusAttendance['Sakit'] = statusAttendance['Sakit'] + 1
            break

          case "Izin":
            statusAttendance['Izin'] = statusAttendance['Izin'] + 1
            break
        
          default:
            statusAttendance['Belum Diabsen'] = statusAttendance['Belum Diabsen'] + 1
            break
        }

        belumTerlaksana = lengtSession - terlaksana;
      }
    });

    setDataChartSession([
      {label: "Terlaksana", value: terlaksana}, 
      {label: "Belum Terlaksana", value: belumTerlaksana}
    ]);

    setDataChartAttendance(Object.entries(statusAttendance).map(([key, value]) => {
      return {label: key, value: value};
    }));

    setDataChartMood([
      { label: "Increased", value: moodIncreased},
      { label: "Steady", value: moodSteady},
      { label: "Decreased", value: moodDecreased}
    ]);

    setTotalMoodCount(countMood);
  }
  
  return (
    <Card>
      <DestroyDialog
        visible={closeRoomDialog}
        visibleHandler={() => setCloseRoomDialog(false)}
        acceptAction={closeRoom}
        rejectAction={() => setCloseRoomDialog(false)}
        titleConfirmation="Close Room"
        messageConfirmation="Are you sure to end session & close the room?"
        acceptLabelButton="Close Room"
        loading={loading}
      />

      <FormLinkAlias 
        visible={linkAliasDialog}
        visibleHandler={() => setLinkAliasDialog(false)}
        linkAlias={aliasLink}
        detailClass={detailClass}
        loading={loading}
        setLoading={setLoading}
        refreshAction={() => getAliasClassRoom()}
        toast={toast}
        type={typeLinkDialog}
      />

      <GenerateRoom 
        visible={generateRoomDialog}
        visibleHandler={() => setGenerateRoomDialog(false)}
        loading={loading}
        setLoading={setLoading}
        toast={toast}
        detailClass={detailClass}
        refreshAction={getDetailClassRoom}
        setDetailClassRoom={setDetailClassRoom}
      />

      <RoomInfo
        visibility={roomInfoDialog}
        visibleHandler={() => setRoomInfoDialog(false)}
        detailRoom={detailClassRoom}
        mainRoom={`${window.location.host}/edufic-room/${detailClass._id}`}
        alternatifRoom={aliasLink === "" ? "" : `${window.location.host}/edufic-room/${aliasLink}`}
        toast={toast}
      />

      <FormCreatePTC 
        visible={createPTCDialog}
        visibleHandler={() => setCreatePTCDialog(false)}
        loading={loading}
        setLoading={setLoading}
        toast={toast}
        listAttendance={listAttendance}
        refreshAction={getDataAttendance}
      />

      <FormAddUpdateClass
        visiblity={updateClassDialog}
        setVisibility={() => setUpdateClassDialog(false)}
        data={detailKelas}
        titleDialog="EDIT CLASS"
        toast={toast}
        refreshAction={setDetailClass}
        loading={loading}
        setLoading={setLoading}
      />

      <div className='flex flex-column gap-2'>
          <div>
            <Button label='FINISH CLASS' icon="pi pi-check-circle" severity='help' className={detailKelas.statusKelas !== "Selesai" ? "hidden" : "w-full text-lg mb-3 cursor-auto"} outlined hidden={detailKelas.statusKelas !== "Selesai"} />
            <div className='flex lg:flex-row md:flex-row sm:flex-row flex-column align-items-center justify-content-between gap-2'>
                <h2 className='m-0 p-0'>{detailKelas.kodeBatch}.{detailKelas.kodeKelas} {detailKelas.namaKelas} (Lvl. {detailKelas.level})</h2>
                <div className='flex gap-2 align-items-center'>
                  <Tag value={detailKelas.jenisKelas} severity={getSeverity("classType", detailKelas.jenisKelas)} className='text-lg'/>
                  <Button icon="pi pi-pencil" size='small' outlined severity='info' className='py-2' onClick={() => setUpdateClassDialog(true)}/>
                </div>
            </div>
            <Panel header="Class Room" className='mt-3' toggleable>
              <div className='flex flex-column gap-2'>
                <div className='flex justify-content-between align-items-center gap-2 h-20rem'>
                  <div className='flex flex-column gap-2 lg:w-max md:w-max sm:w-max w-full'>
                    <div className='flex align-items-center gap-3'>
                      <i className='pi pi-graduation-cap text-4xl lg:block md:block sm:block hidden'></i>
                      <div>
                      <label className='flex align-items-center font-bold'><i className='pi pi-graduation-cap font-bold lg:hidden md:hidden sm:hidden mr-2'></i>Teachers</label>
                        <div className='flex align-items-center gap-1'>
                          <Link to={`/teacher-management/list/${detailClass.guru?._id}`}><i className='pi pi-eye text-blue-500'></i></Link>
                          <p className='p-0 m-0'>{detailKelas.guru?.nama}</p>
                        </div>
                      </div>
                    </div>
                    <div className='flex align-items-center gap-3'>
                      <i className='pi pi-calendar-clock text-4xl lg:block md:block sm:block hidden'></i>
                      <div>
                        <label className='flex align-items-center font-bold'><i className='pi pi-calendar-clock font-bold lg:hidden md:hidden sm:hidden mr-2'></i>Release Schedule</label>
                        <p className='p-0 m-0'>{formattedDate(detailKelas.jadwal)}</p>
                      </div>
                    </div>
                    <div className='flex align-items-center gap-3'>
                      <i className='pi pi-map text-4xl lg:block md:block sm:block hidden'></i>
                      <div>
                        <label className='flex align-items-center font-bold'><i className='pi pi-map font-bold lg:hidden md:hidden sm:hidden mr-2'></i>Level Materi</label>
                        <p className='p-0 m-0'>Level {detailKelas.materiKelas}</p>
                      </div>
                    </div>
                    <div className='flex align-items-center gap-3 w-full'>
                      <i className='pi pi-link text-4xl lg:block md:block sm:block hidden'></i>
                      <div className='flex flex-column gap-1 w-full'>
                        <label className='flex align-items-center font-bold'><i className='pi pi-link font-bold mr-2 lg:hidden md:hidden sm:hidden'></i>Class Link</label>
                        <div className='flex lg:flex-row md:flex-row sm:flex-row flex-column lg:justify-content-start md:justify-content-start sm:justify-content-start justify-content-between gap-2 w-full'>
                          <Button label='Main' icon="pi pi-video" severity='info' size='small' className='lg:w-max md:w-max sm:w-max w-full' onClick={() => { setLinkAliasDialog(true); setTypeLinkDialog("MAIN")}}/>
                          { aliasLink !== "" ? <Button label='Alias' icon="pi pi-video" severity='secondary' size='small' className='lg:w-max md:w-max sm:w-max w-full' onClick={() => { setLinkAliasDialog(true); setTypeLinkDialog("ALIAS") }} /> : 
                            <Button label='Create Alias' icon="pi pi-cog" severity='secondary' outlined size='small' className='lg:w-max md:w-max w-full' onClick={() => { setLinkAliasDialog(true); setTypeLinkDialog("ALIAS")}}/>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='lg:flex md:flex hidden overflow-x-auto h-full'>
                    <EduChart chartTitle="Session Overview" data={dataChartSession} legendPosition='bottom' />
                    <EduChart chartTitle="Attendance Overview" data={dataChartAttendance} legendPosition='bottom' />
                    <EduChart chartTitle="Mood Tracker" data={dataChartMood} legendPosition='bottom' labelFormatter={(value, context) => ( `${(( value/totalMoodCount ) * 100).toFixed(2)}% (${value})` )}/>
                  </div>
                </div>
                
                <Divider className='border-1 text-green-300'/>

                <div className='flex lg:flex-row md:flex-row sm:flex-row flex-column gap-3 justify-content-center align-items-center'>
                  {
                    detailClassRoom.linkRoom === "" ? <Button label="Open Room" size='small' severity='primary' className={detailClass.statusKelas === "Selesai" ? 'hidden' : ' lg:w-max md:w-max sm:w-max w-full'} onClick={() => setGenerateRoomDialog(true)} /> : 
                    <div className='flex lg:flex-row md:flex-row sm:flex-row flex-column gap-1'>
                      <div className='flex gap-1'>
                        <Link to={detailClassRoom.linkRoom} target='_blank'>
                          <Button label='Enter Room' icon="pi pi-sign-in" size='small' outlined severity='info' className={detailClass.statusKelas === "Selesai" ? 'hidden' : ' lg:w-max md:w-max sm:w-max w-full'}/>
                        </Link>
                        <Button icon="pi pi-info-circle" severity='info' size='small' onClick={() => setRoomInfoDialog(true)} disabled={detailClass.statusKelas === "Selesai"}/>
                      </div>
                      <Button label='Close Room' severity='danger' size='small' onClick={() => setCloseRoomDialog(true)}/>
                    </div>
                  }
                  { lengthPTC > 0 ? <Button label="PTC Berlangsung" icon="pi pi-building" severity='danger' size='small' className='w-max' outlined />
                    : <Button label="Mulai PTC" icon="pi pi-building" severity='help' size='small' className='w-max' onClick={() => setCreatePTCDialog(true)}/>
                  }
                </div>
              </div>
            </Panel>
          </div>
          
          <Panel header="Class Graph" toggleable collapsed={true} className='lg:hidden md:hidden'>
            <div className='flex sm:flex-row flex-column gap-3 align-items-center'>
              <EduChart chartTitle="Session Overview" data={dataChartSession}/>
              <EduChart chartTitle="Attendance Overview" data={dataChartAttendance} />
              <EduChart chartTitle="Mood Tracker" data={dataChartMood} labelFormatter={(value, context) => ( `${value} (${(( value/totalMoodCount ) * 100).toFixed(2)}%)` )} />
            </div>
          </Panel>
      </div>
    </Card>
  )
}

export default OverviewClass