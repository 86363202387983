import './App.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import 'primereact/resources/themes/lara-light-amber/theme.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { PrimeReactProvider } from 'primereact/api';
import { ProtectedPages, PublicPages } from './Hooks/auth';
import AuthLayout from './Layouts/AuthLayout';
import Login from './Pages/Authorization/Login';
import Help from './Pages/Authorization/Help/Help';
import MainLayout from './Layouts/MainLayout';
import Dashboard from './Pages/Dashboard';
import ListClass from './Pages/ClassManagement/ListClass';
import ClassOperation from './Pages/ClassManagement/ClassOperation';
import ListStudent from './Pages/StudentManagement/ListStudent';
import ListTeacher from './Pages/TeacherManagement/ListTeacher';
import TeacherPreference from './Pages/TeacherManagement/TeacherPreference';
import TeacherSalary from './Pages/FinanceManagement/SalaryManagement/Teacher';
import DelayedPayment from './Pages/FinanceManagement/DelayedPaymentCR';
import RoomManagement from './Pages/RoomManagement';
import NotFound from './Pages/NotFound';
import ClassRegistration from './Pages/StudentManagement/ClassRegistration';
import ClassPlotting from './Pages/StudentManagement/ClassPlotting';
import DelayedClass from './Pages/ClassManagement/DelayedClass';
import TeacherAccount from './Pages/TeacherManagement/TeacherAccount';
import DetailTeacherSalary from './Pages/FinanceManagement/SalaryManagement/Teacher/Detail Salary';
import SalaryReport from './PDF/SalaryReport';
import DetailTeacher from './Pages/TeacherManagement/ListTeacher/DetailTeacher';
import DetailClass from './Pages/ClassManagement/ListClass/DetailClass';
import DetailStudent from './Pages/StudentManagement/ListStudent/DetailStudent';
import Shortlink from './Pages/SupportManagement/Shortlink';
import Schedule from './Pages/SupportManagement/Schedule';
import StudentConversion from './Pages/StudentManagement/StudentConvertion';

function App() {
  return (
    <PrimeReactProvider>
      <BrowserRouter>
        <Routes>
          <Route element={<ProtectedPages />} >
            <Route path='/' element={<MainLayout />}>
              <Route index element={<Dashboard />} />
              <Route path='/student-management'>
                <Route path='/student-management/list' element={<ListStudent />} />
                <Route path='/student-management/list/:id' element={<DetailStudent />} />
                <Route path='/student-management/class-registration' element={<ClassRegistration />} />
                <Route path='/student-management/class-plotting' element={<ClassPlotting />} />
                <Route path='/student-management/conversion' element={<StudentConversion />} />
              </Route>
              <Route path='/class-management'>
                <Route path='/class-management/list' element={<ListClass />} />
                <Route path='/class-management/list/:id' element={<DetailClass />} />
                <Route path='/class-management/class-operation' element={<ClassOperation />} />
                <Route path='/class-management/delayed-class' element={<DelayedClass />} />
              </Route>
              <Route path='/teacher-management'>
                <Route path='/teacher-management/list' element={<ListTeacher />} />
                <Route path='/teacher-management/list/:id' element={<DetailTeacher />} />
                <Route path='/teacher-management/preference' element={<TeacherPreference />} />
                <Route path='/teacher-management/teacher-account' element={<TeacherAccount />} />
              </Route>
              <Route path='/finance-management'>
                <Route path='/finance-management/teacher-salary' element={<TeacherSalary />} />
                <Route path='/finance-management/teacher-salary/:id' element={<DetailTeacherSalary />} />
                <Route path='/finance-management/teacher-salary/report' element={<SalaryReport />} />
                <Route path='/finance-management/delayed-payment' element={<DelayedPayment />} />
              </Route>
              <Route path='/room-management'>
                <Route index element={<RoomManagement />} />
              </Route>
              <Route path='/support'>
                <Route path='/support/schedule' element={<Schedule />} />
                <Route path='/support/shortlink' element={<Shortlink />} />
              </Route>
            </Route>
          </Route>
          <Route element={<PublicPages />} >
            <Route path='/auth' element={<AuthLayout />}>
              <Route path='/auth/login' element={<Login />} />
              <Route path='/auth/help' element={<Help />} />
            </Route>
          </Route>
          <Route path='/404' element={<NotFound/>} />
          <Route path='*' element={<Navigate replace to="/404" />} />
        </Routes>
      </BrowserRouter>
    </PrimeReactProvider>
  );
}

export default App;
