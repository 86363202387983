import { Button } from 'primereact/button'
import { Card } from 'primereact/card'
import { Panel } from 'primereact/panel'
import { Tag } from 'primereact/tag'
import React, { useState } from 'react'
import { formattedDateWithOutTime, getSeverity } from '../../../../Hooks/helper'
import TeacherTypeTemplate from '../../../../Components/TagTemplate/TeacherTypeTemplate'
import WALinkTemplate from '../../../../Components/TagTemplate/WALinkTemplate'
import RRClusterTemplate from '../../../../Components/TagTemplate/RRClusterTemplate'
import FormUpdateTeacher from '../FormUpdateTeacher'

const OverviewDetailTeacher = ({detailTeacher, setDetailTeacher, loading, setLoading, toast}) => {
  const [ updateTeacherDialog, setUpdateTeacherDialog ] = useState(false);
  
  return (
    <>
      <FormUpdateTeacher
        visible={updateTeacherDialog}
        visibleHandler={() => setUpdateTeacherDialog(false)}
        detailTeacherAccount={detailTeacher}
        loading={loading}
        setLoading={setLoading}
        toast={toast}
        setDetailTeacher={setDetailTeacher}
      />
      <Card>
        <div className='flex flex-column gap-2'>
            <div className='flex lg:flex-row md:flex-row sm:flex-row flex-column align-items-center justify-content-between gap-2 text-center'>
                <h2 className='m-0 p-0'>{detailTeacher.nama}</h2>
                <Tag value={detailTeacher.status} severity={getSeverity("teacherStatus", detailTeacher.status)} className='text-lg'/>
            </div>
            <Panel header="Detail" toggleable>
                <div className='flex flex-wrap justify-content-between gap-2'>
                    <div className='flex flex-column gap-2 lg:w-max md:w-max sm:w-max w-full'>
                        <div className='flex align-items-center gap-3'>
                            <i className='pi pi-graduation-cap text-4xl lg:block md:block sm:block hidden'></i>
                            <div>
                                <label className='font-bold block'><i className='pi pi-graduation-cap font-bold lg:hidden md:hidden sm:hidden mr-2'></i>Education</label>
                                <p className='p-0 m-0'>{detailTeacher.pendidikan}</p>
                            </div>
                        </div>
                        <div className='flex align-items-center gap-3'>
                            <i className='pi pi-calendar text-4xl lg:block md:block sm:block hidden'></i>
                            <div>
                                <label className='font-bold block'><i className='pi pi-calendar font-bold lg:hidden md:hidden sm:hidden mr-2'></i>Place and Date of Birth</label>
                                <p className='p-0 m-0'>{detailTeacher.tempatLahir}, {formattedDateWithOutTime(detailTeacher.tanggalLahir)}</p>
                            </div>
                        </div>
                        <div className='flex align-items-center gap-3'>
                            <i className='pi pi-crown text-4xl lg:block md:block sm:block hidden'></i>
                            <div>
                                <label className='font-bold block'><i className='pi pi-crown font-bold lg:hidden md:hidden sm:hidden mr-2'></i>Teacher Type</label>
                                <TeacherTypeTemplate volunteerTeacher={detailTeacher.volunteerTeacher} superTeacher={detailTeacher.superTeacher}/>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-column gap-2 lg:w-max md:w-max sm:w-max w-full'>
                        <div className='flex align-items-center gap-3'>
                            <i className='pi pi-map-marker text-4xl lg:block md:block sm:block hidden'></i>
                            <div>
                                <label className='font-bold block'><i className='pi pi-map-marker font-bold lg:hidden md:hidden sm:hidden mr-2'></i>Domicile</label>
                                <p className='p-0 m-0'>{detailTeacher.domisili}</p>
                            </div>
                        </div>
                        <div className='flex align-items-center gap-3'>
                            <i className='pi pi-whatsapp text-4xl lg:block md:block sm:block hidden'></i>
                            <div>
                                <label className='font-bold block'><i className='pi pi-whatsapp font-bold lg:hidden md:hidden sm:hidden mr-2'></i>Contact</label>
                                <WALinkTemplate data={detailTeacher.nomor}/>
                            </div>
                        </div>
                        <div className='flex align-items-center gap-3'>
                            <i className='pi pi-envelope text-4xl lg:block md:block sm:block hidden'></i>
                            <div>
                                <label className='font-bold block'><i className='pi pi-envelope font-bold lg:hidden md:hidden sm:hidden mr-2'></i>Email</label>
                                <Tag value={detailTeacher.email} severity='info'/>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-column gap-2 lg:w-max md:w-max sm:w-max w-full'>
                        <div className='flex align-items-center gap-3'>
                            <i className='pi pi-chart-line text-4xl lg:block md:block sm:block hidden'></i>
                            <div>
                                <label className='font-bold block'><i className='pi pi-chart-line font-bold lg:hidden md:hidden sm:hidden mr-2'></i>Retention Rate</label>
                                <RRClusterTemplate rr={parseFloat(detailTeacher.retentionRate).toFixed(2)}/>
                            </div>
                        </div>
                        <div className='flex align-items-center gap-3'>
                            <i className='pi pi-calendar-clock text-4xl lg:block md:block sm:block hidden'></i>
                            <div>
                                <label className='font-bold block'><i className='pi pi-calendar-clock font-bold lg:hidden md:hidden sm:hidden mr-2'></i>Registered at</label>
                                <p className='m-0 p-0'>{formattedDateWithOutTime(detailTeacher.waktuPembuatanAkun)}</p>
                            </div>
                        </div>
                        <Button 
                            label='Bank' 
                            icon='pi pi-building-columns' 
                            severity='info' 
                            outlined 
                            onClick={() => setUpdateTeacherDialog(true)}
                        />
                    </div>
                </div>
            </Panel>
        </div>
      </Card>
    </>
  )
}

export default OverviewDetailTeacher
