import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import React from 'react'

const ListSession = ({
  visible,
  visibleHandler,
  listSession
}) => {
  return (
    <Dialog
      visible={visible}
      onHide={visibleHandler}
      header="Detail List Session"
      style={{ width: '50rem' }}
      breakpoints={{ '960px': '75vm', '641px': '90vw' }}
    >
        <DataTable
          value={listSession}
          scrollable
          removableSort
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 100]}
          size='small'
                className='lg:text-sm md:text-sm sm:text-sm text-xs p-datatable-sm'
        >
            <Column field='subjectClass' sortable header="Subjek kelas" style={{ minWidth: '8rem' }} />
            <Column field='qtySession' sortable header="Qty Sesi" style={{ minWidth: '4rem' }} align='center' />
            <Column field='listSession' sortable header="Rincian Sesi" body={(row) => row.listSession.join(", ")} style={{ minWidth: '5rem' }} align='center' />
        </DataTable>
    </Dialog>
  )
}

export default ListSession
