import { Dialog } from 'primereact/dialog';
import React from 'react'
import { Field, Form } from 'react-final-form';
import { putData } from '../../../Hooks/api';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { getFormErrorMessage } from '../../../Hooks/helper';

const FormTeacherUpdate = ({
  visible,
  visibleHandler,
  loading, 
  refreshAction,
  setLoading,
  toast,
  idPlot,
  listTeacher=[]
}) => {
  const assignTeacher = (data, form) => {
    setLoading(true);

    putData(`plotclasses/${idPlot}/add-teacher`, data)
    .then(() => {
        setTimeout(() => {
            refreshAction();
            toast.current.show({
                severity: 'success',
                summary: 'Assign Teacher Success',
                detail: "Teacher successfully updated, good job for today!",
                life: 3000
            });
            form.restart();
            visibleHandler();
            setLoading(false);
        }, 1000);
    })
    .catch((error) => {
        toast.current.show({
            severity: 'error',
            summary: 'Assign Teacher Failed!',
            detail: error.response.data.message,
            life: 3000
        });
        setLoading(false);
    });
  }

  const validateAssignTeacher = (data) => {
    let errors = {};

    if (!data.teacher) errors.teacher = "Teacher is required";

    return errors;
  } 

  return (
    <Dialog 
      visible={visible}
      onHide={visibleHandler}
      header="Assign Teacher"
      style={{ width: '30rem' }} 
      breakpoints={{ '960px': '75vm', '641px': '90vw' }}
    >
        <Form
            initialValues={{teacher: null}}
            validate={validateAssignTeacher}
            onSubmit={assignTeacher}
            render={({handleSubmit}) => (
                <form onSubmit={handleSubmit} className='flex flex-column gap-3 p-fluid'>
                    <Field
                        name='teacher'
                        render={({input, meta}) => (
                            <div className='flex-auto'>
                                <label className='font-bold block'>Teacher</label>
                                <Dropdown 
                                    id='teacher'
                                    name='teacher'
                                    {...input}
                                    options={listTeacher}
                                    optionLabel='nama'
                                    optionValue='_id'
                                    placeholder='Choose teacher'
                                    filter
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />
                    <div className='flex lg:justify-content-end md:justify-content-end sm:justify-content-end justify-content-center gap-2 w-full'>
                        <Button type='button' label='Cancel' severity='danger' className='w-max' outlined onClick={visibleHandler} loading={loading}/>
                        <Button type='submit' label='Assign' severity='primary' className='w-max' loading={loading} />
                    </div>
                </form>
            )}
        />

    </Dialog>
  )
}

export default FormTeacherUpdate
