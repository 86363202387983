import { DataTable } from 'primereact/datatable'
import React, { useEffect, useState } from 'react'
import { deleteData, getData, putData } from '../../../../Hooks/api';
import { Column } from 'primereact/column';
import { formattedDateWithDay, formattedDateWithOutTime, saveAsExcelFile } from '../../../../Hooks/helper';
import { Button } from 'primereact/button';
import DestroyDialog from '../../../../Components/ConfirmDialog/DestroyDialog';
import AcceptDialog from '../../../../Components/ConfirmDialog/AcceptDialog';
import ClassTypeTemplate from '../../../../Components/TagTemplate/ClassTypeTemplate';

const UnverifiedClass = ({toast}) => {
  const [ loading, setLoading ] = useState(false);
  
  // Data Handler
  const [ detailClass, setDetailClass ] = useState({});
  const [ listUnverifiedClass, setListUnverifiedClass ] = useState([]);

  // Class Dialog
  const [ rejectClassDialog, setRejectClassDialog ] = useState(false);
  const [ acceptClassDialog, setAcceptClassDialog ] = useState(false);

  const getAllUnverifiedClass = async () => {
    setLoading(true);

    getData('unverifiedClass')
    .then((response) => {
        let listClass = response.data;

        listClass.forEach((kelas) => {
            kelas["subject"] = `${kelas.kodeBatch}.${kelas.kodeKelas} ${kelas.namaKelas}`;
            kelas["jadwalText"] = formattedDateWithDay(kelas.jadwal);
        });

        setListUnverifiedClass(response.data);
        setLoading(false);
    })
    .catch((error) => {
        toast.current.show({
            severity: 'error',
            summary: 'Load Failed!',
            detail: error.response.data.message,
            life: 3000
        });

        setLoading(false);
    })
  }

  const acceptVerification = async () => {
    detailClass.statusKelas = "Aktif";

    putData(`class/${detailClass._id}`, detailClass)
    .then(() => {
        setTimeout(() => {
            refreshAction(detailClass._id);
            toast.current.show({
                severity: 'info',
                summary: 'Verification Success',
                detail: 'Class successfully verified, make your day happy.',
                life: 3000
            });

            setAcceptClassDialog(false);
            setLoading(false);
        }, 800);
    })
    .catch((error) => {
        toast.current.show({
            severity: 'error',
            summary: 'Verification Failed!',
            detail: error.response.data.message,
            life: 3000
        });
        setLoading(false);
    })
  }

  const rejectVerification = async () => {
    setLoading(true);

    deleteData(`class/${detailClass._id}`)
    .then(() => {
        setTimeout(() => {
            refreshAction(detailClass._id);
            toast.current.show({
                severity: 'info',
                summary: 'Reject Success',
                detail: 'Class successfully rejected, make your day happy.',
                life: 3000
            });

            setRejectClassDialog(false);
            setLoading(false);
        }, 800);
    })
    .catch((error) => {
        toast.current.show({
            severity: 'error',
            summary: 'Reject Failed!',
            detail: error.response.data.message,
            life: 3000
        });
        setLoading(false);
    });
  }

  useEffect(() => {
    getAllUnverifiedClass();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshAction = (data) => {
    let index = listUnverifiedClass.findIndex(kelas => kelas._id === data._id);
    listUnverifiedClass.splice(index, 1);
  }

  const actionBodyTemplate = (rowData) => (
    <div className='flex gap-1'>
      <Button icon='pi pi-verified' label='Accept' size='small' outlined severity='success' onClick={() => {
        setDetailClass(rowData);
        setAcceptClassDialog(true);
      }}/>
      <Button icon='pi pi-eraser' label='Reject' size='small' severity='danger' onClick={() => {
        setDetailClass(rowData);
        setRejectClassDialog(true);
      }}/>
    </div>
  );

  const exportExcel = async (listClass, titleFile) => {
    let data = listClass.map((kelas) => {
        let newFormat = {
          Subject: kelas.subject,
          Teacher: kelas.guru.nama,
          'Class Type': kelas.jenisKelas,
          'Class Level': kelas.level,
          'Course Level': kelas.materiKelas,
          Schedule: formattedDateWithDay(kelas.jadwal),
          Status: kelas.statusKelas,
        }

        return newFormat;
    });

    import('xlsx').then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(data);   
      
        const workbook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(workbook, worksheet, "Unverified Class");
        // { Sheets: { data: worksheet }, SheetNames: ['WAITING CLASS'] };
        const excelBuffer = xlsx.write(workbook, {
            bookType: 'xlsx',
            type: 'array'
        });

        const fileName = `${titleFile} (${formattedDateWithOutTime(new Date())})`;

        saveAsExcelFile(excelBuffer, fileName);
    });
  }

  const paginatorRight = (<Button icon='pi pi-download' text outlined onClick={() => exportExcel(listUnverifiedClass, 'Unverified Class')}/>);
  const paginatorLeft = (<Button icon='pi pi-refresh' text outlined onClick={() => getAllUnverifiedClass()}/>);

  return (
    <>
        <DestroyDialog
            visible={rejectClassDialog}
            visibleHandler={() => {
                setRejectClassDialog(false);
                setDetailClass({});
            }}
            acceptAction={rejectVerification}
            rejectAction={() => {
                setRejectClassDialog(false);
                setDetailClass({});
            }}
            titleConfirmation="Reject Verification"
            messageConfirmation="Are you sure reject this class?"
            acceptLabelButton='Reject'
            loading={loading}
        />

        <AcceptDialog
            visible={acceptClassDialog}
            visibleHandler={() => {
                setAcceptClassDialog(false);
                setDetailClass({});
            }}
            acceptAction={acceptVerification}
            rejectAction={() => {
                setAcceptClassDialog(false);
                setDetailClass({});
            }}
            titleConfirmation="Accept Verification"
            messageConfirmation="Are you sure accept this class?"
            acceptLabelButton='Accept'
            loading={loading}
        />
        
        <div>
            <h3 className='m-0 p-0 flex align-items-center gap-1'>Total : <span className='font-bold text-2xl text-primary'>{listUnverifiedClass.length}</span> Kelas</h3>
            <DataTable
                value={listUnverifiedClass}
                scrollable
                removableSort
                paginator
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50]}
                loading={loading}
                className='lg:text-sm md:text-sm sm:text-sm text-xs p-datatable-sm'
                size='small'
                paginatorLeft={paginatorLeft}
                paginatorRight={paginatorRight}
            >
                <Column header="No" body={(data, Options) => Options.rowIndex + 1} />
                <Column 
                    header="Subject"
                    field='subject'
                    sortable
                    className='none-dragable'
                    style={{minWidth: '7rem'}}
                />
                <Column 
                    header="Teacher"
                    field='guru.nama'
                    sortable
                    className='none-dragable'
                    style={{minWidth: '12rem'}}
                />
                <Column 
                    header="Class Type"
                    field='jenisKelas'
                    sortable
                    body={(e) => <ClassTypeTemplate data={e.jenisKelas}/>}
                    align='center'
                    className='none-dragable'
                    style={{minWidth: '8rem'}}
                />
                <Column 
                    header="Class Level"
                    field='level'
                    sortable
                    align='center'
                    className='none-dragable'
                    style={{minWidth: '5rem'}}
                />
                <Column 
                    header="Course Level"
                    field='materiKelas'
                    sortable
                    align='center'
                    className='none-dragable'
                    style={{minWidth: '5rem'}}
                />
                <Column 
                    header="Release Schedule"
                    field='jadwal'
                    sortable
                    body={(e) => formattedDateWithDay(e.jadwal)}
                    className='none-dragable'
                    style={{minWidth: '14rem'}}
                />
                <Column body={actionBodyTemplate} style={{minWidth: '14rem'}}/>
            </DataTable> 
        </div>
    </>
  )
}

export default UnverifiedClass
