import { Card } from 'primereact/card';
import React, { useEffect, useState } from 'react';
import { formatDelimeter } from '../../../Hooks/helper';
import { Tag } from 'primereact/tag';
import { Panel } from 'primereact/panel';

const Overview = ({listActiveClass, listFinishClass}) => {
  const [ overviewClass, setOverviewClass ] = useState({
    total: 0,
    status:{
        Aktif: 0,
        'Belum PTC': 0,
        'Belum RR': 0,
        'Belum Diselesaikan': 0,
        Selesai: 0
    }
  });
  useEffect(() => {
    mergeModifyList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listActiveClass, listFinishClass]);

  const mergeModifyList = () => {
    let data = {
      total: 0,
      status: {
        Aktif: 0,
        Selesai: 0,
        'Belum PTC': 0,
        'Belum RR': 0,
        'Belum Diselesaikan': 0
      }
    }
    data.status['Selesai'] = listFinishClass.length;
    data.status['Aktif'] = listActiveClass['Aktif'].length;
    data.status['Belum PTC'] = listActiveClass['Belum PTC'].length;
    data.status['Belum RR'] = listActiveClass['Belum RR'].length;
    data.status['Belum Diselesaikan'] = listActiveClass['Belum Diselesaikan'].length;
    data.total = Object.values(overviewClass.status).reduce((sum, item) => sum += item, 0);
    setOverviewClass(data);
  }

  return (
    <>
        <div className='flex justify-content-between mb-3'>
            <h2 className='m-0 p-0'>List Class</h2>
            <Tag value={`${formatDelimeter(overviewClass.total)} Class`} className='text-lg' severity='danger' />
        </div>
        <div className='lg:flex md:flex flex-wrap hidden gap-2 mb-3'>
            <Card className='col'>
                <div className='flex flex-column gap-1'>
                    <h3 className='m-0 p-0'>Active</h3>
                    <p className='m-0 p-0 lg:text-xl md:text-lg text-base font-bold'><span className='text-primary'>{ formatDelimeter(overviewClass.status['Aktif']) }</span> Class</p>
                </div>
            </Card>
            <Card className='col'>
                <div className='flex flex-column gap-1'>
                    <h3 className='m-0 p-0'>Need PTC</h3>
                    <p className='m-0 p-0 lg:text-xl md:text-lg text-base font-bold'><span className='text-primary'>{ formatDelimeter(overviewClass.status['Belum PTC']) }</span> Class</p>
                </div>
            </Card>
            <Card className='col'>
                <div className='flex flex-column gap-1'>
                    <h3 className='m-0 p-0'>Need RR</h3>
                    <p className='m-0 p-0 lg:text-xl md:text-lg text-base font-bold'><span className='text-primary'>{ formatDelimeter(overviewClass.status['Belum RR']) }</span> Class</p>
                </div>
            </Card>
            <Card className='col'>
                <div className='flex flex-column gap-1'>
                    <h3 className='m-0 p-0'>Need End</h3>
                    <p className='m-0 p-0 lg:text-xl md:text-lg text-base font-bold'><span className='text-primary'>{ formatDelimeter(overviewClass.status['Belum Diselesaikan']) }</span> Class</p>
                </div>
            </Card>
            <Card className='col'>
                <div className='flex flex-column gap-1'>
                    <h3 className='m-0 p-0'>Finish</h3>
                    <p className='m-0 p-0 lg:text-xl md:text-lg text-base font-bold'><span className='text-primary'>{ formatDelimeter(overviewClass.status['Selesai']) }</span> Class</p>
                </div>
            </Card>
        </div>
        <Panel header="Teacher Overview" toggleable collapsed className='lg:hidden md:hidden mb-3'>
            <div className='flex flex-wrap gap-2'>
                <Card  className='col'>
                    <div className='flex flex-column gap-1'>
                        <h3 className='m-0 p-0 w-max'>Active</h3>
                        <p className='m-0 p-0 lg:text-xl md:text-lg text-base font-bold'><span className='text-primary'>{ formatDelimeter(overviewClass.status['Aktif']) }</span> Class</p>
                    </div>
                </Card>
                <Card className='col'>
                    <div className='flex flex-column gap-1'>
                        <h3 className='m-0 p-0 w-max'>Need PTC</h3>
                        <p className='m-0 p-0 lg:text-xl md:text-lg text-base font-bold'><span className='text-primary'>{ formatDelimeter(overviewClass.status['Belum PTC']) }</span> Class</p>
                    </div>
                </Card>
                <Card className='col'>
                    <div className='flex flex-column gap-1'>
                        <h3 className='m-0 p-0 w-max'>Need RR</h3>
                        <p className='m-0 p-0 lg:text-xl md:text-lg text-base font-bold'><span className='text-primary'>{ formatDelimeter(overviewClass.status['Belum RR']) }</span> Class</p>
                    </div>
                </Card>
                <Card className='col'>
                    <div className='flex flex-column gap-1'>
                        <h3 className='m-0 p-0 w-max'>Need End</h3>
                        <p className='m-0 p-0 lg:text-xl md:text-lg text-base font-bold'><span className='text-primary'>{ formatDelimeter(overviewClass.status['Belum Diselesaikan']) }</span> Class</p>
                    </div>
                </Card>
                <Card className='col'>
                    <div className='flex flex-column gap-1'>
                        <h3 className='m-0 p-0 w-max'>Finish</h3>
                        <p className='m-0 p-0 lg:text-xl md:text-lg text-base font-bold'><span className='text-primary'>{ formatDelimeter(overviewClass.status['Selesai']) }</span> Class</p>
                    </div>
                </Card>
            </div>
        </Panel>
    </>
  )
}

export default Overview
