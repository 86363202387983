import { Dialog } from 'primereact/dialog';
import React from 'react';
import { putData } from '../../../../../Hooks/api';
import { Field, Form } from 'react-final-form';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';

const RemoveStudent = ({
  visible, 
  visibleHandler, 
  refreshAction, 
  setLoading, 
  detailAttendance, 
  toast
}) => {
  const removeStudent = (data, form) => {
    setLoading(true);
    data.absensiId = detailAttendance.id;
    
    putData('class-registration/remove-student-class', data)
    .then((response) => {
        toast.current.show({
            severity:'success', 
            summary: response.data.title, 
            detail: response.data.message, 
            life: 3000,
        });
        form.restart();
        refreshAction();
        setLoading(false);
        visibleHandler();
    })
    .catch((error) => {
        if (error.response && error.response.status !== 403 && error.response.data.message !== "Unauthorized: Invalid token") {
            toast.current.show({
                severity: 'error',
                summary: "Error",
                detail: error.response.data.message
            });
        }
        setLoading(false);
    });
  }

  const validateRemoveStudent = (data) => {
    let errors = {};

    if (!data.noteClass) errors.noteClass = 'Reason is required for analyse';
    return errors;
  }

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);

  const getFormErrorMessage = (meta) => {  
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  return (
    <Dialog
        header="Remove Student"
        visible={visible}
        onHide={visibleHandler}
        style={{ width: '25rem' }} 
        breakpoints={{'960px': '75vw', '640px': '95vw'}}
    >
        <Form
            onSubmit={removeStudent}
            validate={validateRemoveStudent}
            initialValues={{noteClass: ""}}
            render={({handleSubmit}) => (
                <form onSubmit={handleSubmit} className='flex flex-column gap-3 p-fluid'>
                    <Field
                        name='noteClass'
                        render={({input, meta}) => (
                            <div className='mb-3'>
                                <label className='font-bold block mb-2'>Alasan Perpindahan</label>
                                <InputTextarea
                                    id='noteClass'
                                    name='noteClass'
                                    {...input}
                                    placeholder='Input the reason ...'
                                    rows={2}
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />
                    <div className='flex lg:justify-content-end md:justify-content-end sm:justify-content-end justify-content-center gap-2'>
                        <Button type='button' label='Batal' severity='info' className='w-max' outlined onClick={() => visibleHandler()} autoFocus></Button>
                        <Button type='submit' label='Pindah' severity='danger' className='w-max'></Button>
                    </div>      
                </form>
            )}
        />
    </Dialog>
  )
}

export default RemoveStudent
