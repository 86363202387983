import React from 'react'
import { Image, StyleSheet, Text, View } from '@react-pdf/renderer'
import { useLocation } from 'react-router-dom';
import PDFA4 from '../Layouts/PDFA4';
import { formatAccounting, formatIDRCurrency, formattedDateWithOutTime } from '../Hooks/helper';


const SalaryReport = () => {
    const location = useLocation()
    const dataReport = location.state
    const {sesi, guru} = dataReport

    const pdfStyles = StyleSheet.create({            
        header: {
            width: '100%',
    
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
    
            marginBottom: '40px',
        },
    
        image: {
            width: '20%',
        },
    
        textHeader: {
            width: '80%',
            fontFamily: 'Times-Bold',
            fontSize: '14px',
            textAlign: 'center',
        },
    
        tHead: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
    
            fontFamily: 'Times-Bold',
            fontSize: 13,
        },
    
        tRow: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            marginTop: '5px'
        },
    
        tColSubject: {
            width: '40%'
        },
    
        tColQtySession: {
            width: '20%',
            textAlign: 'center'
        },
    
        tColDetailSession: {
            width: '25%',
        },
    
        tColSalaryValue: {
            width: '20%',
            textAlign: 'right'
        },
    
        totalValue: {
            width: '100%',
            backgroundColor: 'rgb(189, 189, 189)',
    
            paddingVertical: '3px',
            paddingHorizontal: '10px',
            marginBottom: '20px',
    
            display: 'flex',
            flexDirection: 'row',
            fontFamily: 'Times-Bold',
        },
    
        detailValue: {
            width: '100%',
            textAlign: 'center'
        },
    
        signatureField: {
            height: '90px',
            width: '100%',
    
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
    
            textAlign: 'right',
            marginBottom: '20px'
        },
    });
    
    return (
      <PDFA4>
        <View style={pdfStyles.header}>
            <Image style={pdfStyles.image} src='/img/edufic-logo.png'/>
            <View style={pdfStyles.textHeader}>
                <Text style={pdfStyles.textHeader}>Slip Gaji Pengajar Edufic</Text>
                <Text style={pdfStyles.textHeader}>Masa KBM {sesi[0].bulanGaji}</Text>
            </View>
        </View>

        <View style={{marginBottom: '40px'}}>
            <View style={{display: 'flex', flexDirection: 'row'}}>
                <Text style={{width: '20%', marginRight: '20px'}}>Nama</Text>
                <Text>: {guru.nama}</Text>
            </View>
            <View style={{display: 'flex', flexDirection: 'row'}}>
                <Text style={{width: '20%', marginRight: '20px'}}>Jabatan</Text>
                <Text>: Pengajar</Text>
            </View>
            <View style={{display: 'flex', flexDirection: 'row'}}>
                <Text style={{width: '20%', marginRight: '20px'}}>Status</Text>
                <Text>: {guru.superTeacher ? "Premium" : "Reguler"} Teacher</Text>
            </View>
        </View>

        <View style={{marginBottom: '15px'}}>
            <View style={pdfStyles.tHead}>
                <Text style={pdfStyles.tColSubject}>Keterangan Penghasilan</Text>
                <Text style={pdfStyles.tColQtySession}>Sesi</Text>
                <Text style={pdfStyles.tColDetailSession}>Rincian</Text>
                <Text style={pdfStyles.tColSalaryValue}>Nominal (Rp)</Text>
            </View>
            { sesi.map((detailData) => (
                <View style={pdfStyles.tRow} key={detailData.subjectClass}>
                    <Text style={pdfStyles.tColSubject}>{detailData.subjectClass}</Text>
                    <Text style={pdfStyles.tColQtySession}>{detailData.qtySession}</Text>
                    <Text style={pdfStyles.tColDetailSession}>{detailData.listSession.join(", ")}</Text>
                    <Text style={pdfStyles.tColSalaryValue}>{formatAccounting(detailData.nominal)}</Text>
                </View>
            )) }
        </View>

        <View style={pdfStyles.totalValue}>
            <Text style={pdfStyles.detailValue}>Penerimaan Bersih</Text>
            <Text style={pdfStyles.detailValue}>{formatIDRCurrency(dataReport.sesi[0].totalGaji)}</Text>
        </View>


        <View style={pdfStyles.signatureField}>
            <Text>Surabaya, {formattedDateWithOutTime(new Date())}</Text>
            <View>
                <Text style={{textDecoration: 'underline'}}>Manager Operasional</Text>
                <Text>Haris Al Hamdi, S.T.</Text>
            </View>
        </View>
      </PDFA4>
    )
}

export default SalaryReport
