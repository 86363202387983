import React, { useEffect, useState } from 'react'
import { putData } from '../../../../Hooks/api'
import { Card } from 'primereact/card';
import { formatAccounting, getListYear } from '../../../../Hooks/helper';
import { Dropdown } from 'primereact/dropdown';
import months from '../../../../Constant/month';
import { Panel } from 'primereact/panel';

const GeneralInfoSalary = ({
  setLoading,
  toast,
  title="Overview"
}) => {
  const [ month, setMonth ] = useState(new Date().getMonth() === 0 ? 0 : new Date().getMonth() - 1);
  const [ year, setYear ] = useState(new Date().getFullYear());
  const [ generalInfoSalary, setGeneralInfoSalary ] = useState({
    year: year,
    bulan: month,
    gajiTerbayar: 0,
    gajiBelumTerbayar: 0,
    totalGaji: 0
  });

  const getGeneralInfoSalary = () => {
    setLoading(true);

    putData('general-info-salary', {month: month, year: year})
    .then((response) => {
      setGeneralInfoSalary(response.data.response);
      setLoading(false);
    })
    .catch((error) => {
      toast.current.show({
        severity: 'error',
        summary: 'Failed Load Data!',
        detail: error.response.data.message,
        life: 3000
      });
      setLoading(false);
    });
  }

  useEffect(() => {
    getGeneralInfoSalary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month, year]);
  
  return (
    <>
        <div className='lg:flex md:flex hidden justify-content-between align-items-center gap-2 mb-2'>
            { title === "Overview" ? <h2 className='m-0 p-0 text-center'>{title}</h2> : <h3 className='m-0 p-0 text-center'>{title}</h3>}
            <div className='flex gap-1'>
                <Dropdown 
                    value={month}
                    options={months}
                    placeholder='Month'
                    optionLabel='nameMonth'
                    optionValue='id'
                    onChange={(e) => setMonth(e.target.value)}
                    className='lg:w-max md:w-max sm:w-max w-full'
                />
                <Dropdown 
                    value={year}
                    options={getListYear(new Date().getFullYear())}
                    placeholder='Year'
                    onChange={(e) => setYear(e.target.value)}
                    className='lg:w-max md:w-max sm:w-max w-full'
                />
            </div>
        </div>
        <div className='lg:flex md:flex hidden gap-2 mb-3'>
            <Card className='col'>
                <h3 className='m-0 p-0'>Total Teacher Salary</h3>
                <div className='flex justify-content-between mt-2'>
                    <div className='bg-blue-400 flex justify-content-center align-items-center border-round-xl w-3rem'>
                        <i className='pi pi-dollar text-4xl text-white'></i>
                    </div>
                    <div className='text-right'>
                        <p className='m-0 p-0'>Amount</p>
                        <p className='m-0 p-0 font-bold lg:text-lg md:text-lg text-base text-primary'>Rp. {generalInfoSalary.totalGaji !== null? formatAccounting(generalInfoSalary.totalGaji) : 0}</p>
                    </div>
                </div>
            </Card>
            <Card className='col'>
                <h3 className='m-0 p-0'>Need Payment</h3>
                <div className='flex justify-content-between mt-2'>
                    <div className='bg-red-500 flex justify-content-center align-items-center border-round-xl w-3rem'>
                        <i className='pi pi-exclamation-triangle text-4xl text-white'></i>
                    </div>
                    <div className='text-right'>
                        <p className='m-0 p-0'>Amount</p>
                        <p className='m-0 p-0 font-bold lg:text-lg md:text-lg text-base text-primary'>Rp. {generalInfoSalary.gajiBelumTerbayar !== null? formatAccounting(generalInfoSalary.gajiBelumTerbayar) : 0}</p>
                    </div>
                </div>
            </Card>
            <Card className='col'>
                <h3 className='m-0 p-0'>Paid Of</h3>
                <div className='flex justify-content-between mt-2'>
                    <div className='bg-green-500 flex justify-content-center align-items-center border-round-xl w-3rem'>
                        <i className='pi pi-check text-4xl text-white'></i>
                    </div>
                    <div className='text-right'>
                        <p className='m-0 p-0'>Amount</p>
                        <p className='m-0 p-0 font-bold lg:text-lg md:text-lg text-base text-primary'>Rp. {generalInfoSalary.gajiTerbayar !== null? formatAccounting(generalInfoSalary.gajiTerbayar) : 0}</p>
                    </div>
                </div>
            </Card>
        </div>

        <Panel className='lg:hidden md:hidden block mb-3' toggleable collapsed header={title}>
            <Dropdown 
                value={month}
                options={months}
                placeholder='Choose Month'
                optionLabel='nameMonth'
                optionValue='id'
                onChange={(e) => setMonth(e.target.value)}
                className='w-full mb-2'
            />
            <div className='flex flex-column gap-2 mb-3'>
                <Card className='col'>
                    <h3 className='m-0 p-0'>Teacher Salary</h3>
                    <div className='flex justify-content-between mt-2'>
                        <div className='bg-blue-400 flex justify-content-center align-items-center border-round-xl w-3rem'>
                            <i className='pi pi-dollar text-4xl text-white'></i>
                        </div>
                        <div className='text-right'>
                            <p className='m-0 p-0 text-sm'>Amount</p>
                            <p className='m-0 p-0 font-bold text-sm text-primary'>Rp. {generalInfoSalary.totalGaji !== null? formatAccounting(generalInfoSalary.totalGaji) : 0}</p>
                        </div>
                    </div>
                </Card>
                <Card className='col'>
                    <h3 className='m-0 p-0'>Need Payment</h3>
                    <div className='flex justify-content-between mt-2'>
                        <div className='bg-red-500 flex justify-content-center align-items-center border-round-xl w-3rem'>
                            <i className='pi pi-exclamation-triangle text-4xl text-white'></i>
                        </div>
                        <div className='text-right'>
                            <p className='m-0 p-0 text-sm'>Amount</p>
                            <p className='m-0 p-0 font-bold text-sm text-primary'>Rp. {generalInfoSalary.gajiBelumTerbayar !== null? formatAccounting(generalInfoSalary.gajiBelumTerbayar) : 0}</p>
                        </div>
                    </div>
                </Card>
                <Card className='col'>
                    <h3 className='m-0 p-0'>Paid Of</h3>
                    <div className='flex justify-content-between mt-2'>
                        <div className='bg-green-500 flex justify-content-center align-items-center border-round-xl w-3rem'>
                            <i className='pi pi-check text-4xl text-white'></i>
                        </div>
                        <div className='text-right'>
                            <p className='m-0 p-0 text-sm'>Amount</p>
                            <p className='m-0 p-0 font-bold text-sm text-primary'>Rp. {generalInfoSalary.gajiTerbayar !== null? formatAccounting(generalInfoSalary.gajiTerbayar) : 0}</p>
                        </div>
                    </div>
                </Card>
            </div>
        </Panel>
    </>
  )
}

export default GeneralInfoSalary
