import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { postData } from '../../../../../Hooks/api';
import { Button } from 'primereact/button';

const FormCreatePTC = ({
  visible,
  visibleHandler,
  listAttendance,
  loading,
  setLoading,
  refreshAction,
  toast
}) => {
  const [ initialValues, setInitialValues ] = useState({
    attendanceId: null,
    jadwal: null
  });

  useEffect(() => {
    if (listAttendance && listAttendance.length > 0) setInitialValues({
      jadwal: new Date(),
      attendanceId: listAttendance
    })
  }, [listAttendance]);

  const validatePTC = (data) => {
    let errors = {};

    if (!data.jadwal) errors.jadwal = 'PTC schedule is required';
    return errors;
  }

  const createPTC = (data, form) => {
    setLoading(true);

    postData(`add-array-ptc`, data)
    .then(() => {
      setTimeout(() => {
        refreshAction();
        toast.current.show({
            severity: 'info',
            summary: 'Create Success',
            detail: 'PTC Session successfully created.',
            life: 3000
        });
        form.restart();

        if (!loading) visibleHandler();
      }, 1000);
    })
    .catch((error) => {
      if (error.response && error.response.status !== 403 && error.response.data.message !== "Unauthorized: Invalid token") {
        toast.current.show({
          severity: 'error',
          summary: 'Create Failed!',
          detail: error.response.data.message,
          life: 3000
        });
      }
      setLoading(false);
    })
  }

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {  
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  return (
    <Dialog
      header="Create PTC"
      visible={visible}
      onHide={visibleHandler}
      style={{ width: '25rem' }} 
      breakpoints={{'960px': '75vw', '640px': '95vw'}}
    >
      <Form
        initialValues={initialValues}
        validate={validatePTC}
        onSubmit={createPTC}
        render={({handleSubmit}) => (
          <form onSubmit={handleSubmit} className='flex flex-column gap-3 p-fluid'>
            <Field
              name='jadwal'
              render={({input, meta}) => (
                <div className='flex-auto'>
                  <label htmlFor="jadwal" className='font-bold block mb-2'>Jadwal PTC</label>
                  <Calendar
                    id='jadwal'
                    name='jadwal'
                    {...input}
                    showIcon
                    showTime
                    hourFormat='24'
                    dateFormat='dd MM yy -'
                    className='w-full'
                  />
                  {getFormErrorMessage(meta)}
                </div>
              )}
            />
            <div className='flex gap-2 lg:justify-content-end md:justify-content-end sm:justify-content-end justify-content-center w-full'>
              <Button label="Cancel" icon="pi pi-times" type='button' outlined onClick={() => visibleHandler()} loading={loading} className='w-max'/>
              <Button label="Create" icon="pi pi-check" type='submit' className='w-max' loading={loading}/>
            </div>
          </form>
        )} 
      >
      </Form>
    </Dialog>
  )
}

export default FormCreatePTC
