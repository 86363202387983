import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { postData } from '../../../Hooks/api';
import { Card } from 'primereact/card';
import { Field, Form } from 'react-final-form';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';

const FormLogin = ({toast}) => {
  const navigate = useNavigate();
  const [ loading, setLoading ] = useState(false);
  const initialValues = {
    username: '',
    password: ''
  };

  const validateLogin = (data) => {
    let errors = {};

    if (!data.username) errors.username = "Username is required";
    if (!data.password) errors.password = "Password is required";

    return errors;
  }

  const login = async (data, form) => {
    setLoading(true);

    postData('login', data)
    .then((response) => {
      localStorage.setItem('accessToken', response.data.token);
      toast.current.show({
        severity: 'success',
        summary: 'Login Success!',
        detail: "Please wait a seconds ...",
        life: 3000
      });
      setTimeout(() => {
        setLoading(false);
        navigate('/');
        form.restart();
      }, 2000);
    })
    .catch((error) => {
      if (error.response && error.response.status !== 403 && error.response.data.message !== "Unauthorized: Invalid token") {
        toast.current.show({
          severity: 'error',
          summary: 'Login Failed!',
          detail: error.response.data.message,
          life: 3000
        });
      }
      
      setLoading(false);
    });
  }

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  return (
    <Card  className='p-fluid lg:w-10 md:w-10 sm:w-8 w-12 border-round-xl'>
      <div className='flex align-items-end justify-content-center mb-2'>
        <img src='../icons/edufic-icon.png' alt='Icon Edufic' height={80} className='w-max'/>
        <h2 className='m-0 p-0' style={{fontSize: '3rem'}}>- Login</h2>
      </div>
      <Form
        initialValues={initialValues}
        validate={validateLogin}
        onSubmit={login}
        render={({handleSubmit}) => (
          <form onSubmit={handleSubmit} className='flex flex-column gap-3'>
            <Field
              name='username'
              render={({input, meta}) => (
                <div className="flex-auto">
                  <label htmlFor="username" className="font-bold block mb-1">Username</label>
                  <InputText 
                    id="username" 
                    name='username' 
                    {...input} 
                    invalid = {isFormFieldValid(meta)}
                    disabled={loading}
                  />
                  {getFormErrorMessage(meta)}
                </div>
              )}
            />
            <Field
              name='password'
              render={({input, meta}) => (
                <div className="flex-auto">
                  <label htmlFor="password" className="font-bold block mb-1">Password</label>
                  <Password 
                    id="password" 
                    name='password' 
                    {...input} 
                    invalid = {isFormFieldValid(meta)}
                    disabled={loading}
                    toggleMask
                    feedback={false}
                  />
                  {getFormErrorMessage(meta)}
                </div>
              )}
            />
            <Button label='Login' type='submit' loading={loading} className='w-full'/>
          </form>
        )}
      />
    </Card>
  )
}

export default FormLogin
