import { Tag } from 'primereact/tag'
import React, { useEffect, useState } from 'react'
import { formatDelimeter } from '../../../Hooks/helper'
import { Panel } from 'primereact/panel'
import { Card } from 'primereact/card'

const Overview = ({listStudent}) => {
  const [ overviewStudent, setOverviewStudent ] = useState({
    ACTIVE: 0,
    'WAITING NEW': 0,
    'WAITING CONTINUE': 0,
    'NO RESPONSE': 0,
    STOP: 0,
    FINISH: 0,
    CANCEL: 0,
    total: 0
  });

  useEffect(() => {
    if (listStudent && listStudent.length > 0) modifyStudentData(listStudent);
  }, [listStudent]);

  const modifyStudentData = (data) => {
    let dataCluster = {
        ACTIVE: 0,
        'WAITING NEW': 0,
        'WAITING CONTINUE': 0,
        'NO RESPONSE': 0,
        STOP: 0,
        FINISH: 0,
        CANCEL: 0,
        total: 0
    };
    dataCluster.total = data.length;
    data.forEach((student) => {
        dataCluster[student.status] += 1;
    });

    setOverviewStudent(dataCluster);
  }

  return (
    <>
        <div className='flex justify-content-between mb-3'>
            <h2 className='m-0 p-0'>List Student</h2>
            <Tag value={`${formatDelimeter(overviewStudent.total)} Student`} className='text-lg' severity='danger' />
        </div>
        <div className='lg:flex md:flex flex-wrap hidden gap-2 mb-3'>
            <Card  className='col'>
                <div className='flex flex-column gap-1 w-max'>
                    <h4 className='m-0 p-0 w-max'>ACTIVE</h4>
                    <p className='m-0 p-0 lg:text-lg md:text-lg text-base font-bold'><span className='text-primary'>{ formatDelimeter(overviewStudent['ACTIVE']) }</span> Student</p>
                </div>
            </Card>
            <Card className='col'>
                <div className='flex flex-column gap-1 w-max'>
                    <h4 className='m-0 p-0 w-max'>WAITING CONTINUE</h4>
                    <p className='m-0 p-0 lg:text-lg md:text-lg text-base font-bold'><span className='text-primary'>{ formatDelimeter(overviewStudent['WAITING CONTINUE']) }</span> Student</p>
                </div>
            </Card>
            <Card className='col'>
                <div className='flex flex-column gap-1 w-max'>
                    <h4 className='m-0 p-0 w-max'>WAITING NEW</h4>
                    <p className='m-0 p-0 lg:text-lg md:text-lg text-base font-bold'><span className='text-primary'>{ formatDelimeter(overviewStudent['WAITING NEW']) }</span> Student</p>
                </div>
            </Card>
            <Card className='col'>
                <div className='flex flex-column gap-1 w-max'>
                    <h4 className='m-0 p-0 w-max'>CANCEL</h4>
                    <p className='m-0 p-0 lg:text-lg md:text-lg text-base font-bold'><span className='text-primary'>{ formatDelimeter(overviewStudent['CANCEL']) }</span> Student</p>
                </div>
            </Card>
            <Card className='col'>
                <div className='flex flex-column gap-1 w-max'>
                    <h4 className='m-0 p-0 w-max'>FINISH</h4>
                    <p className='m-0 p-0 lg:text-lg md:text-lg text-base font-bold'><span className='text-primary'>{ formatDelimeter(overviewStudent['FINISH']) }</span> Student</p>
                </div>
            </Card>
            <Card className='col'>
                <div className='flex flex-column gap-1 w-max'>
                    <h4 className='m-0 p-0 w-max'>STOP</h4>
                    <p className='m-0 p-0 lg:text-lg md:text-lg text-base font-bold'><span className='text-primary'>{ formatDelimeter(overviewStudent['STOP']) }</span> Student</p>
                </div>
            </Card>
        </div>

        <Panel header="Student Overview" toggleable collapsed className='lg:hidden md:hidden mb-3'>
            <div className='flex flex-wrap gap-2'>
                <Card  className='col'>
                    <div className='flex flex-column gap-1 w-max'>
                        <h3 className='m-0 p-0 w-max'>ACTIVE</h3>
                        <p className='m-0 p-0 lg:text-xl md:text-lg text-base font-bold'><span className='text-primary'>{ formatDelimeter(overviewStudent['ACTIVE']) }</span> Student</p>
                    </div>
                </Card>
                <Card className='col'>
                    <div className='flex flex-column gap-1 w-max'>
                        <h3 className='m-0 p-0 w-max'>WAITING CONTINUE</h3>
                        <p className='m-0 p-0 lg:text-xl md:text-lg text-base font-bold'><span className='text-primary'>{ formatDelimeter(overviewStudent['WAITING CONTINUE']) }</span> Student</p>
                    </div>
                </Card>
                <Card className='col'>
                    <div className='flex flex-column gap-1 w-max'>
                        <h3 className='m-0 p-0 w-max'>WAITING NEW</h3>
                        <p className='m-0 p-0 lg:text-xl md:text-lg text-base font-bold'><span className='text-primary'>{ formatDelimeter(overviewStudent['WAITING NEW']) }</span> Student</p>
                    </div>
                </Card>
                <Card className='col'>
                    <div className='flex flex-column gap-1 w-max'>
                        <h3 className='m-0 p-0 w-max'>CANCEL</h3>
                        <p className='m-0 p-0 lg:text-xl md:text-lg text-base font-bold'><span className='text-primary'>{ formatDelimeter(overviewStudent['CANCEL']) }</span> Student</p>
                    </div>
                </Card>
                <Card className='col'>
                    <div className='flex flex-column gap-1 w-max'>
                        <h3 className='m-0 p-0 w-max'>FINISH</h3>
                        <p className='m-0 p-0 lg:text-xl md:text-lg text-base font-bold'><span className='text-primary'>{ formatDelimeter(overviewStudent['FINISH']) }</span> Student</p>
                    </div>
                </Card>
                <Card className='col'>
                    <div className='flex flex-column gap-1 w-max'>
                        <h3 className='m-0 p-0 w-max'>STOP</h3>
                        <p className='m-0 p-0 lg:text-xl md:text-lg text-base font-bold'><span className='text-primary'>{ formatDelimeter(overviewStudent['STOP']) }</span> Student</p>
                    </div>
                </Card>
            </div>
        </Panel>
    </>
  )
}

export default Overview
