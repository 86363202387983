import { Card } from 'primereact/card'
import { Tag } from 'primereact/tag'
import React, { useState } from 'react'
import { formattedDateWithOutTime, getSeverity } from '../../../../Hooks/helper'
import { Panel } from 'primereact/panel'
import WALinkTemplate from '../../../../Components/TagTemplate/WALinkTemplate'
import { Button } from 'primereact/button'
import FormAddUpdateStudent from '../FormAddUpdateStudent'

const OverviewDetailStudent = ({detailStudent, setDetailStudent, loading, setLoading, toast}) => {
  const [ studentUpdateDialog, setStudentUpdateDialog ] = useState(false);
  
  const refreshAction = (data) => (setDetailStudent({...detailStudent, ...data}));

  return (
    <>
        <FormAddUpdateStudent
            visibility={studentUpdateDialog}
            visibleHandler={() => setStudentUpdateDialog(false)}
            data={detailStudent}
            titleDialog="Update Student"
            toast={toast}
            loading={loading}
            setLoading={setLoading}
            refreshAction={refreshAction}
        />
        <Card>
            <div className='flex flex-column gap-2'>
            <div className='flex lg:flex-row md:flex-row sm:flex-row flex-column align-items-center justify-content-between gap-2 text-center'>
                <h2 className='m-0 p-0'>{detailStudent.nama}</h2>
                <div className='flex gap-2 align-items-center'>
                <Tag value={detailStudent.status} severity={getSeverity("studentStatus", detailStudent.status)} className='text-lg'/>
                    <Button icon="pi pi-pencil" size='small' outlined severity='info' className='py-2' onClick={() => setStudentUpdateDialog(true)}/>
                </div>
            </div>
            <Panel header='Detail' toggleable>
                <div className='flex flex-wrap justify-content-between gap-2'>
                <div className='flex flex-column gap-2 lg:w-max md:w-max sm:w-max w-full'>
                    <div className='flex align-items-center gap-3'>
                        <i className='pi pi-id-card text-4xl lg:block md:block sm:block hidden'></i>
                        <div>
                        <label className='font-bold block'><i className='pi pi-id-card font-bold lg:hidden md:hidden sm:hidden mr-2'></i>NIE</label>
                        <p className='p-0 m-0'>{detailStudent.nie}</p>
                        </div>
                    </div>
                    <div className='flex align-items-center gap-3'>
                        <i className='pi pi-graduation-cap text-4xl lg:block md:block sm:block hidden'></i>
                        <div>
                            <label className='font-bold block'><i className='pi pi-graduation-cap font-bold lg:hidden md:hidden sm:hidden mr-2'></i>Education</label>
                            <p className='p-0 m-0'>{detailStudent.sekolah}</p>
                        </div>
                    </div>
                    <div className='flex align-items-center gap-3'>
                        <i className='pi pi-calendar text-4xl lg:block md:block sm:block hidden'></i>
                        <div>
                            <label className='font-bold block'><i className='pi pi-calendar font-bold lg:hidden md:hidden sm:hidden mr-2'></i>Date of Birth</label>
                            <p className='p-0 m-0'>{formattedDateWithOutTime(detailStudent.tanggalLahir)} ({detailStudent.usia} Tahun)</p>
                        </div>
                    </div>
                </div>
                <div className='flex flex-column gap-2 lg:w-max md:w-max sm:w-max w-full'>
                    <div className='flex align-items-center gap-3'>
                        <i className='pi pi-user text-4xl lg:block md:block sm:block hidden'></i>
                        <div>
                            <label className='font-bold block'><i className='pi pi-user font-bold lg:hidden md:hidden sm:hidden mr-2'></i>Mother's Name</label>
                            <p className='p-0 m-0'>{detailStudent.ibu}</p>
                        </div>
                    </div>
                    <div className='flex align-items-center gap-3'>
                        <i className='pi pi-briefcase text-4xl lg:block md:block sm:block hidden'></i>
                        <div>
                            <label className='font-bold block'><i className='pi pi-briefcase font-bold lg:hidden md:hidden sm:hidden mr-2'></i>Parents Job</label>
                            <p className='p-0 m-0'>{detailStudent.pekerjaanAyah} & {detailStudent.pekerjaanIbu}</p>
                        </div>
                    </div>
                    <div className='flex align-items-center gap-3'>
                        <i className='pi pi-filter text-4xl lg:block md:block sm:block hidden'></i>
                        <div>
                            <label className='font-bold block'><i className='pi pi-filter font-bold lg:hidden md:hidden sm:hidden mr-2'></i>Funnel</label>
                            <Tag value={detailStudent.funnel} severity='info'/>
                        </div>
                    </div>
                </div>
                <div className='flex flex-column gap-2 lg:w-max md:w-max sm:w-max w-full'>
                    <div className='flex align-items-center gap-3'>
                        <i className='pi pi-map-marker text-4xl lg:block md:block sm:block hidden'></i>
                        <div>
                            <label className='font-bold block'><i className='pi pi-map-marker font-bold lg:hidden md:hidden sm:hidden mr-2'></i>Domicile</label>
                            <p className='p-0 m-0'>{detailStudent.domisili}</p>
                        </div>
                    </div>
                    <div className='flex align-items-center gap-3'>
                        <i className='pi pi-whatsapp text-4xl lg:block md:block sm:block hidden'></i>
                        <div>
                            <label className='font-bold block'><i className='pi pi-whatsapp font-bold lg:hidden md:hidden sm:hidden mr-2'></i>Contact</label>
                            <WALinkTemplate data={detailStudent.nomor}/>
                        </div>
                    </div>
                    <div className='flex align-items-center gap-3'>
                        <i className='pi pi-calendar-clock text-4xl lg:block md:block sm:block hidden'></i>
                        <div>
                            <label className='font-bold block'><i className='pi pi-calendar-clock font-bold lg:hidden md:hidden sm:hidden mr-2'></i>Registered</label>
                            <p className='p-0 m-0'>{formattedDateWithOutTime(detailStudent.waktuMendaftar)}</p>
                        </div>
                    </div>
                </div>
                </div>
            </Panel>  
            </div>
        </Card>
    </>
  )
}

export default OverviewDetailStudent
