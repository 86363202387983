import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React from 'react'
import ClassTypeTemplate from '../../../../Components/TagTemplate/ClassTypeTemplate'
import { formattedDateWithDay } from '../../../../Hooks/helper'
import ClassStatusTemplate from '../../../../Components/TagTemplate/ClassStatusTemplate'
import { Link } from 'react-router-dom'


const TableClass = ({listClass, loading, statusKelas="ACTIVE", headerTableTemplate, filters}) => {
  const actionBodyTemplate = (rowData) => (
    <div className='flex gap-1'>
        <Link to={`/class-management/list/${rowData._id}`}><Button icon='pi pi-eye' size='small' outlined severity='info'/></Link>
    </div>
  );

  return (
    <DataTable
        value={listClass}
        scrollable
        removableSort
        paginator
        rows={10}
        rowsPerPageOptions={[5, 10, 25, 50]}
        filters={filters}
        globalFilterFields={[
        'subject',
        'jenisKelas',
        'statusKelas',
        'level',
        'sesiBerakhir',
        'jadwalText',
        'endSchedule',
        'materiKelas',
        'jumlahSiswa',
        'sesiBulanIni',
        'sesiBulanLalu',
        'statusKelas',
        ]}
        loading={loading}
        className='lg:text-sm md:text-sm sm:text-sm text-xs p-datatable-sm'
        size='small'
        header={headerTableTemplate}
    >
        <Column header="No" body={(data, Options) => Options.rowIndex + 1} />
        <Column 
            field='subject'
            header="Subject"
            sortable
            style={{ minWidth: '8rem' }}
            className='none-dragable'
        />
        <Column 
            field='jenisKelas'
            header="Class Type"
            body={(e) => <ClassTypeTemplate data={e.jenisKelas}/>}
            sortable
            align='center'
            style={{ minWidth: '5rem' }}
            className='none-dragable'
        />
        <Column 
            field='level'
            header="Class Lvl."
            sortable
            align='center'
            style={{ minWidth: '3rem' }}
            className='none-dragable'
        />
        <Column 
            field='materiKelas'
            header="Course Lvl."
            body={(e) => e.materiKelas??e.level}
            sortable
            align='center'
            style={{ minWidth: '3rem' }}
            className='none-dragable'
        />
        <Column 
            field='jadwal'
            header="Schedule"
            sortable
            body={(e) => formattedDateWithDay(e.jadwal)}
            alignHeader='center'
            style={{ minWidth: '10rem' }}
            className='none-dragable'
        />
        <Column 
            field='sesiBerakhir'
            header="End Schedule"
            sortable
            body={(e) => formattedDateWithDay(e.sesiBerakhir)}
            alignHeader='center'
            style={{ minWidth: '10rem' }}
            hidden={(statusKelas !== "FINISH")}
            className='none-dragable'
        />
        <Column 
            field='sesiBulanIni'
            header="Month Session"
            sortable
            align='center'
            style={{ minWidth: '5rem' }}
            hidden={statusKelas === "FINISH"}
            className='none-dragable'
        />
        <Column 
            field='sesiBulanLalu'
            header="Last Month Session"
            sortable
            align='center'
            style={{ minWidth: '5rem' }}
            hidden={statusKelas === "FINISH"}
            className='none-dragable'
        />
        <Column 
            field='statusKelas'
            header="Status"
            body={(e) => <ClassStatusTemplate data={e.statusKelas}/>}
            sortable
            align='center'
            style={{ minWidth: '7rem' }}
            className='none-dragable'
        />
        <Column 
            field='jumlahSiswa'
            header="Students"
            body={(e) => `${e.jumlahSiswa} Orang`}
            sortable
            align='center'
            style={{ minWidth: '5rem' }}
            className='none-dragable'
        />
        <Column body={actionBodyTemplate}/>
    </DataTable>
  )
}

export default TableClass
