import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { putData } from '../../../../../Hooks/api';

const GenerateRoom = ({
  visible,
  visibleHandler,
  loading,
  setLoading,
  detailClass,
  refreshAction,
  setDetailClassRoom,
  toast
}) => {
  const [ initialValues, setInitialValues ] = useState({
    durasi:  1.5,
    mulaiKelas: null,
    idKelas: ""
  });

  useEffect(() => {
    if (detailClass._id) setInitialValues({
      durasi:  1.5,
      mulaiKelas: new Date(),
      idKelas: detailClass._id
    })
  }, [detailClass._id])

  const validateRequestRoom = (data) => {
    let errors = {};

    if (!data.durasi) errors.durasi = "Room duration is required";
    return errors;
  }

  const requestRoom = async (data, form) => {
    setLoading(true);

    putData(`booking-room`, data)
    .then((response) => {
      const updateRoom = response.data.newUpdateRoom;

      setTimeout(() => {
        toast.current.show({
          severity: 'success',
          summary: 'Generate Success',
          detail: "Generate room successfully, keep your smile & spirits up.",
          life: 3000
        });
        
        if (!loading) { 
          visibleHandler();
          form.restart();
        }

        setDetailClassRoom({
          namaRoom: updateRoom.namaRoom,
          linkRoom: updateRoom.linkRoom,
          passCode: updateRoom.passCode,
          meetingID: updateRoom.meetingID,
          hostKey: updateRoom.hostKey,
          mulaiKelas: updateRoom.mulaiKelas,
          berakhirKelas: updateRoom.berakhirKelas
        });

        setLoading(false);

        // refreshAction();
      }, 1000);
    })
    .catch((error) => {
      if (error.response && error.response.status !== 403 && error.response.data.message !== "Unauthorized: Invalid token") {
        toast.current.show({
          severity: 'error',
          summary: 'Update Failed!',
          detail: error.response.data.message,
          life: 3000
        });
      }
      setLoading(false);
    });
  }

  return (
    <Dialog
      header="Generate Room Class"
      visible={visible}
      onHide={visibleHandler}
      style={{ width: '32rem' }} breakpoints={{ '960px': '75vm', '641px': '90vw' }}
    >
      <Form
        initialValues={initialValues}
        validate={validateRequestRoom}
        onSubmit={requestRoom}
        render={({handleSubmit}) => (
          <form onSubmit={handleSubmit} className='flex flex-column gap-3 p-fluid'>
            <Field
              name='durasi'
              render={({input, meta}) => (
                <div className='flex-auto'>
                  <label className='font-bold block'>Durasi</label>
                  <div className='p-inputgroup flex-1'>
                    <InputText 
                      id='durasi'
                      name='durasi'
                      {...input}
                      keyfilter="num"
                    />
                    <span className='p-inputgroup-addon'>
                      Jam
                    </span>
                  </div>
                </div>
              )}
            />
            <Calendar
              id='mulaiKelas'
              name='mulaiKelas'
              value={initialValues.mulaiKelas}
              showIcon
              showTime
              hourFormat='24'
              dateFormat='dd MM yy -'
              disabled
            />
            <div className='flex lg:justify-content-end md:justify-content-end sm:justify-content-end justify-content-center gap-2'>
              <Button type='button' label='Cancel' severity='danger' className='w-max' outlined onClick={() => visibleHandler()} loading={loading}></Button>
              <Button type='submit' icon="pi pi-cog" label='Generate' severity='primary' className='w-max' loading={loading} autoFocus></Button>
            </div>  
          </form>
        )}
      >
      </Form>
    </Dialog>
  )
}

export default GenerateRoom
