import { Sidebar } from 'primereact/sidebar'
import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom';

const NavbarAuth = () => {
  const location = useLocation();
  const [ sidebarVisibility, setSidebarVisibility ] = useState(false);
  const currentLocation = location.pathname.split('/')[2];

  const headerTemplate = () => (
    <h2 className='m-0 p-0 text-primary'>EAP</h2>
  );

  return (
    <div className='lg:px-5 md:px-5 fixed w-full bg-white'>
      <div className='flex justify-content-between align-items-center lg:mx-5 md:mx-5 mx-2 my-3'>
            <img src='../img/edufic-logo.png' alt='Logo Edufic' height={50} />
            <ul className='lg:flex md:flex hidden align-items-center lg:gap-3 md:gap-5 gap-1 list-none p-0 m-0'>
                <li>
                    <Link to='/auth/login'>
                        <div className={`${currentLocation === "login" ? "bg-orange-500 text-white border-round py-2 px-3" : "py-2 px-3 text-color-secondary"}`}>
                            <div className='flex align-items-center cursor-pointer gap-1'>
                                <i className='pi pi-lock'></i>
                                <span className='font-medium'>Login</span>
                            </div>
                        </div>
                    </Link>
                </li>
                <li>
                    <Link to="/auth/help">
                        <div className={`${currentLocation === "help" ? "bg-orange-500 text-white border-round py-2 px-3" : "py-2 px-3 text-color-secondary"}`}>
                            <span className='font-medium'>Help?</span>
                        </div>
                    </Link>
                </li>
            </ul>
            <i className='pi pi-bars text-2xl mr-2 cursor-poointer lg:hidden md:hidden' onClick={() => setSidebarVisibility(true)}></i>
            <Sidebar 
                visible={sidebarVisibility} 
                position='right' 
                className='w-8'
                header={headerTemplate}
                onHide={(() => setSidebarVisibility(false))}
            >
                <ul className='align-items-center lg:gap-5 md:gap-5 gap-1 list-none p-0 m-0'>
                    <li>
                        <Link to='/auth/login'>
                            <div className={`${currentLocation === "login" ? "bg-orange-500 text-white border-round py-2 px-3" : "py-2 px-3 text-color-secondary"}`}>
                                <div className='flex align-items-center cursor-pointer gap-1'>
                                    <i className='pi pi-lock'></i>
                                    <span className='font-medium'>Login</span>
                                </div>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="/auth/help">
                        <div className={`${currentLocation === "help" ? "bg-orange-500 text-white border-round py-2 px-3" : "py-2 px-3 text-color-secondary"}`}>
                                <span className='font-medium'>Help</span>
                            </div>
                        </Link>
                    </li>
                </ul>
            </Sidebar>
      </div>
    </div>
  )
}

export default NavbarAuth
