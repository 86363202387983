import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getData, postData } from '../../../../Hooks/api';
import { getAgeByDate } from '../../../../Hooks/helper';
import TableStudentClass from './TableStudentClass';
import OverviewDetailStudent from './OverviewDetailStudent';
import { Toast } from 'primereact/toast';

const DetailStudent = () => {
  const { id } = useParams();
  const toast = useRef(null);
  const [ loading, setLoading ] = useState(false);
  
  // Data Handler
  const [ detailStudent, setDetailStudent ] = useState({
    nie: '',
    nama: '',
    nomor: '',
    tanggalLahir: '',
    usia: '',
    domisili: '',
    sekolah: '',
    ibu: '',
    pekerjaanIbu: '',
    pekerjaanAyah: '',
    funnel: '',
    status: '',
    waktuMendaftar: '',
    classQty:  '',
  });
  const [ listStudentClass, setListStudentClass ] = useState([]);

  const getDetailStudent = async () => {
    setLoading(true);

    getData(`student/${id}`)
    .then((response) => {
      setDetailStudent({
        _id:  response.data._id,
        nie: response.data.nie,
        nama: response.data.nama,
        nomor: response.data.nomor,
        tanggalLahir: new Date(response.data.tanggalLahir),
        usia: getAgeByDate(response.data.tanggalLahir),
        domisili: response.data.domisili,
        sekolah: response.data.sekolah,
        ibu: response.data.ibu,
        pekerjaanIbu: response.data.pekerjaanIbu,
        pekerjaanAyah: response.data.pekerjaanAyah,
        funnel: response.data.funnel,
        status: response.data.status,
        waktuMendaftar: new Date(response.data.waktuMendaftar),
        classQty: response.data.kelas.length
      });
      // setListStudentClass(response.data.kelas);
      getListClass(response.data.kelas);
    })
    .catch((error) => {
      toast.current.show({
        severity: 'error',
        summary: 'Load Failed',
        detail: error.response.data.message,
        life: 3000
      });
      setLoading(false);
    });
  }

  const getListClass = async (studentClassData) => {
    setLoading(true);
    postData('classes', {idKelas: studentClassData})
    .then((response) => {
      if (response.data.length > 0) {
        let dataClass = response.data;
        dataClass.forEach((kelas) => {
          kelas.subject = `${kelas.kodeBatch}.${kelas.kodeKelas} ${kelas.namaKelas}`;
        });
        setListStudentClass(dataClass);
      }
      setLoading(false);
    })
    .catch((error) => {
      toast.current.show({
        severity: 'error',
        summary: 'Load Failed',
        detail: error.response.data.message,
        life: 3000
      });
      setLoading(false);
    })
  }

  useEffect(() => {
    getDetailStudent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  

  return (
    <>
      <Toast ref={toast}/>
      <div className='flex flex-column gap-3'>
        <OverviewDetailStudent detailStudent={detailStudent} setDetailStudent={setDetailStudent} loading={loading} setLoading={setLoading} toast={toast}/>
        <TableStudentClass loading={loading} listStudentClass={listStudentClass}/>
      </div>
    </>
  )
}

export default DetailStudent
