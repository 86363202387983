import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown';
import React from 'react'
import { Field, Form } from 'react-final-form';
import { classType, getFormErrorMessage, listDay, listTime, subjectClass } from '../../../Hooks/helper';
import { InputText } from 'primereact/inputtext';
import { postData } from '../../../Hooks/api';
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';

const FormCreatePlot = ({
  visible,
  visibleHandler,
  listTeacher,
  loading,
  setLoading,
  toast,
  refreshAction
}) => {
  const initialValues = {
    studentRegist: [],
    teacher: null,
    subject: null,
    day: null,
    time: null,
    courseLevel: null,
    classType: null,
    classLevel: null,
    status: null
  };

  const validatePlotClass = (data) => {
    let errors = {};

    if (!data.subject) errors.subject = "Subject is required";
    if (!data.day) errors.day = "Day is required";
    if (!data.time) errors.time = "Time is required";
    if (!data.classLevel) errors.classLevel = "Required";
    if (!data.courseLevel) errors.courseLevel = "Required";
    if (!data.classType) errors.classType = "Class type is required";

    return errors;
  }

  const createPlotClass = (data, form) => {
    setLoading(true);

    postData('plotclasses/create', data)
    .then((response) => {
        if (data.teacher) data.teacher = listTeacher.find(teacher => teacher._id === data.teacher);
        
        setTimeout(() => {
            refreshAction("CREATE", response.data.data._id, {...response.data.data, ...data});
            visibleHandler();
            toast.current.show({
                severity: 'success',
                summary: 'Create Success',
                detail: "Plot class successfully created, nice job!",
                life: 3000
            });
            
            form.restart();
            setLoading(false);
        }, 800);
    })
    .catch((error) => {
        toast.current.show({
            severity: 'error',
            summary: 'Create Data Failed!',
            detail: error.response.data.message,
            life: 3000
        });
        setLoading(false);
    })
  }

  return (
    <Dialog
        visible={visible}
        onHide={visibleHandler}
        header="Create Plot Class"
        style={{ width: '32rem' }} 
        breakpoints={{ '960px': '75vm', '641px': '90vw' }}
    >
        <Form
            initialValues={initialValues}
            onSubmit={createPlotClass}
            validate={validatePlotClass}
            render={({handleSubmit}) => (
                <form onSubmit={handleSubmit} className='flex flex-column gap-3 p-fluid'>
                    <Field
                        name='subject'
                        render={({input, meta}) => (
                            <div className='flex-auto'>
                                <label className='font-bold block mb-2'>Subject</label>
                                <Dropdown 
                                    id='subject'
                                    name='subject'
                                    {...input}
                                    options={subjectClass}
                                    disabled={loading}
                                    placeholder='Choose subject'
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />
                    <Field
                        name='classType'
                        render={({input, meta}) => (
                            <div className='flex-auto'>
                                <label className='font-bold block mb-2'>Type Class</label>
                                <Dropdown 
                                    id='classType'
                                    name='classType'
                                    {...input}
                                    options={classType}
                                    disabled={loading}
                                    placeholder='Choose class type'
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />
                    <div className='flex-auto'>
                        <label className='font-bold block mb-2'>Preference</label>
                        <div className='flex gap-2'>
                            <Field
                                name='day'
                                render={({input, meta}) => (
                                    <div  className='w-full'>
                                        <Dropdown 
                                            id='day'
                                            name='day'
                                            {...input}
                                            options={listDay}
                                            disabled={loading}
                                            placeholder='day'
                                        />
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )}
                            />
                            <Field
                                name='time'
                                render={({input, meta}) => (
                                    <div className='w-full'>
                                        <Dropdown 
                                            id='time'
                                            name='time'
                                            {...input}
                                            options={listTime}
                                            disabled={loading}
                                            placeholder='time'
                                        />
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                    <div className='flex gap-2'>
                        <Field
                            name='classLevel'
                            render={({input, meta}) => (
                                <div className='flex-auto'>
                                    <label className='font-bold block mb-2'>Class Level</label>
                                    <InputText 
                                        id='classLevel'
                                        name='classLevel'
                                        {...input}
                                        keyfilter='num'
                                        disabled={loading}
                                    />
                                    {getFormErrorMessage(meta)}
                                </div>
                            )}
                        />
                        <Field
                            name='courseLevel'
                            render={({input, meta}) => (
                                <div className='flex-auto'>
                                    <label className='font-bold block mb-2'>Course Level</label>
                                    <InputText 
                                        id='courseLevel'
                                        name='courseLevel'
                                        {...input}
                                        keyfilter='num'
                                        disabled={loading}
                                    />
                                    {getFormErrorMessage(meta)}
                                </div>
                            )}
                        />
                    </div>
                    <Divider className='font-bold'>Optional Atributte</Divider>
                    <Field
                        name='teacher'
                        render={({input, meta}) => (
                            <div className='flex-auto'>
                                <label className='font-bold block mb-2'>Teacher</label>
                                <Dropdown 
                                    id='teacher'
                                    name='teacher'
                                    {...input}
                                    options={listTeacher}
                                    optionLabel='nama'
                                    optionValue='_id'
                                    placeholder='Choose teacher'
                                    filter
                                    disabled={loading}
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />
                    <div className='flex lg:justify-content-end md:justify-content-end sm:justify-content-end justify-content-center gap-2 w-full'>
                        <Button type='button' label='Cancel' severity='danger' className='w-max' outlined onClick={visibleHandler} loading={loading}/>
                        <Button type='submit' label='Create' severity='primary' className='w-max' loading={loading} />
                    </div>
                </form>
            )}
        />
    </Dialog>
  )
}

export default FormCreatePlot
