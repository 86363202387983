import { Card } from 'primereact/card'
import React, { useEffect, useState } from 'react'
import { getData } from '../../../Hooks/api';
import { classRegistrationStatus, formatDelimeter, formatIDRCurrency, getSeverity, paymentStatus } from '../../../Hooks/helper';
import { Panel } from 'primereact/panel';
import months from '../../../Constant/month';
import { Tag } from 'primereact/tag';

const OverviewCR = ({toast, loading, setLoading}) => {
  const [ overviewCR, setOverviewCR ] = useState({
    paymentInfo: {
        PAID: 0,
        UNPAID: 0,
        INSTALLMENT: 0,
        REFUND: 0
    },
    classAssignmentInfo: {
        ACTIVE: 0,
        CANCEL: 0,
        WAITING: 0,
        MOVECLASS: 0
    },
    assetsInfo: {
        totalAssets: 0,
        totalRevenue: 0,
        retainedAsset: 0,
        revCurrentMonth: 0
    },
    register: 0
  });

  const getOverviewClassRegistration = async () => {
    setLoading(true);

    getData('class-registration/overview-class-registration')
    .then((response) => {
        setOverviewCR(response.data);
        setLoading(false);
    })
    .catch((error) => {
        toast.current.show({
            severity: 'error',
            summary: "Load Failed",
            detail: error.response.data.message,
            life: 3000   
        });
        setLoading(false);
    })
  }

  useEffect(() => {
    getOverviewClassRegistration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Panel header='Assets Overview' toggleable collapsed>
        <div className='flex flex-wrap gap-2'>
          <Card className='col'>
            <div className='flex justify-content-between align-items-center gap-2'>
                <i className='pi pi-building text-5xl text-blue-500'></i>
                <div className='flex flex-column align-items-end'>
                  <h3 className='m-0 p-0 lg:text-lg md:text-lg sm:text-base text-sm'>Assets Total</h3>
                  <p className='m-0 p-0 font-bold text-primary lg:text-lg md:text-lg sm:text-base text-sm'>{formatIDRCurrency(overviewCR.assetsInfo.totalAssets)}</p>
                </div>
            </div>
          </Card>
          <Card className='col'>
            <div className='flex justify-content-between align-items-center gap-2'>
                <i className='pi pi-dollar text-5xl text-green-500'></i>
                <div className='flex flex-column align-items-end'>
                  <h3 className='m-0 p-0 lg:text-lg md:text-lg sm:text-base text-sm'>Revenue Total</h3>
                  <p className='m-0 p-0 font-bold text-primary lg:text-lg md:text-lg sm:text-base text-sm'>{formatIDRCurrency(overviewCR.assetsInfo.totalRevenue)}</p>
                </div>
            </div>
          </Card>
          <Card className='col'>
            <div className='flex justify-content-between align-items-center gap-2'>
                <i className='pi pi-exclamation-triangle text-5xl text-red-500'></i>
                <div className='flex flex-column align-items-end'>
                  <h3 className='m-0 p-0 lg:text-lg md:text-lg sm:text-base text-sm'>Retained Assets</h3>
                  <p className='m-0 p-0 font-bold text-primary lg:text-lg md:text-lg sm:text-base text-sm'>{formatIDRCurrency(overviewCR.assetsInfo.retainedAsset)}</p>
                </div>
            </div>
          </Card>
          <Card className='col'>
            <div className='flex justify-content-between align-items-center gap-2'>
                <i className='pi pi-dollar text-5xl text-green-500'></i>
                <div className='flex flex-column align-items-end'>
                  <h3 className='m-0 p-0 lg:text-lg md:text-lg sm:text-base text-sm'>Cash: {months[new Date().getMonth()].nameMonth}</h3>
                  <p className='m-0 p-0 font-bold text-primary lg:text-lg md:text-lg sm:text-base text-sm'>{formatIDRCurrency(overviewCR.assetsInfo.revCurrentMonth)}</p>
                </div>
            </div>
          </Card>
        </div>
      </Panel>
      <Panel header='Registrant & Payment' toggleable collapsed>
        <div className='flex flex-wrap gap-2'>
          <Card className='col'>
            <div className='flex flex-column align-items-center'>
              <h3 className='m-0 p-0 lg:text-lg md:text-lg sm:text-base text-sm font-bold align-self-center'>Class Assignment</h3>
              <div className='flex lg:flex-nowrap md:flex-nowrap sm:flex-nowrap flex-wrap gap-1 align-items-center'>
                {
                  classRegistrationStatus.map((status, index) => (
                    <Tag severity={getSeverity("classRegistration", status)} key={index}>
                      <div className='flex align-items-center gap-2'>
                        <p className='m-0 p-0'>{status}</p>
                        <p className='m-0 p-0'>{overviewCR.classAssignmentInfo[status]}</p>
                      </div>
                    </Tag>
                  ))
                }
              </div>
            </div>
          </Card>
          <Card className='col'>
            <div className='flex flex-column align-items-center'>
              <h3 className='m-0 p-0 lg:text-lg md:text-lg sm:text-base text-sm font-bold align-self-center'>Payment Info</h3>
              <div className='flex lg:flex-nowrap md:flex-nowrap sm:flex-nowrap flex-wrap gap-1 align-items-center'>
                {
                  paymentStatus.map((status, index) => (
                    <Tag severity={getSeverity("paymentStatus", status)} key={index}>
                      <div className='flex align-items-center gap-2'>
                        <p className='m-0 p-0'>{status}</p>
                        <p className='m-0 p-0'>{overviewCR.paymentInfo[status]}</p>
                      </div>
                    </Tag>
                  ))
                }
              </div>
            </div>
          </Card>
          <Card className='col'>
            <div className='flex justify-content-between align-items-center gap-2'>
                <i className='pi pi-users text-5xl text-blue-500'></i>
                <div className='flex flex-column align-items-end'>
                  <h3 className='m-0 p-0 lg:text-lg md:text-lg sm:text-base text-sm'>Total Registrant</h3>
                  <p className='m-0 p-0 font-bold text-primary lg:text-lg md:text-lg sm:text-base text-sm'>{formatDelimeter(overviewCR.register)} Student</p>
                </div>
            </div>
          </Card>
        </div>
      </Panel>
    </>
  )
}

export default OverviewCR
