export const listComedyText = [
    {
        text: "Penyesalan itu datangnya di akhir,",
        nextText: "karena jika datang diawal maka itu namanya pendaftaran."
    },
    {
        text: "Aku rela menjadi lilin dalam hati kamu,",
        nextText: "asal kamu yang jagain lilinnya, ya!. Ya kali udah jadi lilin ga dapet apa-apa, rugi dong."
    }, 
    {
        text: "Selama janur kuning belum melengkung, aku akan tetap mengejar cintamu.",
        nextText: "Tapi kalau udah melengkung, bakal aku setrika lagi biar lurus. #PantangMenyerah"
    },
    {
        text: "Nggak masalah kalau kamu nggak suka sama aku.",
        nextText: "Karena nggak semua orang punya selera yang bagus :)",
    },
    {
        text: "Sepandai-pandainnya tupai melompat, akhirnya akan jatuh juga.",
        nextText: "Sejomblo-jomblonya kamu sekarang, insyaallah akan nikah juga. AAMIINN sing paling banter.",
    },
    {
        text: "Tahukah kamu, terlalu sering memegang pensil alis dapat membuat mata menjadi buta,",
        nextText: "jika dicolok-colokkan ke mata.",
    },
    {
        text: "Jika kamu tak mampu meyakinkan dan memukau orang dengan kepintaranmu",
        nextText: "Tenang, masih ada opsi 'bingungkan dia dengan kebodohanmu'",
    },
    {
        text: "Selelah-lelahnya bekerja, lebih lelah lagi kalau nganggur :)"
    },
    {
        text: "Yang bilang di-PHP-in itu pahit, pasti dia belum pernah kegigit biji duku."
    },
    {
        text: "Awali pagi dengan sarapan,",
        nextText: "karena kemarin saya coba dengan senyuman, jam 10 udah laper."
    },
    {
        text: "Cobalah melihat atau menilai pada sisi yang tak terlihat.",
        nextText: "Jika tetap tak terlihat, cobalah nyalakan lampunya dulu kali aja gelap."
    },
    {
        text:  "Uang bukanlah segalanya,",
        nextText: "tapi lebih baik menangis di dalam mobil Mercedes daripada di atas sepeda."
    },
    {
        text: "Uang bukanlah segalanya,",
        nextText: "kalau kamu hidup dijaman Megalodon, karena yang penting bisa hidup & makan :)"
    },
    {
        text: "Selalu ikuti kata hatimu.",
        nextText: "Masalahnya hati ga punya mulut untuk bicara, gimana dong."
    },
    {
        text: "Saat orang lain menutup pintu hatinya untukmu, jangan sedih ya.",
        nextText: "Tinggal buka lagi saja. Itu hanya pintu, itulah cara kerjanya."
    },
    {
        text: "Kemarin ngeluh gara-gara banyak kerjaan numpuk,",
        nextText: "repot amat tinggal dijejer jangan ditumpuk"
    },
    {
        text: "Beli bakso harganya Rp 10 ribu, aku bayar Rp 8 ribu, yang jual marah-marah. Dari sini aku belajar bahwa tidak ada yang menerima kekuranganku.",
    },
    {
        text: "Kan ku biarkan semua ini terjadi, kan ku biarkan semua ini berlalu, tapi aku nggak akan pernah ngelupain kamu, karena utangmu belum lunas sampai sekarang."
    },
    {
        text: "Jika seseorang melemparmu dengan batu, jangan balas lempar dengan batu.",
        nextText: "Cukup balaslah mereka dengan bunga, Kalau bisa dengan potnya sekalian. Lah kocak dilempar batu ngasih bunga doang."
    },
    {
        text: "Aku janji nggak bakalan nakal lagi.. Kalau aku nakal lagi, ya aku janji lagi."
    },
    {
        text: "Berharap hari ini ada yang mengucapkan selamat pagi?",
        nextText: "Nggak usah galau, pergi saja ke minimarket atau minta Chat GPT."
    },
    {
        text: "Banyak masalah menjadikan kita dewasa. Maka, jadilah orang yang bermasalah :)."
    },
    {
        text: "Jangan ragu dalam memecahkan masalah!",
        nextText: "Kecuali kalau ada tulisannya 'memecahkan berarti membeli'."
    },
    {
        text: "Aku di matamu kayak butiran detergen anti noda, sekali kucek langsung hilang."
    },
    {
        text: "Cara termudah untuk membuat orang mengingat kamu adalah 'pinjamlah uang mereka'."
    },
    {
        text: "Rumah tangga itu hal yang kompleks dan terkadang rumit.",
        nextText: "Karena kalau sederhana itu namanya rumah makan."
    },
    {
        text: "Jangan pernah takut atau ragu untuk melangkah,",
        nextText: "kecuali ada kendaraan lewat di depanmu."
    },
    {
        text: "Kamu tahu darah tinggi?",
        nextText: "Nah sebelum jadi tinggi, itu aku yang nemenin dari bawah."
    },
    {
        text: "Harimu berat?",
        nextText: "Lah aku juga sama, beratnya berapa kilo nih? yok kita adu"
    },
]