import React from 'react'
import NavbarAuth from '../Components/NavbarAuth'
import { Outlet } from 'react-router-dom'

const AuthLayout = () => {
  return (
    <>
      <NavbarAuth />
      <div className='lg:mx-5 md:mx-5 mx-2 lg:px-5 md:px-5 px-2'>
        <Outlet />
      </div>
    </>
  )
}

export default AuthLayout
