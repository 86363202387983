import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import ClassTypeTemplate from '../../../../Components/TagTemplate/ClassTypeTemplate';
import ClassStatusTemplate from '../../../../Components/TagTemplate/ClassStatusTemplate';
import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { formattedDateWithDay } from '../../../../Hooks/helper';

const TableStudentClass = ({listStudentClass, loading}) => {
  // Filter Handler
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [ filters, setFilters ] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const onGlobalFilterChange = (e) => {
    const value = e.target.value
    let _filters = {...filters}

    _filters['global'].value = value
    setFilters(_filters)
    setGlobalFilterValue(value)
  };

  const headerTableTemplate = () => (
    <div className='flex flex-column gap-2'>
        <div className='flex lg:flex-row md:flex-row sm:flex-row flex-column justify-content-between align-items-center gap-2'>
            <h2 className='m-0 p-0 lg:text-left md:text-left sm:text-left text-center'>Student Class</h2>
            <div className='flex lg:flex-row md:flex-row flex-column  gap-2'>
            <IconField iconPosition="left" className='lg:w-max md:w-max sm:w-max w-full'>
                <InputIcon className="pi pi-search"> </InputIcon>
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" className='p-inputtext-sm w-full'/>
            </IconField>
            </div> 
        </div>
    </div>
  );

  const actionBodyTemplate = (rowData) => (
    <div className='flex gap-1'>
        <Link to={`/class-management/list/${rowData._id}`}><Button icon='pi pi-eye' size='small' outlined severity='info'/></Link>
    </div>
  );

  return (
    <Card>
        <DataTable
            value={listStudentClass}
            scrollable
            removableSort
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            loading={loading}
            filters={filters}
            globalFilterFields={[
                'subject', 
                'jenisKelas', 
                'level', 
                'materiKelas', 
                'jadwal', 
                'statusKelas', 
                'jumlahSiswa', 'guru.nama'
            ]}
            className='lg:text-sm md:text-sm sm:text-sm text-xs p-datatable-sm'
            size='small'
            header={headerTableTemplate}
        >
        <Column header="No" body={(data, Options) => Options.rowIndex + 1} />
        <Column 
            field='subject'
            header="Subject"
            sortable
            style={{ minWidth: '8rem' }}
            className='none-dragable'
        />
        <Column 
            field='guru.nama'
            header="Teacher Name"
            sortable
            style={{ minWidth: '11rem' }}
            className='none-dragable'
        />
        <Column 
            field='jenisKelas'
            header="Class Type"
            body={(e) => <ClassTypeTemplate data={e.jenisKelas}/>}
            sortable
            align='center'
            style={{ minWidth: '5rem' }}
            className='none-dragable'
        />
        <Column 
            field='level'
            header="Class Lvl."
            sortable
            align='center'
            style={{ minWidth: '3rem' }}
            className='none-dragable'
        />
        <Column 
            field='materiKelas'
            header="Course Lvl."
            body={(e) => e.materiKelas??e.level}
            sortable
            align='center'
            style={{ minWidth: '3rem' }}
            className='none-dragable'
        />
        <Column 
            field='jadwal'
            header="Schedule"
            sortable
            body={(e) => formattedDateWithDay(e.jadwal)}
            alignHeader='center'
            style={{ minWidth: '10rem' }}
            className='none-dragable'
        />
        <Column 
            field='statusKelas'
            header="Status"
            body={(e) => <ClassStatusTemplate data={e.statusKelas}/>}
            sortable
            align='center'
            style={{ minWidth: '7rem' }}
            className='none-dragable'
        />
        <Column 
            field='jumlahSiswa'
            header="Students"
            body={(e) => `${e.jumlahSiswa} Orang`}
            sortable
            align='center'
            style={{ minWidth: '5rem' }}
            className='none-dragable'
        />
        <Column body={actionBodyTemplate}/>
        </DataTable>
    </Card>
  )
}

export default TableStudentClass
