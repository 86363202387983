import { Tag } from 'primereact/tag'
import React from 'react'
import { getSeverity } from '../../Hooks/helper'

const ClassStatusRegistrationTemplate = ({data}) => {
  return (
    <Tag value={data} severity={getSeverity("classRegistration", data)}></Tag>
  )
}

export default ClassStatusRegistrationTemplate
