import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form';
import { postData, putData } from '../../../../../Hooks/api';
import { Dialog } from 'primereact/dialog';

const FormAddStudent = ({
  visible, 
  visibleHandler, 
  refreshAction, 
  loading, 
  setLoading, 
  toast, 
  dataClass
}) => {
  const [ listWaitingStudent, setListWaitingStudent ] = useState([]);
  const accessToken = localStorage.getItem('accessToken');
  const initialValues = {
    idCR: null
  };
  
  const getDataStudents = async () => {
    const dataBody = {
      subject: dataClass.namaKelas,
      level: dataClass.level,
      classType: dataClass.jenisKelas
    };
  
    postData('class-registration/students-waiting', dataBody, accessToken)
    .then((response) => {
        let listWaiting = response.data;
        listWaiting.forEach((student) => {
          student.identity = `${student.student.nie} - ${student.student.nama}` 
        });
          
        setListWaitingStudent(listWaiting);
    })
    .catch((error) => {
      if (error.response && error.response.status !== 403 && error.response.data.message !== "Unauthorized: Invalid token") {
        toast.current.show({
          severity: 'error',
          summary: 'Adding Failed!',
          detail: error.response.data.message,
          life: 3000
        });
      }
    })
  }
  
  useEffect(() => {
    if (dataClass) getDataStudents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataClass])
  
  const addStudent = async (data, form) => {
    setLoading(true);
  
    putData(`class-registration/${data.idCR}/add-class`, {classId: dataClass._id}, accessToken)
    .then(() => {
      setTimeout(() => {
        refreshAction();
        toast.current.show({
          severity: 'success',
          summary: 'Adding Success',
          detail: "Student successfully added",
          life: 3000
        });
        form.restart();
              
        if (!loading) visibleHandler();
      }, 1000);
    })
    .catch((error) => {
      if (error.response && error.response.status !== 403 && error.response.data.message !== "Unauthorized: Invalid token") {
        toast.current.show({
          severity: 'error',
          summary: 'Adding Failed!',
          detail: error.response.data.message,
          life: 3000
        });
      }
      setLoading(false);
    });
  }
  
  const addStudentValidate = (data) => {
    let errors = {};
      
    if (!data.idCR) errors.idCR = "Student is required";
  
    return errors;
  }
  
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {  
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };
    
  return (
    <Dialog
        header="Tambah Siswa"
        visible={visible}
        onHide={() => visibleHandler()}
        style={{ width: '40rem' }} breakpoints={{ '960px': '75vm', '641px': '90vw' }}
        className='p-fluid'
    >
        <Form 
            initialValues={initialValues}
            onSubmit={addStudent}
            validate={addStudentValidate}
            render={({handleSubmit}) => (
                <form onSubmit={handleSubmit} className='flex flex-column gap-3 p-fluid'>
                    <Field 
                        name='idCR'
                        render={({input, meta}) => (
                            <div className='flex-auto'>
                                <label className='block font-bold mb-2'>Waiting Student</label>
                                <Dropdown
                                    id='idCR'
                                    name='idCR'
                                    {...input}
                                    options={listWaitingStudent}
                                    optionLabel='identity'
                                    optionValue='_id'
                                    filter
                                    width="w-full"
                                    placeholder='Choose valid student'
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />
                    <div className='flex  lg:justify-content-end md:justify-content-end sm:justify-content-end justify-content-center gap-2'>
                        <Button type='button' label='Batal' severity='danger' className='w-max' outlined onClick={() => visibleHandler()} loading={loading}></Button>
                        <Button type='submit' label='Tambah' severity='success' className='w-max' loading={loading} autoFocus></Button>
                    </div>      
                </form>
            )}
        />
    </Dialog>
  )
}

export default FormAddStudent
