import { FilterMatchMode } from 'primereact/api';
import React, { useEffect, useRef, useState } from 'react'
import { formatIDRCurrency, formattedDateWithOutTime, getAgeByDate, getDiffDate } from '../../../Hooks/helper';
import { getData, putData } from '../../../Hooks/api';
import { Button } from 'primereact/button';
import PlotClassCarousel from './PlotClassCarousel';
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import PaymentStatusTemplate from '../../../Components/TagTemplate/PaymentStatusTemplate';
import ClassStatusRegistrationTemplate from '../../../Components/TagTemplate/ClassStatusRegistrationTemplate';
import WALinkTemplate from '../../../Components/TagTemplate/WALinkTemplate';
import ClassSubjectTemplate from '../../../Components/TagTemplate/ClassSubjectTemplate';
import ClassTypeTemplate from '../../../Components/TagTemplate/ClassTypeTemplate';
import { Toast } from 'primereact/toast';

const ClassPlotting = () => {
  const toast = useRef(null);
  
  // Data Handler
  const [ listCRWaiting, setListCRWaiting ] = useState([]);
  const [ listPlotClass, setListPlotClass ] = useState([]);
  const [ listPlotClassFilter, setListPlotClassFilter ] = useState([]); 
  const [ selectAble, setSelectAble ] = useState({});
  const [ idPlotClass, setIdPlotClass ] = useState(null); 

  // Dialog Handler
  const [ loading, setLoading ] = useState(false);

  // Filter Handler
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [ subjectFilter, setSubjectFilter ] = useState(null);
  const [ filters, setFilters ] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    subject: { value: null, matchMode: FilterMatchMode.EQUALS }
  });

  const onGlobalFilterChange = (e) => {
    const value = e.target.value
    let _filters = {...filters}

    _filters['global'].value = value
    setFilters(_filters)
    setGlobalFilterValue(value)
  };

  const onSubjectFilterValue = (e) => {
    const value = e.target.value;
    let _filters = {...filters};

    _filters['subject'].value = value;
    setFilters(_filters);
    setSubjectFilter(value);
    
    if (value) { 
      if (e.plotId) setListPlotClassFilter(listPlotClass.filter((plot) => plot._id === e.plotId));
      else setListPlotClassFilter(listPlotClass.filter((plot) => plot.subject === value));
    }
    else { 
      setListPlotClassFilter(listPlotClass);
      setSelectAble({});
    }
  }

  const getlistCRWaiting = async () => {
    setLoading(true);

    getData('class-registration/waiting-class')
    .then((response) => {
      let today = new Date();
      
      response.data.forEach((register) => {
        register.student.usia = getAgeByDate(register.student.tanggalLahir);
        register.agingDayWaiting = getDiffDate(register.registrationDate, today);
        register.schedule = register.timePref.map((pref) => `${pref.day} - ${pref.time}`).join(", ");
      });

      setListCRWaiting(response.data);
      setLoading(false);
    })
    .catch((error) => {
      toast.current.show({
        severity:'error', 
        summary: "Load Failed", 
        detail: error.response.data.message, 
        life: 3000,
      });
      setLoading(false);
    });
  }

  const getListPlot = async () => {
    getData('plotclasses')
    .then((response) => {
      setListPlotClass(response.data.data);
      setListPlotClassFilter(
        (subjectFilter === null || !subjectFilter) ? 
        response.data.data : 
        ( idPlotClass && idPlotClass !== null ? 
          response.data.data.filter((plot) => plot._id === idPlotClass) : 
          response.data.data.filter((plot) => plot.subject === subjectFilter)
        )
      );
      setLoading(false);
    })
    .catch((error) => {
      toast.current.show({
        severity:'error', 
        summary: "Load Failed", 
        detail: error.response.data.message, 
        life: 3000,
      });
      setLoading(false);
    })
  }

  const addStudentRegist = async (id, data) => {
    setLoading(true);

    putData(`plotclasses/${id}/add-studentregist`, {studentRegist:  [data._id]})
    .then(() => {
      refreshAction("ADD_STUDENT", data, id);
      setSelectAble({});
      setLoading(false);
    })
    .catch((error) => {
      toast.current.show({
        severity: 'error',
        summary: 'Add Student Failed!',
        detail: error.response.data.message,
        life: 3000
      });
      setLoading(false);
    });
  }

  useEffect(() => {
    getlistCRWaiting();
    getListPlot();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const plotHandlerRelease = () => {
    setSelectAble({});
    const e = {target: {value: undefined}};
    
    onSubjectFilterValue(e)
  }

  const headerTableTemplate = () => (
    <div className='flex flex-column gap-2'>
        <div className='flex lg:flex-row md:flex-row sm:flex-row flex-column justify-content-between align-items-center gap-2'>
          <h2 className='m-0 p-0 lg:text-left md:text-left sm:text-left text-center'>List Waiting Student</h2>
          <IconField iconPosition="left" className='lg:w-max md:w-max sm:w-max w-full'>
            <InputIcon className="pi pi-search"> </InputIcon>
            <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" className='p-inputtext-sm w-full'/>
          </IconField>
        </div>
    </div>
  );

  const actionTemplate = (data) => (
    <div className='flex justify-content-center gap-1'>
      {selectAble._id !== data._id ? 
        <Button 
          label='Plot' 
          severity='help' 
          size='small' 
          className='border-round-lg' 
          onClick={() => setPlotHandler(data)} 
          disabled={selectAble._id}
        /> : <Button
          label='Cancel'
          severity='danger'
          size='small'
          className='border-round-lg'
          outlined
          onClick={() => plotHandlerRelease()} 
        />
      }
    </div>
  );

  const choosePlotClass = (data, filter) => {
    let e;

    if (filter) { 
      e = {plotId: data._id,  target: {value: data.subject}}
      setIdPlotClass(data._id);
    } else {
      e = {target: {value: undefined}}
      setIdPlotClass(null);
    }
    
    onSubjectFilterValue(e);
  };

  const refreshAction = (type, data, idPlot = null) => {
    if (type === "REMOVE_STUDENT") {
      listCRWaiting.push(data);
      setListCRWaiting(listCRWaiting);

      let indexPlot = listPlotClassFilter.findIndex(plot => plot._id === idPlot);
      let indexStudent = listPlotClassFilter[indexPlot].studentRegist.findIndex(student => student._id === data._id);
      listPlotClassFilter[indexPlot].studentRegist.splice(indexStudent, 1);
      setListPlotClassFilter(listPlotClassFilter);
    }

    if (type === "ADD_STUDENT") {
      let indexCR = listCRWaiting.findIndex(CR => CR._id === data._id);
      listCRWaiting.splice(indexCR, 1);
      setListCRWaiting(listCRWaiting);
      
      let indexPlot = listPlotClass.findIndex(plot => plot._id === idPlot);
      listPlotClass[indexPlot].studentRegist.push(data);
      setListPlotClass(listPlotClass);
    }
  }

  const setPlotHandler = (data) => {
    if (idPlotClass !== null) {
      addStudentRegist(idPlotClass, data);
    } else {
      setSelectAble(data);
      const e = {...data, target: {value: data.subject} };      
      onSubjectFilterValue(e)
    }
  };

  const paginatorLeft = (<Button text icon="pi pi-refresh" size='small' onClick={getlistCRWaiting} />);

  return (
    <>
      <Toast ref={toast} />
      <div className='flex flex-column gap-2'>
        <PlotClassCarousel
          selectAble={selectAble}
          setSelectAble={setSelectAble}
          subjectFilter={subjectFilter}
          onSubjectFilterValue={onSubjectFilterValue}
          listPlotClassFilter={listPlotClassFilter}
          setListPlotClassFilter={setListPlotClassFilter}
          listPlotClass={listPlotClass}
          setListPlotClass={setListPlotClass}
          idPlotClass={idPlotClass}
          setIdPlotClass={setIdPlotClass}
          choosePlotClassHandler={choosePlotClass}
          addStudentRegist={addStudentRegist}
          toast={toast}
          refreshTableWaiting={getlistCRWaiting}
          refreshListPlot={getListPlot}
          generalRefresh={refreshAction}
        />

        <Card>
          <DataTable
            value={listCRWaiting}
            scrollable
            loading={loading}
            paginator
            rows={5}
            rowsPerPageOptions={[5, 10, 25, 50]}
            removableSort
            filters={filters}
            globalFilterFields={[
              'student.nama', 
              'student.nomor', 
              'student.usia',
              'subject', 
              'classLevel', 
              'courseLevel', 
              'classStatus', 
              'paymentStatus', 
              'nominal', 
              'classType',
              'note',
              'schedule'
            ]}
            size='small'
            className='lg:text-sm md:text-sm sm:text-sm text-xs p-datatable-sm'
            header={headerTableTemplate}
            paginatorLeft={paginatorLeft}
          >
            <Column 
                frozen 
                header="No" 
                body={(data, Options) => Options.rowIndex + 1}
              />
              <Column 
                header="Student" 
                field='student.nama' 
                sortable
                frozen 
                showFilterMenu={false}
                className='none-dragable'
                style={{ minWidth: '12rem' }}
              />
              <Column 
                header="Age"
                field='student.usia'
                sortable
                body={(data) => (`${data.student.usia} Tahun`)} 
                align='center'
                className='none-dragable'
                style={{ minWidth: '10rem' }}
              />
              <Column 
                header="Contact" 
                field='student.nomor'
                sortable
                body={(e) => <WALinkTemplate data={e.student.nomor}/>}
                style={{ minWidth: '10rem' }}
              />
              <Column 
                header="Subject" 
                field='subject'
                sortable
                align='center'
                className='none-dragable'
                style={{ minWidth: '5rem' }}
                body={(e) => <ClassSubjectTemplate data={e.subject}/>}
              />
              <Column 
                header="Class Type" 
                field='classType'
                sortable
                align='center'
                style={{ minWidth: '5rem' }}
                className='none-dragable'
                body={(e) => <ClassTypeTemplate data={e.classType}/>}
              />
              <Column 
                header="Class Level" 
                field='classLevel'
                sortable
                align='center'
                className='none-dragable'
                style={{ minWidth: '5rem' }}
              />
              <Column 
                header="Course Level" 
                field='courseLevel'
                sortable
                align='center'
                className='none-dragable'
                style={{ minWidth: '5rem' }}
              />
              <Column 
                header="Registration Status" 
                field='classStatus'
                sortable
                filter
                showFilterMenu={false}
                align='center'
                className='none-dragable'
                style={{ minWidth: '8rem' }}
                body={(e) => <ClassStatusRegistrationTemplate data={e.classStatus}/>}
              />
              <Column 
                header="Payment Status" 
                field='paymentStatus'
                sortable
                align='center'
                className='none-dragable'
                style={{ minWidth: '7rem' }}
                body={(e) => <PaymentStatusTemplate data={e.paymentStatus} />}
              />
              <Column 
                header="Invoice" 
                field='invoice'
                sortable
                body={(e) => formatIDRCurrency(e.invoice)}
                align='right'
                className='none-dragable'
                style={{ minWidth: '8rem' }}
              />
              <Column 
                header="Paid" 
                field='payment'
                sortable
                body={(data) => formatIDRCurrency(data.payment)}
                align='right'
                className='none-dragable'
                style={{ minWidth: '8rem' }}
              />
              <Column 
                header="Aging Day" 
                field='agingDayWaiting'
                body={(e) => `${e.agingDayWaiting} Hari`}
                sortable
                align='center'
                className='none-dragable'
                style={{ minWidth: '5rem' }}
              />
              <Column 
                header="Register Date" 
                field='registrationDate'
                body={(e) => formattedDateWithOutTime(e.registrationDate)}
                sortable
                align='center'
                className='none-dragable'
                style={{ minWidth: '10rem' }}
              />
              <Column body={actionTemplate}/>
          </DataTable>
        </Card>
      </div>
    </>
  )
}

export default ClassPlotting
