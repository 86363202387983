import { Calendar } from 'primereact/calendar'
import { Dialog } from 'primereact/dialog'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { getFormErrorMessage } from '../../../Hooks/helper'
import { Divider } from 'primereact/divider'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { postData, putData } from '../../../Hooks/api'

const FormCreateUpdateConversion = ({
  visible,
  visibleHandler,
  data,
  loading,
  setLoading,
  toast,
  refreshAction,
}) => {
  const [ initialValues, setInitialValues ] = useState({
    _id: null,
    waktu: new Date(),
    chatOrganikIG: 0,
    daftarOrganikIG: 0,
    chatOrganikFB: 0,
    daftarOrganikFB: 0,
    chatOrganikWA: 0,
    daftarOrganikWA: 0,
    chatAdsIG: 0,
    daftarAdsIG: 0,
    chatAdsFB: 0,
    daftarAdsFB: 0,
  });

  useEffect(() => {
    if (data._id) setInitialValues({
        _id: data._id,
        waktu: new Date(data.waktu),
        chatOrganikIG: data.chatOrganikIG,
        daftarOrganikIG: data.daftarOrganikIG,
        chatOrganikFB: data.chatOrganikFB,
        daftarOrganikFB: data.daftarOrganikFB,
        chatOrganikWA: data.chatOrganikWA,
        daftarOrganikWA: data.daftarOrganikWA,
        chatAdsIG: data.chatAdsIG,
        daftarAdsIG: data.daftarAdsIG,
        chatAdsFB: data.chatAdsFB,
        daftarAdsFB: data.daftarAdsFB,
    }); 
    else setInitialValues({
        _id: null,
        waktu: new Date(),
        chatOrganikIG: 0,
        daftarOrganikIG: 0,
        chatOrganikFB: 0,
        daftarOrganikFB: 0,
        chatOrganikWA: 0,
        daftarOrganikWA: 0,
        chatAdsIG: 0,
        daftarAdsIG: 0,
        chatAdsFB: 0,
        daftarAdsFB: 0
    });
  }, [data]);

  const createUpdateForm = async (data, form) => {
    setLoading(true);
    
    if (data._id === null) {
        postData('konversi-monitor', data)
        .then((response) => {
            refreshAction("CREATE", response.data.data);
            toast.current.show({
                severity: 'success',
                summary: 'Create Data Success',
                detail: "Student convertion successfully created, good job for today!",
                life: 3000
            });
            form.restart();
            setLoading(false);
            visibleHandler();
        })
        .catch((error) => {
            toast.current.show({
                severity: 'error',
                summary: 'Create Data Failed!',
                detail: error.response.data.message,
                life: 3000
            });
            setLoading(false);
        });
    } else {
        putData(`konversi-monitor/${data._id}`, data, localStorage.getItem('accessToken'))
        .then(() => {
            refreshAction("EDIT", data);
            toast.current.show({
                severity: 'success',
                summary: 'Update Data Success',
                detail: "Student convertion successfully updated, good job for today!",
                life: 3000
            });
            form.restart();
            setLoading(false);
            visibleHandler();
        })
        .catch((error) => {
            toast.current.show({
                severity: 'error',
                summary: 'Create Data Failed!',
                detail: error.response.data.message,
                life: 3000
            });
            setLoading(false);
        });
    }
  }

  const validateForm = (data) => {
    let errors = {};

    if (!data.waktu) errors.waktu = "Date Convertion is required";
    if (!data.chatOrganikIG) errors.chatOrganikIG = "Required";
    if (!data.daftarOrganikIG) errors.daftarOrganikIG = "Required";
    if (!data.chatOrganikFB) errors.chatOrganikFB = "Required";
    if (!data.daftarOrganikFB) errors.daftarOrganikFB = "Required";
    if (!data.chatOrganikWA) errors.chatOrganikWA = "Required";
    if (!data.daftarOrganikWA) errors.daftarOrganikWA = "Required";
    if (!data.chatAdsFB) errors.chatAdsFB = "Required";
    if (!data.daftarAdsFB) errors.daftarAdsFB = "Required";
    if (!data.chatAdsIG) errors.chatAdsIG = "Required";
    if (!data.daftarAdsIG) errors.daftarAdsIG = "Required";

    return errors;
  }

  return (
    <Dialog
        header="Konversi Harian"
        visible={visible}
        onHide={visibleHandler}
        style={{ width: '25rem' }} breakpoints={{ '960px': '75vm', '641px': '90vw' }}
    >
        <Form
            initialValues={initialValues}
            onSubmit={createUpdateForm}
            validate={validateForm}
            render={({handleSubmit}) => (
                <form onSubmit={handleSubmit} className='flex flex-column gap-3'>
                    <Field
                        name='waktu'
                        render={({input, meta}) => (
                            <div className='flex-auto'>
                                <label className='block font-bold mb-2'>Tanggal Konversi</label>
                                <Calendar 
                                    id='waktu'
                                    name='waktu'
                                    {...input}
                                    showIcon
                                    showTime
                                    hourFormat='24'
                                    dateFormat='dd/mm/yy -'
                                    className='w-full'
                                    disabled={loading}
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />

                    <Divider className='font-bold m-0 p-0'>Organik</Divider>
                    
                    <div className="flex-auto">
                        <label className='block font-bold'>Instagram</label>
                        <div className='flex gap-2'>
                            <Field
                                name='chatOrganikIG'
                                render={({input, meta}) => (
                                    <div>
                                        <div className="p-inputgroup flex-1">
                                            <InputText 
                                                id='chatOrganikIG'
                                                name='chatOrganikIG'
                                                {...input}
                                                keyfilter="int"
                                                disabled={loading}
                                            />
                                            <span className="p-inputgroup-addon">
                                                Chat
                                            </span>
                                        </div>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )}
                            />
                            <Field
                                name='daftarOrganikIG'
                                render={({input, meta}) => (
                                    <div>
                                        <div className="p-inputgroup flex-1">
                                            <InputText 
                                                id='daftarOrganikIG'
                                                name='daftarOrganikIG'
                                                {...input}
                                                keyfilter="int"
                                                disabled={loading}
                                            />
                                            <span className="p-inputgroup-addon">
                                                Register
                                            </span>
                                        </div>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                    <div className="flex-auto">
                        <label className='block font-bold'>Facebook</label>
                        <div className='flex gap-2'>
                            <Field
                                name='chatOrganikFB'
                                render={({input, meta}) => (
                                    <div>
                                        <div className="p-inputgroup flex-1">
                                            <InputText 
                                                id='chatOrganikFB'
                                                name='chatOrganikFB'
                                                {...input}
                                                keyfilter="int"
                                            />
                                            <span className="p-inputgroup-addon">
                                                Chat
                                            </span>
                                        </div>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )}
                            />
                            <Field
                                name='daftarOrganikFB'
                                render={({input, meta}) => (
                                    <div>
                                        <div className="p-inputgroup flex-1">
                                            <InputText 
                                                id='daftarOrganikFB'
                                                name='daftarOrganikFB'
                                                {...input}
                                                keyfilter="int"
                                                disabled={loading}
                                            />
                                            <span className="p-inputgroup-addon">
                                                Register
                                            </span>
                                        </div>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                    <div className="flex-auto">
                        <label className='block font-bold'>Whatsapp</label>
                        <div className='flex gap-2'>
                            <Field
                                name='chatOrganikWA'
                                render={({input, meta}) => (
                                    <div>
                                        <div className="p-inputgroup flex-1">
                                            <InputText 
                                                id='chatOrganikWA'
                                                name='chatOrganikWA'
                                                {...input}
                                                keyfilter="int"
                                                disabled={loading}
                                            />
                                            <span className="p-inputgroup-addon">
                                                Chat
                                            </span>
                                        </div>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )}
                            />
                            <Field
                                name='daftarOrganikWA'
                                render={({input, meta}) => (
                                    <div>
                                        <div className="p-inputgroup flex-1">
                                            <InputText 
                                                id='daftarOrganikWA'
                                                name='daftarOrganikWA'
                                                {...input}
                                                keyfilter="int"
                                                disabled={loading}
                                            />
                                            <span className="p-inputgroup-addon">
                                                Register
                                            </span>
                                        </div>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )}
                            />
                        </div>
                    </div>

                    <Divider className='font-bold m-0 p-0'>Ads</Divider>

                    <div className="flex-auto">
                        <label className='block font-bold'>Instagram</label>
                        <div className='flex gap-2'>
                            <Field
                                name='chatAdsIG'
                                render={({input, meta}) => (
                                    <div>
                                        <div className="p-inputgroup flex-1">
                                            <InputText 
                                                id='chatAdsIG'
                                                name='chatAdsIG'
                                                {...input}
                                                keyfilter="int"
                                                disabled={loading}
                                            />
                                            <span className="p-inputgroup-addon">
                                                Chat
                                            </span>
                                        </div>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )}
                            />
                            <Field
                                name='daftarAdsIG'
                                render={({input, meta}) => (
                                    <div>
                                        <div className="p-inputgroup flex-1">
                                            <InputText 
                                                id='daftarAdsIG'
                                                name='daftarAdsIG'
                                                {...input}
                                                keyfilter="int"
                                                disabled={loading}
                                            />
                                            <span className="p-inputgroup-addon">
                                                Register
                                            </span>
                                        </div>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                    <div className="flex-auto">
                        <label className='block font-bold'>Facebook</label>
                        <div className='flex gap-2'>
                            <Field
                                name='chatAdsFB'
                                render={({input, meta}) => (
                                    <div>
                                        <div className="p-inputgroup flex-1">
                                            <InputText 
                                                id='chatAdsFB'
                                                name='chatAdsFB'
                                                {...input}
                                                keyfilter="int"
                                                disabled={loading}
                                            />
                                            <span className="p-inputgroup-addon">
                                                Chat
                                            </span>
                                        </div>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )}
                            />
                            <Field
                                name='daftarAdsFB'
                                render={({input, meta}) => (
                                    <div>
                                        <div className="p-inputgroup flex-1">
                                            <InputText 
                                                id='daftarAdsFB'
                                                name='daftarAdsFB'
                                                {...input}
                                                keyfilter="int"
                                                disabled={loading}
                                            />
                                            <span className="p-inputgroup-addon">
                                                Register
                                            </span>
                                        </div>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                    <div className='flex gap-2 lg:justify-content-end md:justify-content-end sm:justify-content-end justify-content-center w-full'>
                        <Button type='button' label="Cancel" size='small' severity='danger' outlined onClick={visibleHandler} loading={loading} />
                        <Button type='submit' label={data._id !== null ? "Update" : "Create"} size='small' autoFocus loading={loading} />
                    </div>
                </form>
            )}
        />
    </Dialog>
  )
}

export default FormCreateUpdateConversion
