import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import React, { useEffect, useState } from 'react'
import { getData } from '../../Hooks/api'
import { Calendar } from 'primereact/calendar'

const DetailClassUser = ({
  visibility,
  visibleHandler,
  idClass,
  startSession,
  endSession,
  toast
}) => {
  const [detailClassUser, setDetailClassUser ] = useState({
    className: "",
    classLevel: "",
    courseLevel: "",
    classType: "",
    teacher: "",
    startSession: "",
    endSession: "",
  }); 

  const getDetailClassUser = async () => {
    getData(`class/${idClass}`)
    .then((response) => {
        let detailClass = response.data;
        setDetailClassUser({
            className: `${detailClass.kodeBatch}.${detailClass.kodeKelas} ${detailClass.namaKelas}`,
            classLevel: detailClass.level,
            courseLevel: detailClass.materiKelas,
            classType: detailClass.jenisKelas,
            teacher: detailClass.guru.nama,
            startSession: new Date(startSession),
            endSession: new Date(endSession)
        });
    })
    .catch((error) => {
        toast.current.show({
            severity: 'error',
            summary: 'Load Failed',
            detail: error.response.data.message,
            life: 3000
        });
    })
  }

  useEffect(() => {
    if(idClass) getDetailClassUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idClass])

  return (
    <Dialog
      visible={visibility}
      onHide={visibleHandler}
      header="User Class Detail"
      style={{width: '32rem'}}
      breakpoints={{ '960px': '75vm', '641px': '90vw' }}
    >
        <div className='flex flex-column gap-3 p-fluid'>
            <div className='flex-auto'>
                <label className='block font-bold mb-2'>Class Name</label>
                <InputText value={detailClassUser.className} />
            </div>
            <div className='flex lg:flex-row md:flex-row sm:flex-row flex-column flex-auto gap-2'>
                <div className='w-full'>
                    <label className='block font-bold mb-2'>Class Level</label>
                    <InputText value={detailClassUser.classLevel} />
                </div>
                <div className='w-full'>
                    <label className='block font-bold mb-2'>Course Level</label>
                    <InputText value={detailClassUser.courseLevel} />
                </div>
            </div>
            <div className='flex-auto'>
                <label className='block font-bold mb-2'>Class Type</label>
                <InputText value={detailClassUser.classType} />
            </div>
            <div className='flex-auto'>
                <label className='block font-bold mb-2'>Teacher Name</label>
                <InputText value={detailClassUser.teacher} />
            </div>
            <div className='flex-auto'>
                <label className='block font-bold mb-2'>Class Agenda</label>
                <div className='flex lg:flex-row md:flex-row sm:flex-row flex-column align-items-center gap-2'>
                    <Calendar value={detailClassUser.startSession} showTime dateFormat='dd/mm/yy -' disabled />
                    <span className='block text-2xl'>-</span>
                    <Calendar value={detailClassUser.endSession} showTime dateFormat='dd/mm/yy -' disabled />
                </div>
            </div>
            <div className='flex justify-content-center'>
                <Button label='Close Info' severity='primary' size='small' className='w-max' onClick={() => visibleHandler()}/>
            </div>
        </div>
    </Dialog>
  )
}

export default DetailClassUser
