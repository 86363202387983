import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form';
import { getFormErrorMessage, roomStatus } from '../../Hooks/helper';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { postData, putData } from '../../Hooks/api';

const AddUpdateForm = ({
  visible,
  visibleHandler,
  detailRoom,
  toast,
  loading,
  setLoading,
  refreshAction
}) => {
  const [ initialValues, setInitialValues ] = useState({
    _id: null,
    namaRoom: '',
    linkRoom: '',
    meetingID: '',
    passCode: '',
    hostKey: '',
    status: ''
  }); 

  useEffect(() => {
    if (detailRoom._id) setInitialValues(detailRoom)
    else setInitialValues({
      _id: null,
      namaRoom: '',
      linkRoom: '',
      meetingID: '',
      passCode: '',
      hostKey: '',
      status: ''
    }); 
  }, [detailRoom]);

  const createRoom = async (data, form) => {
    setLoading(true);

    postData(`rooms`, data)
    .then(() => {
        setTimeout(() => {
            refreshAction();
            form.restart();
            toast.current.show({
                severity: 'success',
                summary: 'Create Success',
                detail: 'Room successfully created, Ganbatte!',
                life: 3000
            });
            if (!loading) visibleHandler();
        }, 1000);
    })
    .catch((error) => {
        setLoading(false);
        toast.current.show({
            severity: 'error',
            summary: 'Create Failed',
            detail: error.response.data.message,
            life: 3000
        });
    })
  }

  const updateRoom = async (data, form) => {
    setLoading(true);

    putData(`rooms/${data._id}`, data)
    .then(() => {
        setTimeout(() => {
            refreshAction();
            form.restart();
            toast.current.show({
                severity: 'success',
                summary: 'Update Success',
                detail: 'Room successfully updated, enjoy your time.',
                life: 3000
            });

            if (!loading) visibleHandler();
        }, 1000);
    })
    .catch((error) => {
        setLoading(false);
        toast.current.show({
            severity: 'error',
            summary: 'Update Failed',
            detail: error.response.data.message,
            life: 3000
        });
    });
  }

  const validateRoom = (data) => {
    let errors = {};

    if (!data.namaRoom) errors.namaRoom = "Nama room is required";
    if (!data.linkRoom) errors.linkRoom = "Link room is required";
    if (!data.meetingID) errors.meetingID = "Meeting ID is required";
    if (!data.passCode) errors.passCode = "Passcode is required";
    if (!data.hostKey) errors.hostKey = "Hostkey is required";
    if (!data.status) errors.status = "Status is required";
    return errors;
  }
  
  return (
    <Dialog
      visible={visible}
      onHide={visibleHandler}
      header={initialValues._id !== null ? "Update Room" : "Create Room"}
      style={{ width: '32rem' }} 
      breakpoints={{ '960px': '75vm', '641px': '90vw' }}
    >
        <Form
            initialValues={initialValues}
            validate={validateRoom}
            onSubmit={initialValues._id === null ? createRoom : updateRoom}
            render={({handleSubmit}) => (
                <form onSubmit={handleSubmit} className='flex flex-column gap-3 p-fluid'>
                    <Field
                        name='namaRoom'
                        render={({input, meta}) => (
                            <div className='flex-auto'>
                                <label htmlFor="namaRoom" className='font-bold block mb-2'>Room Name</label>
                                <InputText 
                                  id='namaRoom' 
                                  name='namaRoom' 
                                  {...input}
                                  placeholder='input room name'
                                  disabled={loading}
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />
                    <Field
                        name='linkRoom'
                        render={({input, meta}) => (
                            <div className='flex-auto'>
                                <label htmlFor="linkRoom" className='font-bold block mb-2'>Link Room</label>
                                <InputText 
                                  id='linkRoom' 
                                  name='linkRoom' 
                                  {...input}
                                  placeholder='input link room'
                                  disabled={loading}
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />
                    <Field
                        name='meetingID'
                        render={({input, meta}) => (
                            <div className='flex-auto'>
                                <label htmlFor="meetingID" className='font-bold block mb-2'>Meeting ID</label>
                                <InputText 
                                  id='meetingID' 
                                  name='meetingID' 
                                  {...input}
                                  placeholder='input meeting id'
                                  disabled={loading}
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />
                    <Field
                        name='passCode'
                        render={({input, meta}) => (
                            <div className='flex-auto'>
                                <label htmlFor="passCode" className='font-bold block mb-2'>Passcode</label>
                                <InputText 
                                  id='passCode' 
                                  name='passCode' 
                                  {...input}
                                  placeholder='input passcode'
                                  disabled={loading}
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />
                    <Field
                        name='hostKey'
                        render={({input, meta}) => (
                            <div className='flex-auto'>
                                <label htmlFor="hostKey" className='font-bold block mb-2'>Host Key</label>
                                <InputText 
                                  id='hostKey' 
                                  name='hostKey' 
                                  {...input}
                                  placeholder='input host key'
                                  disabled={loading}
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />
                    <Field
                        name='status'
                        render={({input, meta}) => (
                            <div className='flex-auto'>
                                <label htmlFor="status" className='font-bold block mb-2'>Status</label>
                                <Dropdown 
                                  id='status' 
                                  name='status' 
                                  options={roomStatus}
                                  {...input}
                                  placeholder='choose status'
                                  disabled={loading}
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />
                    <div className='flex gap-2 lg:justify-content-end md:justify-content-end sm:justify-content-end justify-content-center w-full'>
                        <Button label="Cancel" type='button' severity='danger' outlined onClick={visibleHandler} loading={loading} className='w-max'/>
                        <Button label={initialValues._id === null ? "Create" : "Update" } type='submit' severity='primary' className='w-max' loading={loading}/>
                    </div>
                </form>
            )}
        />
    </Dialog>
  )
}

export default AddUpdateForm
