import months from "../Constant/month";
import { listComedyText } from "../Constant/moodBooster";

export const BASE_URL = "https://eduficportal.com";
//const BASE_API_URL = "http://localhost:5003"

export const teacherType = ['Premium Teacher', 'Super Teacher', 'Volunteer Teacher'];
export const teacherStatus = ['Aktif', 'Pasif', 'Berhenti'];
export const roomStatus = ["Idle", "Maintenance", "Used"];
export const listDay =  ["SENIN", "SELASA", "RABU", "KAMIS", "JUMAT", "SABTU", "MINGGU"];
export const listTime = ["PAGI", "SIANG", "SORE", "MALAM"];
export const classRegistrationStatus = ['ACTIVE', 'CANCEL', 'WAITING', 'MOVECLASS'];
export const studentStatus = ['ACTIVE', 'STOP', 'FINISH', 'WAITING CONTINUE', 'CANCEL', 'WAITING NEW', 'NO RESPONSE'];
export const paymentStatus = ['PAID', 'UNPAID', 'INSTALLMENT', 'REFUND'];
export const subjectClass = ["Adab", "AFQ", "IC Atas", "IC Bawah", "ICWS - ST", "ICWS - W", "IE", "IL & SoIP", "NI", "QT", "ICS", "IEL", "ISM"];
export const classType = ["Small", "Private", "Edu Ramadhan", "Edu Holidays"];
export const classStatus = ['Aktif', 'Belum PTC', 'Belum RR', 'Belum Diselesaikan', 'Selesai'];
export const rangeAge = ["5 - 7", "8 - 10", "11 - 13", "Berumur"];
export const salaryStatus = ['Terbayar', 'Belum dibayar', 'Diangsur' ];
export const SessionAttendance = ['Izin', 'Sakit', 'Hadir', 'Belum diabsen', 'Tidak Hadir'];
export const PTCAttendance = ['Hadir', 'Tidak Hadir', 'Belum PTC'];
export const StopReason = ["FINANCIAL", "REGULAR CLASS", "OCCUPIED SCHEDULE", "NO RESPONSE"];
export const List_CHART_COLOR = [
    '--green-400',
    '--blue-400',
    '--red-400',
    '--yellow-400',
    '--surface-500',
    '--primary-400',
    '--cyan-400',
    '--pink-400',
    '--indigo-400',
    '--teal-400',
    '--orange-400',
    '--bluegray-400',
    '--purple-600',
    '--surface-700',
    '--primary-700',
    '--blue-700',
    '--green-500',
    '--yellow-500',
    '--cyan-600',
    '--pink-600',
    '--indigo-700',
    '--teal-700',
    '--orange-700',
    '--bluegray-700',
    '--purple-900',
    '--red-800'
];
export const FUNNEL_OPTIONS = [
    "Organik", "DM IG", "Iklan IG", "Iklan FB"
];

export const getListYear = (currentYear) => {
    let year;
    const optionsYear = [];

    for (year = 2023; year <= currentYear; year++) {
        optionsYear.push(year);
    }

    return optionsYear;
}

export const getAgeRangeString = (data) => {
    if (data <= 7) return "5 - 7";
    if (data >= 8 && data <= 10) return "8 - 10";
    if (data >= 11 && data <= 13) return "11 - 13";
    if (data > 13) return "Berumur";
}

export const getTimeRangeString = (data) => {
    let hours = new Date(data).getHours();
    
    if (hours >= 4 && hours < 11) return "Pagi";
    if (hours >= 11 && hours < 15) return "Siang";
    if (hours >= 15 && hours < 18) return "Sore";
    if (hours >= 18 || hours < 4) return "Malam";
}

export const formattedDate = (date) => { 
    const newDate = new Date(date);
    
    const formattedDay = newDate.toLocaleString("id-ID", {
        weekday: "long"
    });

    const formattedDate = newDate.toLocaleString("id-ID", {
        day: "numeric",
        month: "long",
        year: "numeric",
    })

    const formattedTime = newDate.toLocaleTimeString('it-IT', { hour: "2-digit", minute: "2-digit" })

    const fullDate = formattedDay + ', ' + formattedDate + " - " + formattedTime;
    
    return fullDate
}

export const longStringToDate = (stringDate) => {
    const dateProcessed = stringDate.split(', ')[1];

    const time = `${dateProcessed.split(" - ")[1]}:00.045+07:00`;
    const rawdate = dateProcessed.split(" - ")[0];
    const date = rawdate.split(" ")[0];
    const year = rawdate.split(" ")[2];
    let month = months.findIndex((detailmonth) => detailmonth === rawdate.split(" ")[1]) + 1;
    
    if (month.toString().length === 1) month = `0${month}`;
    
    const dateResult = new Date(`${year}-${month}-${date}T${time}`)
    return dateResult;
}

export const formattedDate2 = (date) => { 
    const newDate = new Date(date)
    const formattedDate = newDate.toLocaleString("id-ID", {
        day: "numeric",
        month: "long",
        year: "numeric",
    })

    const formattedTime = newDate.toLocaleTimeString('it-IT', { hour: "2-digit", minute: "2-digit" })

    const fullDate = `${formattedDate} (${formattedTime})`

    return fullDate
}

export const formattedDateWithDay = (date) => {
    const newDate = new Date(date)
    const formattedDate = newDate.toLocaleString("id-ID", {
        weekday: "long",
        day: "numeric",
        month: "long",
        year: "numeric",
    })

    const formattedTime = newDate.toLocaleTimeString('it-IT', { hour: "2-digit", minute: "2-digit" })

    const fullDate = `${formattedDate} - ${formattedTime}`

    return fullDate
}

export const formattedDateWithTime = (date) => { 
    const newDate = new Date(date)
    const formattedDate = newDate.toLocaleString("id-ID", {
        day: "numeric",
        month: "long",
        year: "numeric",
    })

    const formattedTime = newDate.toLocaleTimeString('it-IT', { hour: "2-digit", minute: "2-digit" })

    const fullDate = `${formattedDate} (${formattedTime})`

    return fullDate
}

export const formattedDateWithTimeShort = (date) => { 
    const newDate = new Date(date)
    const formattedDate = newDate.toLocaleString("id-ID", {
        day: "numeric",
        month: "short",
        year: "numeric",
    })

    const formattedTime = newDate.toLocaleTimeString('it-IT', { hour: "2-digit", minute: "2-digit" })

    const fullDate = `${formattedDate} (${formattedTime})`

    return fullDate
}

export const formattedDateWithOutTime = (date) => { 
    const newDate = new Date(date)
    const formattedDate = newDate.toLocaleString("id-ID", {
        day: "numeric",
        month: "long",
        year: "numeric",
    })

    const fullDate = formattedDate
    return fullDate
}

export const stringToDate = (stringDate) => {
    const newDate = new Date(stringDate)
    return newDate
}

export const newFormatDate = (currentDate) => {
    const newDate = new Date(currentDate)
    return newDate;
}

export const formatMonth = (stringDate) => {
    const newDate = new Date(stringDate)
    const formattedDate = newDate.toLocaleString("id-ID", {
        month: "long",
        year: "numeric",
    })

    const fullDate = formattedDate
    return fullDate
}

export const getAgeByDate = (theDate) => {
    const currentDate = new Date()
    const bornDate = new Date(theDate)
    let getAge = currentDate.getUTCFullYear() - bornDate.getUTCFullYear()
    let getMonth = currentDate.getUTCMonth() - bornDate.getUTCMonth()
    let getDate = currentDate.getUTCDate() - bornDate.getUTCDate()

    if (getMonth <= 0 && getDate <= 0) getAge--
    return getAge
}

export const getDiffDate = (date1, date2) => {
    const dateStart = new Date(date1);
    const dateFinish = new Date(date2);

    const diffInMS = dateFinish - dateStart;
    const diffInDays = diffInMS / (1000 * 60 * 60 * 24);
    return parseInt(diffInDays);
}

export const formatTTL = (bornPlace, bornDate) => {
    const newBornDate = formattedDateWithOutTime(bornDate)
    const newTTL = `${bornPlace}, ${newBornDate}`

    return newTTL
}

export const getSeverity = (type, data) => {
    if (type === "teacherStatus") {
        switch (data) {
            case 'Berhenti': 
                return 'danger'

            case 'Aktif': 
                return 'success'

            case 'Pasif': 
                return 'info'
        
            default:
                return 'primary';
        }
    }

    if (type === "classType") {
        switch (data) {
            case 'Private':
                return 'warning'
            
            case 'Small': 
                return 'info'
            
            default:
                return 'secondary'
        }
    }

    if (type === "classStatus") {
        switch (data) {
            case "Belum terverifikasi":
              return "primary";
    
            case "Aktif":
              return "info";
            
            case "Selesai":
              return "success";
            
            case "Belum Diselesaikan": 
             return "warning";
            
            default:
                return "danger";
        }
    }

    if (type === "Salary") {
        switch (data) {
            case 'Terbayar':
                return 'success'
            
            case 'Diangsur':
                return 'warning'
            
            case 'Belum dibayar': 
                return 'danger'

            case 'Data Spesial':
                return 'primary'
        
            default:
                return 'info';
        }
    }

    if (type === "attendancePTC") {
        switch (data) {
            case "Hadir":
                return 'success'
            
            case "Belum Diabsen": 
                return 'info'
            
            case "Tidak Hadir":
                return 'danger'

            default:
                return 'info';
        }
    }

    if (type === "kelanjutan") {
        switch (data) {
            case "Berhenti":
                return 'danger';
            
            case "Lanjut": 
                return 'success';
            
            case "Lulus":
                return 'info';

            default:
                return 'warning';
        }
    }

    if (type === "teacherType") {
        switch (data) {
            case "Super Teacher":
                return "rgb(223, 223, 17)";
            
            case "Volunteer Teacher":
                return "var(--blue-400)";

            case "Premium Teacher":
                return "var(--green-700)";
                
            default:
                return "primary";
        }
    }

    if (type === "studentStatus") {
        switch(data) {
            case "ACTIVE" :
                return 'info';
        
            case "CANCEL":
                return 'danger';
        
            case "FINISH":
                return 'success';
        
            case "WAITING NEW":
                return 'warning';
            
            case "WAITING CONTINUE": 
                return 'warning';

            case "STOP": 
                return 'danger';

            case "NO RESPONSE":
                return 'help';
        
            default:
                return 'primary';
        }
    }

    if (type === "classRegistration") {
        switch(data) {
            case "ACTIVE" :
                return 'info';
        
            case "STOP":
                return 'danger';
        
            case "FINISH":
                return 'success';
        
            case "WAITING":
                return 'warning';
            
            case "MOVECLASS": 
                return 'danger';

            case "CANCEL":
                return "danger";
        
            default:
                return 'secondary';
        }
    }

    if (type === "paymentStatus") {
        switch(data) {
            case "PAID" :
                return 'success';
        
            case "UNPAID":
                return 'danger';
        
            case "INSTALLMENT":
                return 'warning';
        
            case "REFUND":
                return 'info';
            
            default:
                return 'info';
        }
    }

    if (type === "subject") {
        switch (data) {
            case "AFQ":
              return "success";
  
            case "Adab":
              return "warning";
  
            case "QT":
              return "success";
  
            case "IC Atas":
              return "info";
  
            case "IC Bawah":
              return "info";
  
            case "ICWS - W":
              return "danger";

            case "ICWS - ST":
              return "danger";
  
            case "IE":
              return "danger";
  
            case "IL & SoIP":
              return "warning";
  
            case "NI":
              return "info";
          
            default:
              return "primary";
        }
    }

    if (type === "roomStatus") {
        switch (data) {
            case 'Idle':
                return "success"
            case 'Maintenance':
                return "danger"
            default:
                return "warning";
        }
    }

    if (type === 'ageRange') {
        switch (data) {
            case '5 - 7':
                return 'warning';

            case '8 - 10':
                return 'success';

            case '11 - 13':
                return 'info';
            
            default:
                return 'danger';
        }
    }

    if (type === 'RR') {
        if (data < 65)
            return 'var(--red-500)';
        
        else if (data > 75) 
            return 'var(--green-500)';
        
        else
            return 'var(--yellow-300)';
    }
}

export const formatDecimal = (data) => {
    const newData = parseFloat(data).toFixed(2)
    return newData
}

export const formatIDRCurrency = (data) => {
    const newData = data.toLocaleString("id-ID", {style:"currency", currency:"IDR"})
    return newData
}

export const formatAccounting = (data) => {
    const newData = data.toLocaleString("id-ID", {style:"currency", currency:"IDR"})
    return (newData.slice(2))
}

export const formatDelimeter = (data) => {
    return data.toLocaleString();
}

export const isFormFieldValid = (meta) => !!(meta.touched && meta.error);

export const getFormErrorMessage = (meta) => {  
  return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
};

export const copyLink = (text, toast) => {
  navigator.clipboard.writeText(text);

  toast.current.show({
    severity: 'info',
    summary: 'Copied!',
    detail: "Success copied content to clipboard",
    life: 3000
  });
}

export const saveAsExcelFile = (buffer, fileName) => {
  import('file-saver').then((module) => {
    if (module && module.default) {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer], {
            type: EXCEL_TYPE
        });

        module.default.saveAs(data, fileName + EXCEL_EXTENSION);
    }
  });
};

export const generateMoodBooster = () => {
    return listComedyText[Math.floor(Math.random() * listComedyText.length)];
}

